import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import VisualRadioList from '@/atoms/forms/radio/VisualRadioList';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useDeckChangeHero from './hook';

type Props = {
  closeModal: () => void;
  deckId: string;
  faction: string;
};

const DeckChangeHeroModal = ({ closeModal, deckId, faction }: Props) => {
  const { handleSubmit, formMethods, options } = useDeckChangeHero({ deckId, faction, closeModal });
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={t('card')}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit}>
          {options && <VisualRadioList name="alterator" options={options} title={t('choose-your-hero')} />}
          <input className="hidden" type="submit" />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default DeckChangeHeroModal;
