import { useThrottledCallback } from '@react-hookz/web';
import { useState, ChangeEventHandler, useEffect } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { FormOptions } from 'types/forms';
import matchOptions from '../utils/matchOptions';

const useSearchSelect = (name: string, options: FormOptions, isMulti: boolean) => {
  const [term, setTerm] = useState('');
  const [matchedOptions, setMatchedOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<FormOptions>({});

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];
  const { field } = useController({
    name,
    control,
    ...(isMulti && { defaultValue: [] }),
  });

  const search = useThrottledCallback<(newTerm: string) => void>(
    (newTerm) => {
      setMatchedOptions(matchOptions(newTerm, options));
    },
    [options],
    100,
  );
  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event.target.value);
    search(event.target.value);
  };

  const addOrDeleteOption = (id: string) => {
    if (selectedOptions[id]) {
      const { [id]: toRemove, ...newSelection } = selectedOptions;

      return newSelection;
    }

    return { ...selectedOptions, [id]: options[id] };
  };
  const handleSelect = (id: string) => {
    if (!isMulti) {
      setSelectedOptions({ [id]: options[id] });
      field.onChange(id);
      setTerm('');
    } else {
      const newSelection = addOrDeleteOption(id);
      setSelectedOptions(newSelection);
      field.onChange(Object.keys(newSelection));
      setTerm('');
    }
  };

  useEffect(() => {
    if (Object.keys(options).length > 0) {
      // get default value
      if (Object.keys(selectedOptions).length === 0 && field.value) {
        setSelectedOptions(
          isMulti
            ? field.value.reduce((dict: FormOptions, id: string) => {
                return { ...dict, [id]: options[id] };
              }, {})
            : { [field.value]: options[field.value] },
        );
      }
    }
  }, [field.value, options]);

  useEffect(() => {
    // get default matched options
    if (Object.keys(options).length > 0) {
      search('');
    }
  }, [options]);

  return {
    selectedOptions,
    term,
    matchedOptions,
    error,
    handleSearch,
    handleSelect,
  };
};

export default useSearchSelect;
