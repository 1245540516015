import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputPassword from '@/atoms/forms/Input/Password';
import Select from '@/atoms/forms/Select';
import TextArea from '@/atoms/forms/TextArea';
import Form from '@/molecules/Form';
import useDeleteShop from 'context/Modal/views/DeleteShop/hooks';
import { ModalHeaderProps } from 'context/Modal/views/types';
import { useToaster } from 'context/Toaster/ToasterContext';
import { VARIANT_COLOR_ENUM } from 'types/utils';
import ModalClassicLayout from '../../layout/Classic';

const DeleteShop = ({ closeModal, title }: ModalHeaderProps) => {
  const { t } = useTranslation();
  const { addToaster } = useToaster();

  const onSuccess = () => {
    addToaster({ variant: VARIANT_COLOR_ENUM.SUCCESS, message: 'deletion-request-submitted' });
    closeModal();
  };
  const { onSubmit, formMethods, deletionStatusesOptions } = useDeleteShop({ onSuccess });

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title={title}>
      <p className="lg-bold text-center mb-1">{t('delete-shop-title')}</p>
      <p className="md-medium text-center">{t('delete-shop-text')}</p>
      <FormProvider {...formMethods}>
        <Form className="flex flex-col gap-4" onSubmit={onSubmit}>
          <InputPassword label="password" name="password" placeholder="password" />
          {deletionStatusesOptions && (
            <Select
              label="shop-deletion-statuses"
              name="shopDeletionStatus"
              options={deletionStatusesOptions}
              placeholder="shop-deletion-statuses"
            />
          )}
          <TextArea
            label="on-deletion-commentary"
            name="onDeletionCommentary"
            placeholder="on-deletion-commentary"
            rows={5}
          />
          <input className="hidden" type="submit" />
        </Form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default DeleteShop;
