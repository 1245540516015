import clx from 'classnames';
import { easeOut, motion } from 'framer-motion';
import { PropsWithChildren, ReactNode, useState } from 'react';

type Props = {
  title: ReactNode;
  isOpenByDefault?: boolean;
  id: string;
  count?: number | string;
  className?: string;
};

const VARIANTS = {
  visible: { height: 'auto', scaleY: '100%', transition: { duration: 0.1, ease: easeOut } },
  hidden: { height: 0, scaleY: 0, transition: { duration: 0.1, ease: easeOut } },
};

const Disclosure = ({ children, title, isOpenByDefault = false, id, count, className }: PropsWithChildren<Props>) => {
  const [opened, setOpened] = useState(isOpenByDefault);

  return (
    <div className={className} id={id}>
      <button
        className={clx(
          'flex flex-row items-center justify-between w-full group gap-4 text-left',
          count ? 'text-secondary-600 hover:text-secondary-400' : 'text-neutral-900 hover:text-neutral-600',
        )}
        onClick={() => setOpened(!opened)}
        type="button"
      >
        <p className="md-bold flex gap-1">
          {title}
          {count ? <span className="sm-bold">{`(${count})`}</span> : ''}
        </p>
        <i
          className={clx(
            'fa-solid',
            'text-neutral-500 group-hover:text-neutral-300 group-active:text-secondary-600 transition',
            opened ? 'fa-caret-up' : 'fa-caret-down',
          )}
        />
      </button>
      <motion.div animate={opened ? 'visible' : 'hidden'} className="origin-top overflow-hidden" variants={VARIANTS}>
        <div className="pt-3 lg-regular text-neutral-900">{children}</div>
      </motion.div>
    </div>
  );
};

export default Disclosure;
