import { useQuery } from '@tanstack/react-query';
import { ParsedQuery } from 'query-string';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const useEventsApi = (params: ParsedQuery, enabled = true) => {
  const eventsFactory = createKeyFactory([KEYS.EVENTS]);
  const queryKey = eventsFactory.list(params);
  const apiClient = useApi();

  const { data, isLoading, status } = useQuery({
    queryKey,
    queryFn: async () => {
      const apiData = await apiClient.events.apiEventsGetCollection(params);

      return { events: apiData.data['hydra:member'], totalItems: apiData.data['hydra:totalItems'] };
    },
    enabled,
  });

  const { events, totalItems } = data || {};

  return { events, totalItems, isLoading, queryKey, status };
};

export default useEventsApi;
