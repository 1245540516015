/* eslint-disable prefer-destructuring */
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import stringifyOptions from 'constants/stringifyOptions';
import { useModal } from 'context/Modal/Context';
import { MODAL_NAME } from 'context/Modal/MODAL_NAME';
import { QueryParametersType } from 'types/cms';
import { FilterDataDto } from 'types/utils';
import { useCmsData } from 'utils/cms';
import queryParamsChoicesToTabs from 'utils/cms/queryParamsChoicesToTabs';
import { useDecodeFilters, encodeFilters } from 'utils/filters/encodeDecodeFilters';
import convertToDotNotation, { NestedFormValues } from '../../../utils/filters/convertToDotNotation';
import { AvailableComponents } from './type';

type ReadParamsResult = { [key in AvailableComponents]?: QueryParametersType };

const readParams = (params: QueryParametersType[]): ReadParamsResult =>
  params.reduce(
    (dict, param) => (param.choicesSource ? { ...dict, [param.choicesSource[0].component]: param } : dict),
    {},
  );

const useFiltersBar = ({ filterData, onFilterSubmit }: { filterData?: FilterDataDto; onFilterSubmit?: () => void }) => {
  const { openModal, closeModal } = useModal();
  const {
    push,
    query: { slug, ...query },
  } = useRouter();
  const pathname = usePathname();
  const {
    acf: { queryParameters },
  } = useCmsData();
  const { searchBar, ...restCmsParams } = readParams(queryParameters);

  const tabsParams = queryParameters.find((param) => param.name === 'tabs');
  const tabs = tabsParams ? queryParamsChoicesToTabs(tabsParams, pathname) : [];
  const tabsKey = tabsParams?.choices ? tabsParams?.choices?.map((choice) => choice.choice) : [];

  const showModal = useMemo(() => queryParameters.some((param) => param.isDisplay === true), [queryParameters]);

  const filteredQueryParam = Object.keys(query).reduce((acc, queryKey) => {
    return {
      ...acc,
      ...(queryKey.toLowerCase().includes('order') ? {} : { [queryKey]: query[queryKey] }),
    };
  }, {});

  const optionsOrder = Object.keys(restCmsParams).reduce(
    (acc: { href: string; label: string; selected: boolean }[], currentKey) => {
      const cur = restCmsParams[currentKey as keyof typeof restCmsParams];
      if (!cur?.choices || !(cur?.choicesSource && cur?.choicesSource?.[0].component.includes('OrderBy'))) {
        return acc;
      }

      return cur.choices
        .map(({ label, value }) => {
          return {
            href: `${pathname}?${queryString.stringify({ [cur.name]: value, ...filteredQueryParam }, { ...stringifyOptions, arrayFormat: 'none' })}`,
            label,
            selected: !!query[cur.name] && query[cur.name] === value,
          };
        })
        .concat(acc);
    },
    [],
  );

  const currFilters = useDecodeFilters();
  const { page, ...restFilter } = currFilters;

  const formMethods = useForm<NestedFormValues>({
    mode: 'onSubmit',
    defaultValues: currFilters as NestedFormValues,
  });

  const onSubmit = (formValues: NestedFormValues) => {
    const tabsParam = Object.keys(query).reduce((acc, key) => {
      if (tabsKey.includes(key)) {
        return {
          ...acc,
          [key]: query[key],
        };
      }

      return acc;
    }, {});
    const { page: _pagination, ...denestedFormValues } = convertToDotNotation({ ...tabsParam, ...formValues });
    if (onFilterSubmit) {
      onFilterSubmit();
    } else {
      closeModal();
    }

    push(`${pathname}?${encodeFilters(denestedFormValues)}`);
  };

  const handleSubmit = formMethods.handleSubmit(onSubmit);

  const onFieldReset = (key: string) => {
    const newState = { ...(currFilters as NestedFormValues), [key]: Array.isArray(currFilters[key]) ? [] : '' };
    formMethods.reset(newState);
    handleSubmit();
  };

  const onClear = () => {
    formMethods.reset(
      Object.keys(currFilters).reduce((clear, key) => {
        return { ...clear, [key]: Array.isArray(currFilters[key]) ? [] : '' };
      }, {}),
    );
    push(pathname || '');
  };

  const handleOpenModal = useCallback(() => {
    openModal(MODAL_NAME.FILTERS, {
      onSubmit,
      onClear,
      formMethods,
      filterData,
    });
  }, [filterData]);

  const activeFilterCount = useMemo(
    () =>
      Object.keys(restFilter).reduce((acc, key) => {
        const current = restFilter[key];
        if (Array.isArray(current)) {
          return acc + current.length;
        }

        return acc + 1;
      }, 0),
    [restFilter],
  );

  return {
    openModal: handleOpenModal,
    formMethods,
    handleSubmit,
    onClear,
    searchBar,
    optionsOrder,
    activeFilterCount,
    onFieldReset,
    showModal,
    tabs,
  };
};

export default useFiltersBar;
