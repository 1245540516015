import * as Dialog from '@radix-ui/react-dialog';
import clx from 'classnames';
import { LayoutGroup, easeOut, motion } from 'framer-motion';
import OVERLAY_CLASSNAME from 'context/Modal/layout/const';
import { ModalProps } from 'context/Modal/views/types';

const MotionDialogOverlay = motion(Dialog.Overlay);
const MotionDialogContent = motion(Dialog.Content);

const ModalSimpleLayout = ({ closeModal, children }: ModalProps) => (
  <Dialog.Root onOpenChange={closeModal} defaultOpen>
    <Dialog.DialogPortal forceMount>
      <LayoutGroup>
        <MotionDialogOverlay
          animate={{
            backgroundColor: '#FBF7EB70',
            backdropFilter: 'blur(10px) opacity(1)',
            WebkitBackdropFilter: 'blur(10px) opacity(1)',
            opacity: 1,
            transition: { duration: 0.2, ease: easeOut },
          }}
          className={clx(OVERLAY_CLASSNAME, 'flex items-center justify-center bg-black/80')}
          exit={{
            backgroundColor: '#D4AF3700',
            backdropFilter: 'blur(10px) opacity(0)',
            WebkitBackdropFilter: 'blur(10px) opacity(0)',
            opacity: 0,
            transition: { duration: 0.2, ease: easeOut },
          }}
          initial={{ backgroundColor: '#D4AF3700', backdropFilter: 'blur(10px) opacity(0)' }}
          forceMount
        >
          <MotionDialogContent
            animate={{
              translateY: 0,
              transition: { duration: 0.2, ease: easeOut },
            }}
            exit={{
              translateY: '-0.75rem',
              transition: { duration: 0.2, ease: easeOut },
            }}
            initial={{ translateY: '-0.75rem' }}
            asChild
            forceMount
          >
            {children}
          </MotionDialogContent>
        </MotionDialogOverlay>
      </LayoutGroup>
    </Dialog.DialogPortal>
  </Dialog.Root>
);

export default ModalSimpleLayout;
