const SearchButton = () => (
  <button
    className="bg-neutral-500 hover:bg-neutral-700 focus:bg-neutral-600 transition clickable-lg text-neutral-0 sm-regular px-3 py-1.5"
    data-testid="search"
    type="submit"
  >
    <i className="fa-solid fa-search" />
  </button>
);
export default SearchButton;
