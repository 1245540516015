import { CardRegistrationPaymentCardJsonldPaymentPaymentCardRead } from '@/api/generated';
import { useModal } from 'context/Modal/Context';
import { MODAL_NAME } from 'context/Modal/MODAL_NAME';

const usePaymentList = (onChange: (value: CardRegistrationPaymentCardJsonldPaymentPaymentCardRead) => void) => {
  const { openModal, closeModal } = useModal();
  const openCreatePayment = () => {
    openModal(MODAL_NAME.CREATE_PAYMENT, {
      onChange: (v: CardRegistrationPaymentCardJsonldPaymentPaymentCardRead) => {
        onChange(v);
        closeModal();
      },
    });
  };

  return { openCreatePayment };
};

export default usePaymentList;
