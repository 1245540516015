import { CardJsonldCardDetailRead } from '@/api/generated';
import { CardDetailsAlias } from 'types/alias/types';
import { ILLUSTRATION_TYPE_API_REF, LORE_ENTRY_ELEMENT_TYPE_API_REF } from 'types/modifiers/references';

type LoreEntryLoreEntryElements = {
  id: string;
  reference: string;
  text: string;
  loreEntryElementType: {
    subject: string;
  };
};

export type LoreDetails = {
  flavorText: string;
  texts: LoreEntryLoreEntryElements[];
};

export type CardDetailDtoType = {
  card: CardDetailsAlias;
  illustrations?: Array<string>;
  lore?: LoreDetails;
};

const cardDetailsDto = (card: CardJsonldCardDetailRead): CardDetailDtoType => {
  const webAssets = card.assets?.[ILLUSTRATION_TYPE_API_REF.WEB] || [];

  return {
    // bad elements typing in generated.ts
    card: card as unknown as CardDetailsAlias,
    illustrations: Array.isArray(webAssets) ? webAssets : [webAssets],
    lore: card.loreEntries?.[0]
      ? card.loreEntries[0]?.loreEntryElements?.reduce(
          (acc: LoreDetails, loreEntryElements: any) => {
            return {
              ...acc,
              ...(loreEntryElements.loreEntryElementType.reference === LORE_ENTRY_ELEMENT_TYPE_API_REF.FLAVOR_TEXT
                ? { flavorText: loreEntryElements.text || null }
                : { texts: [...acc.texts, loreEntryElements] }),
            };
          },
          {
            flavorText: '',
            texts: [],
          },
        )
      : {
          flavorText: '',
          texts: [],
        },
  };
};

export default cardDetailsDto;
