import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import useApi from 'hooks/useApi';
import cardsFactory from 'utils/factories/card';
import printingFactory from 'utils/factories/printing';

type Props = {
  printingPage: string;
  cardId: string;
  closeModal: () => void;
};

const useAddCardToPageModal = ({ printingPage, cardId, closeModal }: Props) => {
  const api = useApi();
  const { query } = useRouter();
  const currentId = query.slug?.[1] || '';
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: cardsFactory.list({ printingPage, cardId }),
    queryFn: async () =>
      (
        await api.printingPages.apiPrintingPagesIdcardsReferenceGetCollection(
          printingPage.split('/').pop() || '',
          cardId.split('/').pop() || '',
        )
      ).data,
  });
  const card = data?.['hydra:member'][0]?.card;

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      quantity: 1,
    },
  });

  const addCard = useMutation({
    mutationKey: ['addCard'],
    mutationFn: ({ quantity }: { quantity: number }) =>
      api.printingPageCards.apiPrintingPageCardsPost({
        owner: data!['hydra:member'][0].owner!,
        quantity,
        printingPage,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: printingFactory.details(currentId) });
      closeModal();
    },
  });

  const onSubmit = ({ quantity }: { quantity: number }) => {
    addCard.mutate({ quantity });
  };

  return { card, formMethods, handleSubmit: formMethods.handleSubmit(onSubmit) };
};

export default useAddCardToPageModal;
