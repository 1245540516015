import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DonationPayload } from 'context/Modal/views/MarketplaceActions/Give/type';
import useNumberCopyAvailable from 'context/Modal/views/MarketplaceActions/useNumberCopyAvailable';
import useGiveOwnership from 'hooks/queries/ownership/useGiveOwnership';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';
import useCardDetailsApi from 'hooks/queries/cards/useCardsDetailsApi';

type FormValues = { quantity: number; user: string };
const ownershipFactory = createKeyFactory([KEYS.OWNERSHIPS]);

const useGive = ({ closeModal, reference }: { closeModal: () => void; reference: string }) => {
  const { data } = useCardDetailsApi(reference);
  const { numberCopyAvailable = 0 } = useNumberCopyAvailable(reference);
  const queryClient = useQueryClient();
  const { push } = useRouter();

  const schema = () =>
    yup
      .object({
        quantity: yup.number().min(1).max(numberCopyAvailable).required(),
        user: yup.string().required(),
      })
      .required();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      quantity: 1,
    },
    resolver: yupResolver(schema()),
  });

  const { handleSubmit } = formMethods;

  const onSuccess = async (payload?: DonationPayload) => {
    if (!payload || !payload.ownerHistoryGroup) {
      return;
    }
    push('card-exchange'.concat(payload ? `/${payload.ownerHistoryGroup.split('/')[2]}` : ''));
    await queryClient.invalidateQueries({
      queryKey: ownershipFactory.all(),
    });
    closeModal();
  };

  const onError = () => {
    closeModal();
    push('card-exchange');
  };

  const { giveOwnership } = useGiveOwnership(onSuccess, onError);

  const onSubmit = (formValues: FormValues) => {
    giveOwnership.mutate({
      ...formValues,
      user: formValues.user,
      cards: [{ reference, quantity: formValues.quantity }],
    });
  };

  return {
    formMethods,
    onSubmit: handleSubmit(onSubmit),
    closeModal,
    numberCopyAvailable,
    card: data?.card,
  };
};

export default useGive;
