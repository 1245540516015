import { parseISO, subDays } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { FormProvider } from 'react-hook-form';
import { EventJsonldEventRead } from '@/api/generated';
import SearchSelect from '@/atoms/forms/Select/SearchSelect';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useDateFnsWithLocale from 'hooks/i18n/useDateFnsWithLocale';
import useDecksRegistrationModal from './hooks';

const DecksRegistrationModal = ({ event, closeModal }: { event: EventJsonldEventRead; closeModal: () => void }) => {
  const { t } = useTranslation();
  const { decksOptions, formMethods, onSubmit } = useDecksRegistrationModal(closeModal, event);
  const { format } = useDateFnsWithLocale();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="decks-to-print">
      <FormProvider {...formMethods}>
        <form className="py-8" name="deck-registration" onSubmit={onSubmit}>
          <p className="mb-8">
            {t('deck-registration-explanation', {
              date: event.dateTime ? format(subDays(parseISO(event.dateTime as unknown as string), 1), 'Pp') : '???',
            })}
          </p>
          {decksOptions ? (
            <SearchSelect
              label={t('select-up-to', { count: event.deckUserMax || 0 })}
              name="decks"
              options={decksOptions}
              isMulti
            />
          ) : (
            <i className="fa-solid fa-circle-notch animate-spin text-primary-300" />
          )}
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default DecksRegistrationModal;
