import ListItemFooter, { ListItemFooterProps } from '@/molecules/ListItem/ListItemFooter';
import ListItemHeader, { ListItemHeaderProps } from '@/molecules/ListItem/ListItemHeader';

export type ProductElementType = {
  headerProps: ListItemHeaderProps;
  footerProps?: ListItemFooterProps;
  className?: string;
};

const ListItem = ({ headerProps, footerProps, ...rest }: ProductElementType) => (
  <div {...rest}>
    <ListItemHeader {...headerProps} />
    {footerProps && <ListItemFooter {...footerProps} />}
  </div>
);
export default ListItem;
