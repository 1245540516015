import { useQuery } from '@tanstack/react-query';
import cardDetailsDto from 'hooks/queries/cards/useCardsDetailsApi/dto';
import useApi from 'hooks/useApi';
import cardsFactory from 'utils/factories/card';

const useCardDetailsApi = (reference: string, enabled: boolean = true) => {
  const apiClient = useApi();

  const queryKey = cardsFactory.details(reference);
  const { data, status, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await apiClient.cards.apiCardsReferenceGet(reference);

      return cardDetailsDto(res.data);
    },
    enabled,
  });

  const { card, lore, illustrations } = data || {};

  return { data, card, lore, illustrations, status, isLoading, queryKey };
};

export default useCardDetailsApi;
