import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useModal } from 'context/Modal/Context';
import useApi from 'hooks/useApi';
import { VisualOptions } from 'types/forms';
import cardsFactory from 'utils/factories/card';
import printingFactory from 'utils/factories/printing';

const printingSchema = () =>
  yup
    .object({
      owner: yup.string().required(),
    })
    .required();

type FormValues = yup.InferType<ReturnType<typeof printingSchema>>;

const useAddCardAlt = ({ printingPage, cardId }: { printingPage: string; cardId: string }) => {
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const { closeModal } = useModal();
  const { query } = useRouter();
  const currentId = query.slug?.[1] || '';

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(printingSchema()),
  });

  const { data } = useQuery({
    queryKey: cardsFactory.list({ printingPage, cardId }),
    queryFn: async () =>
      (
        await api.printingPages.apiPrintingPagesIdcardsReferenceGetCollection(
          printingPage.split('/').pop() || '',
          cardId.split('/').pop() || '',
        )
      ).data,
  });

  const addCard = useMutation({
    mutationKey: ['addCard'],
    mutationFn: ({ owner }: { owner: string }) =>
      api.printingPageCards.apiPrintingPageCardsPost({
        owner,
        quantity: 1,
        printingPage,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: printingFactory.details(currentId) });
      closeModal();
    },
  });

  const handleSubmit = ({ owner }: FormValues) => {
    addCard.mutate({ owner });
  };

  const options =
    data?.['hydra:member']?.reduce((acc: VisualOptions, { card, disponibility, date, owner }) => {
      return {
        ...acc,
        [owner || '']: {
          image: card?.imagePath || '',
          disabled: !disponibility,
          label: card?.cardProduct?.name || '',
          supportingText:
            !disponibility && date
              ? t('unavailable-until', {
                  date: format(date?.toString() || '', 'dd/MM'),
                })
              : t('available'),
        },
      };
    }, {}) || {};

  return { handleSubmit: formMethods.handleSubmit(handleSubmit), formMethods, options };
};

export default useAddCardAlt;
