import { StringifyOptions } from 'query-string';

const stringifyOptions: StringifyOptions = {
  arrayFormat: 'bracket',
  encode: false,
  skipEmptyString: true,
  skipNull: true,
};

export default stringifyOptions;
