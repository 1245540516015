import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import VisualRadioList from '@/atoms/forms/radio/VisualRadioList';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useAddCardAlt from './hook';

type Props = {
  closeModal: () => void;
  cardId: string;
  printingPage: string;
};

const AddCardAltModal = ({ closeModal, cardId, printingPage }: Props) => {
  const { handleSubmit, formMethods, options } = useAddCardAlt({ cardId, printingPage });
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={t('card')}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit}>
          <VisualRadioList
            name="owner"
            options={options}
            supportingText={t('witch-one-to-print')}
            title={t('you-have-multiple-version')}
          />
          <input className="hidden" type="submit" />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default AddCardAltModal;
