import { useRouter } from 'next/router';
import queryString, { ParsedQuery } from 'query-string';
import stringifyOptions from 'constants/stringifyOptions';

export const encodeFilters = (formValues: ParsedQuery) => queryString.stringify(formValues, stringifyOptions);

export const useDecodeFilters = () => {
  const { asPath } = useRouter();

  return queryString.parse(asPath.split('#')[0].split('?')[1] || '', stringifyOptions);
};
