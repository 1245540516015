import { isAfter, parseISO } from 'date-fns';
import { TFunction } from 'next-i18next';
import { EventJsonldEventLocatorReadShopLocatorRead, EventJsonldEventRead } from '@/api/generated';

import { VARIANT_COLOR_ENUM, SIZE } from 'types/utils';
import getRemainingPlace from 'utils/events/getRemainingPlace';

export const computeStatusChip = (
  event: EventJsonldEventRead | EventJsonldEventLocatorReadShopLocatorRead,
  t: TFunction,
) => {
  if (!event.dateTime) {
    throw new Error(`no dateTime for event ${event['@id']}`);
  }
  const isPassed = !isAfter(parseISO(event.dateTime), new Date());
  const isFull = !Math.max(getRemainingPlace(event), 0);

  if (isFull) {
    return {
      children: t('full'),
      color: VARIANT_COLOR_ENUM.ERROR,
      size: SIZE.SM,
    };
  }

  if (isPassed) {
    return {
      children: t('ended'),
      color: VARIANT_COLOR_ENUM.PRIMARY,
      size: SIZE.SM,
    };
  }

  return {
    children: `${getRemainingPlace(event)} ${t('available', { count: getRemainingPlace(event) })}`,
    color: VARIANT_COLOR_ENUM.INFO,
    size: SIZE.SM,
  };
};

export default computeStatusChip;
