import { useTranslation } from 'next-i18next';
import ModalClassicLayout from '../../layout/Classic';

type ConfirmDeckDeletionProps = {
  onSubmit: () => void;
  closeModal: () => void;
  deckName: string;
};
const ConfirmDeckDeletion = ({ onSubmit, closeModal, deckName }: ConfirmDeckDeletionProps) => {
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="delete-a-deck">
      <div className="flex flex-col pb-4">{t('delete-a-deck-description', { deckName })}</div>
    </ModalClassicLayout>
  );
};
export default ConfirmDeckDeletion;
