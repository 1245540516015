import { BUTTON_VARIANTS } from '@/atoms/Button/types';
import { SIZE } from 'types/utils';

export const COMMON_CLASS =
  'hover:rounded-tl hover:rounded-br transition-all duration-quicker ease-out clickable flex items-center whitespace-nowrap justify-center font-sans';

export const VARIANT_TO_CLASS = {
  [BUTTON_VARIANTS.PRIMARY]: `bg-primary-500 text-neutral-900 shadow-raised
  hover:bg-primary-300 active:bg-primary-600 
  disabled:bg-system-disabled-300 disabled:text-system-disabled-400`,
  [BUTTON_VARIANTS.SECONDARY]: `border border-primary-500 text-neutral-900
    hover:text-primary-300 hover:border-primary-300
    active:border-primary-600 active:text-primary-600
    disabled:border-system-disabled-300 disabled:text-system-disabled-300`,
  [BUTTON_VARIANTS.TERTIARY]:
    'bg-neutral-30 hover:bg-neutral-20 active:bg-neutral-40 text-neutral-900 hover:text-neutral-400 disabled:text-system-disabled-300 disabled:bg-disabled-50',
};

export const SIZE_TO_CLASS = {
  [SIZE.SM]: 'p-2 sm-bold-caps gap-x-2',
  [SIZE.MD]: 'py-3 px-4 sm-bold-caps gap-x-4',
  [SIZE.LG]: 'p-4 md-bold-caps gap-x-4',
};
