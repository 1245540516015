import clx from 'classnames';
import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import FactionIcon from '@/atoms/FactionIcon';
import { BaseProps } from '../../types';

type FactionChipProps = InputHTMLAttributes<HTMLInputElement> &
  BaseProps & {
    checked: boolean;
    value: string;
    isInvalid?: boolean;
    factionColor: string;
    factionReference: string;
  };

const FactionChip = forwardRef(
  (
    { name, onChange, label, id, value, checked, isInvalid, factionColor, factionReference }: FactionChipProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <>
      <input
        ref={ref}
        checked={checked}
        className="appearance-none hidden peer"
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <label htmlFor={id}>
        <div
          className={clx(
            'transition p-4 flex bg-neutral-50 peer-disabled:bg-system-disabled-300 peer-disabled:text-system-disabled-400 rounded-2xl',
            isInvalid && 'text-system-error-400 bg-system-error-300 border-system-error-400 border-[1px]',
            checked && 'text-neutral-0',
          )}
          style={{
            backgroundColor: checked ? factionColor : undefined,
          }}
        >
          <FactionIcon className={clx('text-2xl')} name={factionReference} />
        </div>
        <div
          className={clx(
            'transition text-center mt-1 xs-bold-caps',
            'peer-disabled:text-system-disabled-400',
            isInvalid && 'text-system-error-400',
            checked ? 'text-neutral-900' : 'text-neutral-500',
          )}
        >
          {label}
        </div>
      </label>
    </>
  ),
);

FactionChip.displayName = 'FactionChip';

export default FactionChip;
