import {
  HttpResponse,
  OwnershipListDataJsonldOwnershipListRead,
  OwnershipListDataJsonldOwnershipListWrite,
} from '@/api/generated';
import { useMutation } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
import { OwnershipListsName } from 'hooks/useOwnershipListsMutation/types';

const useOwnershipListsMutation = (
  name: OwnershipListsName,
  { onSuccess }: { onSuccess?: (data: HttpResponse<OwnershipListDataJsonldOwnershipListRead, void>) => void } = {},
) => {
  const apiClient = useApi();

  return useMutation({
    mutationKey: ['ownership-lists', { name }],
    mutationFn: (data: OwnershipListDataJsonldOwnershipListWrite) =>
      apiClient.ownershipLists.apiOwnershipListsListNamePut(name, data),

    onSuccess: (v) => {
      if (onSuccess) {
        onSuccess(v);
      }
    },
  });
};

export default useOwnershipListsMutation;
