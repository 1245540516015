import { useModal } from 'context/Modal/Context';
import { MODAL_NAME } from 'context/Modal/MODAL_NAME';
import useCardsApi from 'hooks/queries/cards/useCardsApi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { NestedFormValues } from 'utils/filters/convertToDotNotation';
import { getDirtyValues } from 'utils/form/getDirtyValues';

type Payload = Array<{ card: string; quantity: number }>;
type Props = {
  onSubmit: (p: Payload) => void;
};
const useCardTradeListModal = ({ onSubmit }: Props) => {
  const { openModal } = useModal();

  const { cards, totalItems } = useCardsApi({ collection: 'true' });

  const formMethods = useForm<NestedFormValues>({
    mode: 'onSubmit',
  });

  const { reset } = formMethods;
  const {
    formState: { dirtyFields },
  } = formMethods;
  const onFilterSubmit = () => {
    openModal(MODAL_NAME.PICK_COLLECTIONS_CARDS, { onSubmit });
  };

  const handleSubmit = (value: NestedFormValues) => {
    const values: any = getDirtyValues(dirtyFields, value);
    const formatted = Object.keys(values).reduce(
      (acc: Payload, cardIri) => [
        ...acc,
        {
          card: cardIri,
          quantity: values[cardIri],
        },
      ],
      [],
    );

    onSubmit(formatted);
  };

  useEffect(() => {
    if (!formMethods.formState.defaultValues && cards) {
      const formated = cards.reduce((acc, curr) => {
        return { ...acc, [curr['@id'] || '']: curr.inMyTradelist };
      }, {});

      reset(formated);
    }
  }, [cards]);

  return { cards, onFilterSubmit, formMethods, handleSubmit: formMethods.handleSubmit(handleSubmit), totalItems };
};

export default useCardTradeListModal;
