import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CounterInput from '@/atoms/forms/Counter';
import Spinner from '@/atoms/Spinner';
import CardThumb from '@/molecules/CardThumb';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import { SIZE } from 'types/utils';
import useAddCardToPageModal from './hook';

type Props = {
  printingPage: string;
  cardId: string;
  closeModal: () => void;
};

const AddCardToPage = ({ printingPage, cardId, closeModal }: Props) => {
  const { formMethods, handleSubmit, card } = useAddCardToPageModal({ printingPage, cardId, closeModal });
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={t('card')}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit}>
          {!card && <Spinner size={SIZE.MD} />}
          <div className="max-w-42 mx-auto">
            <CardThumb alt="" className="mb-4" imagePath={card?.imagePath || ''} />
            <CounterInput label="quantity" name="quantity" />
          </div>
          <input className="hidden" type="submit" />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default AddCardToPage;
