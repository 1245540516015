import { useRouter } from 'next/router';
import { TabsProps } from '@/molecules/Tabs/TabItem/types';
import queryString from 'query-string';

const useTabsLink = ({ items }: TabsProps<string>) => {
  const {
    query: { slug, ...query },
  } = useRouter();
  // keep query params other than one in items
  const itemsParams = items.reduce((acc: string[], item) => {
    const param = item.href?.split('?')[1];
    if (!param) {
      return acc;
    }

    return [...acc, param.split('=')[0]];
  }, []);

  const cleanedQuery = Object.keys(query).reduce((acc, key) => {
    if (itemsParams.includes(key) || key === 'page') {
      return acc;
    }

    return { ...acc, [key]: query[key] };
  }, {});

  const itemsWithParam = items.map((item) => {
    const parsed = item.href && queryString.parseUrl(item.href);
    const href =
      parsed &&
      queryString.stringifyUrl({ url: parsed.url, query: { ...cleanedQuery, ...parsed.query } }, { encode: false });

    return { ...item, href };
  });

  return { itemsWithParam };
};

export default useTabsLink;
