import { PropsWithChildren } from 'react';
import Disclosure from '@/molecules/Disclosure';

type DisclosureComponentProps = {
  title: string;
  activeFilters: number;
  id: string;
};

const DisclosureComponent = ({ children, title, activeFilters, id }: PropsWithChildren<DisclosureComponentProps>) => (
  <li>
    <Disclosure
      count={activeFilters}
      id={id}
      title={<div className="flex justify-between items-center gap-3">{title}</div>}
    >
      <div className="flex flex-col gap-2">{children}</div>
    </Disclosure>
  </li>
);

export default DisclosureComponent;
