import clx from 'classnames';
import Chip from '@/atoms/Chip';
import { TileHeaderProps } from '@/molecules/Tile/components/header/types';

const TileHeader = ({ image, chips, title, withDivider, icon, subTitle }: TileHeaderProps) => (
  <div className={clx('bg-neutral-20 overflow-auto rounded-t-lg ')}>
    {image && (
      <div className="overflow-hidden">
        <div
          className="h-40 w-full bg-cover bg-center group-hover:scale-[102.5%] transition-transform duration-quick ease-in-out"
          style={{ backgroundImage: `url(${image})` }}
        />
      </div>
    )}
    <div className="px-3 pt-2 flex flex-col gap-1">
      {chips && (
        <ul className={clx('flex flex-wrap gap-x-4 gap-y-1')}>
          {chips.map((chip) => (
            <li key={chip.children?.toString()}>
              <Chip {...chip} />
            </li>
          ))}
        </ul>
      )}
      {subTitle && <p className="text-neutral-400 md-medium">{subTitle}</p>}
      <div className={clx('flex justify-between', withDivider && 'border-b-2 border-b-neutral-30')}>
        <p className="title-5 text-ellipsis text-neutral-900">{title}</p>
        {icon && <i className={clx(icon, 'text-xl lg:text-2xl text-neutral-200')} />}
      </div>
    </div>
  </div>
);

export default TileHeader;
