import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { ParsedQuery } from 'query-string';

import { CARDS_PER_PAGE } from 'constants/itemsPerPage';
import useApi from 'hooks/useApi';
import { CardAlias } from 'types/alias/types';
import cardsFactory from 'utils/factories/card';

const useCardsApi = (filters?: ParsedQuery, enabled = true) => {
  const apiClient = useApi();
  const {
    query: { slug, page, ...query },
  } = useRouter();

  const queryKey = cardsFactory.list({
    ...(filters || {}),
    ...query,
    ...(!!page && { page }),
    itemsPerPage: CARDS_PER_PAGE.toString(),
  });
  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await apiClient.cards.apiCardsGetCollection({
        ...(filters || {}),
        ...query,
        ...(!!page && { page: Number(page) }),
        itemsPerPage: CARDS_PER_PAGE,
      });

      return res.data;
    },
    enabled,
  });

  return {
    cards: data?.['hydra:member'] as CardAlias[],
    totalItems: data?.['hydra:totalItems'],
    isLoading,
    isFetching,
  };
};
export default useCardsApi;
