import clx from 'classnames';
import { ReactNode } from 'react';
import { SIZE, VARIANT_COLOR_ENUM } from 'types/utils';

enum COLOR_TO_TEXT_COLOR {
  NEUTRAL = 'bg-neutral-40 text-neutral-600 ',
  PRIMARY = 'bg-primary-500 text-neutral-0',
  ERROR = 'bg-system-error-400 text-neutral-0',
  SUCCESS = 'bg-system-success-400 text-neutral-0',
  DISABLED = 'bg-system-disabled-400 text-neutral-0',
  INFO = 'bg-system-info-400 text-neutral-0',
  SECONDARY = 'bg-neutral-600 text-neutral-0',
}

const SIZE_TO_CSS = {
  [SIZE.SM]: 'px-2 xs-bold-caps py-0.5',
  [SIZE.MD]: 'px-2.5 md-bold-caps py-0.5',
  [SIZE.LG]: 'px-3 lg-bold-caps py-1',
};

export type ChipProps = {
  children: ReactNode;
  color?: VARIANT_COLOR_ENUM;
  size?: SIZE;
  className?: string;
};

const Chip = ({ children, color = VARIANT_COLOR_ENUM.PRIMARY, size = SIZE.SM, className }: ChipProps) => (
  <p
    className={clx(
      'w-fit rounded-full flex items-center font-sans whitespace-nowrap',
      SIZE_TO_CSS[size],
      COLOR_TO_TEXT_COLOR[color],
      className,
    )}
  >
    {children}
  </p>
);

export default Chip;
