import { useTranslation } from 'next-i18next';
import { FormProvider } from 'react-hook-form';
import { MarketplaceOfferCardOfferDataJsonldCardOfferDataRead } from '@/api/generated';
import TextInput from '@/atoms/forms/Input';
import ShowRarity from '@/atoms/ShowRarity';
import Spinner from '@/atoms/Spinner';
import CardThumb from '@/molecules/CardThumb';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useCardDetailsApi from 'hooks/queries/cards/useCardsDetailsApi';
import { CardDetailsAlias } from 'types/alias/types';
import { SIZE } from 'types/utils';
import useManageOffer from './hooks';

const ManageOfferContent = ({
  closeModal,
  card,
  cardOffer,
}: {
  closeModal: () => void;
  card: CardDetailsAlias;
  cardOffer?: MarketplaceOfferCardOfferDataJsonldCardOfferDataRead;
}) => {
  const { t } = useTranslation();
  const { formMethods, onSubmit, total, numberCopyAvailable } = useManageOffer({
    closeModal,
    card,
    cardOffer,
  });

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title={cardOffer ? 'edit-offer' : 'sell-copy'}>
      <FormProvider {...formMethods}>
        <form className="py-8" name="manage-offers" onSubmit={onSubmit}>
          <div className="grid grid-rows-2 grid-flow-col auto-cols-max gap-4">
            <div className="w-[114px] m-auto md:m-0 row-span-2">
              <CardThumb alt="" imagePath={card.imagePath} size={SIZE.MD} />
            </div>
            <p>
              <span className="md-bold text-neutral-900 uppercase">{card.name}</span>
              {card.rarity && <ShowRarity {...card.rarity} />}
            </p>
            <div className="flex flex-col md:flex-row md:items-center gap-1 text-neutral-700">
              <TextInput disabled={!!cardOffer} max={numberCopyAvailable} min={1} name="quantity" type="number" />
              <p className="flex gap-2 items-center">
                <span className="md-bold whitespace-nowrap">{`/ ${numberCopyAvailable}`}</span>
                {t('available', { count: numberCopyAvailable })}
              </p>
            </div>
          </div>
          <p className="my-4 text-neutral-700">{t('price')}</p>
          <TextInput name="price" step=".01" type="number" />
          <div className="border-t-2 border-neutral-300 mt-6 py-4 grid grid-cols-2">
            <p className="text-neutral-700">{t('total')}</p>
            <p className="text-neutral-900 md-bold justify-self-end">{`${total || 0}€`}</p>
          </div>
          <input className="hidden" type="submit" />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

const ManageOffer = ({
  reference,
  closeModal,
  cardOffer,
}: {
  reference: string;
  closeModal: () => void;
  cardOffer?: MarketplaceOfferCardOfferDataJsonldCardOfferDataRead;
}) => {
  const { card, isLoading } = useCardDetailsApi(reference);

  if (isLoading || !card) {
    return (
      <ModalClassicLayout closeModal={closeModal} title={cardOffer ? 'edit-offer' : 'sell-copy'}>
        <Spinner size={SIZE.LG} />
      </ModalClassicLayout>
    );
  }

  return <ManageOfferContent card={card} cardOffer={cardOffer} closeModal={closeModal} />;
};

export default ManageOffer;
