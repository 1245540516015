import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { addressSchema, AddressFormValues } from 'constants/schemas/addressSchema';
import useDetailAddress from 'hooks/queries/address/useDetailAddress';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const useAddressCreateModal = ({ closeModal, addressId }: { closeModal: () => void; addressId: string }) => {
  const client = useQueryClient();
  const apiClient = useApi();

  const formMethods = useForm<AddressFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(addressSchema()),
  });
  const { handleSubmit, reset } = formMethods;
  const { data, isLoading } = useDetailAddress({ id: addressId });
  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const addressFactory = createKeyFactory([KEYS.ADDRESSES]);

  const create = useMutation({
    mutationKey: ['createAddress'],
    mutationFn: async (values: AddressFormValues) => apiClient.addresses.apiAddressesIdPut(data?.id || '', values),
    onSuccess: async (res) => {
      if (res?.data) {
        await client.invalidateQueries({ queryKey: addressFactory.list() });
      }
      closeModal();
    },
  });
  const onSubmit = (values: AddressFormValues) => create.mutateAsync(values);

  return { formMethods, onSubmit: handleSubmit(onSubmit), isLoading };
};

export default useAddressCreateModal;
