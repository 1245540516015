/**
 * This file is used to communicate with the webview in the companion mobile app.
 * See corresponding file for more details in https://gitlab.com/equinox-ccg/altered-mobile.
 */

import { QrCodeCardJsonldQrCodeRead } from '@/api/generated';
import { StartScanParams } from '../../types/multiscan';

const isDebug = process.env.NODE_ENV === 'development';

// Webview initialized checked, if you wanna debug it in browser, just return true
export function isInAppWebview() {
  return typeof window !== 'undefined' && window.ReactNativeWebView;
}

function sendEvent(type: string, data: unknown = null) {
  if (!isInAppWebview()) {
    console.error('Webview is not detected or initialized.');

    return;
  }

  if (isDebug) {
    // eslint-disable-next-line no-console
    console.log('Sending event to webview:', type, data);
  }

  window.ReactNativeWebView?.postMessage(JSON.stringify({ type, data }));
}

// Specific events
export function startScan(startScanParams: StartScanParams) {
  sendEvent('scan', startScanParams);
}

export function closeScan() {
  sendEvent('scanClose');
}

export function onCardLoad(id: string, card: QrCodeCardJsonldQrCodeRead) {
  sendEvent('cardLoad', { id, data: card });
}

export function onCardLoadError(id: string, error: string) {
  sendEvent('cardLoad', { id, error });
}

export function onLogin() {
  sendEvent('login');
}

export function onLogout() {
  sendEvent('logout');
}

// Listeners
export function onScan(callback: (hashs: string[]) => void) {
  if (window) {
    window.onNewQrCodeScan = callback;
  }

  return () => {
    if (window) {
      window.onNewQrCodeScan = undefined;
    }
  };
}
export function submitScan(callback: () => void) {
  if (window) {
    window.onSubmitScan = callback;
  }

  return () => {
    if (window) {
      window.onSubmitScan = undefined;
    }
  };
}

export function onDeviceRegistered(callback: (token: string) => void) {
  if (window) {
    window.onNewDeviceToken = callback;
  }

  return () => {
    if (window) {
      window.onNewDeviceToken = undefined;
    }
  };
}

export function onStopProgress(callback: () => void) {
  if (window) {
    window.onStopProgress = callback;
  }

  return () => {
    if (window) {
      window.onStopProgress = undefined;
    }
  };
}

export default sendEvent;
