import { SIZE } from 'types/utils';

const BigDivider = ({ size = SIZE.LG }: { size?: SIZE }) => (
  <picture className="mx-auto">
    {size === SIZE.LG && <source media="(min-width: 768px)" srcSet="/big-divider-desktop.png" />}
    <img alt="" src="/big-divider-mobile.png" />
  </picture>
);

export default BigDivider;
