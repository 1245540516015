import CardThumb from '@/molecules/CardThumb';
import ListHeader from '@/organisms/ListHeader';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useCardsFilterData from 'hooks/queries/cards/useCardsFilterData';
import useCardListModal from './hooks';

type Props = { closeModal: () => void; printingPage: string };

const CardList = ({ closeModal, printingPage }: Props) => {
  const { data, handleCardClick, onFilterSubmit } = useCardListModal({ printingPage });
  const { filterData } = useCardsFilterData();

  return (
    <ModalClassicLayout closeModal={closeModal} title="Cards">
      <ListHeader
        filterData={filterData}
        loading={false}
        onFilterSubmit={onFilterSubmit}
        totalItems={data.totalItems}
      />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-end">
        {data.cards?.map(({ imagePath, inMyCollection, '@id': id, name, cardProduct }) => (
          <button key={id} onClick={() => handleCardClick(id, cardProduct?.reference)} type="button">
            <p className="title-6 pb-2">{name}</p>
            <CardThumb alt="" imagePath={imagePath} inMyCollection={inMyCollection} />
          </button>
        ))}
      </div>
    </ModalClassicLayout>
  );
};

export default CardList;
