import { useQuery } from '@tanstack/react-query';
import { ParsedQuery } from 'query-string';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const paymentMethodsFactory = createKeyFactory([KEYS.PAYMENT_METHODS]);

const useUserPaymentMethods = (params?: ParsedQuery) => {
  const apiClient = useApi();

  const { data, isLoading } = useQuery({
    queryKey: paymentMethodsFactory.list({ 'order[createdAt]': 'asc' }),
    queryFn: async () => apiClient.payments.apiPaymentspaymentCardsGetCollection(params),
  });

  const defaultPaymentMethod = data?.data['hydra:member'].find((paymentMethod) => paymentMethod.active);

  return { paymentMethods: data?.data['hydra:member'], defaultPaymentMethod, isLoading };
};

export default useUserPaymentMethods;
