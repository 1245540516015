import * as Toast from '@radix-ui/react-toast';
import { AnimatePresence } from 'framer-motion';
import { useState, createContext, useContext, useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TOASTER_DELAY } from './consts';
import ToastView from './ToastView';
import { ActiveToast, ToasterContextType, ToastParams } from './types';

const initalState: ToasterContextType = {
  addToaster: () => {},
};

const ToasterContext = createContext(initalState);

export const useToaster = () => useContext(ToasterContext);

export const ToasterProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeToaster, setActiveToaster] = useState<ActiveToast | null>(null);

  const addToaster = useCallback((params: ToastParams) => {
    const newToaster: ActiveToast = {
      ...params,
      id: uuidv4(),
    };
    setActiveToaster(newToaster);
  }, []);

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setActiveToaster(null);
    }
  };

  const providerValue = useMemo(() => {
    return { addToaster };
  }, [addToaster]);

  return (
    <ToasterContext.Provider value={providerValue}>
      <Toast.Provider duration={TOASTER_DELAY} swipeDirection={undefined}>
        {children}
        <AnimatePresence mode="wait">
          {activeToaster && <ToastView key={activeToaster.id} onOpenChange={handleOpenChange} toast={activeToaster} />}
        </AnimatePresence>

        <Toast.Viewport className="fixed z-[100] top-7 translate-x-[50%] right-[50%] md:max-w-[80%] w-full md:w-auto px-4 md:px-0 max-w-full" />
      </Toast.Provider>
    </ToasterContext.Provider>
  );
};
