import { useKeyboardEvent } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { ApiClient } from '@/api/client';
import useDeckEdition from 'components/template/Decks/details/hooks';
import useCardDetailsApi from 'hooks/queries/cards/useCardsDetailsApi';
import cardsFactory from 'utils/factories/card';
import deckFactory from 'utils/factories/decks';

type CacheResult = Awaited<ReturnType<ApiClient['cards']['apiCardsGetCollection']>>['data'];
type DeckCacheResult = ReturnType<typeof useDeckEdition>;

const useZoom = (cardRef: string) => {
  const client = useQueryClient();
  const result = client.getQueriesData<CacheResult>({
    queryKey: cardsFactory.list(),
    type: 'active',
  })[0];
  /**
   * Handle the case where we are in the deck viewer
   */
  const deckResult = client.getQueriesData<{ data: DeckCacheResult['deck'] }>({
    queryKey: deckFactory.all(),
    type: 'active',
    exact: false,
  })[0];
  const [, cardsHydra] = result || [];
  const [, deckData] = deckResult || [];
  const cards =
    cardsHydra?.['hydra:member'] ??
    [
      ...(deckData?.data?.alterator ? [{ card: deckData?.data?.alterator }] : []),
      ...(deckData?.data?.deckCardsByType?.character?.deckUserListCard || []),
      ...(deckData?.data?.deckCardsByType?.permanent?.deckUserListCard || []),
      ...(deckData?.data?.deckCardsByType?.spell?.deckUserListCard || []),
    ].map(({ card }) => card);

  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    cards?.findIndex((card) => card?.reference === cardRef),
  );
  const [initialCurrentIndex] = useState<number | undefined>(cards?.findIndex((card) => card?.reference === cardRef));

  const handleNext = () => {
    if (typeof currentIndex !== 'undefined' && currentIndex + 1 < (cards?.length || 0)) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (typeof currentIndex !== 'undefined' && 0 < currentIndex) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const swipeHandler = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  useKeyboardEvent(
    true,
    (ev: KeyboardEvent) => {
      if (ev.code === 'ArrowRight') {
        handleNext();
      }
      if (ev.code === 'ArrowLeft') {
        handlePrev();
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  const { card } = useCardDetailsApi(cardRef);

  return {
    handleNext,
    handlePrev,
    card: typeof currentIndex !== 'undefined' && currentIndex !== -1 && cards ? cards[currentIndex] : card,
    cardsNumber: cards?.length,
    currentIndex,
    swipeHandler,
    initialCurrentIndex,
  };
};

export default useZoom;
