import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const useUserAddresses = () => {
  const apiClient = useApi();
  const addressFactory = createKeyFactory([KEYS.ADDRESSES]);

  const { data: addresses, isLoading } = useQuery({
    queryKey: addressFactory.list(),
    queryFn: () => apiClient.addresses.apiAddressesGetCollection().then((v) => v.data['hydra:member']),
  });

  const defaultBillingAddress = addresses?.find((address) => address.isDefaultBilling);
  const defaultShippingAddress = addresses?.find((address) => address.isDefaultShipping);

  return {
    addresses,
    isLoading,
    defaultBillingAddress,
    defaultShippingAddress,
  };
};
export default useUserAddresses;
