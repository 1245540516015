import { useQuery } from '@tanstack/react-query';
import useMyWallet from 'hooks/queries/marketplace/wallet/useMyWallet';
import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import useUserApi from 'hooks/UserApi/useUserApi';
import { useCmsData } from 'utils/cms';
import { formatSpecificMenuItems, formatMenuItems } from 'utils/cms/formatMenuItems';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';
import getULID from 'utils/getULID';

const notificationsUnreadFactory = createKeyFactory([KEYS.NOTIFICATIONS_UNREAD]);

const useUserNav = () => {
  const { user, isLoading } = useUserApi();
  const { logout } = useAuth();
  const {
    acf: {
      headerMenus: { headerAccountClassic },
    },
  } = useCmsData();
  const apiClient = useApi();
  const menuItems = formatMenuItems(headerAccountClassic?.menuItems || []);
  const {
    logout: logoutItem,
    shop: shopItem,
    notification: notificationItem,
    scan: scanItem,
    wallet: walletItem,
  } = formatSpecificMenuItems(headerAccountClassic?.menuItems || []);

  // WALLET
  const { data: wallet } = useMyWallet();

  // NOTIFICATIONS
  const { data: unread } = useQuery({
    queryKey: notificationsUnreadFactory.list(),
    queryFn: async () => {
      const apiData = await apiClient.notifications.apiNotificationsunreadGet();

      return apiData.data.unread;
    },
    enabled: !!user && !isLoading,
  });
  if (notificationItem) {
    menuItems.push({ ...notificationItem, count: unread });
  }

  // SHOP
  const shopId = getULID(user?.shop);
  if (shopItem) {
    menuItems.push({ ...shopItem, href: `${shopItem.href}/${shopId}` });
  }

  // LOGOUT
  const { href, ...logoutItemProps } = logoutItem || {};
  const handleLogout = async () => {
    await logout();
  };
  if (logoutItem) {
    menuItems.push({
      ...logoutItemProps,
      onClick: handleLogout,
    });
  }

  return {
    menuItems,
    walletItem,
    scanItem,
    user,
    isLoading,
    wallet,
    footerImage: headerAccountClassic?.footerImage,
  };
};

export default useUserNav;
