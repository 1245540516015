import clx from 'classnames';
import { forwardRef } from 'react';
import { COMMON_CLASS, SIZE_TO_CLASS, VARIANT_TO_CLASS } from '@/atoms/Button/const';
import { BUTTON_VARIANTS, ButtonProps } from '@/atoms/Button/types';
import ButtonOrLink from '@/atoms/ButtonOrLink/ButtonOrLink';
import { SIZE } from 'types/utils';

const Button = forwardRef(
  ({ children, className, size = SIZE.SM, variant = BUTTON_VARIANTS.PRIMARY, icon, ...props }: ButtonProps, ref) => (
    <ButtonOrLink
      ref={ref}
      className={clx(COMMON_CLASS, SIZE_TO_CLASS[size], VARIANT_TO_CLASS[variant], className)}
      {...props}
    >
      {icon && <i className={icon} />} {children}
    </ButtonOrLink>
  ),
);

Button.displayName = 'Button';
export default Button;
