import { FieldsetHTMLAttributes } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Fieldset from '@/molecules/Fieldset';
import { FormOptions } from 'types/forms';
import addOrRemoveItem from 'utils/form/addOrRemoveItem';
import ErrorLabel from '../../labels/ErrorLabel';
import { BaseProps } from '../../types';
import HTMLCheckbox from '../HTMLCheckbox';

type CheckboxListProps = BaseProps &
  FieldsetHTMLAttributes<HTMLFieldSetElement> & {
    options: FormOptions;
  };

const CheckboxList = ({ options, name, disabled, label }: CheckboxListProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name,
    control,
    defaultValue: [],
  });

  const onCheckedChange = (value: string) => {
    field.onChange(addOrRemoveItem(value, field.value));
  };

  return (
    <Fieldset disabled={disabled} legend={label}>
      <ul className="flex flex-col gap-1.5">
        {Object.entries(options).map(([id, option]) => (
          <li key={id}>
            <HTMLCheckbox
              ref={field.ref}
              checked={!!field.value.includes(id)}
              disabled={option.disabled}
              isInvalid={!!errors[name]}
              label={option.label}
              name={name}
              onCheckedChange={() => onCheckedChange(id)}
              value={id}
            />
          </li>
        ))}
      </ul>
      {!!errors[name] && <ErrorLabel error={errors[name]?.message} />}
    </Fieldset>
  );
};

export default CheckboxList;
