import CounterInput from '@/atoms/forms/Counter';
import CardThumb from '@/molecules/CardThumb';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useAddToTradeList from 'context/Modal/views/TradeList/AddToTradeList/hook';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardDetailsAlias } from 'types/alias/types';
import { SIZE } from 'types/utils';

type Props = {
  closeModal: () => void;
  card: CardDetailsAlias;
};

const AddToTradeList = ({ closeModal, card }: Props) => {
  const { handleSubmit, formMethods } = useAddToTradeList(card, closeModal);
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={t('addToTradeList.title')}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit}>
          <div className="w-full flex flex-col justify-center items-center">
            <div className="col-span-12">
              <p className="md-bold-caps mb-2">{card.name}</p>
              <CardThumb alt="" imagePath={card.imagePath} inMyCollection={card.inMyCollection} size={SIZE.LG} />
              <CounterInput
                className="justify-center mt-4"
                max={card.inMyCollection || undefined}
                min={0}
                name="quantity"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default AddToTradeList;
