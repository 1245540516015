import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  DeckUserListJsonldDeckUserListReadDeckUserListDetailRead,
  DeckUserListJsonldDeckUserListWrite,
} from '@/api/generated';
import useApi from 'hooks/useApi';
import deckFactory from 'utils/factories/decks';

const deckSchema = () =>
  yup
    .object({
      name: yup.string().required(),
    })
    .required();

type FormValues = yup.InferType<ReturnType<typeof deckSchema>>;

const useDuplicateDeck = (deck: DeckUserListJsonldDeckUserListReadDeckUserListDetailRead, closeModal: () => void) => {
  const apiClient = useApi();
  const queryClient = useQueryClient();

  const updateDeckMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: DeckUserListJsonldDeckUserListWrite }) =>
      apiClient.deckUserLists.apiDeckUserListsIdduplicatePut(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: deckFactory.all() });
    },
  });

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(deckSchema()),
    defaultValues: { name: deck.name },
  });

  const { handleSubmit } = formMethods;
  const handleRenameSubmit = async (data: FormValues) => {
    if (deck.id) {
      await updateDeckMutation.mutate({ id: deck.id, data });
      closeModal();
    }
  };

  return {
    formMethods,
    handleSubmit: handleSubmit(handleRenameSubmit),
  };
};

export default useDuplicateDeck;
