import clx from 'classnames';
import { useTranslation } from 'react-i18next';
import { PrintingOrderPrintingPricingGridJsonldPrintingRead } from '@/api/generated';
import MoneyData from '@/atoms/MoneyData';
import useUserApi from 'hooks/UserApi/useUserApi';

const Offer = ({
  offer,
  isSelected,
}: {
  offer?: PrintingOrderPrintingPricingGridJsonldPrintingRead;
  isSelected?: boolean;
}) => {
  const { t } = useTranslation();
  const { user } = useUserApi();

  if (!offer) {
    return null;
  }
  const { totalPrice, priceByCard, nbPrintingPage } = offer;

  return (
    <div className="relative pr-5 min-w-72">
      <div className={clx(' p-4 rounded-lg', isSelected ? 'bg-neutral-50' : 'bg-neutral-0')}>
        <p className={clx('title-4 ', isSelected ? 'text-primary-50' : 'text-neutral-400')}>
          {nbPrintingPage} {t('sheet')}
        </p>
        <p className="sm-bold text-neutral-800">
          <MoneyData amount={totalPrice} currency={user?.wallet?.currency} />
        </p>
        <div className="flex justify-between">
          <p className="sm-bold text-neutral-800">
            <MoneyData amount={priceByCard} currency={user?.wallet?.currency} /> / {t('card')}
          </p>
        </div>
        <p className="bg-neutral-500 text-neutral-0 title-3 rounded px-2 py-1 absolute right-0 bottom-0 z-3">
          x{nbPrintingPage}
        </p>
      </div>
      <img alt="" className="absolute z-1 w-30 -right-2 -top-5" src="/uncut.png" />
    </div>
  );
};

export default Offer;
