import { RarityRarityRead } from '@/api/generated';
import { RARITY_ICON_MAP } from 'constants/icons';

const ShowRarity = ({ reference, name }: { reference?: string; name?: string | null }) => (
  <span className="flex sm-regular items-center text-neutral-700 ">
    {RARITY_ICON_MAP[reference as RarityRarityRead['reference']]} {name}
  </span>
);

export default ShowRarity;
