import { useTranslation } from 'react-i18next';
import { CardRegistrationPaymentCardJsonldPaymentPaymentCardRead } from '@/api/generated';
import Button from '@/atoms/Button';
import RedirectTile from '@/atoms/RedirectTile';
import useUserPaymentMethods from 'hooks/queries/banking/useUserPaymentMethods';
import ModalClassicLayout from '../../../layout/Classic';
import usePaymentList from './hook';

type PaymentListModal = {
  onSubmit?: () => void;
  closeModal: () => void;
  onChange: (card: CardRegistrationPaymentCardJsonldPaymentPaymentCardRead) => void;
};

const PaymentList = ({ onSubmit, closeModal, onChange }: PaymentListModal) => {
  const { t } = useTranslation();
  const { paymentMethods } = useUserPaymentMethods();
  const { openCreatePayment } = usePaymentList(onChange);

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="your-payout-method">
      <div className="flex flex-col pb-4">
        {paymentMethods?.map((paymentMethod) => (
          <div key={paymentMethod.externalId} className="first:border-t border-neutral-300 border-b py-6">
            <RedirectTile
              description={`${paymentMethod.expirationMonth}/${paymentMethod.expirationYear}`}
              icon="fa-solid fa-credit-card"
              label={`${paymentMethod.type} ${paymentMethod.number}`}
              onClick={() => {
                closeModal();
                onChange(paymentMethod);
              }}
              tags={[{ label: paymentMethod?.active ? 'active' : 'unactive' }]}
            />
          </div>
        ))}
      </div>
      <Button className="mx-auto my-4" onClick={openCreatePayment}>
        {t('add-new-payment-card')}
      </Button>
    </ModalClassicLayout>
  );
};

export default PaymentList;
