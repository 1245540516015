import clx from 'classnames';
import ToolTip from '@/atoms/ToolTip';

type MosaicItemBadgeProps = {
  icon: string;
  toolTip: string;
  count: number;
  className?: string;
};

const CardThumbBadge = ({ icon, count, toolTip, className }: MosaicItemBadgeProps) => (
  <ToolTip value={toolTip}>
    <div
      className={clx(
        'px-2 py-1 opacity-90 rounded-md border border-white justify-end items-center gap-2 inline-flex',
        'text-neutral-0 md-medium bg-neutral-500',
        className,
      )}
    >
      <i className={icon} />
      <div>{count}</div>
    </div>
  </ToolTip>
);

export default CardThumbBadge;
