import { useTranslation } from 'next-i18next';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import CardThumb from '@/molecules/CardThumb';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useCardDetailsApi from 'hooks/queries/cards/useCardsDetailsApi';
import { SIZE } from 'types/utils';

type ApplyFoilerResultProps = {
  closeModal: () => void;
  reference: string;
  message: string;
};
const ApplyFoilerResult = ({ closeModal, reference, message }: ApplyFoilerResultProps) => {
  const { t } = useTranslation();
  const { card } = useCardDetailsApi(reference);

  return (
    <ModalClassicLayout closeModal={closeModal} title="foiler">
      <div className="flex flex-col gap-8 my-4">
        <p className="md-bold">{t(`${message}-title`, { cardname: card?.name, rarity: card?.rarity?.name })}</p>
        <CardThumb alt={card?.name || ''} className="mx-auto" imagePath={card?.imagePath} size={SIZE.LG} />
        <p>{t(`${message}-text`, { cardname: card?.name, rarity: card?.rarity?.name })}</p>
        <Button className="grow" onClick={closeModal} type="button" variant={BUTTON_VARIANTS.PRIMARY}>
          {t('close')}
        </Button>
      </div>
    </ModalClassicLayout>
  );
};
export default ApplyFoilerResult;
