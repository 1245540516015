import { useMediaQuery } from '@react-hookz/web';
import clx from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import computeLastPage from '@/molecules/Pagination/computeLastPage';
import { COMMON_LINK_CLASS_NAME } from '@/molecules/Pagination/const';
import usePagination from './hooks';

type PaginationProps = {
  totalItems?: number;
  itemsPerPage?: number;
};

const Pagination = ({ totalItems = 0, itemsPerPage }: PaginationProps) => {
  const lastPage = computeLastPage(totalItems, itemsPerPage);
  const { pages, currPage, createUrl } = usePagination(lastPage);
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 768px)', { initializeWithValue: false });

  if (pages.length <= 1) {
    return null;
  }

  return (
    <div className="before:bg-neutral-50 before:h-px before:w-full before:block my-6">
      <ul className="flex gap-x-0.5 mx-auto w-fit items-center pt-5">
        {currPage > 1 && (
          <li>
            <Link
              className={COMMON_LINK_CLASS_NAME}
              data-testid="previous-page"
              href={createUrl(currPage - 1)}
              passHref
            >
              <i className="fa-solid fa-chevron-left" /> {t('previous')}
            </Link>
          </li>
        )}
        {isDesktop ? (
          <>
            {pages.map((page) => (
              <li key={page.key}>
                {page.value !== 0 ? (
                  <Link
                    className={clx(COMMON_LINK_CLASS_NAME, page.value === currPage && 'bg-neutral-50')}
                    href={createUrl(page.value)}
                    passHref
                  >
                    {page.label}
                  </Link>
                ) : (
                  <span className="md-bold text-neutral-700"> {page.label}</span>
                )}
              </li>
            ))}
          </>
        ) : (
          <span className="md-medium text-neutral-900"> {`${currPage} / ${lastPage}`}</span>
        )}
        {currPage < lastPage && (
          <li>
            <Link className={COMMON_LINK_CLASS_NAME} data-testid="next-page" href={createUrl(currPage + 1)} passHref>
              {t('next')} <i className="-rotate-180 fa-solid fa-chevron-left" />
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
