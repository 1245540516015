import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { IllustrationJsonldIllustrationRead } from '@/api/generated';
import useApi from 'hooks/useApi';
import useUserApi from 'hooks/UserApi/useUserApi';
import { ILLUSTRATION_TYPE_API_REF } from 'types/modifiers/references';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';
import sortByFaction from 'utils/illustration';

const queryParams = {
  'illustrationType.reference': ILLUSTRATION_TYPE_API_REF.PROFILE_BACKGROUND,
};
const illustrationsFactory = createKeyFactory([KEYS.ILLUSTRATIONS]);

export const useBackgroundData = () => {
  const apiClient = useApi();
  const { user } = useUserApi();
  const { data, isLoading } = useQuery({
    queryKey: illustrationsFactory.list(queryParams),
    queryFn: async () => {
      const res = await apiClient.illustrations.apiIllustrationsGetCollection(queryParams);

      return res.data['hydra:member'];
    },
  });

  const sortedData = sortByFaction(data);

  return { isLoading, sortedData, user };
};

export const useUpdateBackgroundData = (onSubmit: (key: { id: string; path: string }) => void) => {
  const [newBackground, setNewBackground] = useState({ id: '', path: '' });

  const onChange = (illu: IllustrationJsonldIllustrationRead) =>
    illu['@id'] && illu.filePath && setNewBackground({ id: illu['@id'], path: illu.filePath });
  const handleSubmit = () => {
    if (newBackground.id.length) {
      onSubmit(newBackground);
    }
  };

  return { onChange, handleSubmit, newBackground };
};
