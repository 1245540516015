// eslint-disable-next-line camelcase
import { Work_Sans } from '@next/font/google';
import localFont from '@next/font/local';
import { Provider as ToolTipProvider } from '@radix-ui/react-tooltip';
import { HydrationBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import NextNProgress from 'nextjs-progressbar';
import { useEffect, useRef } from 'react';
import '../styles/globals.css';
import { setLocale } from 'yup';
import ReactQueryProvider from 'context/ReactQuery';
import getErrorI18nConfig from 'i18n/errorConfig';
import PixelScript from 'utils/PixelScript';
import { init as initTracking, trackEvent } from 'utils/tracking';
import nextI18NextConfig from '../../next-i18next.config';
import ModalProvider from '../context/Modal/ModalContext';
import { ToasterProvider } from '../context/Toaster/ToasterContext';
import useDateFnsWithLocale from '../hooks/i18n/useDateFnsWithLocale';
import type { AppProps } from 'next/app';
import { ConfigContext } from 'context/Config';
import CookieConsent from 'utils/CookieConsent';

const sans = Work_Sans({
  subsets: ['latin'],
  style: ['normal'],
});
const tiller = localFont({
  src: [
    {
      path: '../../public/fonts/Tiller-Regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Tiller-Bold.woff',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Tiller-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Tiller-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Tiller-BoldItalic.woff2',
      weight: '700',
      style: 'italic',
    },
    {
      path: '../../public/fonts/Tiller-BoldItalic.woff',
      weight: '700',
      style: 'italic',
    },
    {
      path: '../../public/fonts/Tiller-Italic.woff',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../public/fonts/Tiller-Italic.woff2',
      weight: '400',
      style: 'italic',
    },
  ],
  variable: '--font-tiller',
});

const MyApp = ({ Component, pageProps: { session, generalSettings, isMobileApp, ...pageProps } }: AppProps) => {
  const { format } = useDateFnsWithLocale();
  setLocale(getErrorI18nConfig(format));
  const trackingInitialized = useRef(false);

  useEffect(() => {
    if (trackingInitialized.current) {
      return;
    }

    initTracking();
    trackEvent('App', 'init');

    // eslint-disable-next-line consistent-return
    return () => {
      trackingInitialized.current = true;
    };
  }, []);

  return (
    <>
      <style global jsx>
        {`
          :root {
            --font-sans: ${sans.style.fontFamily};
            --font-tiller: ${tiller.style.fontFamily};
          }
        `}
      </style>
      <NextNProgress color="#BD8049" />
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <ConfigContext.Provider value={{ isMobileApp }}>
        <SessionProvider session={session}>
          <ToolTipProvider>
            <ToasterProvider>
              <ReactQueryProvider>
                <HydrationBoundary state={pageProps.dehydratedState}>
                  <ReactQueryDevtools />
                  <ModalProvider>
                    <Component {...pageProps} />
                  </ModalProvider>
                </HydrationBoundary>
              </ReactQueryProvider>
            </ToasterProvider>
          </ToolTipProvider>
        </SessionProvider>
      </ConfigContext.Provider>
      <Script crossOrigin="anonymous" src="https://kit.fontawesome.com/36790e5020.js" />

      <CookieConsent>
        {generalSettings && (
          <PixelScript
            googleTagId={generalSettings.google_tag}
            googleTagManagerId={generalSettings.google_tag_manager}
            metalPixelId={generalSettings.meta_pixel}
            redditPixelId={generalSettings.reddit_pixel}
            tiktokPixelId={generalSettings.tiktok_pixel}
            twitterPixelId={generalSettings.twitter_pixel}
          />
        )}
      </CookieConsent>
    </>
  );
};

const config: any = nextI18NextConfig;
export default appWithTranslation(MyApp, config);
