import clx from 'classnames';
import { useTranslation } from 'next-i18next';
import getFactionIconName from '@/atoms/FactionIcon/utils';
import Spinner from '@/atoms/Spinner';
import SecondaryTitle from '@/molecules/titles/SecondaryTitle';
import { SIZE } from 'types/utils';
import ModalClassicLayout from '../../layout/Classic';
import { useBackgroundData, useUpdateBackgroundData } from './hooks';

type PropsType = {
  onSubmit: ({ id, path }: { id: string; path: string }) => void;
  closeModal: () => void;
};

const PickBackgroundModal = ({ onSubmit, closeModal }: PropsType) => {
  const { t } = useTranslation();
  const { isLoading, sortedData, user } = useBackgroundData();
  const { handleSubmit, onChange, newBackground } = useUpdateBackgroundData(onSubmit);

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={t('pick-a-background')}>
      {isLoading && <Spinner size={SIZE.LG} />}
      {sortedData &&
        Object.keys(sortedData)
          .toSorted()
          .map((key) => (
            <div key={key} className="my-4 md:min-w-[600px]">
              <SecondaryTitle icon={getFactionIconName(key)}>{key}</SecondaryTitle>
              <ul className="grid md:grid-cols-2 grid-cols-1 gap-1.5 mt-2">
                {sortedData[key].map((illu) => (
                  <button key={illu.id} onClick={() => onChange(illu)} type="button">
                    <li
                      className={clx(
                        'h-[264px] bg-cover bg-center transition bg-no-repeat rounded-t-lg overflow-hidden',
                        'hover:border-primary-500 hover:border-4 relative',
                        user?.backgroundPath === illu.filePath || newBackground.id === illu['@id']
                          ? 'border-primary-500  border-4'
                          : ' border-transparent',
                      )}
                      style={{ backgroundImage: illu.filePath ? `url(${illu.filePath})` : undefined }}
                    >
                      {user?.backgroundPath === illu.filePath && (
                        <span className="uppercase text-neutral-0 absolute w-2/3 text-left sm-regular pl-2 bottom-0 left-0 mb-1 py-1 bg-gradient-to-r from-primary-500 to-transparent">
                          {t('current')}
                        </span>
                      )}
                    </li>
                  </button>
                ))}
              </ul>
            </div>
          ))}
    </ModalClassicLayout>
  );
};

export default PickBackgroundModal;
