/* eslint-disable react/no-danger */
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import { COOKIE_CONSENT } from 'constants/localStorageKeys';
import React, { useState, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Consent = 'yes' | 'no' | 'undecided';

export const getCookieConsent = () => (localStorage.getItem(COOKIE_CONSENT) as Consent) || 'undecided';
export const setCookieConsent = (consent: Consent) => localStorage.setItem(COOKIE_CONSENT, consent);

const CookieConsent = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [consentGiven, setConsentGiven] = useState<Consent>();

  useEffect(() => {
    const consent = getCookieConsent();
    if (consent) {
      setConsentGiven(consent);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem(COOKIE_CONSENT, 'yes');
    setConsentGiven('yes');
  };

  const handleDeclineCookies = () => {
    localStorage.setItem(COOKIE_CONSENT, 'no');
    setConsentGiven('no');
  };

  if (consentGiven === 'undecided') {
    return (
      <div className="bg-sand-300/70 shadow-overflow backdrop-blur-lg flex items-center flex-col space-y-2 py-2 px-2 fixed bottom-0 z-50 w-full">
        <p dangerouslySetInnerHTML={{ __html: t('cookieConsent.text') }} />
        <div className="flex space-x-2">
          <Button onClick={handleAcceptCookies}>{t('cookieConsent.cta.accept')}</Button>
          <Button onClick={handleDeclineCookies} variant={BUTTON_VARIANTS.SECONDARY}>
            {t('cookieConsent.cta.decline')}
          </Button>
        </div>
      </div>
    );
  }

  return children;
};

export default CookieConsent;
