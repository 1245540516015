import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const useDetailAddress = ({ id }: { id: string }) => {
  const apiClient = useApi();
  const addressFactory = createKeyFactory([KEYS.ADDRESSES]);

  const { data, isLoading } = useQuery({
    queryKey: addressFactory.details(id),
    queryFn: () => apiClient.addresses.apiAddressesIdGet(id),
  });

  return {
    data: data?.data,
    isLoading,
  };
};
export default useDetailAddress;
