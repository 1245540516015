import clx from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from '@/atoms/Button';

type PropsType = {
  image?: string;
  icon?: string;
  children: ReactNode;
  subLine: string;
  className?: string;
  buttonProps?: ButtonProps;
  imgClassName?: string;
};
const Metrics = ({ image, icon, children, subLine, className, imgClassName, buttonProps }: PropsType) => {
  const { t } = useTranslation();

  return (
    <div
      className={clx(
        'relative rounded-tl-2xl rounded-br-2xl px-3 py-2 overflow-hidden flex gap-4 justify-between items-center bg-neutral-130',
        className,
      )}
    >
      <hgroup className="flex flex-col gap-2 justify-end h-full z-10">
        <span className="title-2 text-neutral-900">{children}</span>
        {subLine && <h6 className="sm-bold-caps text-neutral-200">{t(subLine)}</h6>}
        {buttonProps && <Button {...buttonProps} />}
      </hgroup>
      {image && <img alt="" className={clx('absolute bottom-0 right-0 ', imgClassName)} src={image} />}
      {icon && <i className={clx(icon, ' text-neutral-500 text-6xl')} />}
    </div>
  );
};

export default Metrics;
