import { FormProvider } from 'react-hook-form';
import { CardRegistrationPaymentCardJsonldPaymentPaymentCardRead } from '@/api/generated';
import Checkbox from '@/atoms/forms/checkbox';
import TextInput from '@/atoms/forms/Input';
import Fieldset from '@/molecules/Fieldset';
import { ORIENTATION } from 'types/utils';
import ModalClassicLayout from '../../../layout/Classic';
import useCreatePaymentMethod from './hooks';

const PaymentCardCreateModal = ({
  closeModal,
  onChange,
  forcePersist = false,
}: {
  closeModal: () => void;
  onChange: (data: CardRegistrationPaymentCardJsonldPaymentPaymentCardRead) => void;
  forcePersist?: boolean;
}) => {
  const { formMethods, onSubmit } = useCreatePaymentMethod(onChange);

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="your-payout-method">
      <FormProvider {...formMethods}>
        <form className="flex flex-col gap-4 pb-4" onSubmit={onSubmit}>
          <TextInput label="card-number" name="cardNumber" placeholder="card-number" type="text" />
          <Fieldset orientation={ORIENTATION.HORIZONTAL}>
            <TextInput label="expirationDate" name="cardExpirationDate" placeholder="expirationDate" type="text" />
            <TextInput label="cvv" name="cardCvx" placeholder="cvv" type="text" />
          </Fieldset>
          {!forcePersist && <Checkbox label="record-payout-method" name="persist" />}
          <input className="hidden" type="submit" />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default PaymentCardCreateModal;
