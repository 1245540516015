import { Mapped, VARIANT_COLOR_ENUM } from 'types/utils';

export const TYPE_TO_ICON: Mapped<VARIANT_COLOR_ENUM, string> = {
  [VARIANT_COLOR_ENUM.ERROR]: 'fa-solid fa-warning',
  [VARIANT_COLOR_ENUM.SUCCESS]: 'fa-solid fa-circle-check',
  [VARIANT_COLOR_ENUM.INFO]: 'fa-solid fa-circle-info',
  [VARIANT_COLOR_ENUM.NEUTRAL]: '',
  [VARIANT_COLOR_ENUM.PRIMARY]: '',
  [VARIANT_COLOR_ENUM.SECONDARY]: '',
  [VARIANT_COLOR_ENUM.DISABLED]: '',
};

export const TYPE_TO_BOX_CLASS: Mapped<VARIANT_COLOR_ENUM, string> = {
  [VARIANT_COLOR_ENUM.ERROR]: 'bg-system-error-200 border-system-error-400 text-system-error-400',
  [VARIANT_COLOR_ENUM.SUCCESS]: 'bg-system-success-200 border-system-success-400 text-system-success-400',
  [VARIANT_COLOR_ENUM.INFO]: 'bg-system-info-200 border-system-info-400 text-system-info-400',
  [VARIANT_COLOR_ENUM.NEUTRAL]: 'bg-system-disabled-200 border-system-disabled-400 text-system-disabled-400',
  [VARIANT_COLOR_ENUM.PRIMARY]: 'bg-primary-500 border-system-primary-500 text-primary-500',
  [VARIANT_COLOR_ENUM.SECONDARY]: 'bg-sand-50 border-system-primary-500 text-primary-500',
  [VARIANT_COLOR_ENUM.DISABLED]: 'bg-system-disabled-200 border-system-disabled-400 text-system-disabled-400',
};
