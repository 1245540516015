import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { EventJsonldEventLocatorReadShopLocatorRead, EventJsonldEventRead } from '@/api/generated';
import ShowAddress from '@/molecules/ShowAddress';
import TextualData, { TEXTUAL_DATA_VARIANT } from '@/molecules/TextualData';
import useDateFnsWithLocale from 'hooks/i18n/useDateFnsWithLocale';
import { ORIENTATION } from 'types/utils';
import getRemainingPlace from 'utils/events/getRemainingPlace';

const EventTileContent = ({
  event,
  minified = false,
}: {
  event: EventJsonldEventRead | EventJsonldEventLocatorReadShopLocatorRead;
  minified?: boolean;
}) => {
  const { format } = useDateFnsWithLocale();

  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-1 text-neutral-800">
      <TextualData
        icon="fa-solid fa-fw fa-calendar"
        label={minified ? '' : t('date')}
        orientation={minified ? ORIENTATION.HORIZONTAL : ORIENTATION.VERTICAL}
        variant={TEXTUAL_DATA_VARIANT.smallInfo}
      >
        {format(parseISO(event.dateTime?.toString() || ''), 'dd MMMM yyyy')}
      </TextualData>
      {'address' in event && event?.address && (
        <ShowAddress
          city={event.address.city}
          minified={minified}
          showStreet={false}
          street={event.address.street}
          zipCode={event.address.zipCode}
        />
      )}
      {!!event.attendeeMax && (
        <TextualData
          icon="fa-solid fa-fw fa-users"
          label={minified ? '' : t('capacity')}
          orientation={minified ? ORIENTATION.HORIZONTAL : ORIENTATION.VERTICAL}
          variant={TEXTUAL_DATA_VARIANT.smallInfo}
        >
          <span>{`${event.attendeeMax} ${t('places')} (`}</span>
          <span className="sm-bold mr-1">{getRemainingPlace(event)}</span>
          <span className="sm">{`${t('available', {
            count: getRemainingPlace(event),
          })})`}</span>
        </TextualData>
      )}
    </div>
  );
};

export default EventTileContent;
