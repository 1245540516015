import clx from 'classnames';
import { SIZE } from 'types/utils';

const SIZE_TO_CLASS = {
  [SIZE.SM]: 'md-regular',
  [SIZE.MD]: 'text-2xl',
  [SIZE.LG]: 'text-6xl',
};

const Spinner = ({ size = SIZE.SM, className }: { className?: string; size?: SIZE }) => (
  <i className={clx('fa-solid fa-circle-notch animate-spin text-neutral-300 m-auto', SIZE_TO_CLASS[size], className)} />
);

export default Spinner;
