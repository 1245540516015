import * as Toast from '@radix-ui/react-toast';
import clx from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React from 'react';

import Xclose from '@/atoms/Xclose';
import { ToastParams } from 'context/Toaster/types';
import { TYPE_TO_BOX_CLASS, TYPE_TO_ICON } from './consts';

const MotionToast = motion(Toast.Root);
const variants = {
  hidden: {
    opacity: 0,
    translateY: '-16px',
  },
  visible: {
    opacity: 1,
    translateY: 0,
    transition: {
      ease: 'easeOut',
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    translateY: '-16px',
    transition: {
      ease: 'easeOut',
      duration: 0.2,
    },
  },
};

const ToastView = ({ toast, onOpenChange }: { toast: ToastParams; onOpenChange: (open: boolean) => void }) => {
  const { t } = useTranslation();

  return (
    <MotionToast
      animate="visible"
      className={clx(TYPE_TO_BOX_CLASS[toast.variant], 'rounded-lg border-2')}
      exit="exit"
      initial="hidden"
      onOpenChange={onOpenChange}
      variants={variants}
      defaultOpen
      forceMount
    >
      <Toast.Description className="flex flex-row items-center p-3 gap-4 md-bold justify-center">
        {toast.icon && <i className={clx(toast.icon || TYPE_TO_ICON[toast.variant])} />}
        {t(toast.message)}
        <Toast.ToastClose asChild>
          <Xclose onClick={() => onOpenChange(false)} variant={toast.variant} />
        </Toast.ToastClose>
      </Toast.Description>
    </MotionToast>
  );
};

export default ToastView;
