import { useQuery } from '@tanstack/react-query';
import { MarketplaceOfferJsonld } from '@/api/generated';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const useNumberCopyAvailable = (reference?: string) => {
  const apiClient = useApi();
  const numberCopyAvailableFactory = createKeyFactory([KEYS.NUMBER_COPY_AVAILABLE]);

  const { data: numberCopyAvailable } = useQuery({
    queryKey: numberCopyAvailableFactory.details(reference as string),
    queryFn: async () => {
      const res = await apiClient.owners.apiOwnersCardRefavailableGet(reference as string);

      // TODO: Wait API to be correctly typed
      return (res.data as MarketplaceOfferJsonld).numberCopyAvailable;
    },
    enabled: !!reference,
  });

  return { numberCopyAvailable };
};

export default useNumberCopyAvailable;
