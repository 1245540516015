import * as PrimitiveCheckbox from '@radix-ui/react-checkbox';
import clx from 'classnames';
import { forwardRef, Ref } from 'react';
import { HTMLCheckboxProps } from '@/atoms/forms/checkbox/types';
import { Label } from '@/atoms/forms/labels';
import CheckSVG from '@/svg/check.svg';

const HTMLCheckbox = forwardRef(
  (
    { label, name, isInvalid, onCheckedChange, value, checked, disabled }: HTMLCheckboxProps,
    ref: Ref<HTMLButtonElement> | undefined,
  ) => (
    <div className="flex items-center gap-x-2">
      <PrimitiveCheckbox.Root
        ref={ref}
        checked={checked}
        className={clx(
          'rounded-md w-5 h-5 flex shrink-0 items-center justify-center border-primary-500 border-2 bg-neutral-40 outline-none',
          'disabled:border-system-disabled-400 disabled:text-system-disabled-400 disabled:hover:bg-inherit',
          isInvalid
            ? 'border-system-error-400 text-system-error-400 focus:shadow-focus-error hover:bg-system-error-300'
            : `bg-text-neutral-40 hover:bg-neutral-50 focus:border-neutral-50
            data-state-checked:hover:bg-primary-600`,
          disabled && 'bg-sand-50',
          !disabled && !isInvalid && 'data-state-checked:bg-primary-500',
          'transition-colors transition-quicker ease-out',
        )}
        disabled={disabled}
        id={`${name}-${label}`}
        name={name}
        onCheckedChange={onCheckedChange}
        value={value}
      >
        <PrimitiveCheckbox.Indicator asChild>
          <CheckSVG className="animation-dash" />
        </PrimitiveCheckbox.Indicator>
      </PrimitiveCheckbox.Root>
      {label && <Label disabled={disabled} isInvalid={isInvalid} label={label} name={`${name}-${label}`} />}
    </div>
  ),
);

HTMLCheckbox.displayName = 'HTMLCheckbox';
export default HTMLCheckbox;
