import clx from 'classnames';
import { LabelHTMLAttributes } from 'react';

type LegendProps = LabelHTMLAttributes<HTMLLegendElement> & {
  disabled?: boolean;
};

const Legend = ({ children, disabled, className }: LegendProps) => (
  <legend className={clx('md-medium', className, disabled ? 'text-system-disabled-300' : 'text-neutral-900')}>
    {children}
  </legend>
);

export default Legend;
