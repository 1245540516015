import { FormProvider } from 'react-hook-form';
import RadioList from '@/atoms/forms/radio/RadioList';
import Form from '@/molecules/Form';
import ModalClassicLayout from '../../layout/Classic';
import useLanguageModal from './hooks';

const LanguageModal = ({ closeModal, title }: { closeModal: () => void; title: string }) => {
  const { formMethods, onSubmit, i18nOptions } = useLanguageModal({ closeModal });

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title={title}>
      <FormProvider {...formMethods}>
        <Form onSubmit={onSubmit}>
          <div className="flex md:px-16">
            <div className="basis-1/2 p-4">
              <RadioList name="locale" options={i18nOptions} />
            </div>
            <div className="basis-1/2 flex justify-end">
              <img alt="" className="hidden md:block" src="/emotes/kitsune.png" />
            </div>
          </div>
          <input className="hidden" type="submit" />
        </Form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default LanguageModal;
