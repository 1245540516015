import { useModal } from 'context/Modal/Context';
import { MODAL_NAME } from 'context/Modal/MODAL_NAME';
import { useCmsData } from 'utils/cms';
import { formatSpecificMenuItems, formatMenuItems } from 'utils/cms/formatMenuItems';

const useEditorialNav = () => {
  const {
    acf: {
      headerMenus: { headerEditorialClassic },
    },
  } = useCmsData();
  const menuItems = formatMenuItems(headerEditorialClassic?.menuItems || []);
  const { i18n: i18nItem, scan: scanItem } = formatSpecificMenuItems(headerEditorialClassic?.menuItems || []);
  const { openModal } = useModal();

  const handleLanguageChoice = () => {
    openModal(MODAL_NAME.LANGUAGE, { title: i18nItem.label });
  };

  return {
    menuItems,
    handleLanguageChoice,
    i18nItem,
    scanItem,
  };
};

export default useEditorialNav;
