import { FormOptions } from 'types/forms';

export type Mapped<I extends string | number, T> = {
  [index in I]: T;
};

export type WithHydraMember<T> = {
  '@context': string;
  '@id': string;
  '@type': string;
  'hydra:member': T;
  'hydra:totalItems': number;
};

export type LinkType = {
  href: string;
  label: string;
};

export type FilterDataDto = {
  [index: string]: FormOptions;
};

export enum SIZE {
  LG = 'LG',
  MD = 'MD',
  SM = 'SM',
}

export enum VARIANT_COLOR_ENUM {
  NEUTRAL = 'NEUTRAL',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  DISABLED = 'DISABLED',
  INFO = 'INFO',
}

export enum ORIENTATION {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export enum ALIGNMENT_ENUM {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum POSITION_ENUM {
  'bottom' = 'bottom',
  'top' = 'top',
  left = 'left',
  'left-bottom' = 'left-bottom',
  'left-top' = 'left-top',
  right = 'right',
  'right-bottom' = 'right-bottom',
  'right-top' = 'right-top',
  center = 'center',
}

export const BACKGROUND_POSITION_TO_CLASSNAME = {
  bottom: 'bg-bottom',
  top: 'bg-top',
  left: 'bg-left',
  'left-bottom': 'bg-left-bottom',
  'left-top': 'bg-left-top',
  right: 'bg-right',
  'right-bottom': 'bg-right-bottom',
  'right-top': 'bg-right-top',
  center: 'bg-center',
};
