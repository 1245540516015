import * as yup from 'yup';

export type AddressFormValues = yup.InferType<ReturnType<typeof addressSchema>>;

export const addressSchema = () =>
  yup
    .object({
      label: yup.string().required().nullable(),
      street: yup.string().required(),
      lastName: yup.string().required().nullable(),
      firstName: yup.string().required().nullable(),
      zipCode: yup.string().required(),
      city: yup.string().required(),
      country: yup.string().required().nullable(),
    })
    .required();

export default addressSchema;
