import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { PrintingOrderJsonldPrintingOrderRead, PrintingOrderJsonldPrintingOrderWrite } from '@/api/generated';
import { useAllLanguagesOptions } from 'hooks/options/useLanguagesOptions';
import useApi from 'hooks/useApi';
import printingFactory from 'utils/factories/printing';

type hookProps = {
  orderName?: string;
  orderLang?: string;
  id?: string;
  onSubmit?: (data: PrintingOrderJsonldPrintingOrderRead) => void;
  closeModal: () => void;
};

const printingSchema = () =>
  yup
    .object({
      name: yup.string().required(),
      language: yup.string().required(),
      id: yup.string(),
    })
    .required();

type FormValues = yup.InferType<ReturnType<typeof printingSchema>>;

const useCreatePrintingModal = ({ orderName, orderLang, id: currentid, onSubmit, closeModal }: hookProps) => {
  const apiClient = useApi();
  const queryClient = useQueryClient();
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(printingSchema()),
    defaultValues: {
      name: orderName,
      language: orderLang,
      id: currentid,
    },
  });
  const { languagesOptions } = useAllLanguagesOptions();
  const create = useMutation({
    mutationFn: async ({ data }: { data: PrintingOrderJsonldPrintingOrderWrite }) =>
      apiClient.printingOrders.apiPrintingOrdersPost(data),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: printingFactory.all() });
      if (onSubmit) {
        onSubmit(res.data);
      }
      closeModal();
    },
  });

  const update = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: PrintingOrderJsonldPrintingOrderWrite }) =>
      apiClient.printingOrders.apiPrintingOrdersIdPut(id, data),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: printingFactory.all() });
      if (onSubmit) {
        onSubmit(res.data);
      }
      closeModal();
    },
  });

  const handleSubmit = ({ id, ...data }: FormValues) => {
    if (create.isPending || update.isPending) {
      return;
    }
    if (id) {
      update.mutate({ id, data });
    } else {
      create.mutate({ data });
    }
  };

  return { formMethods, handleSubmit: formMethods.handleSubmit(handleSubmit), languagesOptions };
};

export default useCreatePrintingModal;
