import { useQueryClient } from '@tanstack/react-query';
import useOwnershipListsMutation from 'hooks/useOwnershipListsMutation';
import { OwnershipListsName } from 'hooks/useOwnershipListsMutation/types';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { CardDetailsAlias } from 'types/alias/types';
import cardsFactory from 'utils/factories/card';

type FormValues = {
  quantity: number;
};

const useAddToTradeList = (card: CardDetailsAlias, onClose: () => void) => {
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      quantity: card.inMyTradelist,
    },
  });
  const {
    query: { slug },
  } = useRouter();
  const reference = slug && slug[1];
  const queryKey = cardsFactory.details(reference || '');
  const queryClient = useQueryClient();
  const { mutateAsync } = useOwnershipListsMutation(OwnershipListsName.TRADELIST, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const handleSubmit = formMethods.handleSubmit(async (values) => {
    await mutateAsync({
      cards: [
        {
          card: card['@id'],
          quantity: values.quantity,
        },
      ],
    });

    onClose();
  });

  return { formMethods, handleSubmit };
};

export default useAddToTradeList;
