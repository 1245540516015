import { TabItemType } from '@/molecules/Tabs/TabItem/types';
import { QueryParametersType } from 'types/cms';

const queryParamsChoicesToTabs = (queryParameters: QueryParametersType, path: string): TabItemType<string>[] =>
  queryParameters.choices
    ? queryParameters.choices.map((param) => {
        return {
          href: path.concat(param.value ? `/${param.value}` : ''),
          label: param.label,
          value: param.value,
          icon: param.icon,
        };
      })
    : [];

export default queryParamsChoicesToTabs;
