import { ParsedQuery } from 'query-string';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FilterDataDto } from 'types/utils';
import { useCmsData } from 'utils/cms';
import dtoSpecificFields from 'utils/cms/dtoSpecificFields';
import { useDecodeFilters } from 'utils/filters/encodeDecodeFilters';
import createFilterComponents from '../utils/createFilterComponents';

const useFilters = ({ filterData }: { filterData?: FilterDataDto }) => {
  const {
    acf: { queryParameters, widgets },
  } = useCmsData();
  const currFilters = useDecodeFilters();
  const formMethods = useForm<ParsedQuery>({
    mode: 'onSubmit',
    defaultValues: currFilters,
  });
  const { filter_layer_title: modalTitle } = useMemo(() => dtoSpecificFields(widgets), []);

  const components = createFilterComponents(queryParameters, filterData, currFilters);

  return { formMethods, components, modalTitle };
};

export default useFilters;
