import clx from 'classnames';
import { ReactNode } from 'react';
import Xclose from '@/atoms/Xclose';
import { SIZE } from 'types/utils';

type TagProps = {
  onClick?: () => void;
  size?: SIZE;
  children: ReactNode;
};

const SIZE_TO_CSS = {
  [SIZE.SM]: 'sm-medium px-2  ',
  [SIZE.MD]: 'md-medium px-2 py-0.5',
  [SIZE.LG]: 'md-medium px-2.5 py-1',
};

const Tag = ({ children, onClick, size = SIZE.SM }: TagProps) => (
  <div
    className={clx(
      'border rounded-md border-neutral-500 text-neutral-500 w-fit flex items-center justify-center',
      SIZE_TO_CSS[size],
    )}
  >
    {children}
    {onClick && <Xclose onClick={onClick} />}
  </div>
);

export default Tag;
