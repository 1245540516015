import { useQuery } from '@tanstack/react-query';
import { EventJsonldEventRead } from '@/api/generated';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';
import formatOptions from 'utils/form/formatOptions';

const useDecksOptions = (event?: EventJsonldEventRead) => {
  const apiClient = useApi();

  const keyFactory = createKeyFactory([KEYS.DECKS]);
  const queryKey = keyFactory.list();

  const { data } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await apiClient.deckUserLists.apiDeckUserListsGetCollection();

      return res.data;
    },
    enabled: !!(event && event?.deckUserMax && event?.deckUserMax > 0),
  });

  return { decksOptions: formatOptions(data?.['hydra:member']) };
};

export default useDecksOptions;
