const addOrRemoveItem = (item: string, arr: string[]) => {
  const clonnedArray = [...arr];
  const index = clonnedArray.indexOf(item);
  if (index > -1) {
    clonnedArray.splice(index, 1);

    return clonnedArray;
  }

  return [...clonnedArray, item];
};

export default addOrRemoveItem;
