import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import VisualRadioList from '@/atoms/forms/radio/VisualRadioList';
import Spinner from '@/atoms/Spinner';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import { SIZE } from 'types/utils';
import useCreatePrintingPage from './hooks';

type Props = {
  printingOrder: string;
  closeModal: () => void;
};

const CreatePrintingPage = ({ printingOrder, closeModal }: Props) => {
  const { formMethods, options, handleSubmit } = useCreatePrintingPage({ printingOrder, closeModal });
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={t('create-a-board')}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit}>
          {!options && <Spinner size={SIZE.SM} />}
          {options && <VisualRadioList name="printingPageType" options={options} title={t('which-kind-of-card')} />}
          <input className="hidden" type="submit" />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default CreatePrintingPage;
