import { DtoPage } from 'types/cms';

const dtoSpecificFields = (widgets: DtoPage['acf']['widgets']) => {
  const widget = widgets.find(({ acf_fc_layout: acfFcLayout }) => acfFcLayout === 'specific_page_fields');

  return widget
    ? widget.specific_fields.reduce((acc: { [key: string]: string }, curr: any) => {
        return {
          ...acc,
          [curr.internal_name]: curr.value,
        };
      }, {})
    : {};
};

export default dtoSpecificFields;
