import ForestIcon from '@/svg/attributes/forest.svg';
import MountainIcon from '@/svg/attributes/mountain.svg';
import OceanIcon from '@/svg/attributes/ocean.svg';
import CommonIcon from '@/svg/rarity/common.svg';
import RareIcon from '@/svg/rarity/rare.svg';
import UniqueIcon from '@/svg/rarity/unique.svg';
import { CARD_ELEMENT_API_REF, RARITY_API_REF } from 'types/modifiers/references';

export const RARITY_ICON_MAP = {
  [RARITY_API_REF.COMMON]: <CommonIcon />,
  [RARITY_API_REF.RARE]: <RareIcon />,
  [RARITY_API_REF.UNIQUE]: <UniqueIcon />,
};

export const RARITY_ICON_PNG_PATH_MAP = {
  [RARITY_API_REF.COMMON]: '/common.png',
  [RARITY_API_REF.RARE]: '/rare.png',
  [RARITY_API_REF.UNIQUE]: '/unique.png',
};

export const RARITY_ICON_PNG_MAP = {
  [RARITY_API_REF.COMMON]: <img alt="" src={RARITY_ICON_PNG_PATH_MAP.COMMON} />,
  [RARITY_API_REF.RARE]: <img alt="" src={RARITY_ICON_PNG_PATH_MAP.RARE} />,
  [RARITY_API_REF.UNIQUE]: <img alt="" src={RARITY_ICON_PNG_PATH_MAP.UNIQUE} />,
};

export const ATTRIBUTES_ICON_MAP = {
  [CARD_ELEMENT_API_REF.MOUNTAIN_POWER]: <MountainIcon />,
  [CARD_ELEMENT_API_REF.FOREST_POWER]: <ForestIcon />,
  [CARD_ELEMENT_API_REF.OCEAN_POWER]: <OceanIcon />,
};
export default RARITY_ICON_MAP;
