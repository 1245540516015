import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { MarketplaceOfferCardOfferDataJsonldCardOfferDataRead } from '@/api/generated';
import useCreateCart from 'hooks/queries/marketplace/carts/useCreateCart';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

type FormValues = { quantity: number };
const cartFactory = createKeyFactory([KEYS.CART]);
const offersFactory = createKeyFactory([KEYS.OFFERS]);

const useCreateCartModale = ({
  closeModal,
  cardOffer,
}: {
  closeModal: () => void;
  cardOffer?: MarketplaceOfferCardOfferDataJsonldCardOfferDataRead;
}) => {
  const queryClient = useQueryClient();
  const schema = () =>
    yup
      .object({
        quantity: yup
          .number()
          .min(1)
          .max(cardOffer?.quantity || 0)
          .required(),
      })
      .required();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      quantity: 1,
    },
    resolver: yupResolver(schema()),
  });
  const { handleSubmit, watch } = formMethods;

  const onSuccess = async () => {
    queryClient.invalidateQueries({ queryKey: cartFactory.list() });
    queryClient.invalidateQueries({ queryKey: offersFactory.all() });
    closeModal();
  };
  const { createCart } = useCreateCart(onSuccess);
  const onSubmit = async (formValues: FormValues) => {
    await createCart.mutate({ offerGroupId: cardOffer?.groupId, ...formValues });
  };

  const total = watch('quantity') * (cardOffer?.price || 0);

  return {
    formMethods,
    onSubmit: handleSubmit(onSubmit),
    closeModal,
    total,
    numberCopyAvailable: cardOffer?.quantity,
  };
};

export default useCreateCartModale;
