import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { AnimatePresence, easeOut, motion } from 'framer-motion';
import { useState } from 'react';
import IconButton from '@/atoms/IconButton';
import { SIZE } from 'types/utils';

const MotionDropdownContent = motion(DropdownMenu.Content);

type DropDownProps = {
  triggerClassName?: string;
  align?: 'start' | 'end' | 'center';
  items: React.ReactElement[];
  trigger?: React.ReactNode;
  defaultOpen?: boolean;
  disabled?: boolean;
  isLight?: boolean;
};

const DropDown = ({
  triggerClassName,
  align,
  items,
  trigger,
  defaultOpen = false,
  disabled = false,
  isLight = false,
}: DropDownProps) => {
  const [isOpen, setOpen] = useState(defaultOpen);

  return (
    <DropdownMenu.Root onOpenChange={setOpen} open={isOpen}>
      {trigger ? (
        <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
      ) : (
        <DropdownMenu.Trigger className={triggerClassName} asChild>
          <IconButton disabled={disabled} icon="fa-solid fa-ellipsis-vertical" isLight={isLight} size={SIZE.MD} />
        </DropdownMenu.Trigger>
      )}

      <DropdownMenu.Portal forceMount>
        <AnimatePresence>
          {isOpen && (
            <MotionDropdownContent
              align={align}
              animate={{ translateY: 0, opacity: 1 }}
              className="bg-sand-50 rounded-lg px-1.5 text-neutral-800 md-medium shadow-raised border border-neutral-30 flex flex-col z-50 overflow-hidden"
              exit={{ translateY: '-25%', opacity: 0 }}
              initial={{ translateY: '-25%', opacity: 0 }}
              sideOffset={5}
              transition={{ duration: 0.1, ease: easeOut }}
              forceMount
            >
              {items.map((item) => (
                <DropdownMenu.Item
                  key={item.key}
                  className="hover:bg-neutral-30 clickable my-1 px-2.5 py-2 outline-0 text-left"
                  asChild
                >
                  {item}
                </DropdownMenu.Item>
              ))}
            </MotionDropdownContent>
          )}
        </AnimatePresence>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DropDown;
