import { parseISO } from 'date-fns';
import { LocaleObject } from 'yup';

const getErrorI18nConfig: (format: (date: Date, formatOption: string) => string) => LocaleObject = (format) => {
  return {
    mixed: {
      default: 'field-invalid',
      required: 'field-required',
    },
    string: {
      email: 'invalid-email',
      min: ({ min }) => {
        return { key: 'string-min', values: { min } };
      },
      max: ({ max }) => {
        return { key: 'string-max', values: { max } };
      },
      length: ({ length }) => {
        return { key: 'string-length', values: { length } };
      },
    },
    date: {
      min: ({ min }) => {
        return {
          key: 'date-min',
          values: {
            min: format(typeof min === 'string' ? parseISO(min) : min, 'PPP'),
          },
        };
      },
      max: ({ max }) => {
        return {
          key: 'date-max',
          values: {
            max: format(typeof max === 'string' ? parseISO(max) : max, 'PPP'),
          },
        };
      },
    },
    number: {
      min: ({ min }) => {
        return { key: 'num-min', values: { min } };
      },
      max: ({ max }) => {
        return { key: 'num-max', values: { max } };
      },
      integer: 'must-be-integer',
    },
    array: {
      min: ({ min }) => {
        return { key: 'array-min', values: { min } };
      },
      max: ({ max }) => {
        return { key: 'array-max', values: { max } };
      },
    },
  };
};

export default getErrorI18nConfig;
