import { useMyFriends } from 'hooks/queries/friends';
import filterAndFormatOptions from '../utils/filterAndFormatOptions';

const useUserOptions = () => {
  const { data } = useMyFriends({ itemsPerPage: 200 });

  return { userOptions: filterAndFormatOptions(data ? data['hydra:member'] : []) };
};

export default useUserOptions;
