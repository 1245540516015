import { useController, useFormContext } from 'react-hook-form';
import ErrorLabel from '../labels/ErrorLabel';

import HTMLCheckbox from './HTMLCheckbox';
import { CheckboxProps } from './types';

const Checkbox = (props: CheckboxProps) => {
  const { name, value, ...restProps } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name,
    control,
  });
  const handleChange = (checked: boolean) => {
    if (!checked) {
      field.onChange(value ? '' : false);
    } else {
      field.onChange(value || true);
    }
  };

  return (
    <>
      <HTMLCheckbox
        {...field}
        {...restProps}
        checked={!!field.value}
        isInvalid={!!errors[name]}
        onCheckedChange={handleChange}
      />
      {!!errors[name] && <ErrorLabel error={errors[name]?.message} />}
    </>
  );
};

export default Checkbox;
