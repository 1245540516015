import { ParsedQuery } from 'query-string';

export type NestedFormValues = {
  [index: string]: any;
};

const convertToDotNotation = (formValues: NestedFormValues, prefix?: string): ParsedQuery => {
  const result: ParsedQuery = {};
  if (!formValues) {
    return result;
  }
  Object.keys(formValues).forEach((key) => {
    const newPrefix = prefix ? `${prefix}.${key}` : key;
    const value = formValues[key];
    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(result, convertToDotNotation(value, newPrefix));
    } else {
      result[newPrefix] = value;
    }
  });

  return result;
};

export default convertToDotNotation;
