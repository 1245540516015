import { useController, useFormContext } from 'react-hook-form';
import { BaseProps } from '@/atoms/forms/types';
import Fieldset from '@/molecules/Fieldset';
import addOrRemoveItem from 'utils/form/addOrRemoveItem';
import FactionChip from './FactionChip';

interface CheckFactionOption {
  acf: {
    api_id: string;
    background_image: string;
    display_title: string;
    color: string;
    icon: false;
    icon_font: string;
    reference: string;
    type: string;
  };
}

export interface CheckFactionOptions {
  [value: string]: CheckFactionOption;
}

export type CheckFactionProps = BaseProps & {
  options: CheckFactionOptions;
  disabled?: boolean;
};

const CheckFactionChip = ({ name, options, label, disabled }: CheckFactionProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name,
    control,
    defaultValue: [],
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(addOrRemoveItem(event.target.value, field.value));
  };

  return (
    <Fieldset className="max-w-full min-w-0 overflow-x-auto" disabled={disabled} legend={label}>
      <ul className="flex gap-1.5">
        {Object.keys(options).map((key) => (
          <li key={`${name}-${options[key].acf.display_title}`}>
            <FactionChip
              checked={field.value.includes(options[key].acf?.reference)}
              factionColor={options[key].acf.color}
              factionReference={options[key].acf.reference}
              id={`${name}-${options[key].acf.display_title}`}
              isInvalid={!!errors[name]}
              label={options[key].acf.display_title}
              name={name}
              onChange={onChange}
              value={options[key].acf?.reference}
            />
          </li>
        ))}
      </ul>
    </Fieldset>
  );
};

export default CheckFactionChip;
