import { useTranslation } from 'react-i18next';
import { CURRENCY_API_CODE } from 'types/modifiers/references';

type MoneyDataType = {
  currency?: string | null;
  amount?: number | null;
  options?: Intl.NumberFormatOptions;
};
const MoneyData = ({ currency, amount, options }: MoneyDataType) => {
  const {
    i18n: { language },
  } = useTranslation();
  const intl = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency || CURRENCY_API_CODE.USD,
    ...options,
  });

  return intl.format(amount || 0);
};

export default MoneyData;
