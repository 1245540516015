import { FormProvider } from 'react-hook-form';
import Spinner from '@/atoms/Spinner';
import Form from '@/molecules/Form';
import AddressForm from '@/organisms/form/AddressForm';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useAddressCreateModal from './hooks';

const AddressEditionModal = ({ closeModal, addressId }: { closeModal: () => void; addressId: string }) => {
  const { formMethods, onSubmit, isLoading } = useAddressCreateModal({ closeModal, addressId });

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="modify-address">
      <FormProvider {...formMethods}>
        <Form onSubmit={onSubmit}>
          {isLoading ? <Spinner /> : <AddressForm />}
          <input className="hidden" type="submit" />
        </Form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default AddressEditionModal;
