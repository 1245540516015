import { useModal } from 'context/Modal/Context';
import { MODAL_NAME } from 'context/Modal/MODAL_NAME';
import { useUserTradeList } from 'hooks/queries/tradeList';
import { useForm } from 'react-hook-form';
import { NestedFormValues } from 'utils/filters/convertToDotNotation';
import { useDecodeFilters } from 'utils/filters/encodeDecodeFilters';

type Payload = Array<{ card: string; quantity: number }>;
type Props = {
  user: { nickName: string; id: string };
  onSubmit: (p: Payload) => void;
};
const useCardTradeListModal = ({ user, onSubmit }: Props) => {
  const { openModal } = useModal();

  const currFilters = useDecodeFilters();
  const { data } = useUserTradeList({ userId: user.id, filter: currFilters });

  const formMethods = useForm<NestedFormValues>({
    mode: 'onSubmit',
  });

  const onFilterSubmit = () => {
    openModal(MODAL_NAME.TRADE_CARD_LIST, { user, onSubmit });
  };

  const handleSubmit = (v: NestedFormValues) => {
    const formatted = Object.keys(v).reduce(
      (acc: Payload, cardIri) => [
        ...acc,
        {
          card: cardIri,
          quantity: v[cardIri],
        },
      ],
      [],
    );
    onSubmit(formatted);
  };

  return { data, onFilterSubmit, formMethods, handleSubmit: formMethods.handleSubmit(handleSubmit) };
};

export default useCardTradeListModal;
