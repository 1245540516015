import { useTranslation } from 'next-i18next';
import { FormProvider } from 'react-hook-form';
import TextInput from '@/atoms/forms/Input';
import SearchSelect from '@/atoms/forms/Select/SearchSelect';
import ShowRarity from '@/atoms/ShowRarity';
import CardThumb from '@/molecules/CardThumb';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useUserOptions from 'context/Modal/views/MarketplaceActions/Give/hooks/useUserOptions';
import { SIZE } from 'types/utils';
import useGive from './hooks/useGive';

const GiveCard = ({ reference, closeModal }: { reference: string; closeModal: () => void }) => {
  const { formMethods, onSubmit, numberCopyAvailable, card } = useGive({ closeModal, reference });
  const { t } = useTranslation();
  const { userOptions } = useUserOptions();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="card-donation">
      <FormProvider {...formMethods}>
        <form className="py-8" name="give-card" onSubmit={onSubmit}>
          <div className="grid grid-rows-2 grid-flow-col auto-cols-max gap-4">
            <div className="w-[114px] m-auto md:m-0 row-span-2">
              <CardThumb alt="" imagePath={card?.imagePath} size={SIZE.MD} />
            </div>
            <p>
              <span className="text-neutral-900 uppercase md-bold">{card?.name}</span>
              {!!card?.rarity && <ShowRarity {...card.rarity} />}
            </p>
            <div className="flex flex-col md:flex-row md:items-center gap-1 text-neutral-700">
              <TextInput max={numberCopyAvailable} min={1} name="quantity" type="number" />
              <p className="flex gap-2 items-center">
                <span className="md-bold whitespace-nowrap">{`/ ${numberCopyAvailable}`}</span>
                {t('available', { count: numberCopyAvailable })}
              </p>
            </div>
          </div>
          <p className="my-4 text-neutral-700">{t('give-to')}</p>
          <SearchSelect className="max-w-xl" label="beneficiary" name="user" options={userOptions || {}} />
          <input className="hidden" type="submit" />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default GiveCard;
