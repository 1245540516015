import commonEn from './locales/en-us/common.json';
import commonFr from './locales/fr-fr/common.json';

export enum SUPPORTED_LANGUAGES {
  'EN' = 'en-us',
  'FR' = 'fr-fr',
}

export const defaultLang = SUPPORTED_LANGUAGES.EN;

export const resources = {
  [SUPPORTED_LANGUAGES.EN]: {
    translation: { ...commonEn },
  },
  [SUPPORTED_LANGUAGES.FR]: {
    translation: { ...commonFr },
  },
};
