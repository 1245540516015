import { yupResolver } from '@hookform/resolvers/yup';
import { MangopayVault } from '@mangopay/vault-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { env } from 'next-runtime-env';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CardRegistrationPaymentCardJsonldPaymentPaymentCardRead } from '@/api/generated';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const paymentSchema = () =>
  yup
    .object({
      cardNumber: yup.string().required(),
      cardExpirationDate: yup.string().required(),
      cardCvx: yup.string().required(),
      persist: yup.boolean(),
    })
    .required();

type FormValues = yup.InferType<ReturnType<typeof paymentSchema>>;
const paymentMethodsFactory = createKeyFactory([KEYS.PAYMENT_METHODS]);

const useCreatePaymentMethod = (callBack: (data: CardRegistrationPaymentCardJsonldPaymentPaymentCardRead) => void) => {
  const apiClient = useApi();
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(paymentSchema()),
  });
  const { handleSubmit } = formMethods;
  const queryClient = useQueryClient();

  const options = {
    clientId: env('NEXT_PUBLIC_MANGOPAY_CLIENT_ID') || '',
    environment: env('NEXT_PUBLIC_MANGOPAY_ENVIRONNEMENT') || '',
  };

  const vault = MangopayVault.initialize(options);

  const createPaiementCard = useMutation({
    mutationKey: ['createPaiementCard'],
    mutationFn: async ({ persist, ...cardInfos }: FormValues) => {
      const {
        data: { cardRegistrationURL, preRegistrationData, id, accessKey },
      } = await apiClient.payments.apiPaymentscardRegistrationPost({});

      const registrationData = {
        cardRegistrationURL: cardRegistrationURL!,
        data: preRegistrationData!,
        id: id!,
        accessKeyRef: accessKey!,
      };

      const tokenizePaymentMethodResult = await vault.tokenizePaymentMethod(cardInfos, registrationData).catch((e) => {
        throw new Error(e.ResultMessage);
      });

      if (!tokenizePaymentMethodResult || !tokenizePaymentMethodResult.Id) {
        throw new Error('tokenizePaymentMethodResult failed');
      }

      return apiClient.payments.apiPaymentscardRegistrationIdPut(tokenizePaymentMethodResult.Id, {
        registrationData: tokenizePaymentMethodResult.RegistrationData,
        persist: !!persist,
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: paymentMethodsFactory.list({ 'order[createdAt]': 'asc' }) });
      if (callBack) {
        callBack(res.data);
      }
    },
  });

  const onSubmit = async ({ cardExpirationDate, ...values }: FormValues) => {
    await createPaiementCard.mutate({
      ...values,
      cardExpirationDate: cardExpirationDate.split('/').join(''),
    });
  };

  return { formMethods, onSubmit: handleSubmit(onSubmit) };
};

export default useCreatePaymentMethod;
