import { format, formatDistanceToNow } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';
import { useTranslation } from 'next-i18next';

const useDateFnsWithLocale = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const locale = language === 'fr-fr' ? fr : enUS;

  const formatDistanceToNowWithLocale = (date: Date) => formatDistanceToNow(date, { locale });

  const formatWithLocale = (date: Date, formatOption: string) => format(date, formatOption, { locale });

  return { formatDistanceToNow: formatDistanceToNowWithLocale, format: formatWithLocale };
};

export default useDateFnsWithLocale;
