import clx from 'classnames';
import CardThumbBadge from '@/molecules/CardThumb/components/Badge';

import { Mapped, SIZE } from 'types/utils';

export const SIZE_TO_CLASSNAME: Mapped<SIZE, string> = {
  [SIZE.SM]: 'w-full max-w-[72px] max-h-[100px]',
  [SIZE.MD]: 'w-full max-w-[120px] max-h-[167px]',
  [SIZE.LG]: 'w-full max-w-[180px] max-h-[250px] md:max-w-[250px] md:max-h-[349px]',
};
type CardThumbProps = {
  size?: SIZE;
  imagePath?: string | null;
  className?: string;
  alt: string;
  inMyCollection?: number;
};
const CardThumb = ({ className, imagePath = '/card-back.jpg', size, alt, inMyCollection }: CardThumbProps) => (
  <div className="relative">
    <div className={clx('overflow-hidden w-fit', size === SIZE.SM ? 'rounded' : 'rounded-xl', className)}>
      <img alt={alt} className={size && SIZE_TO_CLASSNAME[size]} src={imagePath as string} />
    </div>
    {inMyCollection !== undefined && inMyCollection > 0 && (
      <CardThumbBadge
        className="absolute right-1 bottom-1"
        count={inMyCollection}
        icon="fa-kit fa-collection"
        toolTip="copies-I-own"
      />
    )}
  </div>
);

export default CardThumb;
