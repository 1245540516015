import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserJsonldUserWriteShopWriteAddressWrite } from '@/api/generated';
import { useToaster } from 'context/Toaster/ToasterContext';
import useApi from 'hooks/useApi';
import useUserApi from 'hooks/UserApi/useUserApi';
import { VARIANT_COLOR_ENUM } from 'types/utils';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';
import getULID from 'utils/getULID';

const shopsFactory = createKeyFactory([KEYS.SHOPS]);

const useShopMembersApi = () => {
  const apiClient = useApi();
  const client = useQueryClient();
  const { user } = useUserApi();
  const shopId = getULID(user?.shop);

  const queryKey = shopsFactory.details(shopId || '');
  const { addToaster } = useToaster();

  const addMember = useMutation({
    mutationKey: ['addShopMember'],
    mutationFn: async (data: UserJsonldUserWriteShopWriteAddressWrite) => apiClient.users.apiUsersshopMemberPost(data),
    onSuccess: () => {
      client.invalidateQueries({ queryKey });
      addToaster({ variant: VARIANT_COLOR_ENUM.SUCCESS, message: 'changes-saved' });
    },
  });

  const handleAddMember = (data: UserJsonldUserWriteShopWriteAddressWrite) => {
    addMember.mutate(data);
  };

  const removeMember = useMutation({
    mutationKey: ['removeShopMember'],
    mutationFn: async (id: string) => apiClient.shopMembers.apiShopMembersIdDelete(id),
    onSuccess: () => {
      client.invalidateQueries({ queryKey });
      addToaster({ variant: VARIANT_COLOR_ENUM.SUCCESS, message: 'changes-saved' });
    },
  });

  const handleRemoveMember = (id?: string | null) => {
    if (id) {
      removeMember.mutate(id);
    }
  };

  return { handleRemoveMember, handleAddMember };
};

export default useShopMembersApi;
