import Script from 'next/script';

type Props = {
  metalPixelId?: string;
  twitterPixelId?: string;
  redditPixelId?: string;
  tiktokPixelId?: string;
  googleTagId?: string;
  googleTagManagerId?: string;
};

const PixelScript = ({
  metalPixelId,
  twitterPixelId,
  redditPixelId,
  tiktokPixelId,
  googleTagId,
  googleTagManagerId,
}: Props) => (
  <>
    {metalPixelId && (
      <Script data-id={metalPixelId} id="fb-pixel" src="/scripts/facebook.js" strategy="afterInteractive" />
    )}
    {twitterPixelId && (
      <Script data-id={twitterPixelId} id="twitter-pixel" src="/scripts/twitter.js" strategy="afterInteractive" />
    )}
    {redditPixelId && (
      <Script data-id={redditPixelId} id="reddit-pixel" src="/scripts/reddit.js" strategy="afterInteractive" />
    )}
    {tiktokPixelId && (
      <Script data-id={tiktokPixelId} id="tiktok-pixel" src="/scripts/tiktok.js" strategy="afterInteractive" />
    )}
    {googleTagId && (
      <>
        <Script src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`} async />
        <Script data-id={googleTagId} id="gtag-pixel" src="/scripts/google-tag.js" strategy="afterInteractive" />
      </>
    )}
    {googleTagManagerId && (
      <Script
        data-id={googleTagManagerId}
        id="gtm-pixel"
        src="/scripts/google-tag-manager.js"
        strategy="afterInteractive"
      />
    )}
  </>
);
export default PixelScript;
