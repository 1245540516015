import clx from 'classnames';
import { SIZE, VARIANT_COLOR_ENUM } from 'types/utils';

export enum CARD_VARIANT_COLOR_ENUM {
  FOREST = 'FOREST',
  MOUNTAIN = 'MOUNTAIN',
  OCEAN = 'OCEAN',
  COST_HAND = 'COST_HAND',
  COST_RECALL = 'COST_RECALL',
}

type BadgeProps = {
  children: string | number | JSX.Element;
  variant?: VARIANT_COLOR_ENUM | CARD_VARIANT_COLOR_ENUM;
  className?: string;
  size?: SIZE;
};

const VARIANT_TO_CLASS = {
  [VARIANT_COLOR_ENUM.PRIMARY]: 'bg-primary-400',
  [VARIANT_COLOR_ENUM.ERROR]: 'bg-system-error-400',
  [VARIANT_COLOR_ENUM.NEUTRAL]: 'bg-neutral-600',
  [VARIANT_COLOR_ENUM.DISABLED]: 'bg-neutral-600',
  [VARIANT_COLOR_ENUM.INFO]: 'bg-neutral-600',
  [VARIANT_COLOR_ENUM.SECONDARY]: 'bg-neutral-600',
  [VARIANT_COLOR_ENUM.SUCCESS]: 'bg-neutral-600',
  [CARD_VARIANT_COLOR_ENUM.FOREST]: 'bg-forest',
  [CARD_VARIANT_COLOR_ENUM.OCEAN]: 'bg-ocean',
  [CARD_VARIANT_COLOR_ENUM.MOUNTAIN]: 'bg-mountain',
  [CARD_VARIANT_COLOR_ENUM.COST_HAND]: 'bg-cost-hand',
  [CARD_VARIANT_COLOR_ENUM.COST_RECALL]: 'bg-cost-recall',
};

const SIZE_TO_CLASS = {
  [SIZE.SM]: 'w-4 h-4 xs-bold-caps',
  [SIZE.MD]: 'w-6 h-4 xs-bold-caps',
  [SIZE.LG]: 'w-6 h-6 sm-bold-caps',
};

const Badge = ({ children, variant = VARIANT_COLOR_ENUM.NEUTRAL, className, size = SIZE.SM }: BadgeProps) => (
  <div
    className={clx(
      'flex items-center justify-center rounded-full text-neutral-0 text-shadow-lg shadow-black',
      SIZE_TO_CLASS[size],
      VARIANT_TO_CLASS[variant],
      className,
    )}
  >
    {children}
  </div>
);

export default Badge;
