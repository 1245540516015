import React from 'react';
import ButtonOrLink, { ButtonOrLinkProps } from '@/atoms/ButtonOrLink/ButtonOrLink';
import clx from 'classnames';

type Props = ButtonOrLinkProps & { children?: string };

const ScanButton = ({ children, className, ...props }: Props) => (
  <ButtonOrLink
    className={clx(
      `rounded-2xl py-4 px-6 flex items-center justify-center gap-2
      text-white md-bold-caps tracking-wide whitespace-nowrap
      transition-all duration-quicker ease-out clickable font-sans shadow-overlap
      bg-gradient-to-r bg-gradient from-[#2F90AE]  to-[#00C3FF]
      disabled:bg-system-disabled-300 disabled:text-system-disabled-400
      hover:from-20% hover:shadow-raised
      `,
      className,
    )}
    {...props}
  >
    <i className="fa-kit fa-altered-swirl text-xl hover:animate-pulse font-thin shadow-black text-shadow-button" />
    {children && <span className="shadow-black text-shadow-button">{children}</span>}
  </ButtonOrLink>
);

export default ScanButton;
