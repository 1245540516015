import {
  UserBlockedJsonldUserBlockedRead,
  UserFriendshipJsonldUserFriendshipReadUserRead,
  UserJsonldUserFriendshipReadUserRead,
  UserJsonldUserReadUserPrivateRead,
} from '@/api/generated';
import Avatar from '@/atoms/Avatar';
import ListItem from '@/molecules/ListItem';
import FriendListCta, { FRIENDS_STATUS } from './FriendListCta';
import Pagination from '@/molecules/Pagination';
import { FRIENDS_PER_PAGE } from 'constants/itemsPerPage';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import useUserApi from 'hooks/UserApi/useUserApi';

const getProfileUrl = ({
  loggedUser,
  user,
}: {
  loggedUser: UserJsonldUserReadUserPrivateRead | undefined;
  user?: UserJsonldUserFriendshipReadUserRead | null;
}) => {
  let profileUrl = `/profile`;

  if (!loggedUser || (user && loggedUser.id !== user.id && 'uniqueId' in user)) {
    profileUrl = `${profileUrl}/${user?.uniqueId}`;
  }

  return profileUrl;
};

const FriendsList = ({
  users,
  total,
}: {
  users: UserFriendshipJsonldUserFriendshipReadUserRead[] | UserBlockedJsonldUserBlockedRead[];
  total: number;
}) => {
  const { t } = useTranslation();
  const { user: loggedUser } = useUserApi();

  return (
    <div className="flex flex-col gap-2">
      <p className="text-neutral-600">
        <span className="md-bold">{total}</span> {t('results')}
      </p>
      {users.map((value) => {
        const friendShipId = value?.['@id']?.split('/').pop() || '';
        let user;

        if ('userFriend' in value) {
          user = value.userFriend;
        }
        if ('blockedUser' in value) {
          user = value.blockedUser;
        }

        return !user ? null : (
          <ListItem
            key={user['@id']}
            footerProps={
              'userFriend' in value && user.id !== loggedUser?.id
                ? {
                    children: (
                      <FriendListCta
                        friendStatus={(value?.userFriend?.friendStatus as FRIENDS_STATUS) || FRIENDS_STATUS.BLOCKED}
                        friendshipId={friendShipId}
                        userId={user['@id'] || ''}
                      />
                    ),
                  }
                : undefined
            }
            headerProps={{
              href: getProfileUrl({ user: 'userFriend' in value ? value.userFriend : undefined, loggedUser }),
              imageNode: (
                <Link href={`/profile/${'uniqueId' in user ? user.uniqueId : undefined}`}>
                  <Avatar alt="" src={user.avatarPath} border />
                </Link>
              ),
              leadText: user.nickName,
            }}
          />
        );
      })}
      <Pagination itemsPerPage={FRIENDS_PER_PAGE} totalItems={total} />
    </div>
  );
};

export default FriendsList;
