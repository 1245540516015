import clx from 'classnames';
import { ReactNode } from 'react';

const TabsContainer = ({ className, children }: { className?: string; children: ReactNode }) => (
  <ul
    className={clx(
      'flex flex-row rounded-tl-2xl rounded-bl-[4px] rounded-tr-[4px] rounded-br-2xl gap-1 sm:gap-2 rounded-lg w-fit sm:w-full max-w-full overflow-x-auto border border-neutral-40 px-2 sm:px-3',
      className,
    )}
  >
    {children}
  </ul>
);

export default TabsContainer;
