import FriendsList from '@/organisms/FriendsList';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useFriendsListModal from './hooks';

type Props = {
  closeModal: () => void;
  userId: string;
};

const FriendsListModal = ({ closeModal, userId }: Props) => {
  const { data } = useFriendsListModal(userId);

  return (
    <ModalClassicLayout closeModal={closeModal} title="friends-list" alert>
      <FriendsList total={data?.['hydra:totalItems'] || 0} users={data?.['hydra:member'] || []} />
    </ModalClassicLayout>
  );
};

export default FriendsListModal;
