import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CARDS_PER_PAGE, REQUEST_PER_PAGE } from 'constants/itemsPerPage';
import { useToaster } from 'context/Toaster/ToasterContext';
import useApi from 'hooks/useApi';
import { useRouter } from 'next/router';
import { ParsedQuery } from 'query-string';
import { VARIANT_COLOR_ENUM } from 'types/utils';
import tradeListFactory from 'utils/factories/tradeList';

export const useUserTradeList = ({
  userId,
  enabled = true,
  filter,
}: {
  userId: string;
  enabled?: boolean;
  filter?: ParsedQuery;
}) => {
  const api = useApi();
  const { query } = useRouter();
  const queryResult = useQuery({
    queryKey: tradeListFactory.myFriendList({ userId, ...(filter || {}) }),
    queryFn: () =>
      api.ownershipLists.apiOwnershipListsListNameusersUserGetCollection('tradelist', userId, {
        ...filter,
        itemsPerPage: CARDS_PER_PAGE,
        page: Number(query.page as string) || 1,
      }),
    enabled,
    select: (v) => v.data,
  });

  return queryResult;
};

type MyRequestProps = {
  enabled?: boolean;
  param?: ParsedQuery;
};
export const useMyrequests = ({ enabled = true, param }: MyRequestProps) => {
  const api = useApi();
  const { query } = useRouter();

  const queryResult = useQuery({
    queryKey: tradeListFactory.myRequests({ ...(param || {}) }),
    queryFn: () =>
      api.ownerExchangeRequests.apiOwnerExchangeRequestsGetCollection({
        ...param,
        itemsPerPage: REQUEST_PER_PAGE,
        page: Number(query.page as string) || 1,
      }),
    enabled,
    select: (v) => v.data,
  });

  return queryResult;
};

type RequestDetailsProps = {
  enabled?: boolean;
  id: string;
};

export const useRequestsDetails = ({ enabled = true, id }: RequestDetailsProps) => {
  const api = useApi();

  const queryResult = useQuery({
    queryKey: tradeListFactory.details(id),
    queryFn: () => api.ownerExchangeRequests.apiOwnerExchangeRequestsIdGet(id),
    select: (v) => v.data,
    enabled,
  });

  return queryResult;
};

export const useAcceptExchange = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { addToaster } = useToaster();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: string) => api.ownerExchangeRequests.apiOwnerExchangeRequestsIdacceptPut(id, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: tradeListFactory.all() });
      addToaster({ message: 'trading.toaster.accepted', variant: VARIANT_COLOR_ENUM.SUCCESS });
    },
  });

  return { acceptExchange: mutate, isPendingAcceptExchange: isPending };
};

export const useCancelExchange = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { addToaster } = useToaster();

  const { mutate, isPending } = useMutation({
    mutationKey: ['cancelTradeList'],
    mutationFn: (id: string) => api.ownerExchangeRequests.apiOwnerExchangeRequestsIdcancelPut(id, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: tradeListFactory.all() });
      addToaster({ message: 'trading.toaster.canceled', variant: VARIANT_COLOR_ENUM.SUCCESS });
    },
  });

  return { cancelExchange: mutate, isPendingCancelExchange: isPending };
};
