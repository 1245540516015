import { useMutation } from '@tanstack/react-query';
import useApi from 'hooks/useApi';

const useCreateCart = (onSuccess: () => void) => {
  const apiClient = useApi();
  const createCart = useMutation({
    mutationKey: ['createCart'],
    mutationFn: (newCart: { quantity?: number | undefined; offerGroupId?: string | undefined }) =>
      apiClient.marketplace.apiMarketplaceordercreatePost(newCart),
    onSuccess,
  });

  return { createCart };
};

export default useCreateCart;
