import { useMutation } from '@tanstack/react-query';
import useApi from 'hooks/useApi';

const useAddOffers = (onSuccess: () => void, onError: (error: unknown) => void) => {
  const apiClient = useApi();
  const addOffer = useMutation({
    mutationFn: apiClient.marketplace.apiMarketplaceaddPost,
    onSuccess,
    onError,
  });

  return { addOffer };
};

export default useAddOffers;
