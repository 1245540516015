import { useTranslation } from 'next-i18next';
import CardThumb from '@/molecules/CardThumb';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useCardDetailsApi from 'hooks/queries/cards/useCardsDetailsApi';
import useApplyFoiler from 'hooks/queries/print/useApplyFoiler';
import { SIZE } from 'types/utils';

type ApplyFoilerModalProps = {
  closeModal: () => void;
  reference: string;
};
const ApplyFoilerModal = ({ closeModal, reference }: ApplyFoilerModalProps) => {
  const { t } = useTranslation();
  const { card } = useCardDetailsApi(reference);
  const { addFoiler } = useApplyFoiler(reference);

  const onSubmit = () => {
    addFoiler.mutate();
  };

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="foiler">
      <div className="flex flex-col gap-8 my-4">
        <p>{t('apply-foiler-validation', { cardname: card?.name, rarity: card?.rarity?.name })}</p>
        <CardThumb alt={card?.name || ''} className="mx-auto" imagePath={card?.imagePath} size={SIZE.LG} />
      </div>
    </ModalClassicLayout>
  );
};
export default ApplyFoilerModal;
