export const bankAccountTypes = ['IBAN', 'US', 'CA', 'GB', 'OTHER'] as const;

export type BankType = (typeof bankAccountTypes)[number];

export enum BankAccountFields {
  'type' = 'type',
  'ownerName' = 'ownerName',
  'IBAN' = 'IBAN',
  'BIC' = 'BIC',
  'accountNumber' = 'accountNumber',
  'ABA' = 'ABA',
  'institutionNumber' = 'institutionNumber',
  'branchCode' = 'branchCode',
  'bankName' = 'bankName',
  'sortCode' = 'sortCode',
  'country' = 'country',
  'countryLabel' = 'countryLabel',
  'addressLine1' = 'addressLine1',
  'addressLine2' = 'addressLine2',
  'city' = 'city',
  'postalCode' = 'postalCode',
  'region' = 'region',
}
