// TO REMOVE WHEN WE WILL WORK ON POD V2

import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const printingFactory = {
  ...createKeyFactory([KEYS.PRINTING]),
  pageType: () => [...printingFactory.all(), 'printing_page_types'],
  totalQuantity: () => [...printingFactory.all(), 'unread'],
  currentPrintings: () => [...printingFactory.all(), 'currentPrintings'],
  transactions: () => [...printingFactory.all(), 'printingTransaction'],
  transactionsDetail: (id: string) => [...printingFactory.all(), 'printingTransaction', id],
  participant: (id: string) => [...printingFactory.all(), 'printingParticipant', id],
  pricingGrid: (id: string) => [...printingFactory.all(), 'pricingGrid', id],
};

export default printingFactory;
