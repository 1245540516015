import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { EventJsonldEventRead } from '@/api/generated';
import { useToaster } from 'context/Toaster/ToasterContext';
import useApi from 'hooks/useApi';
import useUserApi from 'hooks/UserApi/useUserApi';
import { VARIANT_COLOR_ENUM } from 'types/utils';
import useCurrEventUser from './useCurrEventUser';
import useDecksOptions from './useDecksOptions';

type FormValues = { decks: string[] };

const useDecksRegistrationModal = (closeModal: () => void, event?: EventJsonldEventRead) => {
  const apiClient = useApi();
  const { addToaster } = useToaster();
  const { t } = useTranslation();
  const { decksOptions } = useDecksOptions(event);
  const deckUserMax = event?.deckUserMax || 0;
  const { userId } = useUserApi();

  const decksToPrintSchema = () =>
    yup
      .object({
        decks: yup
          .array()
          .max(deckUserMax, t('deck-max', { count: deckUserMax }) || '')
          .required(),
      })
      .required();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(decksToPrintSchema()),
  });
  const { handleSubmit, setError, reset } = formMethods;

  const { currEventUser, invalidateQuery } = useCurrEventUser(event);

  const registerDecks = useMutation({
    mutationFn: ({ eventId, deckUserLists }: { eventId: string; deckUserLists: string[] }) =>
      apiClient.eventUsers.apiEventUsersPost({
        user: `users/${userId}`,
        event: eventId,
        deckUserLists,
      }),
    onSuccess: () => {
      addToaster({ message: t('deck-registered', { count: deckUserMax }), variant: VARIANT_COLOR_ENUM.SUCCESS });
      invalidateQuery();
      closeModal();
    },
    onError: () => setError('decks', { type: 'server', message: t('unexpected-error') || '' }),
  });

  const onSubmit = (formValues: FormValues) => {
    if (event?.['@id']) {
      registerDecks.mutate({ eventId: event['@id'], deckUserLists: formValues.decks });
    }
  };

  useEffect(() => {
    reset({ decks: currEventUser?.deckUserLists });
  }, [currEventUser]);

  return { decksOptions, formMethods, onSubmit: handleSubmit(onSubmit), closeModal };
};

export default useDecksRegistrationModal;
