import { PropsWithChildren } from 'react';

const Facility = ({ icon, children }: PropsWithChildren<{ icon: string }>) => (
  <div className="flex gap-1 items-center border-r last:border-0 border-neutral-50 pr-4">
    <i className={icon} />
    {children}
  </div>
);

export default Facility;
