import { env } from 'next-runtime-env';
import { useTranslation } from 'react-i18next';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import ModalClassicLayout from 'context/Modal/layout/Classic';

const ShareDeck = ({ closeModal, id }: { closeModal: () => void; id: string }) => {
  const { t } = useTranslation();

  const copyFn = () => navigator.clipboard.writeText(window.location.href);

  return (
    <ModalClassicLayout closeModal={closeModal} title={t('share-deck')}>
      <p className="flex flex-col pb-4 text-center">{t('share-deck-qr-code')}</p>
      <img alt="" className="m-auto" src={`${env('NEXT_PUBLIC_API_ENTRYPOINT')}/display_qr_code?uri=/decks/${id}`} />
      <Button className="m-auto" icon="fa-solid fa-link" onClick={copyFn} variant={BUTTON_VARIANTS.TERTIARY}>
        {t('copy-link')}
      </Button>
    </ModalClassicLayout>
  );
};

export default ShareDeck;
