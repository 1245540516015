export const REGISTER_FUNNEL = 'REGISTER_FUNNEL';

export const NEW_GAME_FUNNEL = 'NEW_GAME_FUNNEL';

export const SURVEY_FUNNEL = 'SURVEY_FUNNEL';

export const FCM_DEVICE_TOKEN = 'FCM_DEVICE_TOKEN';

export const DISMISS_LANGUAGE_SWITCH = 'DISMISS_LANGUAGE_SWITCH';

export const REGISTER_PRO_FUNNEL = 'REGISTER_PRO_FUNNEL';

export const USER_CARD_LAYOUT = 'USER_CARD_LAYOUT';

export const COOKIE_CONSENT = 'cookie_consent';
