import { FormOptions } from 'types/forms';

const slugify = (str: string) => str.toLowerCase().trim();

const matchOptions = (term: string, options: FormOptions) => {
  if (!term || term.length === 0) {
    return Object.keys(options).map((id) => id);
  }

  return Object.entries(options).reduce((arr, [id, option]) => {
    if (slugify(option.label).includes(slugify(term))) {
      return [...arr, id];
    }

    return arr;
  }, [] as string[]);
};

export default matchOptions;
