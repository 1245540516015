import { compareAsc, formatISO9075 } from 'date-fns';
import { createApiClient } from '@/api/client';

const apiClient = createApiClient();

export const isUniqueEmail = async (value?: string): Promise<boolean> => {
  if (!value || value === '') {
    return true;
  }
  const res = await apiClient.users.apiUsersuniqueEmailEmailGet(value);

  return Boolean(res.data.isUnique);
};

export const isUniqueNickName = async (value?: string): Promise<boolean> => {
  if (!value || value === '') {
    return true;
  }
  const res = await apiClient.users.apiUsersuniqueNicknameNickNameGet(value);

  return Boolean(res.data.isUnique);
};

export const birthDate = (dateISO: string): boolean =>
  compareAsc(dateISO, '1920-01-01') > 0 &&
  compareAsc(dateISO, formatISO9075(new Date(), { representation: 'date' })) < 0;
