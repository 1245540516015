import clx from 'classnames';
import { ReactNode } from 'react';
import MoneyData from '@/atoms/MoneyData';

type ProductWrapProps = {
  image?: string | null;
  title?: string | null;
  subTitle?: string | null;
  line1?: ReactNode | null;
  line2?: ReactNode | null;
  line3?: ReactNode | null;
  price?: number;
  currency?: string;
  className?: string;
};

const ProductWrap = ({ image, title, subTitle, line1, line2, line3, price, currency, className }: ProductWrapProps) => (
  <div className={clx('flex flex-wrap bg-neutral-20 rounded-2xl p-2 md:p-4 gap-3', className)}>
    {image && (
      <figure>
        <img alt="" className="w-[72px] md:w-28" height={406} src={image} width={291} />
      </figure>
    )}
    <div className="flex flex-wrap gap-3 grow">
      <div className="flex flex-col items-between grow">
        <div className="grow">
          <p className="lg-bold text-neutral-900">{title}</p>
          <p className="text-neutral-400 sm-bold">{subTitle}</p>
        </div>
        <div>
          {typeof line1 === 'string' ? <p className="md-medium text-neutral-400">{line1}</p> : line1}
          {typeof line2 === 'string' ? <p className="md-medium text-neutral-400">{line2}</p> : line2}
          {typeof line3 === 'string' ? <p className="md-medium text-neutral-400">{line3}</p> : line3}
        </div>
      </div>
      {price && (
        <div className="lg-bold-caps self-end">
          <MoneyData amount={price} currency={currency} />
        </div>
      )}
    </div>
  </div>
);

export default ProductWrap;
