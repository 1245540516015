import clx from 'classnames';
import { InputHTMLAttributes } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import Button from '@/atoms/Button';
import { ErrorLabel, Label } from '@/atoms/forms/labels';
import { SIZE } from 'types/utils';
import HTMLInput from '../Input/Text/HTMLInput';

type CounterType = InputHTMLAttributes<InputHTMLAttributes<HTMLInputElement>> & {
  label?: string;
  name: string;
  hintText?: string;
};

const CounterInput = ({ label, name, disabled, min, max, required, hintText, className }: CounterType) => {
  const { control, formState } = useFormContext();
  const { field } = useController({
    name,
    control,
    defaultValue: 0,
  });
  const { errors } = formState;
  const error = errors[name];

  const increase = () => {
    if (!max || field.value < max) {
      field.onChange(Number(field.value) + 1);
    }
  };
  const decrease = () => {
    if (!min || min < field.value) {
      field.onChange(Number(field.value) - 1);
    }
  };

  return (
    <>
      {label && <Label disabled={disabled} label={label} name={name} required={required} />}
      <div className={clx('flex gap-4', className)}>
        <Button
          disabled={disabled || min === field.value}
          icon="fa-solid fa-minus"
          onClick={() => decrease()}
          size={SIZE.LG}
        />
        <div className="w-16">
          <HTMLInput
            disabled={disabled}
            {...field}
            id={name}
            max={max}
            min={min}
            onChange={({ target: { value } }) => field.onChange(Number(value))}
            type="number"
            value={field.value || 0}
          />
        </div>
        <Button
          disabled={disabled || max === field.value}
          icon="fa-solid fa-plus-large"
          onClick={() => increase()}
          size={SIZE.LG}
        />
      </div>
      {!!hintText && <p className="md-regular">{hintText}</p>}
      {!!error && <ErrorLabel error={error.message} />}
    </>
  );
};

export default CounterInput;
