import TextInput from '@/atoms/forms/Input';
import { BankAccountFields } from './types';

const BankAccountTypeFields = ({ type }: { type: string }) => {
  switch (type) {
    case 'IBAN':
      return (
        <>
          <TextInput
            label="bank-account-iban"
            name={BankAccountFields.IBAN}
            placeholder="bank-account-iban"
            type="text"
          />
          <TextInput label="bank-account-bic" name={BankAccountFields.BIC} placeholder="bank-account-bic" type="text" />
        </>
      );
    case 'US':
      return (
        <>
          <TextInput
            label="bank-account-number"
            name={BankAccountFields.accountNumber}
            placeholder="bank-account-number"
            type="text"
          />
          <TextInput label="bank-account-aba" name={BankAccountFields.ABA} placeholder="bank-account-aba" type="text" />
        </>
      );
    case 'CA':
      return (
        <>
          <TextInput
            label="bank-account-number"
            name={BankAccountFields.accountNumber}
            placeholder="bank-account-number"
            type="text"
          />
          <TextInput
            label="bank-account-institution-number"
            name={BankAccountFields.institutionNumber}
            placeholder="bank-account-institution-number"
            type="text"
          />
          <TextInput
            label="bank-account-branch-code"
            name={BankAccountFields.branchCode}
            placeholder="bank-account-branch-code"
            type="text"
          />
          <TextInput
            label="bank-account-bank-name"
            name={BankAccountFields.bankName}
            placeholder="bank-account-bank-name"
            type="text"
          />
        </>
      );
    case 'GB':
      return (
        <>
          <TextInput
            label="bank-account-number"
            name={BankAccountFields.accountNumber}
            placeholder="bank-account-number"
            type="text"
          />
          <TextInput
            label="bank-account-sort-code"
            name={BankAccountFields.sortCode}
            placeholder="bank-account-sort-code"
            type="text"
          />
        </>
      );
    case 'OTHER':
    default:
      return (
        <>
          <TextInput
            label="bank-account-number"
            name={BankAccountFields.accountNumber}
            placeholder="bank-account-number"
            type="text"
          />
          <TextInput label="bank-account-bic" name={BankAccountFields.BIC} placeholder="bank-account-bic" type="text" />
          <TextInput
            label="bank-account-country"
            name={BankAccountFields.country}
            placeholder="bank-account-country"
            type="text"
          />
        </>
      );
  }
};

export default BankAccountTypeFields;
