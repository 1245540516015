import Checkbox from '@/atoms/forms/checkbox';
import TextInput from '@/atoms/forms/Input';
import Select from '@/atoms/forms/Select';
import Spinner from '@/atoms/Spinner';
import useCountriesOptions from 'hooks/options/useCountriesOptions';

const AddressForm = () => {
  const { countriesOptions } = useCountriesOptions();

  return (
    <>
      <TextInput label="address-name" name="label" placeholder="address-name" type="text" required />
      <TextInput label="last-name" name="lastName" placeholder="last-name" type="text" required />
      <TextInput label="first-name" name="firstName" placeholder="first-name" type="text" required />
      {countriesOptions ? (
        <Select label="country" name="country" options={countriesOptions} placeholder="country" required />
      ) : (
        <Spinner />
      )}
      <TextInput label="address" name="street" placeholder="address" type="text" required />
      <TextInput label="zip-code" name="zipCode" placeholder="zip-code" type="text" required />
      <TextInput label="city" name="city" placeholder="city" type="text" required />
      <Checkbox label="set-as-default-billing" name="isDefaultBilling" />
      <Checkbox label="set-as-default-shipping" name="isDefaultShipping" />
    </>
  );
};

export default AddressForm;
