import { useTranslation } from 'next-i18next';
import React from 'react';
import { EventJsonldEventLocatorReadShopLocatorRead } from '@/api/generated';

import Tile from '@/molecules/Tile';
import EventTileContent from '@/organisms/EventTile/EventTileContent';
import { Event } from 'types/modifiers/Event';

import { SIZE, VARIANT_COLOR_ENUM } from 'types/utils';
import { computeStatusChip } from './utils/computeStatusChip';

const EventTile = ({
  event,
  // onClick,
  slug,
}: {
  event: Event | EventJsonldEventLocatorReadShopLocatorRead;
  // eslint-disable-next-line react/no-unused-prop-types
  onClick: (arg: Event) => void;
  slug: string | undefined;
}) => {
  const { t } = useTranslation();
  if (!event.dateTime) {
    throw new Error(`no dateTime for event ${event['@id']}`);
  }

  return (
    <Tile
      chips={[
        ...(event.eventType?.name
          ? [
              {
                children: event.eventType.name,
                color: VARIANT_COLOR_ENUM.NEUTRAL,
                size: SIZE.SM,
              },
            ]
          : []),
        ...(event.status && event.attendeeMax ? [computeStatusChip({ ...event }, t)] : []),
      ]}
      className="h-full"
      href={`${slug}/${event.id}`}
      icon={event.mobilityReducedAccessibility ? 'fa-brands fa-accessible-icon' : ''}
      image={event.illustrationPath}
      title={event.name}
    >
      <EventTileContent event={event} minified />
    </Tile>
  );
};

export default EventTile;
