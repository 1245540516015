import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const useMyCart = () => {
  const apiClient = useApi();
  const keyFactory = createKeyFactory([KEYS.CART]);
  const queryClient = useQueryClient();

  const {
    data: cart,
    isLoading,
    isError,
  } = useQuery({
    queryKey: keyFactory.list(),
    queryFn: async () => {
      const res = await apiClient.marketplace.apiMarketplaceordermyCartGet();

      return res.data;
    },
  });

  const removeGroupOffer = useMutation({
    mutationKey: ['removeGroupOffer'],
    mutationFn: (offerGroupId: string) =>
      apiClient.marketplace.apiMarketplaceordermyCartofferGroupOfferGroupIdDelete(offerGroupId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keyFactory.list() });
    },
  });

  return { cart, isLoading, isError, removeGroupOffer };
};

export default useMyCart;
