import { useTranslation } from 'next-i18next';
import { FormProvider } from 'react-hook-form';
import { DeckUserListJsonldDeckUserListReadDeckUserListDetailRead } from '@/api/generated';
import Checkbox from '@/atoms/forms/checkbox';
import TextInput from '@/atoms/forms/Input';
import TextArea from '@/atoms/forms/TextArea';
import ModalClassicLayout from '../../../layout/Classic';
import useRenameDeck from './hooks';

type RenameDeckProps = {
  closeModal: () => void;
  deck: DeckUserListJsonldDeckUserListReadDeckUserListDetailRead;
};
const RenameDeckModal = ({ closeModal, deck }: RenameDeckProps) => {
  const { handleSubmit, formMethods } = useRenameDeck(deck, closeModal);
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={t('modify')}>
      <div className="flex flex-col pb-4">
        <FormProvider {...formMethods}>
          <form className="flex flex-col gap-4" name="rename-deck" onSubmit={handleSubmit}>
            <TextInput label="deck-name" max={20} name="name" />
            <TextArea label="description" name="description" />
            <Checkbox label="private-deck" name="private" />
          </form>
        </FormProvider>
      </div>
    </ModalClassicLayout>
  );
};

export default RenameDeckModal;
