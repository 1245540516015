import clx from 'classnames';
import { forwardRef } from 'react';
import ButtonOrLink, { ButtonOrLinkProps } from '@/atoms/ButtonOrLink/ButtonOrLink';
import { SIZE_TO_CLASS } from '@/atoms/IconButton/const';
import { IconButtonBaseProps } from '@/atoms/IconButton/types';
import { SIZE } from 'types/utils';

const IconButton = forwardRef(
  (
    { children, className, size = SIZE.SM, icon, isLight, ...buttonProps }: IconButtonBaseProps & ButtonOrLinkProps,
    ref,
  ) => (
    <ButtonOrLink
      ref={ref}
      className={clx(
        'transition-colors duration-quicker ease-out bg-transparent',
        'p-2 disabled:text-system-disabled-400 rounded-lg',
        isLight
          ? 'text-neutral-0 hover:bg-primary-400 active:bg-primary-600'
          : 'text-neutral-500 hover:bg-neutral-50 active:bg-neutral-70',
        SIZE_TO_CLASS[size],
        className,
      )}
      {...buttonProps}
    >
      {icon && <i className={icon} />}
    </ButtonOrLink>
  ),
);

IconButton.displayName = 'IconButton';
export default IconButton;
