import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';
import filterDataDto, { AgnosticFilterData } from 'utils/filters/filterDataDto';

const keyFactory = createKeyFactory([KEYS.CARDS_FILTER_DATA]);
const queryKey = keyFactory.all();

const useCardsFilterData = () => {
  const apiClient = useApi();

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await apiClient.cards.apiCardsfilterDataGetCollection();

      return res.data;
    },
  });

  return { filterData: filterDataDto((data || {}) as unknown as AgnosticFilterData), isLoading };
};

export default useCardsFilterData;
