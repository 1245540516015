import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useApi from 'hooks/useApi';
import useUserApi from 'hooks/UserApi/useUserApi';
import { FormOptions } from 'types/forms';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';
import formatOptions from 'utils/form/formatOptions';
import getULID from 'utils/getULID';

const deletionStatusesFactory = createKeyFactory([KEYS.DELETION_STATUSES]);
const deleteShopSchema = () =>
  yup
    .object({
      password: yup.string().min(8).required(),
      shopDeletionStatus: yup.string().required(),
      onDeletionCommentary: yup.string(),
    })
    .required();

type FormValues = yup.InferType<ReturnType<typeof deleteShopSchema>>;

const useDeleteShop = ({ onSuccess }: { onSuccess: () => void }) => {
  const apiClient = useApi();
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(deleteShopSchema()),
  });
  const { handleSubmit } = formMethods;
  const { user } = useUserApi();
  const shopId = getULID(user?.shop);

  const { data: deletionStatusesOptions } = useQuery<FormOptions>({
    queryKey: deletionStatusesFactory.list(),
    queryFn: async () => {
      const res = await apiClient.shopDeletionStatuses.apiShopDeletionStatusesGetCollection();

      return formatOptions(res.data['hydra:member'], { labelField: 'label' });
    },
  });

  const deleteShop = useMutation({
    mutationKey: ['deleteShop'],
    mutationFn: async (formValues: FormValues) => {
      if (shopId) {
        apiClient.shops.apiShopsIdDelete(shopId as string, formValues);
      }
    },
    onSuccess,
  });

  const onSubmit = (formValues: FormValues) => {
    deleteShop.mutate(formValues);
  };

  return { onSubmit: handleSubmit(onSubmit), formMethods, deletionStatusesOptions };
};

export default useDeleteShop;
