import { PrintingOrderPrintingPricingGridJsonldPrintingRead } from '@/api/generated';
import Offer from './Offer';

const VisualOffers = ({ offers }: { offers: PrintingOrderPrintingPricingGridJsonldPrintingRead[] }) => (
  <div className="flex my-6 gap-4">
    {offers.map((o) => (
      <Offer key={o.nbPrintingPage} isSelected={!!o.selected} offer={o} />
    ))}
  </div>
);

export default VisualOffers;
