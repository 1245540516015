import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import TextualData, { TEXTUAL_DATA_VARIANT } from '@/molecules/TextualData';
import { ORIENTATION } from 'types/utils';

const ShowAddress = ({
  street,
  city,
  zipCode,
  showStreet = true,
  minified = false,
}: {
  street?: string;
  city?: string;
  zipCode?: string;
  showStreet?: boolean;
  minified?: boolean;
}) => {
  const gMapLink = `https://www.google.fr/maps/place/${street?.replace(/ /gi, '+')}+${zipCode}+${city}/`;
  const { t } = useTranslation();

  return (
    <TextualData
      icon="fa-solid fa-fw fa-location-dot"
      label={minified ? '' : t('localization')}
      orientation={minified ? ORIENTATION.HORIZONTAL : ORIENTATION.VERTICAL}
      variant={TEXTUAL_DATA_VARIANT.smallInfo}
    >
      <Link className="underline flex gap-1" href={gMapLink} target="_blank">
        {showStreet && <span>{street}</span>}
        <span>{zipCode}</span>
        <span>{city}</span>
      </Link>
    </TextualData>
  );
};

export default ShowAddress;
