import { Mapped } from 'types/utils';
import { MenuItemType } from '../../types/cms';
import { CMSMenuItemType } from './types';

const formatMenuItem = (item: CMSMenuItemType): MenuItemType => {
  return {
    key: item.id,
    label: item.title.rendered,
    acf: item.acf,
    href: `${item.url}${item.acf?.params ?? ''}`,
    hasPlaceholder: item.page_placeholder,
  };
};

export const formatMenuItems = (items: CMSMenuItemType[]): MenuItemType[] =>
  items.reduce((arr, item) => {
    if (item.acf?.action) {
      return arr;
    }

    return [
      ...arr,
      {
        ...formatMenuItem(item),
        ...(item.children.length > 0 && { items: formatMenuItems(item.children) }),
      },
    ];
  }, [] as MenuItemType[]);

export const formatSpecificMenuItems = (items: CMSMenuItemType[]): Mapped<string, MenuItemType> =>
  items.reduce(
    (result, item) => {
      if (item.children.length > 0) {
        return { ...result, ...formatSpecificMenuItems(item.children) };
      }

      if (!item.acf?.action) {
        return result;
      }

      return {
        ...result,
        [item.acf.action]: {
          ...formatMenuItem(item),
        },
      };
    },
    {} as Mapped<string, MenuItemType>,
  );

export default formatMenuItems;
