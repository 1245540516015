import { useTranslation } from 'react-i18next';

import Badge, { CARD_VARIANT_COLOR_ENUM } from '@/atoms/Badge';
import VerticalBars from '@/atoms/dataWiz/VerticalBars';
import Metrics from '@/molecules/Metrics';
import Tile from '@/molecules/Tile';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import { SIZE } from 'types/utils';
import { useDeckStats } from 'hooks/queries/decks';

const RARITY_TO_PNG: { [s: string]: string } = {
  common: '/decks/RarityGemCommon.png',
  rare: '/decks/RarityGemRare.png',
  unique: '/decks/RarityGemUnique.png',
};

const ATTRIBUTS_TO_PNG: { [s: string]: string } = {
  forest: '/decks/tree.png',
  mountain: '/decks/mountain.png',
  ocean: '/decks/droplet.png',
};

const TYPE_TO_ICON: { [s: string]: string } = {
  characters: 'fa-solid fa-person',
  spells: 'fa-solid fa-star-christmas',
  permanents: 'fa-solid fa-torii-gate',
};

type Props = {
  closeModal: () => void;
  deckId: string;
};
const ModalDeckStats = ({ deckId, closeModal }: Props) => {
  const { data } = useDeckStats(deckId);
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} title={t('stats')}>
      <Tile title={t('rarity')} withDivider>
        <div className="flex gap-2 flex-col md:flex-row">
          {data?.cardsByRarityData?.map(([key, value]) => (
            <Metrics
              key={key}
              className="grow"
              image={RARITY_TO_PNG[key.toLowerCase()] || ''}
              subLine={key.toLowerCase()}
            >
              {value}
              {key.toLowerCase() === 'rare' && <span className="title-4 text-neutral-200">/15</span>}
            </Metrics>
          ))}
        </div>
      </Tile>
      <Tile title={t('mana')} withDivider>
        <div className="flex justify-around flex-col md:flex-row gap-2">
          {data && data?.costInvocation && (
            <VerticalBars
              data={data.costInvocation}
              label="hand-cost"
              max={data.cardsQuantity || '0'}
              renderLabel={(v) => (
                <Badge size={SIZE.LG} variant={CARD_VARIANT_COLOR_ENUM.COST_HAND}>
                  {v}
                </Badge>
              )}
            />
          )}
          {data && data?.costRecall && (
            <VerticalBars
              data={data?.costRecall}
              label="recall-cost"
              max={data.cardsQuantity || '0'}
              renderLabel={(v) => (
                <Badge size={SIZE.LG} variant={CARD_VARIANT_COLOR_ENUM.COST_RECALL}>
                  {v}
                </Badge>
              )}
            />
          )}
        </div>
      </Tile>
      <Tile title={t('attributs')} withDivider>
        <div className="flex gap-2 flex-col lg:flex-row">
          {data?.attributesData?.map(([key, value]) => (
            <Metrics
              key={key}
              className="grow"
              image={ATTRIBUTS_TO_PNG[key.toLowerCase()] || ''}
              imgClassName="h-16 p-1 block"
              subLine={key.toLowerCase()}
            >
              {value}
            </Metrics>
          ))}
        </div>
      </Tile>
      <Tile title={t('types')} withDivider>
        <div className="flex gap-2 flex-col lg:flex-row">
          {data?.cardsByTypeData?.map(([key, value]) => (
            <Metrics key={key} className="grow" icon={TYPE_TO_ICON[key]} subLine={key}>
              {value}
            </Metrics>
          ))}
        </div>
      </Tile>
      <Tile title={t('ownership')} withDivider>
        <div className="flex gap-2">
          <Metrics className="grow" icon="fa-solid fa-qrcode" subLine="card-ownership">
            <p>
              {data?.cardsOwned}
              <span className="title-4 text-neutral-200">/{data?.cardsQuantity}</span>
            </p>
          </Metrics>
        </div>
      </Tile>
    </ModalClassicLayout>
  );
};
export default ModalDeckStats;
