import clx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CardJsonldCardRead } from '@/api/generated';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import { SIZE_TO_CLASS } from '@/atoms/Button/const';
import ModalSimpleLayout from 'context/Modal/layout/Simple';
import { CardDetailsAlias } from 'types/alias/types';
import { LinkType, SIZE } from 'types/utils';
import { applyClassesForBreakpoint } from 'utils/styles';
import useZoom from './hooks';

type Prop = {
  closeModal: () => void;
  cardRef: string;
  linkProps?: LinkType;
  extraElement?: JSX.Element;
};

export type ZoomSliderExtraProps = {
  card?: CardJsonldCardRead | CardDetailsAlias;
};

const ZoomSlider = ({ closeModal, linkProps, cardRef, extraElement }: Prop) => {
  const { t } = useTranslation();
  const { card, handleNext, handlePrev, currentIndex, swipeHandler, cardsNumber, initialCurrentIndex } =
    useZoom(cardRef);

  const href = linkProps?.href || `/cards/${card?.reference}`;
  const label = linkProps?.label || 'see-details';

  return (
    <ModalSimpleLayout closeModal={closeModal}>
      <div
        className="flex w-full sm:w-3/4 flex-col items-center relative px-10 mx-2 focus:outline-none !pointer-events-none"
        {...swipeHandler}
      >
        {!!cardsNumber && (
          <>
            <Button
              className={clx(
                'absolute left-4 z-1 md:left-0 top-0 bottom-0 my-auto h-fit pointer-events-auto',
                applyClassesForBreakpoint('md', SIZE_TO_CLASS[SIZE.LG]),
              )}
              disabled={currentIndex! === 0}
              onClick={handlePrev}
              size={SIZE.SM}
              tabIndex={-1}
            >
              <i className="fa-solid fa-arrow-left-long text-base" />
            </Button>
            <Button
              className={clx(
                'absolute right-4 z-1 md:right-0 top-0 bottom-0 my-auto h-fit pointer-events-auto',
                applyClassesForBreakpoint('md', SIZE_TO_CLASS[SIZE.LG]),
              )}
              disabled={cardsNumber === (currentIndex || 0) + 1}
              onClick={handleNext}
              size={SIZE.SM}
              tabIndex={-1}
            >
              <i className="fa-solid fa-arrow-right-long text-base" />
            </Button>
          </>
        )}
        <figure className="rounded-lg sm:h-[70vh] w-[85vw] sm:w-auto aspect-[3/4] relative pointer-events-auto z-0">
          {card?.imagePath && (
            <AnimatePresence initial={false}>
              <motion.img
                key={`${card?.imagePath}  ${card.reference}`}
                animate={{ opacity: 1, scale: 1, zIndex: 1, x: '0' }}
                className="max-h-full rounded-lg  max-w-full absolute top-0 left-0 right-0 m-auto"
                exit={{ opacity: 0, scale: 1.05, zIndex: 0, x: '-1.5rem', transformOrigin: 'right' }}
                initial={{ opacity: 0, scale: 1.05, zIndex: 1, x: '1.5rem' }}
                src={card.imagePath}
                transition={{ ease: 'easeInOut' }}
              />
            </AnimatePresence>
          )}
        </figure>
        {!!card && !!extraElement && initialCurrentIndex === currentIndex && (
          <div className="pt-6 pointer-events-auto">{cloneElement(extraElement, { card })}</div>
        )}
        <div className="pt-6 pointer-events-auto">
          <Button href={href} variant={BUTTON_VARIANTS.PRIMARY}>
            {t(label)}
          </Button>
        </div>
      </div>
    </ModalSimpleLayout>
  );
};

export default ZoomSlider;
