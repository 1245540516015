import clx from 'classnames';
import { useTranslation } from 'react-i18next';
import ButtonOrLink from '@/atoms/ButtonOrLink/ButtonOrLink';
import { TabItemType } from '@/molecules/Tabs/TabItem/types';

const TabItem = ({ isActive, label, className, icon, ...props }: TabItemType<string>) => {
  const { t } = useTranslation();

  return (
    <li>
      <ButtonOrLink
        className={clx(
          'relative py-3 px-2 sm:px-3 flex justify-center items-center gap-2 sm-bold-caps whitespace-nowrap font-sans hover:text-neutral-800',
          isActive ? 'text-neutral-800' : 'text-neutral-500',
          className,
        )}
        {...props}
        type="button"
      >
        {icon && <i className={icon} />}
        {t(label)}
        {isActive && <div className="absolute left-0 right-0 bottom-0 h-1 bg-primary-500" />}
      </ButtonOrLink>
    </li>
  );
};
export default TabItem;
