import { ReactNode } from 'react';
import ModalClassicLayout from '../../layout/Classic';

type ConfirmModalProps = {
  onSubmit: () => void;
  closeModal: () => void;
  title: string;
  content: ReactNode;
  submitLabel?: string;
};

const ConfirmModal = ({ onSubmit, closeModal, title, content, submitLabel }: ConfirmModalProps) => (
  <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} submitLabel={submitLabel} title={title}>
    <div className="flex flex-col pb-4">{content}</div>
  </ModalClassicLayout>
);
export default ConfirmModal;
