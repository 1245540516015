import { ReactNode } from 'react';

export type ListItemFooterProps = {
  label?: ReactNode;
  children?: ReactNode;
};

const ListItemFooter = ({ label, children }: ListItemFooterProps) => (
  <div className="flex items-center justify-between rounded-b-lg bg-neutral-40 px-4 py-2">
    {typeof label === 'string' ? <p className="sm-bold-caps text-neutral-800">{label}</p> : label}
    {typeof children === 'string' ? (
      <p className="lg-bold-caps text-neutral-800 ">{children}</p>
    ) : (
      <div className="ml-auto">{children}</div>
    )}
  </div>
);

export default ListItemFooter;
