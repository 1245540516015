import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useModal } from 'context/Modal/Context';
import { MODAL_NAME } from 'context/Modal/MODAL_NAME';
import { getErrorMessage } from 'hooks/networkError/utils';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const useApplyFoiler = (reference: string) => {
  const apiClient = useApi();
  const queryClient = useQueryClient();
  const { openModal } = useModal();
  const cardsFactory = createKeyFactory([KEYS.CARDS]);

  const addFoiler = useMutation({
    mutationKey: ['addPrinting'],
    mutationFn: () => apiClient.cards.apiCardsReferencefoilPost(reference, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cardsFactory.details(reference) });
      openModal(MODAL_NAME.APPLY_FOILER_RESULT, {
        reference,
        message: 'apply-foiler-success',
      });
    },
    onError: async (error) => {
      const message = await getErrorMessage(error);
      openModal(MODAL_NAME.APPLY_FOILER_RESULT, {
        reference,
        message,
      });
    },
  });

  return { addFoiler };
};

export default useApplyFoiler;
