import * as RadioGroup from '@radix-ui/react-radio-group';
import clx from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import FieldLayout from '@/atoms/forms/FieldLayout';
import { FormOptions } from 'types/forms';
import { ORIENTATION, SIZE } from 'types/utils';
import { Label } from '../../labels';
import ErrorLabel from '../../labels/ErrorLabel';
import { BaseProps } from '../../types';

type Props = {
  disabled?: boolean;
  options: FormOptions;
  size?: SIZE;
  orientation?: ORIENTATION;
  required?: boolean;
};

const RadioList = ({
  disabled,
  name,
  options,
  size,
  orientation = ORIENTATION.VERTICAL,
  required = false,
  label,
}: BaseProps & Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name,
    control,
  });

  const handleChange = (value: string) => {
    field.onChange(value);
  };

  return (
    <RadioGroup.Root disabled={disabled} onValueChange={handleChange} required={required} value={field.value}>
      <FieldLayout>
        {label && (
          <Label label={label} name={name} required={required}>
            {label}
          </Label>
        )}
        <ul className={clx('flex gap-3', orientation === ORIENTATION.VERTICAL ? 'flex-col' : 'flex-row')}>
          {Object.entries(options).map(([id, option]) => (
            <li key={id} className="flex gap-2 flex-wrap items-center">
              <RadioGroup.Item
                className={clx(
                  'outline-none border rounded-full bg-neutral-40 border-neutral-50 hover:bg-neutral-50 transition-all relative focus:bg-neutral-30',
                  field.value === id && !disabled && !errors[name] && 'data-state-checked:bg-primary-500 border-0',
                  errors[name] && 'border-red-500',
                  (option.disabled || disabled) && 'bg-system-disabled-200 border-system-disabled-300',
                  size === SIZE.LG ? 'w-5 h-5' : 'w-4 h-4',
                )}
                disabled={option.disabled}
                id={`${name}-${id}`}
                value={id}
              >
                <RadioGroup.Indicator
                  className={clx(
                    size === SIZE.LG ? 'w-2 h-2 ' : 'w-1.5 h-1.5',
                    'bg-neutral-800 block absolute m-auto top-0 bottom-0 left-0 right-0 rounded-full duration-quick',
                    disabled && 'bg-system-disabled-400',
                    field.value === id ? 'opacity-100' : 'opacity-0',
                  )}
                  forceMount
                />
              </RadioGroup.Item>
              <Label
                disabled={option.disabled || disabled}
                isInvalid={!!errors[name]}
                label={option.label}
                name={`${name}-${id}`}
              />
              {!!errors[name] && <ErrorLabel className="w-full" error={errors[name]?.message} />}
            </li>
          ))}
        </ul>
      </FieldLayout>
    </RadioGroup.Root>
  );
};

export default RadioList;
export {};
