import clx from 'classnames';

import Link from 'next/link';
import { ReactNode } from 'react';

export type ListItemHeaderProps = {
  leadText?: string | null;
  subText?: string | null;
  thirdText?: ReactNode | null;
  fourText?: ReactNode | null;
  fifthText?: ReactNode | null;
  sixthText?: ReactNode | null;
  imageNode?: ReactNode | null;
  href?: string;
  className?: string;
};

const ListItemHeader = ({
  leadText,
  subText,
  thirdText,
  fourText,
  fifthText,
  sixthText,
  imageNode,
  href,
  className,
}: ListItemHeaderProps) => (
  <div className={clx('flex items-center gap-3 bg-neutral-30 rounded-t-lg only:rounded-b-lg p-2', className)}>
    {imageNode}
    <div className="grow grid grid-cols-1 md:grid-flow-col md:auto-cols-fr auto-rows-min items-center gap-2">
      <div className="flex flex-col gap-1">
        {href ? (
          <Link href={href}>
            <h6 className="text-neutral-900 md-bold-caps line-clamp-1 text-ellipsis">{leadText}</h6>
          </Link>
        ) : (
          <h6 className="text-neutral-900 md-bold-caps">{leadText}</h6>
        )}
        {subText && <p className="text-neutral-400 md-regular">{subText}</p>}
      </div>
      {thirdText && <div className="text-neutral-800 md-regular md:justify-self-center">{thirdText}</div>}
      {fourText && <div className="text-neutral-800 md-regular md:justify-self-center">{fourText}</div>}
      {fifthText && <div className="text-neutral-800 md-regular md:justify-self-center">{fifthText}</div>}
      {sixthText && <div className="text-neutral-800 md-regular hidden md:block md:justify-self-end">{sixthText}</div>}
    </div>
  </div>
);

export default ListItemHeader;
