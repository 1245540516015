import clx from 'classnames';
import { FieldsetHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import Legend from '@/molecules/Fieldset/Legend';
import { ORIENTATION } from 'types/utils';

type FieldsetProps = FieldsetHTMLAttributes<HTMLFieldSetElement> & {
  orientation?: ORIENTATION;
  legend?: string | null;
};

const Fieldset = ({ children, orientation, className, legend, ...props }: FieldsetProps) => {
  const { t } = useTranslation();

  return (
    <fieldset
      className={clx(className, 'flex gap-1.5', orientation === ORIENTATION.HORIZONTAL ? 'flex-row' : 'flex-col')}
      {...props}
    >
      {legend && <Legend>{t(legend)}</Legend>}
      {children}
    </fieldset>
  );
};

export default Fieldset;
