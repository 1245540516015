import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from '@reach/combobox';
import clx from 'classnames';
import { useTranslation } from 'next-i18next';
import FieldLayout from '@/atoms/forms/FieldLayout';
import { ErrorLabel, Label } from '@/atoms/forms/labels';
import useSearchSelect from '@/atoms/forms/Select/SearchSelect/useSearchSelect';
import { BaseProps } from '@/atoms/forms/types';
import TagGroup from '@/molecules/TagGroup';
import { FormOptions } from 'types/forms';
import { SIZE } from 'types/utils';

interface SearchSelectProps extends BaseProps {
  options: FormOptions;
  isMulti?: boolean;
  className?: string;
  disabled?: boolean;
}

const SearchSelect = ({
  isMulti = false,
  options,
  disabled,
  label,
  name,
  className,
  ...restProps
}: SearchSelectProps) => {
  const { t } = useTranslation();
  const { error, term, matchedOptions, selectedOptions, handleSearch, handleSelect } = useSearchSelect(
    name,
    options,
    isMulti,
  );

  return (
    <FieldLayout className={className}>
      {label && <Label label={label} name={name} />}
      <Combobox
        aria-label={(label && t(label)) || ''}
        {...restProps}
        aria-labelledby={(label && t(label)) || ''}
        onSelect={handleSelect}
        openOnFocus
      >
        <ComboboxInput
          className={clx(
            'text-neutral-300 placeholder:text-neutral-100 lg-regular flex relative w-full',
            'transition-all border-neutral-500 rounded-lg border px-3 py-2 outline-0',
            error
              ? `border-system-error-400 focus:border-system-error-400 focus:shadow-focus-error
              data-[state=open]:shadow-focus-error data-[state=open]:border-system-error-400`
              : `bg-sand-50 autofill:bg-sand-50 focus:border-primary-300 focus:shadow-focus 
              data-[state=open]:shadow-focus data-[state=open]:border-primary-300`,
            `disabled:border-system-disabled-300 disabled:bg-system-disabled-50 disabled:autofill:bg-system-disabled-50
              disabled:text-system-disabled-300 disabled:placeholder:text-system-disabled-300`,
          )}
          disabled={disabled}
          name={name}
          onChange={handleSearch}
          placeholder={(label && t(label)) || ''}
          value={term}
          selectOnClick
        />
        <ComboboxPopover portal={false}>
          <ComboboxList
            className="max-h-96 bg-sand-50 rounded-b-lg border border-neutral-30 overflow-auto shadow-raised outline-0"
            persistSelection
          >
            {matchedOptions.length > 0 ? (
              matchedOptions.map((optionId) => (
                <ComboboxOption key={optionId} className="px-1.5 py-0.5" value={optionId}>
                  <span
                    className={clx(
                      'gap-2 text-neutral-900 outline-0 lg-regular p-2.5 clickable cursor-pointer',
                      'hover:bg-neutral-30 flex items-center justify-between',
                      selectedOptions[optionId] && 'bg-neutral-50',
                    )}
                  >
                    {t(options[optionId]?.label)}
                    {selectedOptions[optionId] && <i className="fa-regular fa-check" />}
                  </span>
                </ComboboxOption>
              ))
            ) : (
              <div className="px-1.5 py-0.5">
                <span className="text-neutral-900 lg-regular">{t('no-result')}</span>
              </div>
            )}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      <TagGroup closeTag={isMulti ? handleSelect : undefined} options={selectedOptions} size={SIZE.MD} />
      {!!error && <ErrorLabel error={error.message} />}
    </FieldLayout>
  );
};

export default SearchSelect;
