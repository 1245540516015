import clx from 'classnames';
import ButtonOrLink from '@/atoms/ButtonOrLink/ButtonOrLink';
import { SIZE, VARIANT_COLOR_ENUM } from 'types/utils';
import Chip from '../Chip';

type Props = {
  icon: string;
  label: string;
  description?: string;
  tags?: { label?: string; color?: VARIANT_COLOR_ENUM }[];
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
};

const RedirectTile = ({ icon, label, description, tags, disabled, ...props }: Props) => (
  <ButtonOrLink
    {...props}
    className={clx(
      'flex items-center w-full p-4 border border-neutral-40 rounded-lg',
      'hover:bg-neutral-40 focus:bg-neutral-50 transition-colors duration-quick ease-out',
      disabled && 'bg-system-disabled-200',
    )}
    data-testid={label}
    disabled={disabled}
    type="button"
  >
    {icon && <i className={clx('mr-3.5 text-lg text-neutral-500', icon)} />}
    <div className="flex flex-col grow gap-1">
      {tags && (
        <ul className="flex gap-4">
          {tags.map(
            (tag) =>
              tag.label && (
                <li key={tag.label}>
                  <Chip
                    color={disabled ? VARIANT_COLOR_ENUM.DISABLED : tag.color || VARIANT_COLOR_ENUM.NEUTRAL}
                    size={SIZE.SM}
                  >
                    {tag.label}
                  </Chip>
                </li>
              ),
          )}
        </ul>
      )}
      <p className={clx('lg-medium text-left', disabled ? 'text-system-disabled-400' : 'text-neutral-900')}>{label}</p>
      <div className="text-left">
        <p className={clx('md-regular', disabled ? 'text-system-disabled-400' : 'text-neutral-400')}>{description}</p>
      </div>
    </div>
    <i className="fa-solid fa-chevron-right text-neutral-500 text-xl" />
  </ButtonOrLink>
);

export default RedirectTile;
