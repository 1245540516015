import { useQuery } from '@tanstack/react-query';
import { FRIENDS_PER_PAGE } from 'constants/itemsPerPage';
import useUserApi from 'hooks/UserApi/useUserApi';
import useApi from 'hooks/useApi';
import { useRouter } from 'next/router';
import usersFactory from 'utils/factories/user';

type MyFriendsProps = {
  enabled?: boolean;
  itemsPerPage?: number;
  filterByName?: boolean;
};

export const useMyFriends = ({
  enabled = true,
  itemsPerPage = FRIENDS_PER_PAGE,
  filterByName = true,
}: MyFriendsProps = {}) => {
  const { query } = useRouter();
  const api = useApi();

  const queryResult = useQuery({
    queryKey: usersFactory.myFriends({ name: query.name as string, page: (query.page as string) || '1' }),
    queryFn: () =>
      api.userFriendships.apiUserFriendshipsGetCollection({
        itemsPerPage,
        page: Number(query.page as string) || 1,
        ...(filterByName && query.name ? { 'userFriend.nickName': query.name as string } : {}),
      }),
    enabled,
    select: (v) => v.data,
  });

  return queryResult;
};

type FriendsProps = {
  id: string;
  enabled?: boolean;
};

export const useFriends = ({ id, enabled = true }: FriendsProps) => {
  const { query } = useRouter();
  const api = useApi();
  const queryResult = useQuery({
    queryKey: usersFactory.friends({ name: query.name as string, page: (query.page as string) || '1' }),
    queryFn: () =>
      api.userFriendships.apiUserFriendshipsfriendsIdGetCollection(id, {
        itemsPerPage: FRIENDS_PER_PAGE,
        page: Number(query.page as string) || 1,
      }),
    enabled,
    select: (v) => v.data,
  });

  return queryResult;
};

type MyrequestProps = {
  enabled?: boolean;
};

export const useMyRequests = ({ enabled = true }: MyrequestProps = {}) => {
  const api = useApi();
  const me = useUserApi();
  const { query } = useRouter();

  return useQuery({
    queryKey: usersFactory.myRequests({ page: (query.page as string) || '1' }),
    queryFn: () =>
      api.userFriendRequests.apiUserFriendRequestsGetCollection({
        itemsPerPage: FRIENDS_PER_PAGE,
        page: Number(query.page as string) || 1,
        status: 'PENDING',
        ...(me.user?.nickName ? { 'receiver.nickName': me.user?.nickName } : {}),
      }),
    select: (v) => v.data,
    enabled,
  });
};

export const useFriendsBlocked = ({ enabled = true }: MyrequestProps = {}) => {
  const api = useApi();
  const { query } = useRouter();
  const queryResult = useQuery({
    queryKey: usersFactory.blockedFriends({ page: (query.page as string) || '1' }),
    queryFn: () =>
      api.userBlockeds.apiUserBlockedsGetCollection({
        itemsPerPage: FRIENDS_PER_PAGE,
        page: Number(query.page as string) || 1,
      }),
    select: (v) => v.data,
    enabled,
  });

  return queryResult;
};

export const useSearchFriends = (search?: string) => {
  const api = useApi();
  const queryResult = useQuery({
    queryKey: usersFactory.myRequests({ search: search || '' }),
    queryFn: async () => api.users.apiUserssearchUniqueIdGet(search || '', { format: 'json' }),
    select: (v) => v.data,
    enabled: !!search,
  });

  return queryResult;
};
