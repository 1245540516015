import * as Tooltip from '@radix-ui/react-tooltip';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const TooltipContentMotion = motion(Tooltip.Content);

const ToolTip = ({ children, value }: { children: ReactNode; value: string }) => {
  const { t } = useTranslation();

  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Tooltip.Portal>
        <TooltipContentMotion
          animate={{ opacity: 1, translateY: 0, transition: { duration: 0.2 } }}
          className="select-none rounded-md text-center bg-neutral-900 text-white px-3 py-2 text-sm leading-none"
          exit={{ opacity: 0, translateY: 4, transition: { duration: 0.2 } }}
          initial={{ opacity: 0, translateY: 4 }}
          sideOffset={5}
        >
          {t(value)}
          <Tooltip.Arrow className="fill-neutral-900" />
        </TooltipContentMotion>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

export default ToolTip;
