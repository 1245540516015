import clx from 'classnames';
import { PropsWithChildren } from 'react';
import { ORIENTATION } from 'types/utils';

export enum TEXTUAL_DATA_VARIANT {
  'normal' = 'normal',
  'emphasis' = 'emphasis',
  'smallInfo' = 'smallInfo',
}

const VARIANT_TO_LABEL_CLASSNAME = {
  normal: 'lg-medium text-neutral-900',
  emphasis: 'lg-bold text-neutral-900',
  smallInfo: 'sm-bold-caps text-neutral-400',
};

const VARIANT_TO_TEXT_CLASSNAME = {
  normal: 'md-regular text-neutral-400',
  emphasis: 'lg-regular text-neutral-600',
  smallInfo: 'md-regular text-neutral-800',
};

type TextualDataProps = {
  label?: string | null;
  variant?: TEXTUAL_DATA_VARIANT;
  icon?: string;
  orientation?: ORIENTATION;
  reverse?: boolean;
  className?: string;
};

const TextualData = ({
  label,
  variant = TEXTUAL_DATA_VARIANT.normal,
  children,
  icon,
  orientation = ORIENTATION.VERTICAL,
  reverse = false,
  className,
}: PropsWithChildren<TextualDataProps>) => (
  <div
    className={clx(
      className,
      'flex items-baseline gap-y-1 gap-x-3 w-fit',
      orientation === ORIENTATION.HORIZONTAL && !reverse && 'flex-row',
      orientation === ORIENTATION.HORIZONTAL && reverse && 'flex-row-reverse',
      orientation === ORIENTATION.VERTICAL && !reverse && 'flex-col',
      orientation === ORIENTATION.VERTICAL && reverse && 'flex-col-reverse',
    )}
  >
    <p className={VARIANT_TO_LABEL_CLASSNAME[variant]}>
      {icon && <i className={clx(icon, 'mr-2')} />}
      {label}
    </p>
    <div className={VARIANT_TO_TEXT_CLASSNAME[variant]}>{children}</div>
  </div>
);

export default TextualData;
