import { PrintingOrderPrintingPricingGridJsonldPrintingRead } from '@/api/generated';
import VisualOffers from '@/molecules/VisualOffers';
import ModalClassicLayout from 'context/Modal/layout/Classic';

type PricingOffersProps = {
  closeModal: () => void;
  title: string;
  submitLabel?: string;
  offers: PrintingOrderPrintingPricingGridJsonldPrintingRead[];
};

const PricingOffers = ({ closeModal, title, submitLabel, offers }: PricingOffersProps) => (
  <ModalClassicLayout closeModal={closeModal} submitLabel={submitLabel} title={title} alert>
    <div className="  max-w-full overflow-auto flex items-center py-1">
      <VisualOffers offers={offers} />
    </div>
  </ModalClassicLayout>
);

export default PricingOffers;
