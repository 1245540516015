import Spinner from '@/atoms/Spinner';
import CounterInput from '@/atoms/forms/Counter';
import CardThumb from '@/molecules/CardThumb';
import Pagination from '@/molecules/Pagination';
import ListHeader from '@/organisms/ListHeader';
import { CARDS_PER_PAGE } from 'constants/itemsPerPage';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useCardsFilterData from 'hooks/queries/cards/useCardsFilterData';
import { FormProvider } from 'react-hook-form';
import { SIZE } from 'types/utils';
import useCardTradeListModal from './hook';

type Props = {
  closeModal: () => void;
  user: { nickName: string; id: string };
  onSubmit: (payload: Array<{ card: string; quantity: number }>) => void;
};

const TradeCardList = ({ closeModal, user, onSubmit }: Props) => {
  const { data, onFilterSubmit, formMethods, handleSubmit } = useCardTradeListModal({ user, onSubmit });
  const { filterData } = useCardsFilterData();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title="Cards">
      <ListHeader
        filterData={filterData}
        loading={false}
        onFilterSubmit={onFilterSubmit}
        totalItems={data?.['hydra:totalItems']}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit}>
          <div className="grid-12 gap-y-6 items-end">
            {data?.['hydra:member'] ? (
              data['hydra:member'].map((card) => (
                <div key={card['@id']} className="xl:col-span-3 sm:col-span-4 col-span-6">
                  {card.imagePath && (
                    <>
                      <p className="md-bold-caps mb-2">{card.name}</p>
                      <div className="flex flex-col items-center">
                        <div className="relative">
                          <CardThumb
                            alt=""
                            imagePath={card.imagePath}
                            inMyCollection={card.inMyCollection}
                            size={SIZE.MD}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <CounterInput
                    className="justify-end mt-4"
                    max={card.quantity || undefined}
                    min={0}
                    name={card['@id'] || ''}
                  />
                </div>
              ))
            ) : (
              <Spinner />
            )}
          </div>
          <Pagination itemsPerPage={CARDS_PER_PAGE} totalItems={data?.['hydra:totalItems']} />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default TradeCardList;
