import { useTranslation } from 'next-i18next';
import ModalClassicLayout from '../../layout/Classic';

type ConfirmUnsubscribeProps = {
  onSubmit: () => void;
  closeModal: () => void;
  eventName: string;
};
const ConfirmUnsubscribeModalView = ({ closeModal, onSubmit, eventName }: ConfirmUnsubscribeProps) => {
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="unsubscribe">
      <div className="flex flex-col pb-4">{t('unsubscribe_description', { eventName })}</div>
    </ModalClassicLayout>
  );
};

export default ConfirmUnsubscribeModalView;
