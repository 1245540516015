import { ComponentProps, ReactNode } from 'react';
import ApplyFoilerModal from 'context/Modal/views/ApplyFoilerModal';
import ApplyFoilerResult from 'context/Modal/views/ApplyFoilerModal/ApplyFoilerResult';
import CreateTeamMember from 'context/Modal/views/CreateTeamMember';
import DeleteShop from 'context/Modal/views/DeleteShop';
import Filters from 'context/Modal/views/Filters/Filters';
import LanguageModal from 'context/Modal/views/Language';
import GiveCard from 'context/Modal/views/MarketplaceActions/Give/GiveCard';
import ManageOffer from 'context/Modal/views/MarketplaceActions/ManageOffer/ManageOffer';
import CartNavModal from 'context/Modal/views/nav/cart';
import EditorialNavModal from 'context/Modal/views/nav/editorial';
import UserNavModal from 'context/Modal/views/nav/user';
import ZoomModal from 'context/Modal/views/Zoom';
import { MODAL_NAME } from './MODAL_NAME';
import AdressCreateModal from './views/AddressCreate';
import AddressEditionModal from './views/AddressEdition';
import AddressList from './views/AddressList';
import AlertModal from './views/AlertModal';
import BankAccountCreateModal from './views/BankAccountCreate';
import ConfirmDeckDeletion from './views/ConfirmDeckDeletion';
import ConfirmModal from './views/ConfirmModal';
import ConfirmUnsubscribe from './views/ConfirmUnsubscribe';
import DeckChangeHeroModal from './views/Decks/DeckChangeHero';
import DecksRegistrationModal from './views/Decks/DeckRegistration';
import ShareDeck from './views/Decks/DeckShare';
import ModalDeckStats from './views/Decks/DeckStats';
import DuplicateDeckModal from './views/Decks/DuplicateDeck';
import ModalDeckFilter from './views/Decks/Filter';
import RenameDeckModal from './views/Decks/RenameDeck';
import ConfirmOfferDeletion from './views/MarketplaceActions/ConfirmDeletion';
import CreateCart from './views/MarketplaceActions/CreateCart';
import PaymentCardCreateModal from './views/MarketplaceActions/CreatePaymentCard';
import PaymentList from './views/MarketplaceActions/PaymentList';
import PaymentSummary from './views/MarketplaceActions/PaymentSummary';
import PaymentTypeList from './views/PaymentTypeList';
import PickAnAvatar from './views/PickAnAvatar';
import PickBackgroundModal from './views/PickBackground';
import PricingOffers from './views/PricingOffers';
import AddCardAltModal from './views/printing/AddCardAlt';
import AddCardToPage from './views/printing/AddCardToPage';
import CardList from './views/printing/CardsList';
import CreatePrintingOrderModal from './views/printing/CreatePrintingOrder';
import CreatePrintingPage from './views/printing/CreatePrintingPage';
import SharePrintingOrder from './views/printing/Share';
import ShopDetailsModal from './views/ShopDetails';
import ShopEventsListModal from './views/ShopEventsList';
import ZoomSlider from './views/ZoomSlider';
import FriendsListModal from './views/Friends/FriendsList';
import TradeCardList from './views/TradeList/CardTradeList';
import AddToTradeList from './views/TradeList/AddToTradeList';
import PickCollectionCards from './views/CardList/PickCards';

type ModalViewsType = Record<keyof typeof MODAL_NAME, (props?: any) => ReactNode>;

export const MODAL_VIEWS = {
  [MODAL_NAME.ADD_CARD_ALT]: AddCardAltModal,
  [MODAL_NAME.ADD_CARD_TO_PAGE]: AddCardToPage,
  [MODAL_NAME.ADD_TO_TRADELIST]: AddToTradeList,
  [MODAL_NAME.ADDRESS_CREATE]: AdressCreateModal,
  [MODAL_NAME.ADDRESS_EDITION]: AddressEditionModal,
  [MODAL_NAME.ADDRESS_LIST]: AddressList,
  [MODAL_NAME.ALERT]: AlertModal,
  [MODAL_NAME.APPLY_FOILER_RESULT]: ApplyFoilerResult,
  [MODAL_NAME.APPLY_FOILER]: ApplyFoilerModal,
  [MODAL_NAME.BANK_ACCOUNT_CREATE]: BankAccountCreateModal,
  [MODAL_NAME.CARD_LIST]: CardList,
  [MODAL_NAME.CART_NAV]: CartNavModal,
  [MODAL_NAME.TRADE_CARD_LIST]: TradeCardList,
  [MODAL_NAME.FRIENDS_LIST]: FriendsListModal,
  [MODAL_NAME.DUPLICATE_DECK]: DuplicateDeckModal,
  [MODAL_NAME.CONFIRM_DECK_DELETION]: ConfirmDeckDeletion,
  [MODAL_NAME.CONFIRM_MODAL]: ConfirmModal,
  [MODAL_NAME.CONFIRM_OFFER_DELETION]: ConfirmOfferDeletion,
  [MODAL_NAME.CONFIRM_UNSUBSUCRIBE]: ConfirmUnsubscribe,
  [MODAL_NAME.CREATE_CART]: CreateCart,
  [MODAL_NAME.CREATE_PAYMENT]: PaymentCardCreateModal,
  [MODAL_NAME.PICK_COLLECTIONS_CARDS]: PickCollectionCards,
  [MODAL_NAME.CREATE_PRINTING_ORDER]: CreatePrintingOrderModal,
  [MODAL_NAME.CREATE_PRINTING_PAGE]: CreatePrintingPage,
  [MODAL_NAME.CREATE_TEAM_MEMBER]: CreateTeamMember,
  [MODAL_NAME.DECK_CHANGE_HERO]: DeckChangeHeroModal,
  [MODAL_NAME.DECK_FILTER]: ModalDeckFilter,
  [MODAL_NAME.DECK_REGISTRATION]: DecksRegistrationModal,
  [MODAL_NAME.DECK_RENAME]: RenameDeckModal,
  [MODAL_NAME.DELETE_SHOP]: DeleteShop,
  [MODAL_NAME.EDITORIAL_NAV]: EditorialNavModal,
  [MODAL_NAME.FILTERS]: Filters,
  [MODAL_NAME.GIVE_CARD]: GiveCard,
  [MODAL_NAME.LANGUAGE]: LanguageModal,
  [MODAL_NAME.MANAGE_OFFER]: ManageOffer,
  [MODAL_NAME.PAYMENT_LIST]: PaymentList,
  [MODAL_NAME.PAYMENT_SUMMARY]: PaymentSummary,
  [MODAL_NAME.PAYMENT_TYPE_LIST]: PaymentTypeList,
  [MODAL_NAME.PICK_AVATAR]: PickAnAvatar,
  [MODAL_NAME.PICK_BACKGROUND]: PickBackgroundModal,
  [MODAL_NAME.PRINCING_OFFERS]: PricingOffers,
  [MODAL_NAME.SHARE_PRINTING_ORDER]: SharePrintingOrder,
  [MODAL_NAME.SHARE_DECK]: ShareDeck,
  [MODAL_NAME.DECK_STATS]: ModalDeckStats,
  [MODAL_NAME.SHOP_DETAILS]: ShopDetailsModal,
  [MODAL_NAME.SHOP_EVENTS_LIST]: ShopEventsListModal,
  [MODAL_NAME.USER_NAV]: UserNavModal,
  [MODAL_NAME.ZOOM_SLIDER]: ZoomSlider,
  [MODAL_NAME.ZOOM]: ZoomModal,
} as const satisfies ModalViewsType;
export type ModalViewsKeys = keyof typeof MODAL_VIEWS;
export type BaseModalViewsProps<T extends ModalViewsKeys> = ComponentProps<(typeof MODAL_VIEWS)[T]>;
export type ModalViewsProps<T extends ModalViewsKeys> = Omit<BaseModalViewsProps<T>, 'closeModal'>;
