import { useTranslation } from 'next-i18next';
import { FormProvider } from 'react-hook-form';
import { DeckUserListJsonldDeckUserListReadDeckUserListDetailRead } from '@/api/generated';
import TextInput from '@/atoms/forms/Input';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useDuplicateDeck from './hooks';

type duplicateDeckProps = {
  closeModal: () => void;
  deck: DeckUserListJsonldDeckUserListReadDeckUserListDetailRead;
};
const DuplicateDeckModal = ({ closeModal, deck }: duplicateDeckProps) => {
  const { handleSubmit, formMethods } = useDuplicateDeck(deck, closeModal);
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={t('give_a_name_to_deck')}>
      <div className="flex flex-col pb-4">
        <p>{t('when-duplicate-deck')}</p>
        <FormProvider {...formMethods}>
          <form className="flex flex-col gap-4" name="duplicate-deck" onSubmit={handleSubmit}>
            <TextInput label="deck-name" name="name" />
          </form>
        </FormProvider>
      </div>
    </ModalClassicLayout>
  );
};

export default DuplicateDeckModal;
