import { PropsWithChildren } from 'react';

type BaseComponentProps = {
  title: string;
  id: string;
};

const BaseComponent = ({ children, title, id }: PropsWithChildren<BaseComponentProps>) => (
  <li className="flex flex-col gap-2" id={id}>
    <p className="md-bold">{title}</p>
    <div className="flex flex-col gap-2">{children}</div>
  </li>
);

export default BaseComponent;
