import { FormProvider } from 'react-hook-form';
import TextInput from '@/atoms/forms/Input';
import Form from '@/molecules/Form';
import useCreateTeamMember from 'context/Modal/views/CreateTeamMember/hooks';
import { ModalHeaderProps } from 'context/Modal/views/types';
import ModalClassicLayout from '../../layout/Classic';

const CreateTeamMember = ({ closeModal, title }: ModalHeaderProps) => {
  const { onSubmit, formMethods } = useCreateTeamMember({ closeModal });

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title={title}>
      <FormProvider {...formMethods}>
        <Form className="flex flex-col gap-4" onSubmit={onSubmit}>
          <TextInput label="last-name" name="lastName" placeholder="last-name" type="text" />
          <TextInput label="first-name" name="firstName" placeholder="first-name" type="text" />
          <TextInput label="email" name="email" placeholder="email" type="text" validMessage="available-email" />
          <input className="hidden" type="submit" />
        </Form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default CreateTeamMember;
