import * as RadioGroup from '@radix-ui/react-radio-group';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { useController, useFormContext } from 'react-hook-form';
import TextualData from '@/molecules/TextualData';
import { VisualOptions } from 'types/forms';
import { ErrorLabel } from '../../labels';
import VisualRadio from '../VisualRadio';

const VisualRadioList = ({
  options,
  name,
  title,
  supportingText,
}: {
  options: VisualOptions;
  name: string;
  title?: string;
  supportingText?: string | null;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name,
    control,
  });

  const handleChange = (value: string) => {
    field.onChange(value);
  };

  return (
    <RadioGroup.Root className="w-fit max-w-full m-auto" onValueChange={handleChange} value={field.value}>
      {title && <TextualData label={supportingText}>{title}</TextualData>}
      <ScrollArea.Root>
        <ScrollArea.Viewport>
          <ul className="flex gap-2 max-w-full w-fit m-auto">
            {Object.entries(options).map(([id, option]) => (
              <li key={id} className="shrink-0 first:ml-auto last:mr-auto">
                <VisualRadio {...option} checked={field.value === id} id={`${name}-${id}`} value={id} />
              </li>
            ))}
          </ul>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar orientation="horizontal">
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
      {!!errors[name] && <ErrorLabel className="w-full" error={errors[name]?.message} />}
    </RadioGroup.Root>
  );
};
export default VisualRadioList;
