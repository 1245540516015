import { QueryKey } from '@tanstack/react-query';
import { ParsedQuery } from 'query-string';

export enum KEYS {
  'ADDRESSES' = 'ADDRESSES',
  'BILLING_ADDRESS' = 'BILLING_ADDRESS',
  'CARDS' = 'CARDS',
  'CARDS_MARKETPLACE' = 'CARDS_MARKETPLACE',
  'CARDS_FILTER_DATA' = 'CARDS_FILTER_DATA',
  'CARD_SETS' = 'CARD_SETS',
  'CARDS_VARIANTS' = 'CARDS_VARIANTS',
  'CART' = 'CART',
  'COUNTRIES' = 'COUNTRIES',
  'COUNTRIES_LOCATOR' = 'COUNTRIES_LOCATOR',
  'CURRENCIES' = 'CURRENCIES',
  'DECKS' = 'DECKS',
  'DECK_STATS' = 'DECK_STATS',
  'DELETION_STATUSES' = 'DELETION_STATUSES',
  'EVENTS' = 'EVENTS',
  'EVENTS_BY_ORGANIZER' = 'EVENTS_BY_ORGANIZER',
  'EVENT_RANKING' = 'EVENT_RANKING',
  'EVENT_USERS' = 'EVENT_USERS',
  'FACTIONS' = 'FACTIONS',
  'GAMES' = 'GAMES',
  'GAME_TYPES' = 'GAME_TYPES',
  'GOOGLE_FORM' = 'GOOGLE_FORM',
  'ILLUSTRATIONS' = 'ILLUSTRATIONS',
  'LANGUAGES' = 'LANGUAGES',
  'ME' = 'ME',
  'MY_GAMES_STATS' = 'MY_GAMES_STATS',
  'MY_OFFERS' = 'MY_OFFERS',
  'NOTIFICATIONS' = 'NOTIFICATIONS',
  'NOTIFICATIONS_UNREAD' = 'NOTIFICATIONS_UNREAD',
  'NOTIFICATION_PARAMETERS' = 'NOTIFICATION_PARAMETERS',
  'NOTIFICATION_USER_PARAMETERS' = 'NOTIFICATIONS_USER_PARAMETERS',
  'NUMBER_COPY_AVAILABLE' = 'NUMBER_COPY_AVAILABLE',
  'OFFERS' = 'OFFERS',
  'OPTINS' = 'OPTINS',
  'OWNERSHIPS' = 'OWNERSHIPS',
  'OWNERSHIP_HISTORY' = 'OWNERSHIP_HISTORY',
  'PAYMENT_METHODS' = 'PAYMENT_METHODS',
  'PAYOUT_BANK' = 'PAYOUT_BANK',
  'PAYOUT' = 'PAYOUT',
  'PRICING_GRID' = 'PRICING_GRID',
  'PRINTING' = 'PRINTING',
  'QR_CODES' = 'QR_CODES',
  'SHOPS' = 'SHOPS',
  'SHOP_STATS' = 'SHOP_STATS',
  'SOCIAL_NETWORKS' = 'SOCIAL_NETWORKS',
  'TRANSACTIONS' = 'TRANSACTIONS',
  'USERS' = 'USERS',
  'TRADE_LIST' = 'TRADE_LIST',
  'USER_TOKENS' = 'USER_TOKENS',
  'WALLET_BALANCE' = 'WALLET_BALANCE',
  'WALLET_HISTORY' = 'WALLET_HISTORY',
}

const createKeyFactory = (keys: KEYS[]) => {
  return {
    all: (): QueryKey => keys,
    details: (id: string | null): QueryKey => Array.prototype.flat.call([keys, 'DETAILS', ...(id ? [id] : [])]),
    list: (params?: ParsedQuery | string): QueryKey =>
      Array.prototype.flat.call([keys, 'LIST', ...(params ? [params] : [])]),
  };
};

export default createKeyFactory;
