import clx from 'classnames';
import Button from '@/atoms/Button';
import { FormProps } from '@/molecules/Form/types';

const Form = ({ children, submitLabel, isMutating = false, className, ...props }: FormProps) => (
  <form className={clx('flex flex-col gap-8', className)} {...props}>
    <div className="max-h-full flex flex-col gap-4">{children}</div>

    {submitLabel && (
      <Button
        className="w-full md:w-fit m-auto"
        disabled={isMutating}
        icon={isMutating ? 'fa-solid fa-solid fa-circle-notch animate-spin' : ''}
        type="submit"
      >
        {submitLabel}
      </Button>
    )}
  </form>
);

export default Form;
