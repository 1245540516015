import { useTranslation } from 'next-i18next';

type SuccessProps = { text: string };

const SuccessLabel = ({ text }: SuccessProps) => {
  const { t } = useTranslation();

  return <p className="text-system-success-400 sm-medium">{t(text)}</p>;
};

export default SuccessLabel;
