import { FormProvider, UseFormReturn } from 'react-hook-form';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import { NestedFormValues } from 'utils/filters/convertToDotNotation';
import useFilters from './hooks';

type FiltersPropsType = {
  onSubmit: (values: NestedFormValues) => void;
  onClear: () => void;
  closeModal: () => void;
  formMethods: UseFormReturn<NestedFormValues>;
} & Parameters<typeof useFilters>[0];

const Filters = ({ closeModal, onSubmit, onClear, formMethods, ...hookProps }: FiltersPropsType) => {
  const { components, modalTitle } = useFilters(hookProps);
  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit(onSubmit)} title={modalTitle}>
        <form className="min-w-vw-1/2">
          <ul className="flex flex-col gap-4">{components}</ul>
          <input className="hidden" type="submit" />
        </form>
      </ModalClassicLayout>
    </FormProvider>
  );
};

export default Filters;
