import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MarketplaceOfferCardOfferDataJsonldCardOfferDataRead } from '@/api/generated';
import AvatarLabel from '@/atoms/AvatarLabel';
import CounterInput from '@/atoms/forms/Counter';
import CardThumb from '@/molecules/CardThumb';
import TextualData from '@/molecules/TextualData';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import { CardDetailsAlias } from 'types/alias/types';
import { SIZE } from 'types/utils';
import useCreateCart from './hooks';

const CreateCart = ({
  card,
  closeModal,
  cardOffer,
}: {
  card: CardDetailsAlias;
  closeModal: () => void;
  cardOffer: MarketplaceOfferCardOfferDataJsonldCardOfferDataRead;
}) => {
  const { t } = useTranslation();
  const { formMethods, onSubmit, total, numberCopyAvailable } = useCreateCart({ closeModal, cardOffer });

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="buy">
      <FormProvider {...formMethods}>
        <form className="py-0" name="create-cart" onSubmit={onSubmit}>
          <div className="flex flex-col">
            <div className="flex">
              <AvatarLabel avatarPath={cardOffer?.user?.avatarPath} nickName={cardOffer?.user?.nickName} />
              <TextualData className="ml-auto">
                {cardOffer?.price}€ / {t('unit')}
              </TextualData>
            </div>

            <div className="flex flex-col items-center mt-4 gap-4">
              <p className="text-neutral-700 lg-bold">
                {numberCopyAvailable} {t('available')}
              </p>
              <CardThumb alt="" imagePath={card.imagePath} size={SIZE.MD} />
              <CounterInput label="quantity" max={numberCopyAvailable} min={1} name="quantity" />
            </div>
          </div>

          <div className="mt-6 py-4 grid grid-cols-2">
            <p className="text-neutral-400 md-bold">{t('total')}</p>
            <p className="text-neutral-900 md-bold justify-self-end">{`${total}€`}</p>
          </div>
          <input className="hidden" type="submit" />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default CreateCart;
