import { useTranslation } from 'react-i18next';
import VerticalBar from './VerticalBar';

type Props = {
  label: string;
  max: string | number;
  data: string[][];
  renderLabel: (v: string) => React.ReactElement;
};

const VerticalBars = ({ label, data, max, renderLabel }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <p className="sm-bold text-center mb-2">{t(label)}</p>
      <ul className="flex gap-1 w-fit m-auto">
        {data.map(([dataLabel, value]) => (
          <li key={dataLabel + value}>
            <VerticalBar max={max} value={value} />
            {renderLabel(dataLabel)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VerticalBars;
