import * as RadioGroup from '@radix-ui/react-radio-group';
import clx from 'classnames';
import { InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

export type VisualRadioProps = InputHTMLAttributes<HTMLInputElement> & {
  value: string;
  icon?: string;
  image?: string;
  id: string;
  supportingText?: string;
  label: string;
};

const VisualRadio = ({ disabled, label, id, checked, icon, supportingText, value, image }: VisualRadioProps) => {
  const { t } = useTranslation();

  return (
    <RadioGroup.Item
      checked={checked}
      className={clx(
        'flex flex-col items-center gap-4 p-4 pb-2 bg-neutral-30 hover:bg-neutral-40 rounded-lg relative w-full',
        'border-transparent data-state-checked:border-primary-500 border-4 data-state-checked:bg-neutral-0 max-w-56',
        'transition-colors duration-quick ease-out',
        disabled && 'bg-neutral-0 text-system-disabled-400',
      )}
      disabled={disabled}
      id={id}
      value={value}
    >
      <>
        {icon && (
          <i
            className={clx(
              icon,
              'text-5xl transition-all',
              checked && 'text-primary-500',
              disabled && 'text-system-disabled-300',
              'text-neutral-500',
            )}
          />
        )}
        {image && <img alt="" src={image} />}
        <div>
          <div className="flex items-center gap-2 relative">
            <p className="uppercase w-full text-center md-bold-caps">{label}</p>
          </div>
          {supportingText && (
            <p className={clx('md-regular text-neutral-400', disabled && 'text-system-disabled-400')}>
              {t(supportingText)}
            </p>
          )}
        </div>
      </>
    </RadioGroup.Item>
  );
};

export default VisualRadio;
