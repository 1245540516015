const path = require('path');
const sprintf = require('i18next-sprintf-postprocessor');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    locales: ['en-us', 'fr-fr'],
    defaultLocale: 'en-us',
    lowerCaseLng: true,
    localePath: path.resolve('./src/i18n/locales'),
    localeStructure: '{{lng}}/{{ns}}',
    postProcess: 'sprintf',
    use: [sprintf],
    serializeConfig: false,
  },
};
