import clx from 'classnames';
import Link from 'next/link';
import { CmsImageType } from 'utils/cms/types';

type VisualButtonProps = {
  title: string;
  image?: CmsImageType;
  icon?: string;
  url: string;
  className?: string;
};
const VisualButton = ({ image, title, className, icon, url }: VisualButtonProps) => (
  <Link className="hover:no-underline group" href={url}>
    <div
      className={clx(
        'relative bg-cover bg-center clickable h-[77px] md:h-[114px] overflow-hidden',
        'hover:rounded-tl hover:rounded-br transition-all duration-quick ease-in-out',
        'before:bg-neutral-900 before:absolute before:w-full before:h-full before:block before:opacity-30 hover:before:opacity-40',
        className,
      )}
      style={{ backgroundImage: image?.sizes.large ? `url(${image.sizes.large})` : undefined }}
    >
      <div className="flex gap-4 justify-center items-center w-full h-full p-4">
        <i className={clx(icon, 'text-5xl text-shadow-lg text-neutral-0')} />
        <h5 className={clx('title-3 text-shadow-lg shadow-black text-neutral-0 z-10 relative')}>{title}</h5>
      </div>
      <div
        className={`text-transparent absolute bottom-2 right-4 -translate-x-1/4
       group-hover:text-neutral-0 group-hover:translate-x-0 transition-all duration-quick ease-in-out`}
      >
        <i className="fa-solid fa-arrow-right" />
      </div>
    </div>
  </Link>
);

export default VisualButton;
