import clx from 'classnames';
import { SIZE } from 'types/utils';
import { BORDER_SIZE, IMAGE_SIZE, SIZE_TO_CLASSE } from './consts';

type AvatarProps = {
  src?: string | null;
  size?: SIZE;
  alt: string;
  className?: string;
  border?: boolean;
};

const Avatar = ({ src, size = SIZE.SM, alt, className, border }: AvatarProps) => (
  <div
    className={clx(
      SIZE_TO_CLASSE[size],
      'rounded-full overflow-hidden isolate relative',
      !!border && BORDER_SIZE[size],
      className,
    )}
  >
    <img alt={alt} height={IMAGE_SIZE[size]} src={src || '/default-avatar.png'} width={IMAGE_SIZE[size]} />
  </div>
);

export default Avatar;
