import { useSession } from 'next-auth/react';
import { createApiClient } from '@/api/client';

const useApi = () => {
  const session = useSession();
  const jwt = session.data?.accessToken ?? session.data?.jwt;

  return createApiClient(jwt);
};

export default useApi;
