import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import OrderDetails from '@/organisms/OrderDetails';
import OrderItemsList from '@/organisms/OrderItemList';
import ModalNavLayout from 'context/Modal/layout/Nav';
import useDateFnsWithLocale from 'hooks/i18n/useDateFnsWithLocale';
import useMyCart from 'hooks/queries/marketplace/carts/useMyCart';
import { MarketplaceOrderItem } from 'types/alias/types';
import { SIZE } from 'types/utils';

type Prop = {
  closeModal: () => void;
};

const CartNavModal = ({ closeModal }: Prop) => {
  const { cart } = useMyCart();
  const { t } = useTranslation();
  const { format } = useDateFnsWithLocale();
  // @ts-expect-error: bad API typing
  const orderItems = cart?.orderItems as MarketplaceOrderItem[];

  return (
    <ModalNavLayout closeModal={closeModal} direction="right">
      <div className="p-4 mb-[52px] flex flex-col gap-4">
        <p className="sm-bold-caps flex gap-2 items-center text-neutral-400 py-3">
          <i className="fa-solid fa-cart-shopping text-neutral-500" />
          {t('my-cart')}
        </p>
        {cart?.dateEnd && (
          <p className="text-neutral-700 w-full bg-neutral-40 flex gap-2 px-3 py-2 items-center rounded-lg">
            <i className="fa-solid fa-circle-info text-neutral-400" />
            {t('cart-will-delete', { time: format(parseISO(cart.dateEnd.concat('Z')), 'p') })}
          </p>
        )}
        <OrderItemsList orderItems={orderItems} />
        <div className="h-px bg-neutral-50 w-full" />
        {cart && <OrderDetails cart={cart} />}
      </div>
      <Button
        className="fixed left-4 right-4 bottom-4"
        href="/marketplace/market-payment-checkout"
        icon="fa-solid fa-trash"
        size={SIZE.MD}
        variant={BUTTON_VARIANTS.PRIMARY}
      >
        {t('validate-pay-in')}
      </Button>
    </ModalNavLayout>
  );
};

export default CartNavModal;
