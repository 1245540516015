export const getErrorMessage = async (e: any) => {
  const error = e.json && !e.body?.locked ? await e.json() : e;
  let errorMessage = 'Unknown Error';
  if (typeof error === 'string') {
    errorMessage = error;
  }
  if (typeof error === 'object' && error.message) {
    errorMessage = error.message;
  } else if (typeof error === 'object' && error['hydra:description']) {
    errorMessage = error['hydra:description'];
  } else if (typeof error === 'object' && error.error['hydra:description']) {
    errorMessage = error.error['hydra:description'];
  } else if (typeof error === 'object' && typeof error.then === 'function') {
    const jsonError = await error.json();
    errorMessage = jsonError['hydra:description'];
  }

  return errorMessage;
};

export const getErrorCode = (error: any) => {
  if (typeof error === 'object' && error.code) {
    return error.code;
  }

  return 500;
};
