import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useCardsApi from 'hooks/queries/cards/useCardsApi';
import useApi from 'hooks/useApi';
import { VisualOptions } from 'types/forms';
import { CARD_PRODUCT_API_REF, CARD_TYPE_API_REF, RARITY_API_REF } from 'types/modifiers/references';
import deckFactory from 'utils/factories/decks';

type Props = {
  deckId: string;
  faction: string;
  closeModal: () => void;
};

const deckSchema = () =>
  yup
    .object({
      alterator: yup.string().required(),
    })
    .required();

type FormValues = yup.InferType<ReturnType<typeof deckSchema>>;

const useDeckChangeHero = ({ deckId, faction, closeModal }: Props) => {
  const { cards } = useCardsApi({
    rarity: RARITY_API_REF.COMMON,
    'cardProduct.reference': CARD_PRODUCT_API_REF.BOOSTER,
    cardType: CARD_TYPE_API_REF.HERO,
    'factions[]': faction,
  });
  const queryClient = useQueryClient();
  const api = useApi();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(deckSchema()),
  });

  const options = cards?.reduce((acc: VisualOptions, cur) => {
    return {
      ...acc,
      [cur['@id']]: {
        image: cur.imagePath || '',
        label: cur.name || '',
      },
    };
  }, {});

  const updateDeck = useMutation({
    mutationKey: ['updateDeckHero'],
    mutationFn: (alterator: string) => api.deckUserLists.apiDeckUserListsIdPut(deckId, { alterator }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: deckFactory.details(deckId) });
      closeModal();
    },
  });

  const handleSubmit = ({ alterator }: FormValues) => {
    updateDeck.mutate(alterator);
  };

  return { options, handleSubmit: formMethods.handleSubmit(handleSubmit), formMethods };
};

export default useDeckChangeHero;
