// eslint-disable-next-line import/prefer-default-export
export const MODAL_NAME = {
  ADD_CARD_ALT: 'ADD_CARD_ALT',
  ADD_CARD_TO_PAGE: 'ADD_CARD_TO_PAGE',
  ADD_TO_TRADELIST: 'ADD_TO_TRADELIST',
  ADDRESS_CREATE: 'ADDRESS_CREATE',
  ADDRESS_EDITION: 'ADDRESS_EDITION',
  ADDRESS_LIST: 'ADDRESS_LIST',
  ALERT: 'ALERT',
  APPLY_FOILER_RESULT: 'APPLY_FOILER_RESULT',
  APPLY_FOILER: 'APPLY_FOILER',
  BANK_ACCOUNT_CREATE: 'BANK_ACCOUNT_CREATE',
  DUPLICATE_DECK: 'DUPLICATE_DECK',
  CARD_LIST: 'CARD_LIST',
  TRADE_CARD_LIST: 'TRADE_CARD_LIST',
  PICK_COLLECTIONS_CARDS: 'PICK_COLLECTIONS_CARDS',
  CART_NAV: 'CART_NAV',
  CONFIRM_DECK_DELETION: 'CONFIRM_DECK_DELETION',
  CONFIRM_MODAL: 'CONFIRM_MODAL',
  CONFIRM_OFFER_DELETION: 'CONFIRM_OFFER_DELETION',
  CONFIRM_UNSUBSUCRIBE: 'CONFIRM_UNSUBSUCRIBE',
  CREATE_CART: 'CREATE_CART',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
  CREATE_PRINTING_ORDER: 'CREATE_PRINTING_ORDER',
  CREATE_PRINTING_PAGE: 'CREATE_PRINTING_PAGE',
  CREATE_TEAM_MEMBER: 'CREATE_TEAM_MEMBER',
  DECK_CHANGE_HERO: 'DECK_CHANGE_HERO',
  DECK_REGISTRATION: 'DECK_REGISTRATION',
  DECK_RENAME: 'DECK_RENAME',
  DECK_FILTER: 'DECK_FILTER',
  DECK_STATS: 'DECK_STATS',
  DELETE_SHOP: 'DELETE_SHOP',
  FRIENDS_LIST: 'FRIENDS_LIST',
  EDITORIAL_NAV: 'EDITORIAL_NAV',
  FILTERS: 'FILTERS',
  GIVE_CARD: 'GIVE_CARD',
  LANGUAGE: 'LANGUAGE',
  MANAGE_OFFER: 'MANAGE_OFFER',
  PAYMENT_LIST: 'PAYMENT_LIST',
  PAYMENT_SUMMARY: 'PAYMENT_SUMMARY',
  PAYMENT_TYPE_LIST: 'PAYMENT_TYPE_LIST',
  PICK_AVATAR: 'PICK_AVATAR',
  PICK_BACKGROUND: 'PICK_BACKGROUND',
  PRINCING_OFFERS: 'PRINCING_OFFERS',
  SHARE_PRINTING_ORDER: 'SHARE_PRINTING_ORDER',
  SHARE_DECK: 'SHARE_DECK',
  SHOP_DETAILS: 'SHOP_DETAILS',
  SHOP_EVENTS_LIST: 'SHOP_EVENTS_LIST',
  USER_NAV: 'USER_NAV',
  ZOOM_SLIDER: 'ZOOM_SLIDER',
  ZOOM: 'ZOOM',
} as const;
