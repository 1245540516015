import clx from 'classnames';
import { useTranslation } from 'react-i18next';
import { MarketplaceOrderJsonldMarketplaceOrderDetailRead } from '@/api/generated';
import MoneyData from '@/atoms/MoneyData';
import useMyWallet from 'hooks/queries/marketplace/wallet/useMyWallet';

const OrderDetails = ({
  cart,
  className,
}: {
  cart: MarketplaceOrderJsonldMarketplaceOrderDetailRead;
  className?: string;
}) => {
  const { t } = useTranslation();
  const { data: wallet } = useMyWallet();

  return (
    <div className={clx('grid grid-cols-2 grid-auto-rows gap-y-3', className)}>
      <p className="col-span-2 lg-bold">{t('order-details')}</p>
      <p className="sm-medium text-neutral-800">{t('card-quantity')}</p>
      <p className="justify-self-end sm-medium text-neutral-800">{cart?.totalQuantity}</p>
      <p className="sm-medium text-neutral-800">{t('total-order')}</p>
      <p className=" sm-medium justify-self-end text-neutral-800">
        <MoneyData amount={cart?.totalPrice} currency={wallet?.currency} />
      </p>
      <p className="sm-bold text-secondary-400">{t('wallet-balance')}</p>
      <p className="justify-self-end sm-bold text-secondary-400">
        <MoneyData amount={wallet?.balance} currency={wallet?.currency} />
      </p>
      <div className="h-px bg-neutral-50 col-span-2" />
      <p className="sm-bold text-neutral-800">{t('left-to-pay')}</p>
      <p className="justify-self-end title-6 text-neutral-800">
        <MoneyData amount={cart?.missingAmount} currency={wallet?.currency} />
      </p>
    </div>
  );
};

export default OrderDetails;
