import { useQueryClient } from '@tanstack/react-query';
import { signOut } from 'next-auth/react';
import { env } from 'next-runtime-env';
import { HttpResponse } from '@/api/generated';
import useApi from 'hooks/useApi';
import { LoginPayloadtype } from 'hooks/useAuth/types';
import { onLogout } from 'utils/webview';
// import usersFactory from 'utils/factories/user';

const useAuth = () => {
  const apiClient = useApi();

  const queryClient = useQueryClient();

  const logout = async () => {
    await signOut({ redirect: true, callbackUrl: window.location.origin });
    await queryClient.invalidateQueries();
    onLogout();

    return true;
  };

  const login = async (payload: LoginPayloadtype) => {
    let response = null;
    if ('email' in payload && 'password' in payload) {
      response = await apiClient.login.loginCheckPost(payload);
    }
    if ('provider' in payload) {
      // no method in Api Client for /social/login
      response = (await fetch(`${env('NEXT_PUBLIC_API_ENTRYPOINT')}/social/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })) as unknown as HttpResponse<
        {
          token: string;
          refresh_token?: string | undefined;
        },
        any
      >;
    }

    if (!response || !response.data.token || !response.data.refresh_token) {
      throw new Error(response?.error);
    }

    await queryClient.invalidateQueries();

    return response;
  };

  return { login, logout };
};

export default useAuth;
