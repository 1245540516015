/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddressAddressRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressAddressWrite {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
}

export interface AddressEventLocatorReadShopLocatorRead {
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
}

export interface AddressEventRead {
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
}

export interface AddressEventReadAddressReadEventTypeReadIllustrationRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressEventReadEventTypeReadIllustrationRead {
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
}

export interface AddressMarketplaceOrderDetailRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  zipCode?: string;
  city?: string;
  country?: CountryMarketplaceOrderDetailRead | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressPrintingOrderPartnerRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
}

export interface AddressPrintingPageReadPrintingOrderPartnerRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
}

export interface AddressPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
}

export interface AddressPrintingPaymentRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  zipCode?: string;
  city?: string;
  /** @format iri-reference */
  country?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressShopLocatorRead {
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
}

export interface AddressShopRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
}

export interface AddressShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  country?: CountryShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressUserPrivateReadUserTokenRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressUserReadUserPrivateRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  country?: CountryUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressUserWriteAddressWrite {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
}

export interface AddressUserWriteShopWriteAddressWrite {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
}

export interface AddressJsonldAddressRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressJsonldAddressWrite {
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
}

export interface AddressJsonldEventLocatorReadShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
}

export interface AddressJsonldEventRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
}

export interface AddressJsonldEventReadAddressReadEventTypeReadIllustrationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressJsonldEventReadEventTypeReadIllustrationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
}

export interface AddressJsonldMarketplaceOrderDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  zipCode?: string;
  city?: string;
  country?: CountryJsonldMarketplaceOrderDetailRead | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressJsonldPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
}

export interface AddressJsonldPrintingPageReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
}

export interface AddressJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
}

export interface AddressJsonldPrintingPaymentRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  zipCode?: string;
  city?: string;
  /** @format iri-reference */
  country?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressJsonldShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
}

export interface AddressJsonldShopRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
}

export interface AddressJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  country?: CountryJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressJsonldUserPrivateReadUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressJsonldUserReadUserPrivateRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  country?: CountryJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface AddressJsonldUserWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
}

export interface AddressJsonldUserWriteShopWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  label?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string;
  additionalAddress?: string | null;
  zipCode?: string;
  city?: string;
  region?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  /** @format iri-reference */
  country?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
}

export interface BankAccount {
  id?: string;
  IBAN?: string;
  BIC?: string;
  accountNumber?: string;
  sortCode?: string;
  institutionNumber?: string;
  branchCode?: string;
  bankName?: string;
  ABA?: string;
  ownerName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  postalCode?: string;
  region?: string;
  type?: 'IBAN' | 'CA' | 'US' | 'GB' | 'OTHER';
  iBAN?: string;
  bIC?: string;
  aBA?: string;
  iban?: string;
  bic?: string;
}

export interface BankAccountBankAccountRead {
  id?: string;
  ownerName?: string;
  iban?: string;
  bic?: string;
}

export interface BankAccountJsonld {
  id?: string;
  IBAN?: string;
  BIC?: string;
  accountNumber?: string;
  sortCode?: string;
  institutionNumber?: string;
  branchCode?: string;
  bankName?: string;
  ABA?: string;
  ownerName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  postalCode?: string;
  region?: string;
  type?: 'IBAN' | 'CA' | 'US' | 'GB' | 'OTHER';
  iBAN?: string;
  bIC?: string;
  aBA?: string;
  iban?: string;
  bic?: string;
}

export interface BankAccountJsonldBankAccountRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  id?: string;
  ownerName?: string;
  iban?: string;
  bic?: string;
}

export interface CardCardDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  loreEntries?: LoreEntryCardDetailRead[];
  cardType: CardTypeCardDetailRead | null;
  cardSubTypes?: CardSubTypeCardDetailRead[];
  cardSet: CardSetCardDetailRead | null;
  rarity: RarityCardDetailRead | null;
  cardCycle?: CardCycleCardDetailRead | null;
  cardRulings?: CardRulingCardDetailRead[];
  cardProduct: CardProductCardDetailRead | null;
  imagePath?: string | null;
  /** @format date-time */
  latestAddition?: string | null;
  inMyTradelist?: number;
  inMyCollection?: number;
  inMyWantlist?: boolean;
  assets?: Record<string, string | string[]>;
  lowerPrice?: number;
  qrUrlDetail?: string | null;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionCardDetailRead | null;
  allImagePath?: string[];
  name?: string;
  elements?: Record<string, string>;
  foiled?: boolean;
}

export interface CardCardRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeCardRead | null;
  cardSubTypes?: CardSubTypeCardRead[];
  rarity: RarityCardRead | null;
  cardProduct: CardProductCardRead | null;
  imagePath?: string | null;
  /** @format date-time */
  latestAddition?: string | null;
  inMyTradelist?: number;
  inMyCollection?: number;
  inMyWantlist?: boolean;
  assets?: Record<string, string | string[]>;
  qrUrlDetail?: string | null;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionCardRead | null;
  name?: string;
  elements?: Record<string, string>;
  collectorNumberFormatted?: string | null;
}

export interface CardCardReadRarityReadCardMarketplaceRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeCardReadRarityReadCardMarketplaceRead | null;
  cardSubTypes?: CardSubTypeCardReadRarityReadCardMarketplaceRead[];
  rarity: RarityCardReadRarityReadCardMarketplaceRead | null;
  cardProduct: CardProductCardReadRarityReadCardMarketplaceRead | null;
  imagePath?: string | null;
  /** @format date-time */
  latestAddition?: string | null;
  inMyTradelist?: number;
  inMyCollection?: number;
  inMyWantlist?: boolean;
  assets?: Record<string, string | string[]>;
  lowerPrice?: number;
  inSale?: number;
  qrUrlDetail?: string | null;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionCardReadRarityReadCardMarketplaceRead | null;
  name?: string;
  elements?: Record<string, string>;
  collectorNumberFormatted?: string | null;
}

export interface CardCardShortRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  assets?: Record<string, string | string[]>;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardCardVariant {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  rarity: RarityCardVariant | null;
  imagePath?: string | null;
  inMyCollection?: number;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardCardMySalesRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  rarity: RarityCardMySalesRead | null;
  imagePath?: string | null;
  inMyCollection?: number;
  /** @format date-time */
  date?: string;
  myPrice?: number;
  inMySale?: number;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardDeckUserListRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeDeckUserListRead | null;
  rarity: RarityDeckUserListRead | null;
  imagePath?: string | null;
  inMyCollection?: number;
  assets?: Record<string, string | string[]>;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionDeckUserListRead | null;
  name?: string;
}

export interface CardDeckUserListReadDeckUserListDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeDeckUserListReadDeckUserListDetailRead | null;
  rarity: RarityDeckUserListReadDeckUserListDetailRead | null;
  imagePath?: string | null;
  inMyCollection?: number;
  assets?: Record<string, string | string[]>;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionDeckUserListReadDeckUserListDetailRead | null;
  name?: string;
  elements?: Record<string, string>;
}

export interface CardOwnerClaimMultiRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  cardType: string | null;
  rarity: RarityOwnerClaimMultiRead | null;
  imagePath?: string | null;
  inMyCollection?: number;
  assets?: Record<string, string | string[]>;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionOwnerClaimMultiRead | null;
  name?: string;
  collectorNumberFormatted?: string | null;
}

export interface CardOwnerExchangeRequestReadOwnerExchangeRequestDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  imagePath?: string | null;
}

export interface CardOwnerHistoryGroupRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  rarity: RarityOwnerHistoryGroupRead | null;
  imagePath?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardOwnershipRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  imagePath?: string | null;
  inMyCollection?: number;
  inMyWantlist?: boolean;
  loreEntryElements?: Record<string, string>;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardOwnershipListRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  imagePath?: string | null;
  inMyCollection?: number;
  quantity?: number | null;
  name?: string;
}

export interface CardPrintingAuthorizationRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardSet: CardSetPrintingAuthorizationRead | null;
  cardProduct: CardProductPrintingAuthorizationRead | null;
  imagePath?: string | null;
  name?: string;
}

export interface CardPrintingOrderRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  rarity: string | null;
  imagePath?: string | null;
  name?: string;
}

export interface CardPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  rarity: string | null;
  imagePathPartner?: string | null;
  imagePDFPartner?: string | null;
  name?: string;
}

export interface CardPrintingPageReadPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  rarity: string | null;
  imagePathPartner?: string | null;
  imagePDFPartner?: string | null;
  name?: string;
}

export interface CardPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  rarity: string | null;
  imagePath?: string | null;
  imagePathPartner?: string | null;
  imagePDFPartner?: string | null;
  name?: string;
}

export interface CardTransactionReadPrintingReadPrintingTransactionRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeTransactionReadPrintingReadPrintingTransactionRead | null;
  cardSubTypes?: CardSubTypeTransactionReadPrintingReadPrintingTransactionRead[];
  rarity: RarityTransactionReadPrintingReadPrintingTransactionRead | null;
  imagePath?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  loreEntries?: LoreEntryJsonldCardDetailRead[];
  cardType: CardTypeJsonldCardDetailRead | null;
  cardSubTypes?: CardSubTypeJsonldCardDetailRead[];
  cardSet: CardSetJsonldCardDetailRead | null;
  rarity: RarityJsonldCardDetailRead | null;
  cardCycle?: CardCycleJsonldCardDetailRead | null;
  cardRulings?: CardRulingJsonldCardDetailRead[];
  cardProduct: CardProductJsonldCardDetailRead | null;
  imagePath?: string | null;
  /** @format date-time */
  latestAddition?: string | null;
  inMyTradelist?: number;
  inMyCollection?: number;
  inMyWantlist?: boolean;
  assets?: Record<string, string | string[]>;
  lowerPrice?: number;
  qrUrlDetail?: string | null;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionJsonldCardDetailRead | null;
  allImagePath?: string[];
  name?: string;
  elements?: Record<string, string>;
  foiled?: boolean;
}

export interface CardJsonldCardRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeJsonldCardRead | null;
  cardSubTypes?: CardSubTypeJsonldCardRead[];
  rarity: RarityJsonldCardRead | null;
  cardProduct: CardProductJsonldCardRead | null;
  imagePath?: string | null;
  /** @format date-time */
  latestAddition?: string | null;
  inMyTradelist?: number;
  inMyCollection?: number;
  inMyWantlist?: boolean;
  assets?: Record<string, string | string[]>;
  qrUrlDetail?: string | null;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionJsonldCardRead | null;
  name?: string;
  elements?: Record<string, string>;
  collectorNumberFormatted?: string | null;
}

export interface CardJsonldCardReadRarityReadCardMarketplaceRead {
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeJsonldCardReadRarityReadCardMarketplaceRead | null;
  cardSubTypes?: CardSubTypeJsonldCardReadRarityReadCardMarketplaceRead[];
  rarity: RarityJsonldCardReadRarityReadCardMarketplaceRead | null;
  cardProduct: CardProductJsonldCardReadRarityReadCardMarketplaceRead | null;
  imagePath?: string | null;
  /** @format date-time */
  latestAddition?: string | null;
  inMyTradelist?: number;
  inMyCollection?: number;
  inMyWantlist?: boolean;
  assets?: Record<string, string | string[]>;
  lowerPrice?: number;
  inSale?: number;
  qrUrlDetail?: string | null;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionJsonldCardReadRarityReadCardMarketplaceRead | null;
  name?: string;
  elements?: Record<string, string>;
  collectorNumberFormatted?: string | null;
}

export interface CardJsonldCardShortRead {
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  assets?: Record<string, string | string[]>;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardJsonldCardVariant {
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  rarity: RarityJsonldCardVariant | null;
  imagePath?: string | null;
  inMyCollection?: number;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardJsonldCardMySalesRead {
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  rarity: RarityJsonldCardMySalesRead | null;
  imagePath?: string | null;
  inMyCollection?: number;
  /** @format date-time */
  date?: string;
  myPrice?: number;
  inMySale?: number;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardJsonldDeckUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeJsonldDeckUserListRead | null;
  rarity: RarityJsonldDeckUserListRead | null;
  imagePath?: string | null;
  inMyCollection?: number;
  assets?: Record<string, string | string[]>;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionJsonldDeckUserListRead | null;
  name?: string;
}

export interface CardJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeJsonldDeckUserListReadDeckUserListDetailRead | null;
  rarity: RarityJsonldDeckUserListReadDeckUserListDetailRead | null;
  imagePath?: string | null;
  inMyCollection?: number;
  assets?: Record<string, string | string[]>;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionJsonldDeckUserListReadDeckUserListDetailRead | null;
  name?: string;
  elements?: Record<string, string>;
}

export interface CardJsonldOwnerClaimMultiRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  cardType: string | null;
  rarity: RarityJsonldOwnerClaimMultiRead | null;
  imagePath?: string | null;
  inMyCollection?: number;
  assets?: Record<string, string | string[]>;
  /** @format ulid */
  id?: string | null;
  mainFaction?: FactionJsonldOwnerClaimMultiRead | null;
  name?: string;
  collectorNumberFormatted?: string | null;
}

export interface CardJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  imagePath?: string | null;
}

export interface CardJsonldOwnerHistoryGroupRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  rarity: RarityJsonldOwnerHistoryGroupRead | null;
  imagePath?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardJsonldOwnershipRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  imagePath?: string | null;
  inMyCollection?: number;
  inMyWantlist?: boolean;
  loreEntryElements?: Record<string, string>;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardJsonldOwnershipListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  imagePath?: string | null;
  inMyCollection?: number;
  quantity?: number | null;
  name?: string;
}

export interface CardJsonldPrintingAuthorizationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardSet: CardSetJsonldPrintingAuthorizationRead | null;
  cardProduct: CardProductJsonldPrintingAuthorizationRead | null;
  imagePath?: string | null;
  name?: string;
}

export interface CardJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  rarity: string | null;
  imagePath?: string | null;
  name?: string;
}

export interface CardJsonldPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  rarity: string | null;
  imagePathPartner?: string | null;
  imagePDFPartner?: string | null;
  name?: string;
}

export interface CardJsonldPrintingPageReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  rarity: string | null;
  imagePathPartner?: string | null;
  imagePDFPartner?: string | null;
  name?: string;
}

export interface CardJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format iri-reference */
  rarity: string | null;
  imagePath?: string | null;
  imagePathPartner?: string | null;
  imagePDFPartner?: string | null;
  name?: string;
}

export interface CardJsonldTransactionReadPrintingReadPrintingTransactionRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeJsonldTransactionReadPrintingReadPrintingTransactionRead | null;
  cardSubTypes?: CardSubTypeJsonldTransactionReadPrintingReadPrintingTransactionRead[];
  rarity: RarityJsonldTransactionReadPrintingReadPrintingTransactionRead | null;
  imagePath?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export type CardCycleCardDetailRead = object;

export interface CardCycleJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export type CardEffectDisplayCardElementRead = object;

export interface CardEffectDisplayJsonldCardElementRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export interface CardElementCardElementRead {
  /** @format iri-reference */
  cardElementType?: string | null;
  /** @format iri-reference */
  card?: string | null;
  cardEffectDisplays?: CardEffectDisplayCardElementRead[];
  value?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface CardElementJsonldCardElementRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  cardElementType?: string | null;
  /** @format iri-reference */
  card?: string | null;
  cardEffectDisplays?: CardEffectDisplayJsonldCardElementRead[];
  value?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface CardElementTypeCardElementTypeRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'MAIN_COST'
    | 'RECALL_COST'
    | 'OCEAN_POWER'
    | 'MOUNTAIN_POWER'
    | 'FOREST_POWER'
    | 'MAIN_EFFECT'
    | 'ECHO_EFFECT'
    | 'RESERVE'
    | 'PERMANENT';
  sequence?: number | null;
  /** @format iri-reference */
  cardType?: string | null;
  icon?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  name?: string | null;
}

export interface CardElementTypeJsonldCardElementTypeRead {
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'MAIN_COST'
    | 'RECALL_COST'
    | 'OCEAN_POWER'
    | 'MOUNTAIN_POWER'
    | 'FOREST_POWER'
    | 'MAIN_EFFECT'
    | 'ECHO_EFFECT'
    | 'RESERVE'
    | 'PERMANENT';
  sequence?: number | null;
  /** @format iri-reference */
  cardType?: string | null;
  icon?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  name?: string | null;
}

export interface CardFilterDataCardFilterData {
  factions?: string[];
  cardType?: string[];
  cardSet?: string[];
  cardSubTypes?: string[];
  mainCost?: string[];
  recallCost?: string[];
  oceanPower?: string[];
  mountainPower?: string[];
  forestPower?: string[];
  rarity?: string[];
  keyword?: string[];
  artist?: string[];
}

export interface CardFilterDataJsonldCardFilterData {
  '@id'?: string;
  '@type'?: string;
  factions?: string[];
  cardType?: string[];
  cardSet?: string[];
  cardSubTypes?: string[];
  mainCost?: string[];
  recallCost?: string[];
  oceanPower?: string[];
  mountainPower?: string[];
  forestPower?: string[];
  rarity?: string[];
  keyword?: string[];
  artist?: string[];
}

export interface CardGoogleSheetCardGoogleSheetCardGoogleSheetRead {
  googleSheetId?: string;
  /** @format iri-reference */
  cardSet?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface CardGoogleSheetCardGoogleSheetCardGoogleSheetWrite {
  googleSheetId?: string;
  /** @format iri-reference */
  cardSet?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface CardGoogleSheetJsonldCardGoogleSheetCardGoogleSheetRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  googleSheetId?: string;
  /** @format iri-reference */
  cardSet?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface CardGoogleSheetJsonldCardGoogleSheetCardGoogleSheetWrite {
  googleSheetId?: string;
  /** @format iri-reference */
  cardSet?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface CardManufactureCardManufactureRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeManufactureRead | null;
  rarity: RarityManufactureRead | null;
  qrCode?: string | null;
  translations?: Record<string, CardTranslationManufactureRead>;
  mainFaction?: FactionManufactureRead | null;
  name?: string;
  collectorNumberFormatted?: string | null;
}

export interface CardManufactureCardJsonldManufactureRead {
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  cardType: CardTypeJsonldManufactureRead | null;
  rarity: RarityJsonldManufactureRead | null;
  qrCode?: string | null;
  translations?: Record<string, CardTranslationJsonldManufactureRead>;
  mainFaction?: FactionJsonldManufactureRead | null;
  name?: string;
  collectorNumberFormatted?: string | null;
}

export interface CardProductCardDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export interface CardProductCardRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export interface CardProductCardReadRarityReadCardMarketplaceRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export interface CardProductCardProductRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export interface CardProductPrintingAuthorizationRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export interface CardProductJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export interface CardProductJsonldCardRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export interface CardProductJsonldCardReadRarityReadCardMarketplaceRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export interface CardProductJsonldCardProductRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export interface CardProductJsonldPrintingAuthorizationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'B';
  name?: string | null;
}

export type CardRegistrationPaymentCardPostWrite = object;

export type CardRegistrationPaymentCardPutWrite = object;

export interface CardRegistrationPaymentPaymentCardRead {
  id?: string;
  preRegistrationData?: string;
  accessKey?: string;
  cardRegistrationURL?: string;
}

export interface CardRegistrationPaymentCardPaymentPaymentCardRead {
  externalId?: string;
  /**
   * @minLength 0
   * @maxLength 2
   */
  expirationMonth?: string;
  /**
   * @minLength 0
   * @maxLength 2
   */
  expirationYear?: string;
  type?: string;
  active?: boolean;
  validity?: string | null;
  cardProvider?: string | null;
  number?: string;
  /** @format iri-reference */
  user?: string | null;
}

export interface CardRegistrationPaymentCardJsonldPaymentPaymentCardRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  externalId?: string;
  /**
   * @minLength 0
   * @maxLength 2
   */
  expirationMonth?: string;
  /**
   * @minLength 0
   * @maxLength 2
   */
  expirationYear?: string;
  type?: string;
  active?: boolean;
  validity?: string | null;
  cardProvider?: string | null;
  number?: string;
  /** @format iri-reference */
  user?: string | null;
}

export type CardRegistrationJsonldPaymentCardPostWrite = object;

export type CardRegistrationJsonldPaymentCardPutWrite = object;

export interface CardRegistrationJsonldPaymentPaymentCardRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  id?: string;
  preRegistrationData?: string;
  accessKey?: string;
  cardRegistrationURL?: string;
}

export interface CardRulingCardDetailRead {
  gameType?: GameTypeCardDetailRead | null;
  /** @format date-time */
  createdAt?: string | null;
  question?: string;
  answer?: string;
}

export interface CardRulingJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  gameType?: GameTypeJsonldCardDetailRead | null;
  /** @format date-time */
  createdAt?: string | null;
  question?: string;
  answer?: string;
}

export interface CardSetCardDetailRead {
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
}

export interface CardSetCardSetRead {
  /** @format date-time */
  date?: string | null;
  code?: string | null;
  isActive?: boolean | null;
  /** @format iri-reference */
  illustration?: string | null;
  illustrationPath?: string | null;
  cardGoogleSheets?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string | null;
}

export interface CardSetPrintingAuthorizationRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
}

export interface CardSetCardSetDataCardSetCollectionRead {
  cardSets?: PaginatorCardSetCollectionRead;
  cardInCollection?: number;
  averageValue?: number;
}

export interface CardSetCardSetDataJsonldCardSetCollectionRead {
  '@id'?: string;
  '@type'?: string;
  cardSets?: PaginatorJsonldCardSetCollectionRead;
  cardInCollection?: number;
  averageValue?: number;
}

export interface CardSetJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
}

export interface CardSetJsonldCardSetRead {
  '@id'?: string;
  '@type'?: string;
  /** @format date-time */
  date?: string | null;
  code?: string | null;
  isActive?: boolean | null;
  /** @format iri-reference */
  illustration?: string | null;
  illustrationPath?: string | null;
  cardGoogleSheets?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string | null;
}

export interface CardSetJsonldPrintingAuthorizationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
}

export interface CardSubTypeCardDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: string;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardSubTypeCardRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: string;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardSubTypeCardReadRarityReadCardMarketplaceRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: string;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardSubTypeTransactionReadPrintingReadPrintingTransactionRead {
  name?: string;
}

export interface CardSubTypeJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: string;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardSubTypeJsonldCardRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: string;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardSubTypeJsonldCardReadRarityReadCardMarketplaceRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: string;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardSubTypeJsonldTransactionReadPrintingReadPrintingTransactionRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
}

export interface CardTranslationManufactureRead {
  image?: string | null;
  imagePdf?: string | null;
}

export interface CardTranslationJsonldManufactureRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  image?: string | null;
  imagePdf?: string | null;
}

export interface CardTypeCardDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeCardRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeCardReadRarityReadCardMarketplaceRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeCardTypeRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  tags?: string[];
  /** @format ulid */
  id?: string | null;
  name?: string;
  description?: string;
}

export interface CardTypeDeckUserListRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeDeckUserListReadDeckUserListDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeManufactureRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
}

export interface CardTypeTransactionReadPrintingReadPrintingTransactionRead {
  name?: string;
}

export interface CardTypeJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeJsonldCardRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeJsonldCardReadRarityReadCardMarketplaceRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeJsonldCardTypeRead {
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  tags?: string[];
  /** @format ulid */
  id?: string | null;
  name?: string;
  description?: string;
}

export interface CardTypeJsonldDeckUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface CardTypeJsonldManufactureRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference:
    | 'TOKEN'
    | 'HERO'
    | 'CHARACTER'
    | 'SPELL'
    | 'PERMANENT'
    | 'FOILER'
    | 'TOKEN_MANA'
    | 'LANDMARK_PERMANENT'
    | 'EXPEDITION_PERMANENT';
}

export interface CardTypeJsonldTransactionReadPrintingReadPrintingTransactionRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
}

export interface CardUserListCardUserListRead {
  name?: string;
  cardQuantity?: number | null;
  /** @format ulid */
  id?: string | null;
}

export interface CardUserListJsonldCardUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  cardQuantity?: number | null;
  /** @format ulid */
  id?: string | null;
}

export type CardUserListCardCardUserListRead = object;

export interface CardUserListCardCardUserListCardRead {
  /** @format iri-reference */
  card?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface CardUserListCardPostCardsUserListCardDataPostCardsUserListCardDataWrite {
  name: string;
  cards: string[];
}

export interface CardUserListCardPostCardsUserListCardDataJsonldPostCardsUserListCardDataWrite {
  name: string;
  cards: string[];
}

export interface CardUserListCardJsonldCardUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export interface CardUserListCardJsonldCardUserListCardRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  /** @format iri-reference */
  card?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface ContactContactRead {
  name: string;
  email: string;
  reason: string;
  otherReason?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  /** @format iri-reference */
  user?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface ContactContactWrite {
  name: string;
  email: string;
  reason: string;
  otherReason?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  /** @format iri-reference */
  user?: string | null;
}

export interface ContactJsonldContactRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name: string;
  email: string;
  reason: string;
  otherReason?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  /** @format iri-reference */
  user?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface ContactJsonldContactWrite {
  name: string;
  email: string;
  reason: string;
  otherReason?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  /** @format iri-reference */
  user?: string | null;
}

export interface CountryCountryRead {
  flagPath?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface CountryMarketplaceOrderDetailRead {
  name?: string | null;
}

export interface CountryShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  flagPath?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface CountryUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  flagPath?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface CountryJsonldCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  flagPath?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface CountryJsonldMarketplaceOrderDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string | null;
}

export interface CountryJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  flagPath?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface CountryJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  flagPath?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface CurrencyPaymentCurrencyRead {
  id?: string | null;
  code?: string | null;
}

export interface CurrencyJsonldPaymentCurrencyRead {
  '@id'?: string;
  '@type'?: string;
  id?: string | null;
  code?: string | null;
}

export interface DeckCardDeckUserListReadDeckUserListDetailRead {
  character?: DeckCardsDeckUserListReadDeckUserListDetailRead | null;
  spell?: DeckCardsDeckUserListReadDeckUserListDetailRead | null;
  permanent?: DeckCardsDeckUserListReadDeckUserListDetailRead | null;
}

export interface DeckCardJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  character?: DeckCardsJsonldDeckUserListReadDeckUserListDetailRead | null;
  spell?: DeckCardsJsonldDeckUserListReadDeckUserListDetailRead | null;
  permanent?: DeckCardsJsonldDeckUserListReadDeckUserListDetailRead | null;
}

export interface DeckCardsDeckUserListReadDeckUserListDetailRead {
  deckUserListCard?: DeckUserListCardDeckUserListReadDeckUserListDetailRead[];
  number?: number;
}

export interface DeckCardsJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  deckUserListCard?: DeckUserListCardJsonldDeckUserListReadDeckUserListDetailRead[];
  number?: number;
}

export interface DeckLegalityDeckUserListRead {
  resume?: string[];
}

export interface DeckLegalityDeckUserListReadDeckUserListDetailRead {
  resume?: string[];
}

export interface DeckLegalityJsonldDeckUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  resume?: string[];
}

export interface DeckLegalityJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  resume?: string[];
}

export interface DeckUserListDeckUserListPut {
  name?: string;
  /** @format iri-reference */
  alterator?: string | null;
  public?: boolean;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
}

export interface DeckUserListDeckUserListRead {
  name?: string;
  user?: UserDeckUserListRead | null;
  faction?: FactionDeckUserListRead | null;
  alterator?: CardDeckUserListRead | null;
  gameType?: GameTypeDeckUserListRead | null;
  public?: boolean;
  cardQuantity?: number;
  deckLegality?: DeckLegalityDeckUserListRead | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  legal?: boolean;
}

export interface DeckUserListDeckUserListReadDeckUserListDetailRead {
  name?: string;
  user?: UserDeckUserListReadDeckUserListDetailRead | null;
  faction?: FactionDeckUserListReadDeckUserListDetailRead | null;
  alterator?: CardDeckUserListReadDeckUserListDetailRead | null;
  gameType?: GameTypeDeckUserListReadDeckUserListDetailRead | null;
  public?: boolean;
  cardQuantity?: number;
  deckLegality?: DeckLegalityDeckUserListReadDeckUserListDetailRead | null;
  deckCardsByType?: DeckCardDeckUserListReadDeckUserListDetailRead | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  legal?: boolean;
  cardIndexes?: Record<string, string>;
}

export interface DeckUserListDeckUserListWrite {
  name?: string;
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  faction?: string | null;
  /** @format iri-reference */
  alterator?: string | null;
  public?: boolean;
  deckUserListCards?: DeckUserListCardDeckUserListWrite[];
  tags?: string[];
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
}

export interface DeckUserListDeckAddCardDataDeckAddCardDataWrite {
  cards?: DeckUserListCardDeckAddCardDataWrite[];
  putOption?: string | null;
}

export interface DeckUserListDeckAddCardDataJsonldDeckAddCardDataWrite {
  cards?: DeckUserListCardJsonldDeckAddCardDataWrite[];
  putOption?: string | null;
}

export interface DeckUserListDeckMultiScanDeckUserListRead {
  id?: string | null;
  successes?: CardDeckUserListRead[];
  fails?: CardDeckUserListRead[];
}

export interface DeckUserListDeckMultiScanJsonldDeckUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  id?: string | null;
  successes?: CardJsonldDeckUserListRead[];
  fails?: CardJsonldDeckUserListRead[];
}

export interface DeckUserListDeckStatisticsDataDeckUserListReadDeckUserListData {
  costInvocation?: string[][];
  costRecall?: string[][];
  cardsByTypeData?: string[][];
  attributesData?: string[][];
  cardsByRarityData?: string[][];
  cardsOwned?: number;
  cardsQuantity?: number;
}

export interface DeckUserListDeckStatisticsDataJsonldDeckUserListReadDeckUserListData {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  costInvocation?: string[][];
  costRecall?: string[][];
  cardsByTypeData?: string[][];
  attributesData?: string[][];
  cardsByRarityData?: string[][];
  cardsOwned?: number;
  cardsQuantity?: number;
}

export interface DeckUserListJsonldDeckUserListPut {
  name?: string;
  /** @format iri-reference */
  alterator?: string | null;
  public?: boolean;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
}

export interface DeckUserListJsonldDeckUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  user?: UserJsonldDeckUserListRead | null;
  faction?: FactionJsonldDeckUserListRead | null;
  alterator?: CardJsonldDeckUserListRead | null;
  gameType?: GameTypeJsonldDeckUserListRead | null;
  public?: boolean;
  cardQuantity?: number;
  deckLegality?: DeckLegalityJsonldDeckUserListRead | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  legal?: boolean;
}

export interface DeckUserListJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  user?: UserJsonldDeckUserListReadDeckUserListDetailRead | null;
  faction?: FactionJsonldDeckUserListReadDeckUserListDetailRead | null;
  alterator?: CardJsonldDeckUserListReadDeckUserListDetailRead | null;
  gameType?: GameTypeJsonldDeckUserListReadDeckUserListDetailRead | null;
  public?: boolean;
  cardQuantity?: number;
  deckLegality?: DeckLegalityJsonldDeckUserListReadDeckUserListDetailRead | null;
  deckCardsByType?: DeckCardJsonldDeckUserListReadDeckUserListDetailRead | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  legal?: boolean;
  cardIndexes?: Record<string, string>;
}

export interface DeckUserListJsonldDeckUserListWrite {
  name?: string;
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  faction?: string | null;
  /** @format iri-reference */
  alterator?: string | null;
  public?: boolean;
  deckUserListCards?: DeckUserListCardJsonldDeckUserListWrite[];
  tags?: string[];
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
}

export interface DeckUserListCardDeckAddCardDataWrite {
  quantity: number;
  /** @format iri-reference */
  card: string | null;
}

export interface DeckUserListCardDeckUserListReadDeckUserListDetailRead {
  quantity: number;
  card: CardDeckUserListReadDeckUserListDetailRead | null;
  /** @format ulid */
  id?: string | null;
}

export type DeckUserListCardDeckUserListWrite = object;

export interface DeckUserListCardOwnershipListRead {
  quantity: number;
  card: CardOwnershipListRead | null;
}

export interface DeckUserListCardOwnershipListWrite {
  quantity: number;
  /** @format iri-reference */
  card: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface DeckUserListCardJsonldDeckAddCardDataWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  quantity: number;
  /** @format iri-reference */
  card: string | null;
}

export interface DeckUserListCardJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  quantity: number;
  card: CardJsonldDeckUserListReadDeckUserListDetailRead | null;
  /** @format ulid */
  id?: string | null;
}

export interface DeckUserListCardJsonldDeckUserListWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export interface DeckUserListCardJsonldOwnershipListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  quantity: number;
  card: CardJsonldOwnershipListRead | null;
}

export interface DeckUserListCardJsonldOwnershipListWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  quantity: number;
  /** @format iri-reference */
  card: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface EventEventLocatorReadShopLocatorRead {
  eventType?: EventTypeEventLocatorReadShopLocatorRead | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  address?: AddressEventLocatorReadShopLocatorRead | null;
  organizer?: UserEventLocatorReadShopLocatorRead | null;
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  distance?: number;
  name?: string;
  description?: string;
}

export interface EventEventRead {
  eventType?: EventTypeEventRead | null;
  formUrl?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  address?: AddressEventRead | null;
  userGroups?: string[];
  organizer?: UserEventRead | null;
  surveys?: string[];
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  deckUserMax?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  distance?: number;
  name?: string;
  description?: string;
}

export interface EventEventReadAddressReadEventTypeReadIllustrationRead {
  eventType?: EventTypeEventReadAddressReadEventTypeReadIllustrationRead | null;
  formUrl?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  address?: AddressEventReadAddressReadEventTypeReadIllustrationRead | null;
  userGroups?: string[];
  organizer?: UserEventReadAddressReadEventTypeReadIllustrationRead | null;
  surveys?: string[];
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  deckUserMax?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  distance?: number;
  name?: string;
  description?: string;
}

export interface EventEventReadEventTypeReadIllustrationRead {
  eventType?: EventTypeEventReadEventTypeReadIllustrationRead | null;
  formUrl?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  address?: AddressEventReadEventTypeReadIllustrationRead | null;
  userGroups?: string[];
  organizer?: UserEventReadEventTypeReadIllustrationRead | null;
  surveys?: string[];
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  deckUserMax?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  distance?: number;
  name?: string;
  description?: string;
}

export interface EventEventWrite {
  /** @format iri-reference */
  eventType?: string | null;
  formUrl?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  /** @format iri-reference */
  illustration?: string | null;
  /** @format iri-reference */
  address?: string | null;
  userGroups?: string[];
  /** @format iri-reference */
  organizer?: string | null;
  surveys?: string[];
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  deckUserMax?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  translations?: Record<string, EventTranslationEventWrite>;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string;
}

export interface EventGameRead {
  eventType?: EventTypeGameRead | null;
  name?: string;
}

export interface EventShopRead {
  /** @format iri-reference */
  eventType?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  illustrationPath?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string;
}

export interface EventShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  /** @format iri-reference */
  eventType?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  illustrationPath?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string;
}

export interface EventUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  /** @format iri-reference */
  eventType?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  illustrationPath?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string;
}

export interface EventEventLocatorEventLocatorReadShopLocatorRead {
  events?: EventEventLocatorReadShopLocatorRead[];
  shops?: ShopEventLocatorReadShopLocatorRead[];
}

export interface EventEventLocatorJsonldEventLocatorReadShopLocatorRead {
  '@id'?: string;
  '@type'?: string;
  events?: EventJsonldEventLocatorReadShopLocatorRead[];
  shops?: ShopJsonldEventLocatorReadShopLocatorRead[];
}

export interface EventEventRankingEventRankingRead {
  nickName?: string | null;
  avatar?: string | null;
  factions?: FactionEventRankingRead[];
  win?: number;
  lose?: number;
}

export interface EventEventRankingJsonldEventRankingRead {
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
  avatar?: string | null;
  factions?: FactionJsonldEventRankingRead[];
  win?: number;
  lose?: number;
}

export interface EventJsonldEventLocatorReadShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  eventType?: EventTypeJsonldEventLocatorReadShopLocatorRead | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  address?: AddressJsonldEventLocatorReadShopLocatorRead | null;
  organizer?: UserJsonldEventLocatorReadShopLocatorRead | null;
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  distance?: number;
  name?: string;
  description?: string;
}

export interface EventJsonldEventRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  eventType?: EventTypeJsonldEventRead | null;
  formUrl?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  address?: AddressJsonldEventRead | null;
  userGroups?: string[];
  organizer?: UserJsonldEventRead | null;
  surveys?: string[];
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  deckUserMax?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  distance?: number;
  name?: string;
  description?: string;
}

export interface EventJsonldEventReadAddressReadEventTypeReadIllustrationRead {
  '@id'?: string;
  '@type'?: string;
  eventType?: EventTypeJsonldEventReadAddressReadEventTypeReadIllustrationRead | null;
  formUrl?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  address?: AddressJsonldEventReadAddressReadEventTypeReadIllustrationRead | null;
  userGroups?: string[];
  organizer?: UserJsonldEventReadAddressReadEventTypeReadIllustrationRead | null;
  surveys?: string[];
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  deckUserMax?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  distance?: number;
  name?: string;
  description?: string;
}

export interface EventJsonldEventReadEventTypeReadIllustrationRead {
  '@id'?: string;
  '@type'?: string;
  eventType?: EventTypeJsonldEventReadEventTypeReadIllustrationRead | null;
  formUrl?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  address?: AddressJsonldEventReadEventTypeReadIllustrationRead | null;
  userGroups?: string[];
  organizer?: UserJsonldEventReadEventTypeReadIllustrationRead | null;
  surveys?: string[];
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  deckUserMax?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  distance?: number;
  name?: string;
  description?: string;
}

export interface EventJsonldEventWrite {
  /** @format iri-reference */
  eventType?: string | null;
  formUrl?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  /** @format iri-reference */
  illustration?: string | null;
  /** @format iri-reference */
  address?: string | null;
  userGroups?: string[];
  /** @format iri-reference */
  organizer?: string | null;
  surveys?: string[];
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  deckUserMax?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  /**
   * @default "not-subscribed"
   * @example "not-subscribed"
   */
  userStatus?: string;
  userEventId?: string;
  illustrationPath?: string;
  translations?: Record<string, EventTranslationJsonldEventWrite>;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string;
}

export interface EventJsonldGameRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  eventType?: EventTypeJsonldGameRead | null;
  name?: string;
}

export interface EventJsonldShopRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  eventType?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  illustrationPath?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string;
}

export interface EventJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  eventType?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  illustrationPath?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string;
}

export interface EventJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  eventType?: string | null;
  displayPlace: boolean;
  mobilityReducedAccessibility: boolean;
  isPublic: boolean;
  /** @format date-time */
  dateTime?: string | null;
  status?: string | null;
  attendeeMax?: number;
  attendeeMaxEnd?: number | null;
  attendeeTotal?: number;
  attendeeInQueue?: number;
  attendeeSubscribed?: number;
  illustrationPath?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string;
}

export interface EventTranslationEventWrite {
  description?: string | null;
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface EventTranslationJsonldEventWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  description?: string | null;
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface EventTypeEventLocatorReadShopLocatorRead {
  name?: string;
}

export interface EventTypeEventRead {
  name?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface EventTypeEventReadAddressReadEventTypeReadIllustrationRead {
  name?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface EventTypeEventReadEventTypeReadIllustrationRead {
  name?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface EventTypeEventTypeRead {
  name?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface EventTypeGameRead {
  name?: string;
}

export interface EventTypeJsonldEventLocatorReadShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
}

export interface EventTypeJsonldEventRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface EventTypeJsonldEventReadAddressReadEventTypeReadIllustrationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface EventTypeJsonldEventReadEventTypeReadIllustrationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface EventTypeJsonldEventTypeRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  name?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface EventTypeJsonldGameRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
}

export interface EventUserEventUserRead {
  status?: string;
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  event: string | null;
  deckUserLists?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface EventUserEventUserWrite {
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  event: string | null;
  deckUserLists?: string[];
}

export interface EventUserJsonldEventUserRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  status?: string;
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  event: string | null;
  deckUserLists?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface EventUserJsonldEventUserWrite {
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  event: string | null;
  deckUserLists?: string[];
}

export interface FactionCardDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionCardRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionCardReadRarityReadCardMarketplaceRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionDeckUserListRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionDeckUserListReadDeckUserListDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionEventRankingRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionFactionRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
  description?: string | null;
}

export interface FactionGameRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  name?: string;
}

export interface FactionIllustrationRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionManufactureRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
}

export interface FactionOwnerClaimMultiRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionJsonldCardRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionJsonldCardReadRarityReadCardMarketplaceRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionJsonldDeckUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionJsonldEventRankingRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionJsonldFactionRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
  description?: string | null;
}

export interface FactionJsonldGameRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  name?: string;
}

export interface FactionJsonldIllustrationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionJsonldManufactureRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
}

export interface FactionJsonldOwnerClaimMultiRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'YZ' | 'MU' | 'OR' | 'AX' | 'LY' | 'BR';
  color?: string | null;
  /** @format ulid */
  id?: string | null;
  name?: string;
}

export interface FactionPlayedUserGameStatsRead {
  timesPlayed?: number;
  name?: string;
  reference?: string;
}

export interface FactionPlayedJsonldUserGameStatsRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  timesPlayed?: number;
  name?: string;
  reference?: string;
}

export interface GameGameRead {
  gameType?: GameTypeGameRead | null;
  /** @format date-time */
  dateTime?: string | null;
  durationMin: number | null;
  event?: EventGameRead | null;
  /** @format iri-reference */
  cardSet: string | null;
  gameUsers?: GameUserGameRead[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface GameGameWrite {
  /** @format iri-reference */
  gameType?: string | null;
  /** @format date-time */
  dateTime?: string | null;
  durationMin: number | null;
  /** @format iri-reference */
  event?: string | null;
  /** @format iri-reference */
  cardSet: string | null;
  gameUsers?: string[];
}

export interface GameGameWriteGameUserWrite {
  /** @format iri-reference */
  gameType?: string | null;
  /** @format date-time */
  dateTime?: string | null;
  durationMin: number | null;
  /** @format iri-reference */
  event?: string | null;
  /** @format iri-reference */
  cardSet: string | null;
  gameUsers?: GameUserGameWriteGameUserWrite[];
}

export interface GameJsonldGameRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  gameType?: GameTypeJsonldGameRead | null;
  /** @format date-time */
  dateTime?: string | null;
  durationMin: number | null;
  event?: EventJsonldGameRead | null;
  /** @format iri-reference */
  cardSet: string | null;
  gameUsers?: GameUserJsonldGameRead[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface GameJsonldGameWrite {
  /** @format iri-reference */
  gameType?: string | null;
  /** @format date-time */
  dateTime?: string | null;
  durationMin: number | null;
  /** @format iri-reference */
  event?: string | null;
  /** @format iri-reference */
  cardSet: string | null;
  gameUsers?: string[];
}

export interface GameJsonldGameWriteGameUserWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  gameType?: string | null;
  /** @format date-time */
  dateTime?: string | null;
  durationMin: number | null;
  /** @format iri-reference */
  event?: string | null;
  /** @format iri-reference */
  cardSet: string | null;
  gameUsers?: GameUserJsonldGameWriteGameUserWrite[];
}

export interface GameTypeCardDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface GameTypeDeckUserListRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface GameTypeDeckUserListReadDeckUserListDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface GameTypeGameRead {
  name?: string | null;
}

export interface GameTypeGameTypeRead {
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
  description?: string | null;
}

export interface GameTypeJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface GameTypeJsonldDeckUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface GameTypeJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface GameTypeJsonldGameRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string | null;
}

export interface GameTypeJsonldGameTypeRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
  description?: string | null;
}

export interface GameUserGameRead {
  winner: boolean;
  faction: FactionGameRead | null;
  user?: UserGameRead | null;
}

export interface GameUserGameWriteGameUserWrite {
  winner: boolean;
  notation: number;
  /** @format iri-reference */
  faction: string | null;
  /** @format iri-reference */
  user?: string | null;
  game: GameGameWriteGameUserWrite | null;
}

export interface GameUserGameUserRead {
  winner: boolean;
  notation: number;
  /** @format iri-reference */
  faction: string | null;
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  game: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface GameUserGameUserWrite {
  winner: boolean;
  notation: number;
  /** @format iri-reference */
  faction: string | null;
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  game: string | null;
}

export interface GameUserJsonldGameRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  winner: boolean;
  faction: FactionJsonldGameRead | null;
  user?: UserJsonldGameRead | null;
}

export interface GameUserJsonldGameWriteGameUserWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  winner: boolean;
  notation: number;
  /** @format iri-reference */
  faction: string | null;
  /** @format iri-reference */
  user?: string | null;
  game: GameJsonldGameWriteGameUserWrite | null;
}

export interface GameUserJsonldGameUserRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  winner: boolean;
  notation: number;
  /** @format iri-reference */
  faction: string | null;
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  game: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface GameUserJsonldGameUserWrite {
  winner: boolean;
  notation: number;
  /** @format iri-reference */
  faction: string | null;
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  game: string | null;
}

export interface IllustrationIllustrationRead {
  filePath?: string | null;
  /** @format iri-reference */
  illustrationType: string | null;
  factions?: FactionIllustrationRead[];
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface IllustrationJsonldIllustrationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  filePath?: string | null;
  /** @format iri-reference */
  illustrationType: string | null;
  factions?: FactionJsonldIllustrationRead[];
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface IllustrationStatusIllustrationStatusRead {
  color?: string;
  sequence: number;
  illustrations?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
  description?: string | null;
}

export interface IllustrationStatusJsonldIllustrationStatusRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  color?: string;
  sequence: number;
  illustrations?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
  description?: string | null;
}

export interface IllustrationTypeIllustrationTypeRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference:
    | 'CARD'
    | 'USER_AVATARS'
    | 'USER_BACKGROUNDS'
    | 'DECK_THUMB'
    | 'HERO_THUMB'
    | 'FACTION_ICON_FILTER'
    | 'FRAMELESS'
    | 'WEB'
    | 'CARD_BACK'
    | 'LORE';
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  tags?: string[];
  name?: string | null;
  description?: string | null;
}

export interface IllustrationTypeJsonldIllustrationTypeRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference:
    | 'CARD'
    | 'USER_AVATARS'
    | 'USER_BACKGROUNDS'
    | 'DECK_THUMB'
    | 'HERO_THUMB'
    | 'FACTION_ICON_FILTER'
    | 'FRAMELESS'
    | 'WEB'
    | 'CARD_BACK'
    | 'LORE';
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  tags?: string[];
  name?: string | null;
  description?: string | null;
}

export interface InDesignCardExportRead {
  type?:
    | 'one file'
    | 'playtest'
    | 'playtest_a4'
    | 'playtest_letter'
    | 'separate file'
    | 'meridian'
    | 'cartamundi'
    | 'jpg_bleed'
    | 'png_transparent';
  /**
   * @default "created"
   * @example "created"
   */
  status?: 'created' | 'sent' | 'start_indesign_process' | 'finish_indesign_process' | 'finish_indesign_error';
  roundedEdge?: boolean | null;
  /**
   * @default "fr_FR"
   * @example "fr_FR"
   */
  locale?: string;
  exportPath?: string | null;
}

export interface InDesignCardExportWrite {
  /**
   * @default "created"
   * @example "created"
   */
  status?: 'created' | 'sent' | 'start_indesign_process' | 'finish_indesign_process' | 'finish_indesign_error';
}

export interface InDesignJsonldCardExportRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  type?:
    | 'one file'
    | 'playtest'
    | 'playtest_a4'
    | 'playtest_letter'
    | 'separate file'
    | 'meridian'
    | 'cartamundi'
    | 'jpg_bleed'
    | 'png_transparent';
  /**
   * @default "created"
   * @example "created"
   */
  status?: 'created' | 'sent' | 'start_indesign_process' | 'finish_indesign_process' | 'finish_indesign_error';
  roundedEdge?: boolean | null;
  /**
   * @default "fr_FR"
   * @example "fr_FR"
   */
  locale?: string;
  exportPath?: string | null;
}

export interface InDesignJsonldCardExportWrite {
  /**
   * @default "created"
   * @example "created"
   */
  status?: 'created' | 'sent' | 'start_indesign_process' | 'finish_indesign_process' | 'finish_indesign_error';
}

export interface KYCPaymentKycRead {
  id?: string;
  type?: string;
  flags?: string[];
  tag?: string;
  /** @format date-time */
  creationDate?: string | null;
  /** @format date-time */
  processedDate?: string | null;
  status?: string;
  refusedReasonType?: string | null;
  refusedReasonMessage?: string | null;
}

export interface KYCPaymentKycWrite {
  file?: string[] | null;
}

export interface KYCJsonldPaymentKycRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  id?: string;
  type?: string;
  flags?: string[];
  tag?: string;
  /** @format date-time */
  creationDate?: string | null;
  /** @format date-time */
  processedDate?: string | null;
  status?: string;
  refusedReasonType?: string | null;
  refusedReasonMessage?: string | null;
}

export interface KYCJsonldPaymentKycWrite {
  file?: string[] | null;
}

export interface LanguageLanguageRead {
  locale?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguagePrintingOrderRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguagePrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguagePrintingPageReadPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguagePrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguageUserPrivateReadUserTokenRead {
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface LanguageUserReadUserPrivateRead {
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface LanguageUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface LanguageUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface LanguageJsonldLanguageRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  locale?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguageJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguageJsonldPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguageJsonldPrintingPageReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguageJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string | null;
}

export interface LanguageJsonldUserPrivateReadUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface LanguageJsonldUserReadUserPrivateRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface LanguageJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface LanguageJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  locale?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export type LogQrAccessLogQrRead = object;

export type LogQrAccessLogQrWrite = object;

export interface LogQrAccessJsonldLogQrRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export type LogQrAccessJsonldLogQrWrite = object;

export interface LoreEntryCardDetailRead {
  loreEntryElements?: LoreEntryElementCardDetailRead[];
  loreEntryType: LoreEntryTypeCardDetailRead | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface LoreEntryLoreEntryRead {
  loreEntryElements?: string[];
  /** @format iri-reference */
  loreEntryType: string | null;
  /** @format iri-reference */
  loreEntryStatus: string | null;
  factions?: string[];
  tags?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  comment?: string | null;
  elements?: string[];
  name?: string;
}

export interface LoreEntryJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  loreEntryElements?: LoreEntryElementJsonldCardDetailRead[];
  loreEntryType: LoreEntryTypeJsonldCardDetailRead | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface LoreEntryJsonldLoreEntryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  loreEntryElements?: string[];
  /** @format iri-reference */
  loreEntryType: string | null;
  /** @format iri-reference */
  loreEntryStatus: string | null;
  factions?: string[];
  tags?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  comment?: string | null;
  elements?: string[];
  name?: string;
}

export interface LoreEntryElementCardDetailRead {
  loreEntryElementType: LoreEntryElementTypeCardDetailRead | null;
  /** @format ulid */
  id?: string | null;
  text?: string;
}

export interface LoreEntryElementLoreEntryElementRead {
  /** @format iri-reference */
  loreEntryElementType: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  text?: string;
}

export interface LoreEntryElementJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  loreEntryElementType: LoreEntryElementTypeJsonldCardDetailRead | null;
  /** @format ulid */
  id?: string | null;
  text?: string;
}

export interface LoreEntryElementJsonldLoreEntryElementRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  loreEntryElementType: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  text?: string;
}

export interface LoreEntryElementTypeCardDetailRead {
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  subject?: string | null;
}

export interface LoreEntryElementTypeLoreEntryElementTypeRead {
  sequence: number;
  isPublic: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  comment?: string | null;
  subject?: string | null;
  description?: string | null;
}

export interface LoreEntryElementTypeJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  subject?: string | null;
}

export interface LoreEntryElementTypeJsonldLoreEntryElementTypeRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  sequence: number;
  isPublic: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  comment?: string | null;
  subject?: string | null;
  description?: string | null;
}

export interface LoreEntryStatusLoreEntryStatusRead {
  color?: string;
  sequence: number;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
}

export interface LoreEntryStatusJsonldLoreEntryStatusRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  color?: string;
  sequence: number;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
}

export interface LoreEntryTypeCardDetailRead {
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string | null;
}

export interface LoreEntryTypeLoreEntryTypeRead {
  parent?: LoreEntryTypeLoreEntryTypeRead | null;
  children?: LoreEntryTypeLoreEntryTypeRead[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  tags?: string[];
  name?: string;
  description?: string | null;
}

export interface LoreEntryTypeJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string | null;
}

export interface LoreEntryTypeJsonldLoreEntryTypeRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  parent?: LoreEntryTypeJsonldLoreEntryTypeRead | null;
  children?: LoreEntryTypeJsonldLoreEntryTypeRead[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  tags?: string[];
  name?: string;
  description?: string | null;
}

export interface MarketplaceOffer {
  numberCopyAvailable?: number;
}

export interface MarketplaceOfferCardOfferDataCardOfferDataRead {
  user?: UserCardOfferDataRead;
  quantity?: number;
  price?: number;
  originalPrice?: number | null;
  groupId?: string;
}

export interface MarketplaceOfferCardOfferDataJsonldCardOfferDataRead {
  '@id'?: string;
  '@type'?: string;
  user?: UserJsonldCardOfferDataRead;
  quantity?: number;
  price?: number;
  originalPrice?: number | null;
  groupId?: string;
}

export interface MarketplaceOfferJsonld {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  numberCopyAvailable?: number;
}

export interface MarketplaceOrder {
  orderItems?: string[];
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  taxTotal?: number | null;
  taxPercent?: number | null;
  missingAmount?: number | null;
  /** @format iri-reference */
  language?: string | null;
  shippingTotal?: number;
  createdAt?: string | null;
  dateEnd?: string | null;
  orderStatus?: string | null;
  number?: string | null;
  cartError?: boolean | null;
  /** @format iri-reference */
  recipient?: string | null;
  type?: string | null;
  /** @format iri-reference */
  billingAddress?: string | null;
  /** @format iri-reference */
  shippingAddress?: string | null;
  /** @format iri-reference */
  userPaymentMethod?: string | null;
  tokenValue?: string;
}

export interface MarketplaceOrderMarketplaceOrderDetailRead {
  orderItems?: string[];
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  taxTotal?: number | null;
  taxPercent?: number | null;
  missingAmount?: number | null;
  shippingTotal?: number;
  createdAt?: string | null;
  dateEnd?: string | null;
  orderStatus?: string | null;
  number?: string | null;
  cartError?: boolean | null;
  recipient?: UserMarketplaceOrderDetailRead | null;
  type?: string | null;
  billingAddress?: AddressMarketplaceOrderDetailRead | null;
}

export interface MarketplaceOrderJsonld {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  orderItems?: string[];
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  taxTotal?: number | null;
  taxPercent?: number | null;
  missingAmount?: number | null;
  /** @format iri-reference */
  language?: string | null;
  shippingTotal?: number;
  createdAt?: string | null;
  dateEnd?: string | null;
  orderStatus?: string | null;
  number?: string | null;
  cartError?: boolean | null;
  /** @format iri-reference */
  recipient?: string | null;
  type?: string | null;
  /** @format iri-reference */
  billingAddress?: string | null;
  /** @format iri-reference */
  shippingAddress?: string | null;
  /** @format iri-reference */
  userPaymentMethod?: string | null;
  tokenValue?: string;
}

export interface MarketplaceOrderJsonldMarketplaceOrderDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  orderItems?: string[];
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  taxTotal?: number | null;
  taxPercent?: number | null;
  missingAmount?: number | null;
  shippingTotal?: number;
  createdAt?: string | null;
  dateEnd?: string | null;
  orderStatus?: string | null;
  number?: string | null;
  cartError?: boolean | null;
  recipient?: UserJsonldMarketplaceOrderDetailRead | null;
  type?: string | null;
  billingAddress?: AddressJsonldMarketplaceOrderDetailRead | null;
}

export interface MarketplaceTransactionMarketplaceOrderMarketplaceOrderDetailRead {
  orderItems?: string[];
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  taxTotal?: number | null;
  taxPercent?: number | null;
  missingAmount?: number | null;
  shippingTotal?: number;
  createdAt?: string | null;
  dateEnd?: string | null;
  orderStatus?: string | null;
  number?: string | null;
  cartError?: boolean | null;
  recipient?: UserMarketplaceOrderDetailRead | null;
  type?: string | null;
  billingAddress?: AddressMarketplaceOrderDetailRead | null;
}

export interface MarketplaceTransactionMarketplaceOrderMarketplaceOrderRead {
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  createdAt?: string | null;
  orderStatus?: string | null;
  number?: string | null;
  type?: string | null;
  tokenValue?: string;
}

export type MarketplaceTransactionMarketplaceOrderTransactionFilterData = object;

export interface MarketplaceTransactionMarketplaceOrderJsonldMarketplaceOrderDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  orderItems?: string[];
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  taxTotal?: number | null;
  taxPercent?: number | null;
  missingAmount?: number | null;
  shippingTotal?: number;
  createdAt?: string | null;
  dateEnd?: string | null;
  orderStatus?: string | null;
  number?: string | null;
  cartError?: boolean | null;
  recipient?: UserJsonldMarketplaceOrderDetailRead | null;
  type?: string | null;
  billingAddress?: AddressJsonldMarketplaceOrderDetailRead | null;
}

export interface MarketplaceTransactionMarketplaceOrderJsonldMarketplaceOrderRead {
  '@id'?: string;
  '@type'?: string;
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  createdAt?: string | null;
  orderStatus?: string | null;
  number?: string | null;
  type?: string | null;
  tokenValue?: string;
}

export interface MarketplaceTransactionMarketplaceOrderJsonldTransactionFilterData {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export interface MediaMediaRead {
  contentUrl?: string | null;
  type?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface MediaShopRead {
  contentUrl?: string | null;
}

export interface MediaShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  contentUrl?: string | null;
  type?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface MediaUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  contentUrl?: string | null;
}

export interface MediaJsonldMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  contentUrl?: string | null;
  type?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface MediaJsonldShopRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  contentUrl?: string | null;
}

export interface MediaJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  contentUrl?: string | null;
  type?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface MediaJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  contentUrl?: string | null;
}

export interface NotificationNotificationRead {
  triggerUser?: UserNotificationRead | null;
  read?: boolean;
  category?: string;
  cms?: {
    /** @example "Echange" */
    category?: string;
    /** @example "fa-solid fa-fan" */
    icon?: string;
    /** @example "**lane65** vous a donné 1 exemplaire(s) de la carte **Eidolon Yzmir 1**" */
    content?: string;
    buttons?: {
      link?: {
        /** @example "Voir la carte" */
        title?: string;
        /** @example "/fr-fr/cards/YZ_1" */
        url?: string;
        /** @example "" */
        target?: string;
      } | null;
      /** @example "coucou" */
      link_class?: string;
    }[];
    image_url?: string;
    image_position?: 'top' | 'bottom' | 'left' | 'right';
  };
  /** @format date-time */
  date?: string;
  /** @format ulid */
  id?: string | null;
}

export interface NotificationJsonldNotificationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  triggerUser?: UserJsonldNotificationRead | null;
  read?: boolean;
  category?: string;
  cms?: {
    /** @example "Echange" */
    category?: string;
    /** @example "fa-solid fa-fan" */
    icon?: string;
    /** @example "**lane65** vous a donné 1 exemplaire(s) de la carte **Eidolon Yzmir 1**" */
    content?: string;
    buttons?: {
      link?: {
        /** @example "Voir la carte" */
        title?: string;
        /** @example "/fr-fr/cards/YZ_1" */
        url?: string;
        /** @example "" */
        target?: string;
      } | null;
      /** @example "coucou" */
      link_class?: string;
    }[];
    image_url?: string;
    image_position?: 'top' | 'bottom' | 'left' | 'right';
  };
  /** @format date-time */
  date?: string;
  /** @format ulid */
  id?: string | null;
}

export interface NotificationParameterNotificationParameterRead {
  channel?: string;
  locked?: boolean;
  /** @format ulid */
  id?: string | null;
}

export interface NotificationParameterJsonldNotificationParameterRead {
  '@id'?: string;
  '@type'?: string;
  channel?: string;
  locked?: boolean;
  /** @format ulid */
  id?: string | null;
}

export interface NotificationParameterUser {
  system?: string[];
  marketplace?: string[];
  print?: string[];
  collection?: string[];
  marketing?: string[];
  event?: string[];
  tradingAndDonation?: string[];
  social?: string[];
}

export interface NotificationParameterUserNotificationParameterUserRead {
  system?: string[];
  marketplace?: string[];
  print?: string[];
  collection?: string[];
  marketing?: string[];
  event?: string[];
  tradingAndDonation?: string[];
  social?: string[];
}

export interface NotificationParameterUserJsonld {
  system?: string[];
  marketplace?: string[];
  print?: string[];
  collection?: string[];
  marketing?: string[];
  event?: string[];
  tradingAndDonation?: string[];
  social?: string[];
}

export interface NotificationParameterUserJsonldNotificationParameterUserRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  system?: string[];
  marketplace?: string[];
  print?: string[];
  collection?: string[];
  marketing?: string[];
  event?: string[];
  tradingAndDonation?: string[];
  social?: string[];
}

export interface OptinOptinRead {
  mandatory?: boolean;
  userProfiles: UserProfileOptinRead[];
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  comment?: string | null;
  name?: string | null;
}

export interface OptinJsonldOptinRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  mandatory?: boolean;
  userProfiles: UserProfileJsonldOptinRead[];
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  comment?: string | null;
  name?: string | null;
}

export type OwnerMarketplaceRead = object;

export interface OwnerOwnerRead {
  /** @format iri-reference */
  ownership: string | null;
  /** @format iri-reference */
  user?: string | null;
  /**
   * @default "available"
   * @example "available"
   */
  status?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface OwnerOwnerReadUserRead {
  /** @format iri-reference */
  ownership: string | null;
  user?: UserOwnerReadUserRead | null;
  /**
   * @default "available"
   * @example "available"
   */
  status?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface OwnerOwnerWrite {
  /** @format iri-reference */
  ownership: string | null;
  /** @format iri-reference */
  user?: string | null;
  /**
   * @default "available"
   * @example "available"
   */
  status?: string;
}

export interface OwnerOwnerClaimMultiOwnerClaimMultiRead {
  id?: string | null;
  successes?: CardOwnerClaimMultiRead[];
  fails?: CardOwnerClaimMultiRead[];
}

export interface OwnerOwnerClaimMultiJsonldOwnerClaimMultiRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  id?: string | null;
  successes?: CardJsonldOwnerClaimMultiRead[];
  fails?: CardJsonldOwnerClaimMultiRead[];
}

export interface OwnerJsonldMarketplaceRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export interface OwnerJsonldOwnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  ownership: string | null;
  /** @format iri-reference */
  user?: string | null;
  /**
   * @default "available"
   * @example "available"
   */
  status?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface OwnerJsonldOwnerReadUserRead {
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  ownership: string | null;
  user?: UserJsonldOwnerReadUserRead | null;
  /**
   * @default "available"
   * @example "available"
   */
  status?: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface OwnerJsonldOwnerWrite {
  /** @format iri-reference */
  ownership: string | null;
  /** @format iri-reference */
  user?: string | null;
  /**
   * @default "available"
   * @example "available"
   */
  status?: string;
}

export interface OwnerExchangeCardOwnerExchangeRequestReadOwnerExchangeRequestDetailRead {
  card?: CardOwnerExchangeRequestReadOwnerExchangeRequestDetailRead;
  quantity?: number;
}

export interface OwnerExchangeCardOwnerExchangeRequestWrite {
  /** @format iri-reference */
  card?: string;
  quantity?: number;
}

export interface OwnerExchangeCardJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  card?: CardJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead;
  quantity?: number;
}

export interface OwnerExchangeCardJsonldOwnerExchangeRequestWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  card?: string;
  quantity?: number;
}

export interface OwnerExchangeRequestOwnerExchangeRequestRead {
  userLastUpdate?: UserOwnerExchangeRequestRead | null;
  status?: string | null;
  friend?: UserOwnerExchangeRequestRead;
  myTurn?: boolean;
  /** @format ulid */
  id?: string | null;
}

export interface OwnerExchangeRequestOwnerExchangeRequestReadOwnerExchangeRequestDetailRead {
  sender?: UserOwnerExchangeRequestReadOwnerExchangeRequestDetailRead | null;
  userLastUpdate?: UserOwnerExchangeRequestReadOwnerExchangeRequestDetailRead | null;
  status?: string | null;
  friend?: UserOwnerExchangeRequestReadOwnerExchangeRequestDetailRead;
  myTurn?: boolean;
  /** @format ulid */
  id?: string | null;
  ownerExchangeCardsSender?: OwnerExchangeCardOwnerExchangeRequestReadOwnerExchangeRequestDetailRead[];
  ownerExchangeCardsReceiver?: OwnerExchangeCardOwnerExchangeRequestReadOwnerExchangeRequestDetailRead[];
}

export interface OwnerExchangeRequestOwnerExchangeRequestWrite {
  ownerExchangeCards?: OwnerExchangeCardOwnerExchangeRequestWrite[];
  /** @format iri-reference */
  friend?: string;
}

export interface OwnerExchangeRequestJsonldOwnerExchangeRequestRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  userLastUpdate?: UserJsonldOwnerExchangeRequestRead | null;
  status?: string | null;
  friend?: UserJsonldOwnerExchangeRequestRead;
  myTurn?: boolean;
  /** @format ulid */
  id?: string | null;
}

export interface OwnerExchangeRequestJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  sender?: UserJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead | null;
  userLastUpdate?: UserJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead | null;
  status?: string | null;
  friend?: UserJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead;
  myTurn?: boolean;
  /** @format ulid */
  id?: string | null;
  ownerExchangeCardsSender?: OwnerExchangeCardJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead[];
  ownerExchangeCardsReceiver?: OwnerExchangeCardJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead[];
}

export interface OwnerExchangeRequestJsonldOwnerExchangeRequestWrite {
  ownerExchangeCards?: OwnerExchangeCardJsonldOwnerExchangeRequestWrite[];
  /** @format iri-reference */
  friend?: string;
}

export interface OwnerHistoryGroupOwnerHistoryGroupRead {
  id?: string | null;
  card?: CardOwnerHistoryGroupRead | null;
  owner?: UserOwnerHistoryGroupRead | null;
  previousOwner?: UserOwnerHistoryGroupRead | null;
  quantity?: number | null;
}

export interface OwnerHistoryGroupJsonldOwnerHistoryGroupRead {
  '@id'?: string;
  '@type'?: string;
  id?: string | null;
  card?: CardJsonldOwnerHistoryGroupRead | null;
  owner?: UserJsonldOwnerHistoryGroupRead | null;
  previousOwner?: UserJsonldOwnerHistoryGroupRead | null;
  quantity?: number | null;
}

export interface OwnershipOwnershipRead {
  /** @format ulid */
  id?: string | null;
  card: CardOwnershipRead | null;
  /**
   * @default true
   * @example true
   */
  available?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  code?: string;
}

export interface OwnershipQrCodeRead {
  code?: string;
}

export interface OwnershipJsonldOwnershipRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string | null;
  card: CardJsonldOwnershipRead | null;
  /**
   * @default true
   * @example true
   */
  available?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  code?: string;
}

export interface OwnershipJsonldQrCodeRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  code?: string;
}

export interface OwnershipListDataOwnershipListRead {
  cards?: DeckUserListCardOwnershipListRead[];
}

export interface OwnershipListDataOwnershipListWrite {
  cards?: DeckUserListCardOwnershipListWrite[];
  putOption?: string | null;
}

export interface OwnershipListDataJsonldOwnershipListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  cards?: DeckUserListCardJsonldOwnershipListRead[];
}

export interface OwnershipListDataJsonldOwnershipListWrite {
  cards?: DeckUserListCardJsonldOwnershipListWrite[];
  putOption?: string | null;
}

export interface PagesPagesGetList {
  id?: number;
  date?: string | null;
  date_gmt?: string | null;
  modified?: string | null;
  modified_gmt?: string | null;
  slug?: string | null;
  status?: string | null;
  type?: string | null;
  title?: string[];
  author?: number;
  parent?: string[];
  menu_order?: number;
  template?: string | null;
  acf?: string[];
  yoast_head_json?: string[];
  childrens?: string[];
  children_previous?: string | null;
  children_next?: string | null;
  url?: string | null;
}

export interface PagesJsonldPagesGetList {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  id?: number;
  date?: string | null;
  date_gmt?: string | null;
  modified?: string | null;
  modified_gmt?: string | null;
  slug?: string | null;
  status?: string | null;
  type?: string | null;
  title?: string[];
  author?: number;
  parent?: string[];
  menu_order?: number;
  template?: string | null;
  acf?: string[];
  yoast_head_json?: string[];
  childrens?: string[];
  children_previous?: string | null;
  children_next?: string | null;
  url?: string | null;
}

export type PaginatorCardSetCollectionRead = object;

export interface PaginatorJsonldCardSetCollectionRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export interface PayinBrowserInfoPaymentPaymentCardPayinWrite {
  javaEnabled?: boolean;
  language?: string;
  colorDepth?: number;
  screenHeight?: number;
  screenWidth?: number;
  timeZoneOffset?: number;
  userAgent?: string;
  javascriptEnabled?: boolean;
}

export interface PayinBrowserInfoJsonldPaymentPaymentCardPayinWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  javaEnabled?: boolean;
  language?: string;
  colorDepth?: number;
  screenHeight?: number;
  screenWidth?: number;
  timeZoneOffset?: number;
  userAgent?: string;
  javascriptEnabled?: boolean;
}

export interface PaymentCardPaymentPaymentCardPayinWrite {
  browserInfo?: PayinBrowserInfoPaymentPaymentCardPayinWrite | null;
  amount?: number;
  redirectURL?: string;
}

export interface PaymentCardPaymentPaymentCardRead {
  externalId?: string;
  /**
   * @minLength 0
   * @maxLength 2
   */
  expirationMonth?: string;
  /**
   * @minLength 0
   * @maxLength 2
   */
  expirationYear?: string;
  type?: string;
  active?: boolean;
  validity?: string | null;
  cardProvider?: string | null;
  number?: string;
  /** @format iri-reference */
  user?: string | null;
}

export interface PaymentCardCardPayinPaymentPaymentCardPayinRead {
  id?: string;
  secureModeRedirectURL?: string | null;
}

export interface PaymentCardCardPayinJsonldPaymentPaymentCardPayinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  id?: string;
  secureModeRedirectURL?: string | null;
}

export interface PaymentCardJsonldPaymentPaymentCardPayinWrite {
  browserInfo?: PayinBrowserInfoJsonldPaymentPaymentCardPayinWrite | null;
  amount?: number;
  redirectURL?: string;
}

export interface PaymentCardJsonldPaymentPaymentCardRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  externalId?: string;
  /**
   * @minLength 0
   * @maxLength 2
   */
  expirationMonth?: string;
  /**
   * @minLength 0
   * @maxLength 2
   */
  expirationYear?: string;
  type?: string;
  active?: boolean;
  validity?: string | null;
  cardProvider?: string | null;
  number?: string;
  /** @format iri-reference */
  user?: string | null;
}

export interface PaymentPricingGridPaymentPricingGridRead {
  fee?: number;
  amount?: number;
  currency?: string;
  /** @format ulid */
  id?: string | null;
}

export interface PaymentPricingGridJsonldPaymentPricingGridRead {
  '@id'?: string;
  '@type'?: string;
  fee?: number;
  amount?: number;
  currency?: string;
  /** @format ulid */
  id?: string | null;
}

export interface PayoutPayoutRead {
  /** @format date-time */
  lastPayout?: string | null;
  /** @format date-time */
  nextFreePayout?: string | null;
  lastPayoutDays?: number;
  fees?: number;
  freePayout?: boolean;
}

export type PayoutPayoutWrite = object;

export interface PayoutJsonldPayoutRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format date-time */
  lastPayout?: string | null;
  /** @format date-time */
  nextFreePayout?: string | null;
  lastPayoutDays?: number;
  fees?: number;
  freePayout?: boolean;
}

export type PayoutJsonldPayoutWrite = object;

export type PrintingPrintingRead = object;

export interface PrintingJsonldPrintingRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export interface PrintingCardTransactionReadPrintingReadPrintingTransactionRead {
  card?: CardTransactionReadPrintingReadPrintingTransactionRead | null;
  printType?: string;
  quantity?: number;
  /** @format date-time */
  createdAt?: string | null;
}

export interface PrintingCardJsonldTransactionReadPrintingReadPrintingTransactionRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  card?: CardJsonldTransactionReadPrintingReadPrintingTransactionRead | null;
  printType?: string;
  quantity?: number;
  /** @format date-time */
  createdAt?: string | null;
}

export interface PrintingOrderPrintingOrderRead {
  printingOrderStatus: PrintingOrderStatusPrintingOrderRead | null;
  /**
   * @default true
   * @example true
   */
  name: string;
  administrator: UserPrintingOrderRead | null;
  members?: UserPrintingOrderRead[];
  /**
   * TODO Problème avec le Listener
   * "Typed property App\\PrintOnDemand\\Entity\\PrintingOrder::$printingPages must not be accessed before initialization".
   */
  printingPages?: PrintingPagePrintingOrderRead[];
  language: LanguagePrintingOrderRead | null;
  nbNormalCards?: number;
  nbTotalNormalCards?: number;
  nbFoilCards?: number;
  nbTotalFoilCards?: number;
  full?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingOrderPrintingOrderWrite {
  /**
   * @default true
   * @example true
   */
  name: string;
  /** @format iri-reference */
  language: string | null;
}

export interface PrintingOrderPrintingOrderPartnerRead {
  printingOrderStatus: PrintingOrderStatusPrintingOrderPartnerRead | null;
  /**
   * @default true
   * @example true
   */
  name: string;
  administrator: UserPrintingOrderPartnerRead | null;
  members?: UserPrintingOrderPartnerRead[];
  /**
   * TODO Problème avec le Listener
   * "Typed property App\\PrintOnDemand\\Entity\\PrintingOrder::$printingPages must not be accessed before initialization".
   */
  printingPages?: PrintingPagePrintingOrderPartnerRead[];
  language: LanguagePrintingOrderPartnerRead | null;
  shippingAddress?: AddressPrintingOrderPartnerRead | null;
  shippingTrackingId?: string | null;
  nbNormalCards?: number;
  nbTotalNormalCards?: number;
  nbFoilCards?: number;
  nbTotalFoilCards?: number;
  full?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingOrderPrintingOrderStatusWrite {
  /** @format iri-reference */
  printingOrderStatus: string | null;
}

export interface PrintingOrderPrintingPageReadPrintingOrderPartnerRead {
  printingOrderStatus: PrintingOrderStatusPrintingPageReadPrintingOrderPartnerRead | null;
  /**
   * @default true
   * @example true
   */
  name: string;
  administrator: UserPrintingPageReadPrintingOrderPartnerRead | null;
  members?: UserPrintingPageReadPrintingOrderPartnerRead[];
  /**
   * TODO Problème avec le Listener
   * "Typed property App\\PrintOnDemand\\Entity\\PrintingOrder::$printingPages must not be accessed before initialization".
   */
  printingPages?: PrintingPagePrintingPageReadPrintingOrderPartnerRead[];
  language: LanguagePrintingPageReadPrintingOrderPartnerRead | null;
  shippingAddress?: AddressPrintingPageReadPrintingOrderPartnerRead | null;
  shippingTrackingId?: string | null;
  nbNormalCards?: number;
  nbTotalNormalCards?: number;
  nbFoilCards?: number;
  nbTotalFoilCards?: number;
  full?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingOrderPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  printingOrderStatus: PrintingOrderStatusPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  /**
   * @default true
   * @example true
   */
  name: string;
  administrator: UserPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  members?: UserPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead[];
  /**
   * TODO Problème avec le Listener
   * "Typed property App\\PrintOnDemand\\Entity\\PrintingOrder::$printingPages must not be accessed before initialization".
   */
  printingPages?: PrintingPagePrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead[];
  language: LanguagePrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  shippingAddress?: AddressPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  shippingTrackingId?: string | null;
  nbNormalCards?: number;
  nbTotalNormalCards?: number;
  nbFoilCards?: number;
  nbTotalFoilCards?: number;
  full?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingOrderMarketplaceOrderPrintingPaymentRead {
  orderItems?: string[];
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  taxTotal?: number | null;
  taxPercent?: number | null;
  missingAmount?: number | null;
  /** @format iri-reference */
  language?: string | null;
  shippingTotal?: number;
  orderStatus?: string | null;
  billingAddress?: AddressPrintingPaymentRead | null;
  shippingAddress?: AddressPrintingPaymentRead | null;
}

export interface PrintingOrderMarketplaceOrderJsonldPrintingPaymentRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  orderItems?: string[];
  totalQuantity?: number;
  price?: number;
  totalPrice?: number;
  taxTotal?: number | null;
  taxPercent?: number | null;
  missingAmount?: number | null;
  /** @format iri-reference */
  language?: string | null;
  shippingTotal?: number;
  orderStatus?: string | null;
  billingAddress?: AddressJsonldPrintingPaymentRead | null;
  shippingAddress?: AddressJsonldPrintingPaymentRead | null;
}

export interface PrintingOrderPrintingOrderDetailPrintingOrderRead {
  printingOrderStatus?: PrintingOrderStatusPrintingOrderRead;
  detailsCommand?: string[];
  delivery?: number;
  total?: number;
  costs?: number;
  totalAmount?: number;
}

export interface PrintingOrderPrintingOrderDetailJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  printingOrderStatus?: PrintingOrderStatusJsonldPrintingOrderRead;
  detailsCommand?: string[];
  delivery?: number;
  total?: number;
  costs?: number;
  totalAmount?: number;
}

export interface PrintingOrderPrintingOrderMemberListPrintingOrderRead {
  administrator?: string | null;
  printingOrderMembers?: PrintingOrderMemberPrintingOrderRead[];
}

export interface PrintingOrderPrintingOrderMemberListJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  administrator?: string | null;
  printingOrderMembers?: PrintingOrderMemberJsonldPrintingOrderRead[];
}

export interface PrintingOrderPrintingPricingGridPrintingRead {
  nbPrintingPage?: number;
  totalPrice?: number;
  selected?: boolean;
  priceByCard?: number;
}

export interface PrintingOrderPrintingPricingGridJsonldPrintingRead {
  '@id'?: string;
  '@type'?: string;
  nbPrintingPage?: number;
  totalPrice?: number;
  selected?: boolean;
  priceByCard?: number;
}

export interface PrintingOrderJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  printingOrderStatus: PrintingOrderStatusJsonldPrintingOrderRead | null;
  /**
   * @default true
   * @example true
   */
  name: string;
  administrator: UserJsonldPrintingOrderRead | null;
  members?: UserJsonldPrintingOrderRead[];
  /**
   * TODO Problème avec le Listener
   * "Typed property App\\PrintOnDemand\\Entity\\PrintingOrder::$printingPages must not be accessed before initialization".
   */
  printingPages?: PrintingPageJsonldPrintingOrderRead[];
  language: LanguageJsonldPrintingOrderRead | null;
  nbNormalCards?: number;
  nbTotalNormalCards?: number;
  nbFoilCards?: number;
  nbTotalFoilCards?: number;
  full?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingOrderJsonldPrintingOrderWrite {
  /**
   * @default true
   * @example true
   */
  name: string;
  /** @format iri-reference */
  language: string | null;
}

export interface PrintingOrderJsonldPrintingOrderPartnerRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  printingOrderStatus: PrintingOrderStatusJsonldPrintingOrderPartnerRead | null;
  /**
   * @default true
   * @example true
   */
  name: string;
  administrator: UserJsonldPrintingOrderPartnerRead | null;
  members?: UserJsonldPrintingOrderPartnerRead[];
  /**
   * TODO Problème avec le Listener
   * "Typed property App\\PrintOnDemand\\Entity\\PrintingOrder::$printingPages must not be accessed before initialization".
   */
  printingPages?: PrintingPageJsonldPrintingOrderPartnerRead[];
  language: LanguageJsonldPrintingOrderPartnerRead | null;
  shippingAddress?: AddressJsonldPrintingOrderPartnerRead | null;
  shippingTrackingId?: string | null;
  nbNormalCards?: number;
  nbTotalNormalCards?: number;
  nbFoilCards?: number;
  nbTotalFoilCards?: number;
  full?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingOrderJsonldPrintingOrderStatusWrite {
  /** @format iri-reference */
  printingOrderStatus: string | null;
}

export interface PrintingOrderJsonldPrintingPageReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  printingOrderStatus: PrintingOrderStatusJsonldPrintingPageReadPrintingOrderPartnerRead | null;
  /**
   * @default true
   * @example true
   */
  name: string;
  administrator: UserJsonldPrintingPageReadPrintingOrderPartnerRead | null;
  members?: UserJsonldPrintingPageReadPrintingOrderPartnerRead[];
  /**
   * TODO Problème avec le Listener
   * "Typed property App\\PrintOnDemand\\Entity\\PrintingOrder::$printingPages must not be accessed before initialization".
   */
  printingPages?: PrintingPageJsonldPrintingPageReadPrintingOrderPartnerRead[];
  language: LanguageJsonldPrintingPageReadPrintingOrderPartnerRead | null;
  shippingAddress?: AddressJsonldPrintingPageReadPrintingOrderPartnerRead | null;
  shippingTrackingId?: string | null;
  nbNormalCards?: number;
  nbTotalNormalCards?: number;
  nbFoilCards?: number;
  nbTotalFoilCards?: number;
  full?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingOrderJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  printingOrderStatus: PrintingOrderStatusJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  /**
   * @default true
   * @example true
   */
  name: string;
  administrator: UserJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  members?: UserJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead[];
  /**
   * TODO Problème avec le Listener
   * "Typed property App\\PrintOnDemand\\Entity\\PrintingOrder::$printingPages must not be accessed before initialization".
   */
  printingPages?: PrintingPageJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead[];
  language: LanguageJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  shippingAddress?: AddressJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  shippingTrackingId?: string | null;
  nbNormalCards?: number;
  nbTotalNormalCards?: number;
  nbFoilCards?: number;
  nbTotalFoilCards?: number;
  full?: boolean;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingOrderMemberPrintingOrderRead {
  userNickName?: string;
  userIri?: string;
  userAvatarPath?: string;
  cards?: CardPrintingOrderRead[];
  nbCards?: number;
  price?: number;
}

export interface PrintingOrderMemberJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  userNickName?: string;
  userIri?: string;
  userAvatarPath?: string;
  cards?: CardJsonldPrintingOrderRead[];
  nbCards?: number;
  price?: number;
}

export interface PrintingOrderStatusPrintingOrderRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingOrderStatusPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingOrderStatusPrintingOrderStatusRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingOrderStatusPrintingPageReadPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingOrderStatusPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingOrderStatusJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingOrderStatusJsonldPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingOrderStatusJsonldPrintingOrderStatusRead {
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingOrderStatusJsonldPrintingPageReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingOrderStatusJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NOT_STARTED' | 'STARTED' | 'CONFIRMED' | 'PRINTED' | 'SHIPPED' | 'DELIVERED' | 'INTEGRATION_ERROR';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPagePrintingOrderRead {
  printingPageType: PrintingPageTypePrintingOrderRead | null;
  printingOrder?: PrintingOrderPrintingOrderRead | null;
  printingPageCardsForUser?: PrintingPageCardPrintingOrderRead[];
  nbPageCards?: number;
  nbAvailablePlaces?: number;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPagePrintingOrderPartnerRead {
  printingPageType: PrintingPageTypePrintingOrderPartnerRead | null;
  printingOrder?: PrintingOrderPrintingOrderPartnerRead | null;
  printingPageCards?: PrintingPageCardPrintingOrderPartnerRead[];
  nbPageCards?: number;
  nbAvailablePlaces?: number;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPagePrintingPageReadPrintingOrderPartnerRead {
  printingPageType: PrintingPageTypePrintingPageReadPrintingOrderPartnerRead | null;
  printingOrder?: PrintingOrderPrintingPageReadPrintingOrderPartnerRead | null;
  printingPageCards?: PrintingPageCardPrintingPageReadPrintingOrderPartnerRead[];
  printingPageCardsForUser?: PrintingPageCardPrintingPageReadPrintingOrderPartnerRead[];
  nbPageCards?: number;
  nbAvailablePlaces?: number;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPagePrintingPageWrite {
  /** @format iri-reference */
  printingPageType: string | null;
  /** @format iri-reference */
  printingOrder?: string | null;
}

export interface PrintingPagePrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  printingPageType: PrintingPageTypePrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  printingOrder?: PrintingOrderPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  printingPageCards?: PrintingPageCardPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead[];
  printingPageCardsForUser?: PrintingPageCardPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead[];
  nbPageCards?: number;
  nbAvailablePlaces?: number;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPagePrintingAuthorizationPrintingAuthorizationRead {
  /** @format iri-reference */
  owner?: string;
  /** @format date-time */
  date?: string | null;
  disponibility?: boolean;
  numberOfCards?: number;
  card?: CardPrintingAuthorizationRead | null;
}

export interface PrintingPagePrintingAuthorizationJsonldPrintingAuthorizationRead {
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  owner?: string;
  /** @format date-time */
  date?: string | null;
  disponibility?: boolean;
  numberOfCards?: number;
  card?: CardJsonldPrintingAuthorizationRead | null;
}

export interface PrintingPageJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  printingPageType: PrintingPageTypeJsonldPrintingOrderRead | null;
  printingOrder?: PrintingOrderJsonldPrintingOrderRead | null;
  printingPageCardsForUser?: PrintingPageCardJsonldPrintingOrderRead[];
  nbPageCards?: number;
  nbAvailablePlaces?: number;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageJsonldPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  printingPageType: PrintingPageTypeJsonldPrintingOrderPartnerRead | null;
  printingOrder?: PrintingOrderJsonldPrintingOrderPartnerRead | null;
  printingPageCards?: PrintingPageCardJsonldPrintingOrderPartnerRead[];
  nbPageCards?: number;
  nbAvailablePlaces?: number;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageJsonldPrintingPageReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  printingPageType: PrintingPageTypeJsonldPrintingPageReadPrintingOrderPartnerRead | null;
  printingOrder?: PrintingOrderJsonldPrintingPageReadPrintingOrderPartnerRead | null;
  printingPageCards?: PrintingPageCardJsonldPrintingPageReadPrintingOrderPartnerRead[];
  printingPageCardsForUser?: PrintingPageCardJsonldPrintingPageReadPrintingOrderPartnerRead[];
  nbPageCards?: number;
  nbAvailablePlaces?: number;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageJsonldPrintingPageWrite {
  /** @format iri-reference */
  printingPageType: string | null;
  /** @format iri-reference */
  printingOrder?: string | null;
}

export interface PrintingPageJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  printingPageType: PrintingPageTypeJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  printingOrder?: PrintingOrderJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  printingPageCards?: PrintingPageCardJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead[];
  printingPageCardsForUser?: PrintingPageCardJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead[];
  nbPageCards?: number;
  nbAvailablePlaces?: number;
  /** @format date-time */
  createdAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageCardPrintingOrderRead {
  card?: CardPrintingOrderRead | null;
  printingPage: PrintingPagePrintingOrderRead | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageCardPrintingOrderPartnerRead {
  qrCodeUrl?: string;
  card?: CardPrintingOrderPartnerRead | null;
  printingPage: PrintingPagePrintingOrderPartnerRead | null;
  /**
   * @min 1
   * @max 20
   * @default 1
   * @example 1
   */
  quantity?: number;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageCardPrintingPageReadPrintingOrderPartnerRead {
  qrCodeUrl?: string;
  card?: CardPrintingPageReadPrintingOrderPartnerRead | null;
  printingPage: PrintingPagePrintingPageReadPrintingOrderPartnerRead | null;
  /**
   * @min 1
   * @max 20
   * @default 1
   * @example 1
   */
  quantity?: number;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageCardPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  qrCodeUrl?: string;
  card?: CardPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  printingPage: PrintingPagePrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  /**
   * @min 1
   * @max 20
   * @default 1
   * @example 1
   */
  quantity?: number;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageCardPrintingPageCardWrite {
  /** @format iri-reference */
  owner: string | null;
  /** @format iri-reference */
  printingPage: string | null;
  /**
   * @min 1
   * @max 20
   * @default 1
   * @example 1
   */
  quantity?: number;
}

export interface PrintingPageCardJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  card?: CardJsonldPrintingOrderRead | null;
  printingPage: PrintingPageJsonldPrintingOrderRead | null;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageCardJsonldPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  qrCodeUrl?: string;
  card?: CardJsonldPrintingOrderPartnerRead | null;
  printingPage: PrintingPageJsonldPrintingOrderPartnerRead | null;
  /**
   * @min 1
   * @max 20
   * @default 1
   * @example 1
   */
  quantity?: number;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageCardJsonldPrintingPageReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  qrCodeUrl?: string;
  card?: CardJsonldPrintingPageReadPrintingOrderPartnerRead | null;
  printingPage: PrintingPageJsonldPrintingPageReadPrintingOrderPartnerRead | null;
  /**
   * @min 1
   * @max 20
   * @default 1
   * @example 1
   */
  quantity?: number;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageCardJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  qrCodeUrl?: string;
  card?: CardJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  printingPage: PrintingPageJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead | null;
  /**
   * @min 1
   * @max 20
   * @default 1
   * @example 1
   */
  quantity?: number;
  /** @format ulid */
  id?: string | null;
}

export interface PrintingPageCardJsonldPrintingPageCardWrite {
  /** @format iri-reference */
  owner: string | null;
  /** @format iri-reference */
  printingPage: string | null;
  /**
   * @min 1
   * @max 20
   * @default 1
   * @example 1
   */
  quantity?: number;
}

export interface PrintingPageTypePrintingOrderRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPageTypePrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPageTypePrintingPageReadPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPageTypePrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPageTypePrintingPageTypeRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPageTypeJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPageTypeJsonldPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPageTypeJsonldPrintingPageReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPageTypeJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingPageTypeJsonldPrintingPageTypeRead {
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'NORMAL' | 'FOIL';
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface PrintingTransactionTransactionReadPrintingReadPrintingTransactionRead {
  printingCards?: PrintingCardTransactionReadPrintingReadPrintingTransactionRead[];
  id?: string;
  transactionReference?: string;
  /** @format date-time */
  transactionDate?: string | null;
  totalPrice?: number;
  unitPrice?: number;
  quantity?: number;
  itemTotal?: number;
  taxTotal?: number;
  shippingTotal?: number;
  shippingTracking?: string | null;
  orderStatus?: string | null;
  printingLocale?: string | null;
}

export interface PrintingTransactionJsonldTransactionReadPrintingReadPrintingTransactionRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  printingCards?: PrintingCardJsonldTransactionReadPrintingReadPrintingTransactionRead[];
  id?: string;
  transactionReference?: string;
  /** @format date-time */
  transactionDate?: string | null;
  totalPrice?: number;
  unitPrice?: number;
  quantity?: number;
  itemTotal?: number;
  taxTotal?: number;
  shippingTotal?: number;
  shippingTracking?: string | null;
  orderStatus?: string | null;
  printingLocale?: string | null;
}

export interface QrCodeQrCodeRead {
  ownership?: OwnershipQrCodeRead | null;
  url?: string | null;
  hash?: string;
  qrCodeUrl?: string | null;
}

export interface QrCodeCardQrCodeRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  imagePath?: string | null;
  collectorNumberFormatted?: string | null;
}

export interface QrCodeCardJsonldQrCodeRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  imagePath?: string | null;
  collectorNumberFormatted?: string | null;
}

export interface QrCodeJsonldQrCodeRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  ownership?: OwnershipJsonldQrCodeRead | null;
  url?: string | null;
  hash?: string;
  qrCodeUrl?: string | null;
}

export interface RarityCardDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  /** @format ulid */
  id?: string | null;
  name?: string | null;
}

export interface RarityCardRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  /** @format ulid */
  id?: string | null;
  name?: string | null;
}

export interface RarityCardReadRarityReadCardMarketplaceRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  /** @format ulid */
  id?: string | null;
  name?: string | null;
  description?: string | null;
}

export interface RarityCardVariant {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
}

export interface RarityCardMySalesRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  name?: string | null;
}

export interface RarityDeckUserListRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  name?: string | null;
}

export interface RarityDeckUserListReadDeckUserListDetailRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  name?: string | null;
}

export interface RarityManufactureRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
}

export interface RarityOwnerClaimMultiRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
}

export interface RarityOwnerHistoryGroupRead {
  name?: string | null;
}

export interface RarityRarityRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  /** @format ulid */
  id?: string | null;
  name?: string | null;
  description?: string | null;
}

export interface RarityTransactionReadPrintingReadPrintingTransactionRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  name?: string | null;
}

export interface RarityJsonldCardDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  /** @format ulid */
  id?: string | null;
  name?: string | null;
}

export interface RarityJsonldCardRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  /** @format ulid */
  id?: string | null;
  name?: string | null;
}

export interface RarityJsonldCardReadRarityReadCardMarketplaceRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  /** @format ulid */
  id?: string | null;
  name?: string | null;
  description?: string | null;
}

export interface RarityJsonldCardVariant {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
}

export interface RarityJsonldCardMySalesRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  name?: string | null;
}

export interface RarityJsonldDeckUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  name?: string | null;
}

export interface RarityJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  name?: string | null;
}

export interface RarityJsonldManufactureRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
}

export interface RarityJsonldOwnerClaimMultiRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
}

export interface RarityJsonldOwnerHistoryGroupRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string | null;
}

export interface RarityJsonldRarityRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  /** @format ulid */
  id?: string | null;
  name?: string | null;
  description?: string | null;
}

export interface RarityJsonldTransactionReadPrintingReadPrintingTransactionRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([A-Z0-9]+(?:-[A-Z0-9]+)*(?:_[A-Z0-9]+)*)$
   */
  reference: 'COMMON' | 'RARE' | 'UNIQUE';
  name?: string | null;
}

export interface RuleRuleRead {
  name: string;
  type: string;
  event?: string[] | null;
  entity?: string | null;
  groupConditions?: RuleGroupConditionRuleRead[];
  ruleActions?: RuleActionRuleRead[];
  /** todo: choice OR, AND. */
  groupRelationalOp?: string | null;
  category?: string | null;
  enabled?: boolean;
  locked?: boolean;
  ttl?: number;
  entityName?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface RuleRuleWrite {
  name: string;
  type: string;
  event?: string[] | null;
  entity?: string | null;
  groupConditions?: RuleGroupConditionRuleWrite[];
  ruleActions?: RuleActionRuleWrite[];
  /** todo: choice OR, AND. */
  groupRelationalOp?: string | null;
  category?: string | null;
  enabled?: boolean;
  locked?: boolean;
  ttl?: number;
  entityName?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface RuleJsonldRuleRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name: string;
  type: string;
  event?: string[] | null;
  entity?: string | null;
  groupConditions?: RuleGroupConditionJsonldRuleRead[];
  ruleActions?: RuleActionJsonldRuleRead[];
  /** todo: choice OR, AND. */
  groupRelationalOp?: string | null;
  category?: string | null;
  enabled?: boolean;
  locked?: boolean;
  ttl?: number;
  entityName?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface RuleJsonldRuleWrite {
  name: string;
  type: string;
  event?: string[] | null;
  entity?: string | null;
  groupConditions?: RuleGroupConditionJsonldRuleWrite[];
  ruleActions?: RuleActionJsonldRuleWrite[];
  /** todo: choice OR, AND. */
  groupRelationalOp?: string | null;
  category?: string | null;
  enabled?: boolean;
  locked?: boolean;
  ttl?: number;
  entityName?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface RuleActionRuleRead {
  action?: string | null;
  parameters: string[] | null;
  sequence?: number | null;
  actionName?: string;
  requestedArguments?: string[];
}

export interface RuleActionRuleWrite {
  action?: string | null;
  parameters: string[] | null;
  sequence?: number | null;
}

export interface RuleActionJsonldRuleRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  action?: string | null;
  parameters: string[] | null;
  sequence?: number | null;
  actionName?: string;
  requestedArguments?: string[];
}

export interface RuleActionJsonldRuleWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  action?: string | null;
  parameters: string[] | null;
  sequence?: number | null;
}

export interface RuleConditionRuleRead {
  name: string;
  entity?: string | null;
  property?: string | null;
  /** todo: choice [==, >, >=, <, <=, IN, NOT IN, LIKE]. */
  logicOp?: string | null;
  value?: string | null;
  past?: boolean;
  sequence?: number | null;
  callback?: string | null;
  callbackName?: string;
  entityName?: string | null;
}

export interface RuleConditionRuleWrite {
  name: string;
  entity?: string | null;
  property?: string | null;
  /** todo: choice [==, >, >=, <, <=, IN, NOT IN, LIKE]. */
  logicOp?: string | null;
  value?: string | null;
  past?: boolean;
  sequence?: number | null;
  callback?: string | null;
}

export interface RuleConditionRuleRuleRead {
  name: string;
  entity?: string | null;
  property?: string | null;
  /** todo: choice [==, >, >=, <, <=, IN, NOT IN, LIKE]. */
  logicOp?: string | null;
  value?: string | null;
  past?: boolean;
  sequence?: number | null;
  callback?: string | null;
  callbackName?: string;
  entityName?: string | null;
}

export interface RuleConditionRuleRuleWrite {
  name: string;
  entity?: string | null;
  property?: string | null;
  /** todo: choice [==, >, >=, <, <=, IN, NOT IN, LIKE]. */
  logicOp?: string | null;
  value?: string | null;
  past?: boolean;
  sequence?: number | null;
  callback?: string | null;
}

export interface RuleConditionJsonldRuleRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name: string;
  entity?: string | null;
  property?: string | null;
  /** todo: choice [==, >, >=, <, <=, IN, NOT IN, LIKE]. */
  logicOp?: string | null;
  value?: string | null;
  past?: boolean;
  sequence?: number | null;
  callback?: string | null;
  callbackName?: string;
  entityName?: string | null;
}

export interface RuleConditionJsonldRuleWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name: string;
  entity?: string | null;
  property?: string | null;
  /** todo: choice [==, >, >=, <, <=, IN, NOT IN, LIKE]. */
  logicOp?: string | null;
  value?: string | null;
  past?: boolean;
  sequence?: number | null;
  callback?: string | null;
}

export interface RuleConditionJsonldRuleRuleRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name: string;
  entity?: string | null;
  property?: string | null;
  /** todo: choice [==, >, >=, <, <=, IN, NOT IN, LIKE]. */
  logicOp?: string | null;
  value?: string | null;
  past?: boolean;
  sequence?: number | null;
  callback?: string | null;
  callbackName?: string;
  entityName?: string | null;
}

export interface RuleConditionJsonldRuleRuleWrite {
  name: string;
  entity?: string | null;
  property?: string | null;
  /** todo: choice [==, >, >=, <, <=, IN, NOT IN, LIKE]. */
  logicOp?: string | null;
  value?: string | null;
  past?: boolean;
  sequence?: number | null;
  callback?: string | null;
}

export interface RuleGroupConditionRuleRead {
  conditions?: RuleConditionRuleRead[];
  name: string;
  /** todo: choice OR, AND. */
  conditionRelationalOp?: string | null;
  /** todo: choice [FILTER, TEST]. */
  type?: string;
  sequence?: number | null;
}

export interface RuleGroupConditionRuleWrite {
  conditions?: RuleConditionRuleWrite[];
  name: string;
  /** todo: choice OR, AND. */
  conditionRelationalOp?: string | null;
  /** todo: choice [FILTER, TEST]. */
  type?: string;
  sequence?: number | null;
}

export interface RuleGroupConditionJsonldRuleRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  conditions?: RuleConditionJsonldRuleRead[];
  name: string;
  /** todo: choice OR, AND. */
  conditionRelationalOp?: string | null;
  /** todo: choice [FILTER, TEST]. */
  type?: string;
  sequence?: number | null;
}

export interface RuleGroupConditionJsonldRuleWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  conditions?: RuleConditionJsonldRuleWrite[];
  name: string;
  /** todo: choice OR, AND. */
  conditionRelationalOp?: string | null;
  /** todo: choice [FILTER, TEST]. */
  type?: string;
  sequence?: number | null;
}

export type RuleProcessRuleRead = object;

export type RuleProcessRuleWrite = object;

export interface RuleProcessJsonldRuleRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export type RuleProcessJsonldRuleWrite = object;

export interface ShopEventLocatorReadShopLocatorRead {
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  user?: UserEventLocatorReadShopLocatorRead | null;
  shopSocialNetworks?: ShopSocialNetworkEventLocatorReadShopLocatorRead[];
  /** @format ulid */
  id?: string | null;
  distance?: number;
}

export interface ShopShopLocatorRead {
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  user?: UserShopLocatorRead | null;
  shopSocialNetworks?: ShopSocialNetworkShopLocatorRead[];
  /** @format ulid */
  id?: string | null;
  distance?: number;
}

export interface ShopShopRead {
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  user?: UserShopRead | null;
  logo?: MediaShopRead | null;
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  user?: UserShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  identificationNumber?: string;
  taxNumber?: string;
  incorporationCertificate?: MediaShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  logo?: MediaShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  background?: MediaShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  photos?: MediaShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  members?: ShopMemberShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  shopSocialNetworks?: ShopSocialNetworkShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  owner?: UserShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
}

export interface ShopShopStatRead {
  stats?:
    | {
        sales?: number;
        events?: string;
        affiliates?: string;
      }[]
    | null;
}

export interface ShopShopWrite {
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  identificationNumber?: string;
  taxNumber?: string;
  /** @format iri-reference */
  incorporationCertificate?: string | null;
  /** @format iri-reference */
  logo?: string | null;
  /** @format iri-reference */
  background?: string | null;
  photos?: string[];
  shopSocialNetworks?: ShopSocialNetworkShopWrite[];
}

export interface ShopUserPrivateReadUserTokenRead {
  name?: string;
}

export interface ShopUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  name?: string;
}

export interface ShopUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  user?: UserUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  logo?: MediaUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopUserWriteShopWriteAddressWrite {
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  identificationNumber?: string;
  taxNumber?: string;
  /** @format iri-reference */
  incorporationCertificate?: string | null;
  /** @format iri-reference */
  logo?: string | null;
  /** @format iri-reference */
  background?: string | null;
  photos?: string[];
  shopSocialNetworks?: ShopSocialNetworkUserWriteShopWriteAddressWrite[];
}

export interface ShopUserTokenRead {
  name?: string;
}

export interface ShopJsonldEventLocatorReadShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  user?: UserJsonldEventLocatorReadShopLocatorRead | null;
  shopSocialNetworks?: ShopSocialNetworkJsonldEventLocatorReadShopLocatorRead[];
  /** @format ulid */
  id?: string | null;
  distance?: number;
}

export interface ShopJsonldShopLocatorRead {
  '@id'?: string;
  '@type'?: string;
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  user?: UserJsonldShopLocatorRead | null;
  shopSocialNetworks?: ShopSocialNetworkJsonldShopLocatorRead[];
  /** @format ulid */
  id?: string | null;
  distance?: number;
}

export interface ShopJsonldShopRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  user?: UserJsonldShopRead | null;
  logo?: MediaJsonldShopRead | null;
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  user?: UserJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  identificationNumber?: string;
  taxNumber?: string;
  incorporationCertificate?: MediaJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  logo?: MediaJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  background?: MediaJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  photos?: MediaJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  members?: ShopMemberJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  shopSocialNetworks?: ShopSocialNetworkJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  owner?: UserJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
}

export interface ShopJsonldShopStatRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  stats?:
    | {
        sales?: number;
        events?: string;
        affiliates?: string;
      }[]
    | null;
}

export interface ShopJsonldShopWrite {
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  identificationNumber?: string;
  taxNumber?: string;
  /** @format iri-reference */
  incorporationCertificate?: string | null;
  /** @format iri-reference */
  logo?: string | null;
  /** @format iri-reference */
  background?: string | null;
  photos?: string[];
  shopSocialNetworks?: ShopSocialNetworkJsonldShopWrite[];
}

export interface ShopJsonldUserPrivateReadUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
}

export interface ShopJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
}

export interface ShopJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  user?: UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  logo?: MediaJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopJsonldUserWriteShopWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  biography?: string | null;
  mobilityReducedAccessibility?: boolean;
  eatingSpace?: boolean;
  sanitary?: boolean | null;
  printOnDemand?: boolean | null;
  identificationNumber?: string;
  taxNumber?: string;
  /** @format iri-reference */
  incorporationCertificate?: string | null;
  /** @format iri-reference */
  logo?: string | null;
  /** @format iri-reference */
  background?: string | null;
  photos?: string[];
  shopSocialNetworks?: ShopSocialNetworkJsonldUserWriteShopWriteAddressWrite[];
}

export interface ShopJsonldUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
}

export interface ShopDeletionStatusShopDeletionStatusRead {
  sequence?: number | null;
  /** @format ulid */
  id?: string | null;
  label?: string;
}

export interface ShopDeletionStatusJsonldShopDeletionStatusRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  sequence?: number | null;
  /** @format ulid */
  id?: string | null;
  label?: string;
}

export interface ShopDistributorShopDistributorRead {
  name?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopDistributorJsonldShopDistributorRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  name?: string;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopMemberShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  user: UserShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  /**
   * @default "member"
   * @example "member"
   */
  status?: string;
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopMemberShopMemberRead {
  /** @format iri-reference */
  user: string | null;
  /**
   * @default "member"
   * @example "member"
   */
  status?: string;
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopMemberShopMemberWrite {
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  shop: string | null;
  /**
   * @default "member"
   * @example "member"
   */
  status?: string;
}

export interface ShopMemberJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user: UserJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  /**
   * @default "member"
   * @example "member"
   */
  status?: string;
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopMemberJsonldShopMemberRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  user: string | null;
  /**
   * @default "member"
   * @example "member"
   */
  status?: string;
  /** @format ulid */
  id?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface ShopMemberJsonldShopMemberWrite {
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  shop: string | null;
  /**
   * @default "member"
   * @example "member"
   */
  status?: string;
}

export interface ShopSocialNetworkEventLocatorReadShopLocatorRead {
  socialNetwork?: SocialNetworkEventLocatorReadShopLocatorRead | null;
  socialUrl?: string | null;
}

export interface ShopSocialNetworkShopLocatorRead {
  socialNetwork?: SocialNetworkShopLocatorRead | null;
  socialUrl?: string | null;
}

export interface ShopSocialNetworkShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  socialNetwork?: SocialNetworkShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  socialUrl?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface ShopSocialNetworkShopWrite {
  /** @format iri-reference */
  socialNetwork?: string | null;
  socialUrl?: string | null;
}

export interface ShopSocialNetworkShopSocialNetworkRead {
  /** @format iri-reference */
  socialNetwork?: string | null;
  socialUrl?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface ShopSocialNetworkShopSocialNetworkWrite {
  /** @format iri-reference */
  socialNetwork?: string | null;
  socialUrl?: string | null;
}

export interface ShopSocialNetworkUserWriteShopWriteAddressWrite {
  /** @format iri-reference */
  socialNetwork?: string | null;
  socialUrl?: string | null;
}

export interface ShopSocialNetworkJsonldEventLocatorReadShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  socialNetwork?: SocialNetworkJsonldEventLocatorReadShopLocatorRead | null;
  socialUrl?: string | null;
}

export interface ShopSocialNetworkJsonldShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  socialNetwork?: SocialNetworkJsonldShopLocatorRead | null;
  socialUrl?: string | null;
}

export interface ShopSocialNetworkJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  socialNetwork?: SocialNetworkJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead | null;
  socialUrl?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface ShopSocialNetworkJsonldShopWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  socialNetwork?: string | null;
  socialUrl?: string | null;
}

export interface ShopSocialNetworkJsonldShopSocialNetworkRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  socialNetwork?: string | null;
  socialUrl?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface ShopSocialNetworkJsonldShopSocialNetworkWrite {
  /** @format iri-reference */
  socialNetwork?: string | null;
  socialUrl?: string | null;
}

export interface ShopSocialNetworkJsonldUserWriteShopWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  socialNetwork?: string | null;
  socialUrl?: string | null;
}

export interface SocialNetworkEventLocatorReadShopLocatorRead {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface SocialNetworkShopLocatorRead {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface SocialNetworkShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  isConnect?: boolean | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface SocialNetworkSocialNetworkRead {
  isConnect?: boolean | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface SocialNetworkJsonldEventLocatorReadShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface SocialNetworkJsonldShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface SocialNetworkJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  isConnect?: boolean | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface SocialNetworkJsonldSocialNetworkRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  isConnect?: boolean | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export type Survey = object;

export interface SurveySurveyRead {
  name?: string;
  users?: string[];
  /** @format iri-reference */
  surveyType?: string | null;
  /** @format iri-reference */
  event?: string | null;
  isPublic: boolean | null;
  isActive: boolean | null;
  hasReplied?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  google_form_id?: any;
}

export interface SurveySurveyWrite {
  name?: string;
  users?: string[];
  /** @format iri-reference */
  surveyType?: string | null;
  /** @format iri-reference */
  event?: string | null;
  isPublic: boolean | null;
  isActive: boolean | null;
  translations?: Record<string, SurveyTranslationSurveyWrite>;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  google_form_id?: any;
}

export interface SurveyJsonld {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
}

export interface SurveyJsonldSurveyRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  name?: string;
  users?: string[];
  /** @format iri-reference */
  surveyType?: string | null;
  /** @format iri-reference */
  event?: string | null;
  isPublic: boolean | null;
  isActive: boolean | null;
  hasReplied?: boolean | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  google_form_id?: any;
}

export interface SurveyJsonldSurveyWrite {
  name?: string;
  users?: string[];
  /** @format iri-reference */
  surveyType?: string | null;
  /** @format iri-reference */
  event?: string | null;
  isPublic: boolean | null;
  isActive: boolean | null;
  translations?: Record<string, SurveyTranslationJsonldSurveyWrite>;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  google_form_id?: any;
}

export interface SurveyTranslationSurveyWrite {
  googleFormId?: string;
  locale?: string;
}

export interface SurveyTranslationJsonldSurveyWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  googleFormId?: string;
  locale?: string;
}

export interface SurveyTypeSurveyTypeRead {
  name?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface SurveyTypeJsonldSurveyTypeRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  name?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface TagTagRead {
  parent?: TagTagRead | null;
  children?: TagTagRead[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string | null;
}

export interface TagJsonldTagRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  parent?: TagJsonldTagRead | null;
  children?: TagJsonldTagRead[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  name?: string;
  description?: string | null;
}

export interface TokenDeviceTokenDeviceRead {
  device: string;
  token: string;
  /** @format iri-reference */
  user: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface TokenDeviceTokenDeviceWrite {
  device: string;
  token: string;
  /** @format iri-reference */
  user: string | null;
}

export interface TokenDeviceJsonldTokenDeviceRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  device: string;
  token: string;
  /** @format iri-reference */
  user: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface TokenDeviceJsonldTokenDeviceWrite {
  device: string;
  token: string;
  /** @format iri-reference */
  user: string | null;
}

export interface UserCardOfferDataRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  isProfessional?: boolean | null;
}

export interface UserDeckUserListRead {
  /** @format ulid */
  id?: string;
}

export interface UserDeckUserListReadDeckUserListDetailRead {
  /** @format ulid */
  id?: string;
}

export interface UserEventLocatorReadShopLocatorRead {
  avatarPath?: string;
  addresses?: AddressEventLocatorReadShopLocatorRead[];
  shop?: ShopEventLocatorReadShopLocatorRead | null;
}

export interface UserEventRead {
  nickName?: string | null;
  avatarPath?: string;
  addresses?: AddressEventRead[];
  /** @format iri-reference */
  shop?: string | null;
}

export interface UserEventReadAddressReadEventTypeReadIllustrationRead {
  nickName?: string | null;
  avatarPath?: string;
  addresses?: AddressEventReadAddressReadEventTypeReadIllustrationRead[];
  /** @format iri-reference */
  shop?: string | null;
}

export interface UserEventReadEventTypeReadIllustrationRead {
  nickName?: string | null;
  avatarPath?: string;
  addresses?: AddressEventReadEventTypeReadIllustrationRead[];
  /** @format iri-reference */
  shop?: string | null;
}

export interface UserGameRead {
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserMarketplaceOrderDetailRead {
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserMessengerUserRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
}

export interface UserNotificationRead {
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserOwnerReadUserRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
}

export interface UserOwnerExchangeRequestRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  avatarPath?: string;
  uniqueId?: string | null;
}

export interface UserOwnerExchangeRequestReadOwnerExchangeRequestDetailRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  avatarPath?: string;
  uniqueId?: string | null;
}

export interface UserOwnerHistoryGroupRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserPrintingOrderRead {
  nickName?: string | null;
}

export interface UserPrintingOrderPartnerRead {
  nickName?: string | null;
}

export interface UserPrintingPageReadPrintingOrderPartnerRead {
  nickName?: string | null;
}

export interface UserPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  nickName?: string | null;
}

export interface UserShopLocatorRead {
  avatarPath?: string;
  addresses?: AddressShopLocatorRead[];
}

export interface UserShopRead {
  avatarPath?: string;
  addresses?: AddressShopRead[];
  organizedEvents?: EventShopRead[];
}

export interface UserShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  /** @format ulid */
  id?: string;
  /** @format email */
  email?: string;
  nickName?: string | null;
  avatarPath?: string;
  /** @format date-time */
  lastConnection?: string | null;
  addresses?: AddressShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  organizedEvents?: EventShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  fullName?: string | null;
}

export interface UserUserPrivateReadUserTokenRead {
  /** @format ulid */
  id?: string;
  description?: string | null;
  email?: string;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  avatarPath?: string;
  /** @format date-time */
  birthDate?: string;
  isActive?: boolean | null;
  /** @format date-time */
  activation?: string | null;
  /** @format iri-reference */
  userProfile?: string | null;
  roles?: string[];
  /** @format date-time */
  emailUpdatedAt?: string | null;
  /** @format iri-reference */
  country?: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  language?: LanguageUserPrivateReadUserTokenRead | null;
  userGroups?: string[];
  userOptins?: UserOptinUserPrivateReadUserTokenRead[];
  addresses?: AddressUserPrivateReadUserTokenRead[];
  userSocialNetworks?: string[];
  userTokens?: UserTokenUserPrivateReadUserTokenRead[];
  biography?: string | null;
  backgroundPath?: string | null;
  isProfessional?: boolean | null;
  shippingAddress?: AddressUserPrivateReadUserTokenRead;
  billingAddress?: AddressUserPrivateReadUserTokenRead;
  affiliateShop?: ShopUserPrivateReadUserTokenRead | null;
  shop?: ShopUserPrivateReadUserTokenRead | null;
  provenance?: string | null;
  wallet?: WalletBalanceUserPrivateReadUserTokenRead | null;
  userFriendRequestsSend?: string[];
  userFriendRequestsReceive?: string[];
  inMyTradelist?: number | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserUserRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
}

export interface UserUserReadUserPrivateRead {
  /** @format ulid */
  id?: string;
  /**
   * @minLength 0
   * @maxLength 300
   */
  description?: string | null;
  /** @format email */
  email?: string;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  civility?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  phone?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  cellPhone?: string | null;
  avatarPath?: string;
  /** @format date-time */
  birthDate?: string;
  isActive?: boolean | null;
  /** @format date-time */
  activation?: string | null;
  /** @format iri-reference */
  userProfile: string | null;
  roles?: string[];
  /** @format date-time */
  emailUpdatedAt?: string | null;
  /** @format iri-reference */
  country: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  language?: LanguageUserReadUserPrivateRead | null;
  userGroups?: string[];
  userOptins?: string[];
  addresses?: AddressUserReadUserPrivateRead[];
  userSocialNetworks?: string[];
  userTokens?: string[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  backgroundPath?: string | null;
  isProfessional?: boolean | null;
  shippingAddress?: AddressUserReadUserPrivateRead;
  billingAddress?: AddressUserReadUserPrivateRead;
  /** @format iri-reference */
  affiliateShop?: string | null;
  /** @format iri-reference */
  shop?: string | null;
  wallet?: WalletBalanceUserReadUserPrivateRead | null;
  userFriendRequestsSend?: string[];
  userFriendRequestsReceive?: string[];
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  inMyTradelist?: number | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  /** @format ulid */
  id?: string;
  description?: string | null;
  email?: string;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  avatarPath?: string;
  /** @format date-time */
  birthDate?: string;
  isActive?: boolean | null;
  /** @format date-time */
  activation?: string | null;
  /** @format iri-reference */
  userProfile?: string | null;
  roles?: string[];
  /** @format date-time */
  emailUpdatedAt?: string | null;
  /** @format iri-reference */
  country?: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  language?: LanguageUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  userGroups?: string[];
  userOptins?: UserOptinUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead[];
  addresses?: AddressUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead[];
  userSocialNetworks?: UserSocialNetworkUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead[];
  userTokens?: UserTokenUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead[];
  biography?: string | null;
  backgroundPath?: string | null;
  isProfessional?: boolean | null;
  shippingAddress?: AddressUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead;
  billingAddress?: AddressUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead;
  affiliateShop?: ShopUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  shop?: ShopUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  provenance?: string | null;
  wallet?: WalletBalanceUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  userFriendRequestsSend?: string[];
  userFriendRequestsReceive?: string[];
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  inMyTradelist?: number | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  /** @format ulid */
  id?: string;
  description?: string | null;
  email?: string;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  avatarPath?: string;
  /** @format date-time */
  birthDate?: string;
  isActive?: boolean | null;
  /** @format date-time */
  activation?: string | null;
  /** @format iri-reference */
  userProfile?: string | null;
  roles?: string[];
  /** @format date-time */
  emailUpdatedAt?: string | null;
  country?: CountryUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  nationalityCountry?: CountryUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  language?: LanguageUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  userGroups?: string[];
  userOptins?: UserOptinUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  addresses?: AddressUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  userSocialNetworks?: UserSocialNetworkUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  userTokens?: UserTokenUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  organizedEvents?: EventUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  biography?: string | null;
  backgroundPath?: string | null;
  isProfessional?: boolean | null;
  shippingAddress?: AddressUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead;
  billingAddress?: AddressUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead;
  affiliateShop?: ShopUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  shop?: ShopUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  provenance?: string | null;
  wallet?: WalletBalanceUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  userFriendRequestsSend?: string[];
  userFriendRequestsReceive?: string[];
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  inMyTradelist?: number | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserUserReadUserFriendshipRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  backgroundPath?: string | null;
  totalFriends?: number;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  userFriendship?: UserUserReadUserFriendshipRead;
  inMyTradelist?: number | null;
}

export interface UserUserWrite {
  /**
   * @minLength 0
   * @maxLength 300
   */
  description?: string | null;
  /** @format email */
  email?: string;
  password?: string | null;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  civility?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  phone?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  cellPhone?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  /** @format date-time */
  birthDate?: string;
  /** @format iri-reference */
  country: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  /** @format iri-reference */
  language?: string | null;
  userGroups?: string[];
  userOptins?: UserOptinUserWrite[];
  optins?: string[];
  addresses?: string[];
  userSocialNetworks?: UserSocialNetworkUserWrite[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  /** @format iri-reference */
  affiliateShop?: string | null;
  /** @format iri-reference */
  shop?: string | null;
  /** @example "EUR" */
  currency?: string | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserUserWriteAddressWrite {
  description?: string | null;
  email?: string;
  password?: string | null;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  /** @format date-time */
  birthDate?: string;
  /** @format iri-reference */
  country?: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  /** @format iri-reference */
  language?: string | null;
  userGroups?: string[];
  userOptins?: UserOptinUserWriteAddressWrite[];
  optins?: string[];
  addresses?: AddressUserWriteAddressWrite[];
  userSocialNetworks?: UserSocialNetworkUserWriteAddressWrite[];
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  /** @format iri-reference */
  affiliateShop?: string | null;
  /** @format iri-reference */
  shop?: string | null;
  /** @example "EUR" */
  currency?: string | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserUserWriteShopWriteAddressWrite {
  description?: string | null;
  email?: string;
  password?: string | null;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  /** @format date-time */
  birthDate?: string;
  /** @format iri-reference */
  country?: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  /** @format iri-reference */
  language?: string | null;
  userGroups?: string[];
  userOptins?: UserOptinUserWriteShopWriteAddressWrite[];
  optins?: string[];
  addresses?: AddressUserWriteShopWriteAddressWrite[];
  userSocialNetworks?: UserSocialNetworkUserWriteShopWriteAddressWrite[];
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  affiliateShop?: ShopUserWriteShopWriteAddressWrite | null;
  shop?: ShopUserWriteShopWriteAddressWrite | null;
  /** @example "EUR" */
  currency?: string | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserUserBlockedRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserUserFriendRequestRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserUserFriendshipReadUserRead {
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  backgroundPath?: string | null;
  totalFriends?: number;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  userFriendship?: UserUserFriendshipReadUserRead;
  inMyTradelist?: number | null;
}

export interface UserUserTokenRead {
  /** @format email */
  email?: string;
  lastName?: string | null;
  firstName?: string | null;
  userOptins?: UserOptinUserTokenRead[];
  shop?: ShopUserTokenRead | null;
  provenance?: string | null;
}

export interface UserUserTokenWrite {
  optins?: string[];
}

export interface UserShopMemberActivationUserWrite {
  token?: string | null;
  password?: string | null;
}

export interface UserShopMemberActivationJsonldUserWrite {
  token?: string | null;
  password?: string | null;
}

export interface UserUserGameStatsUserGameStatsRead {
  factionPlayed?: FactionPlayedUserGameStatsRead[];
  gamesWin?: number;
  gamesLost?: number;
  totalGame?: number;
}

export interface UserUserGameStatsJsonldUserGameStatsRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  factionPlayed?: FactionPlayedJsonldUserGameStatsRead[];
  gamesWin?: number;
  gamesLost?: number;
  totalGame?: number;
}

export interface UserUserUniqueEmailUserUniqueEmailRead {
  /**
   * @default true
   * @example true
   */
  isUnique?: boolean;
  hasGoogleAccount?: boolean;
}

export interface UserUserUniqueEmailJsonldUserUniqueEmailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @default true
   * @example true
   */
  isUnique?: boolean;
  hasGoogleAccount?: boolean;
}

export interface UserUserUniqueNicknameUserUniqueNicknameRead {
  /**
   * @default true
   * @example true
   */
  isUnique?: boolean;
}

export interface UserUserUniqueNicknameJsonldUserUniqueNicknameRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @default true
   * @example true
   */
  isUnique?: boolean;
}

export interface UserJsonldCardOfferDataRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  isProfessional?: boolean | null;
}

export interface UserJsonldDeckUserListRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
}

export interface UserJsonldDeckUserListReadDeckUserListDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
}

export interface UserJsonldEventLocatorReadShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  avatarPath?: string;
  addresses?: AddressJsonldEventLocatorReadShopLocatorRead[];
  shop?: ShopJsonldEventLocatorReadShopLocatorRead | null;
}

export interface UserJsonldEventRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
  avatarPath?: string;
  addresses?: AddressJsonldEventRead[];
  /** @format iri-reference */
  shop?: string | null;
}

export interface UserJsonldEventReadAddressReadEventTypeReadIllustrationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
  avatarPath?: string;
  addresses?: AddressJsonldEventReadAddressReadEventTypeReadIllustrationRead[];
  /** @format iri-reference */
  shop?: string | null;
}

export interface UserJsonldEventReadEventTypeReadIllustrationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
  avatarPath?: string;
  addresses?: AddressJsonldEventReadEventTypeReadIllustrationRead[];
  /** @format iri-reference */
  shop?: string | null;
}

export interface UserJsonldGameRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserJsonldMarketplaceOrderDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserJsonldMessengerUserRead {
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
}

export interface UserJsonldNotificationRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserJsonldOwnerReadUserRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
}

export interface UserJsonldOwnerExchangeRequestRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  avatarPath?: string;
  uniqueId?: string | null;
}

export interface UserJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  avatarPath?: string;
  uniqueId?: string | null;
}

export interface UserJsonldOwnerHistoryGroupRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserJsonldPrintingOrderRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
}

export interface UserJsonldPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
}

export interface UserJsonldPrintingPageReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
}

export interface UserJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  nickName?: string | null;
}

export interface UserJsonldShopLocatorRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  avatarPath?: string;
  addresses?: AddressJsonldShopLocatorRead[];
}

export interface UserJsonldShopRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  avatarPath?: string;
  addresses?: AddressJsonldShopRead[];
  organizedEvents?: EventJsonldShopRead[];
}

export interface UserJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  /** @format email */
  email?: string;
  nickName?: string | null;
  avatarPath?: string;
  /** @format date-time */
  lastConnection?: string | null;
  addresses?: AddressJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  organizedEvents?: EventJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  fullName?: string | null;
}

export interface UserJsonldUserPrivateReadUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  description?: string | null;
  email?: string;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  avatarPath?: string;
  /** @format date-time */
  birthDate?: string;
  isActive?: boolean | null;
  /** @format date-time */
  activation?: string | null;
  /** @format iri-reference */
  userProfile?: string | null;
  roles?: string[];
  /** @format date-time */
  emailUpdatedAt?: string | null;
  /** @format iri-reference */
  country?: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  language?: LanguageJsonldUserPrivateReadUserTokenRead | null;
  userGroups?: string[];
  userOptins?: UserOptinJsonldUserPrivateReadUserTokenRead[];
  addresses?: AddressJsonldUserPrivateReadUserTokenRead[];
  userSocialNetworks?: string[];
  userTokens?: UserTokenJsonldUserPrivateReadUserTokenRead[];
  biography?: string | null;
  backgroundPath?: string | null;
  isProfessional?: boolean | null;
  shippingAddress?: AddressJsonldUserPrivateReadUserTokenRead;
  billingAddress?: AddressJsonldUserPrivateReadUserTokenRead;
  affiliateShop?: ShopJsonldUserPrivateReadUserTokenRead | null;
  shop?: ShopJsonldUserPrivateReadUserTokenRead | null;
  provenance?: string | null;
  wallet?: WalletBalanceJsonldUserPrivateReadUserTokenRead | null;
  userFriendRequestsSend?: string[];
  userFriendRequestsReceive?: string[];
  inMyTradelist?: number | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserJsonldUserRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
}

export interface UserJsonldUserReadUserPrivateRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  /**
   * @minLength 0
   * @maxLength 300
   */
  description?: string | null;
  /** @format email */
  email?: string;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  civility?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  phone?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  cellPhone?: string | null;
  avatarPath?: string;
  /** @format date-time */
  birthDate?: string;
  isActive?: boolean | null;
  /** @format date-time */
  activation?: string | null;
  /** @format iri-reference */
  userProfile: string | null;
  roles?: string[];
  /** @format date-time */
  emailUpdatedAt?: string | null;
  /** @format iri-reference */
  country: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  language?: LanguageJsonldUserReadUserPrivateRead | null;
  userGroups?: string[];
  userOptins?: string[];
  addresses?: AddressJsonldUserReadUserPrivateRead[];
  userSocialNetworks?: string[];
  userTokens?: string[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  backgroundPath?: string | null;
  isProfessional?: boolean | null;
  shippingAddress?: AddressJsonldUserReadUserPrivateRead;
  billingAddress?: AddressJsonldUserReadUserPrivateRead;
  /** @format iri-reference */
  affiliateShop?: string | null;
  /** @format iri-reference */
  shop?: string | null;
  wallet?: WalletBalanceJsonldUserReadUserPrivateRead | null;
  userFriendRequestsSend?: string[];
  userFriendRequestsReceive?: string[];
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  inMyTradelist?: number | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  description?: string | null;
  email?: string;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  avatarPath?: string;
  /** @format date-time */
  birthDate?: string;
  isActive?: boolean | null;
  /** @format date-time */
  activation?: string | null;
  /** @format iri-reference */
  userProfile?: string | null;
  roles?: string[];
  /** @format date-time */
  emailUpdatedAt?: string | null;
  /** @format iri-reference */
  country?: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  language?: LanguageJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  userGroups?: string[];
  userOptins?: UserOptinJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead[];
  addresses?: AddressJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead[];
  userSocialNetworks?: UserSocialNetworkJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead[];
  userTokens?: UserTokenJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead[];
  biography?: string | null;
  backgroundPath?: string | null;
  isProfessional?: boolean | null;
  shippingAddress?: AddressJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead;
  billingAddress?: AddressJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead;
  affiliateShop?: ShopJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  shop?: ShopJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  provenance?: string | null;
  wallet?: WalletBalanceJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  userFriendRequestsSend?: string[];
  userFriendRequestsReceive?: string[];
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  inMyTradelist?: number | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  description?: string | null;
  email?: string;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  avatarPath?: string;
  /** @format date-time */
  birthDate?: string;
  isActive?: boolean | null;
  /** @format date-time */
  activation?: string | null;
  /** @format iri-reference */
  userProfile?: string | null;
  roles?: string[];
  /** @format date-time */
  emailUpdatedAt?: string | null;
  country?: CountryJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  nationalityCountry?: CountryJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  language?: LanguageJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  userGroups?: string[];
  userOptins?: UserOptinJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  addresses?: AddressJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  userSocialNetworks?: UserSocialNetworkJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  userTokens?: UserTokenJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  organizedEvents?: EventJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead[];
  biography?: string | null;
  backgroundPath?: string | null;
  isProfessional?: boolean | null;
  shippingAddress?: AddressJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead;
  billingAddress?: AddressJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead;
  affiliateShop?: ShopJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  shop?: ShopJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  provenance?: string | null;
  wallet?: WalletBalanceJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  userFriendRequestsSend?: string[];
  userFriendRequestsReceive?: string[];
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  inMyTradelist?: number | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserJsonldUserReadUserFriendshipRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  backgroundPath?: string | null;
  totalFriends?: number;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  userFriendship?: UserJsonldUserReadUserFriendshipRead;
  inMyTradelist?: number | null;
}

export interface UserJsonldUserWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 300
   */
  description?: string | null;
  /** @format email */
  email?: string;
  password?: string | null;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  civility?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  phone?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  cellPhone?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  /** @format date-time */
  birthDate?: string;
  /** @format iri-reference */
  country: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  /** @format iri-reference */
  language?: string | null;
  userGroups?: string[];
  userOptins?: UserOptinJsonldUserWrite[];
  optins?: string[];
  addresses?: string[];
  userSocialNetworks?: UserSocialNetworkJsonldUserWrite[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  /** @format iri-reference */
  affiliateShop?: string | null;
  /** @format iri-reference */
  shop?: string | null;
  /** @example "EUR" */
  currency?: string | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserJsonldUserWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  description?: string | null;
  email?: string;
  password?: string | null;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  /** @format date-time */
  birthDate?: string;
  /** @format iri-reference */
  country?: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  /** @format iri-reference */
  language?: string | null;
  userGroups?: string[];
  userOptins?: UserOptinJsonldUserWriteAddressWrite[];
  optins?: string[];
  addresses?: AddressJsonldUserWriteAddressWrite[];
  userSocialNetworks?: UserSocialNetworkJsonldUserWriteAddressWrite[];
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  /** @format iri-reference */
  affiliateShop?: string | null;
  /** @format iri-reference */
  shop?: string | null;
  /** @example "EUR" */
  currency?: string | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserJsonldUserWriteShopWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  description?: string | null;
  email?: string;
  password?: string | null;
  nickName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  civility?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  /** @format iri-reference */
  avatar?: string | null;
  /** @format date-time */
  birthDate?: string;
  /** @format iri-reference */
  country?: string | null;
  /** @format iri-reference */
  nationalityCountry?: string | null;
  /** @format iri-reference */
  language?: string | null;
  userGroups?: string[];
  userOptins?: UserOptinJsonldUserWriteShopWriteAddressWrite[];
  optins?: string[];
  addresses?: AddressJsonldUserWriteShopWriteAddressWrite[];
  userSocialNetworks?: UserSocialNetworkJsonldUserWriteShopWriteAddressWrite[];
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  affiliateShop?: ShopJsonldUserWriteShopWriteAddressWrite | null;
  shop?: ShopJsonldUserWriteShopWriteAddressWrite | null;
  /** @example "EUR" */
  currency?: string | null;
  fullName?: string | null;
  faction?: string[];
}

export interface UserJsonldUserBlockedRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserJsonldUserFriendRequestRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
}

export interface UserJsonldUserFriendshipReadUserRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format ulid */
  id?: string;
  nickName?: string | null;
  avatarPath?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  biography?: string | null;
  /** @format iri-reference */
  background?: string | null;
  backgroundPath?: string | null;
  totalFriends?: number;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  friendStatus?: 'PENDING' | 'SENDING' | 'ACCEPTED' | 'NONE' | 'BLOCKED' | 'REFUSED';
  uniqueId?: string | null;
  userFriendship?: UserJsonldUserFriendshipReadUserRead;
  inMyTradelist?: number | null;
}

export interface UserJsonldUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format email */
  email?: string;
  lastName?: string | null;
  firstName?: string | null;
  userOptins?: UserOptinJsonldUserTokenRead[];
  shop?: ShopJsonldUserTokenRead | null;
  provenance?: string | null;
}

export interface UserJsonldUserTokenWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  optins?: string[];
}

export interface UserAccessUserAccessRead {
  name: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface UserAccessJsonldUserAccessRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  name: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
}

export interface UserBlocked {
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  blockedUser?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserBlockedUserBlockedRead {
  blockedUser?: UserUserBlockedRead | null;
}

export interface UserBlockedJsonld {
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  blockedUser?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserBlockedJsonldUserBlockedRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  blockedUser?: UserJsonldUserBlockedRead | null;
}

export interface UserFriendRequestUserFriendRequestRead {
  sender?: UserUserFriendRequestRead | null;
  receiver?: UserUserFriendRequestRead | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  status?: 'PENDING' | 'ACCEPTED' | 'NONE' | 'REFUSED';
  /**
   * @default true
   * @example true
   */
  accept?: boolean;
}

export interface UserFriendRequestUserFriendRequestWrite {
  /** @format iri-reference */
  receiver?: string | null;
  /**
   * @default true
   * @example true
   */
  accept?: boolean;
}

export interface UserFriendRequestJsonldUserFriendRequestRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  sender?: UserJsonldUserFriendRequestRead | null;
  receiver?: UserJsonldUserFriendRequestRead | null;
  /**
   * @default "NONE"
   * @example "NONE"
   */
  status?: 'PENDING' | 'ACCEPTED' | 'NONE' | 'REFUSED';
  /**
   * @default true
   * @example true
   */
  accept?: boolean;
}

export interface UserFriendRequestJsonldUserFriendRequestWrite {
  /** @format iri-reference */
  receiver?: string | null;
  /**
   * @default true
   * @example true
   */
  accept?: boolean;
}

export interface UserFriendshipUserFriendshipReadUserRead {
  userFriend?: UserUserFriendshipReadUserRead | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserFriendshipJsonldUserFriendshipReadUserRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  userFriend?: UserJsonldUserFriendshipReadUserRead | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserGroupUserGroupRead {
  users?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
}

export interface UserGroupJsonldUserGroupRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  users?: string[];
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
}

export interface UserOptinUserPrivateReadUserTokenRead {
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
}

export interface UserOptinUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  user: UserUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  user: UserUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinUserWrite {
  user: UserUserWrite | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinUserWriteAddressWrite {
  user: UserUserWriteAddressWrite | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinUserWriteShopWriteAddressWrite {
  user: UserUserWriteShopWriteAddressWrite | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinUserOptinRead {
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinUserOptinWrite {
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
}

export interface UserOptinUserTokenRead {
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
}

export interface UserOptinJsonldUserPrivateReadUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
}

export interface UserOptinJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user: UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user: UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinJsonldUserWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user: UserJsonldUserWrite | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinJsonldUserWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user: UserJsonldUserWriteAddressWrite | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinJsonldUserWriteShopWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user: UserJsonldUserWriteShopWriteAddressWrite | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinJsonldUserOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserOptinJsonldUserOptinWrite {
  /** @format iri-reference */
  user: string | null;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
}

export interface UserOptinJsonldUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  optin: string | null;
  value: boolean | null;
}

export interface UserProfileOptinRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference:
    | 'ROLE_CUSTOMER'
    | 'ROLE_SHOP'
    | 'ROLE_SHOP_OWNER'
    | 'ROLE_SHOP_MEMBER'
    | 'ROLE_BGA'
    | 'ROLE_SHOP_ARTIST'
    | 'ROLE_PROD_PARTNER'
    | 'ROLE_ADMIN';
}

export interface UserProfileUserProfileRead {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference:
    | 'ROLE_CUSTOMER'
    | 'ROLE_SHOP'
    | 'ROLE_SHOP_OWNER'
    | 'ROLE_SHOP_MEMBER'
    | 'ROLE_BGA'
    | 'ROLE_SHOP_ARTIST'
    | 'ROLE_PROD_PARTNER'
    | 'ROLE_ADMIN';
  userAccesses?: string[];
  users?: string[];
  name: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserProfileJsonldOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference:
    | 'ROLE_CUSTOMER'
    | 'ROLE_SHOP'
    | 'ROLE_SHOP_OWNER'
    | 'ROLE_SHOP_MEMBER'
    | 'ROLE_BGA'
    | 'ROLE_SHOP_ARTIST'
    | 'ROLE_PROD_PARTNER'
    | 'ROLE_ADMIN';
}

export interface UserProfileJsonldUserProfileRead {
  '@id'?: string;
  '@type'?: string;
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(?:_[a-zA-Z0-9]+)*)$
   */
  reference:
    | 'ROLE_CUSTOMER'
    | 'ROLE_SHOP'
    | 'ROLE_SHOP_OWNER'
    | 'ROLE_SHOP_MEMBER'
    | 'ROLE_BGA'
    | 'ROLE_SHOP_ARTIST'
    | 'ROLE_PROD_PARTNER'
    | 'ROLE_ADMIN';
  userAccesses?: string[];
  users?: string[];
  name: string;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserSocialNetworkUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  user?: UserUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  user?: UserUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkUserWrite {
  user?: UserUserWrite | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkUserWriteAddressWrite {
  user?: UserUserWriteAddressWrite | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkUserWriteShopWriteAddressWrite {
  user?: UserUserWriteShopWriteAddressWrite | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkUserSocialNetworkRead {
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkUserSocialNetworkWrite {
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user?: UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user?: UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkJsonldUserWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user?: UserJsonldUserWrite | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkJsonldUserWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user?: UserJsonldUserWriteAddressWrite | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkJsonldUserWriteShopWriteAddressWrite {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  user?: UserJsonldUserWriteShopWriteAddressWrite | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkJsonldUserSocialNetworkRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserSocialNetworkJsonldUserSocialNetworkWrite {
  /** @format iri-reference */
  user?: string | null;
  /** @format iri-reference */
  socialNetwork?: string | null;
  connectId?: string | null;
  socialUrl?: string | null;
}

export interface UserTokenUserPrivateReadUserTokenRead {
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  /** @format date-time */
  ttl: string;
  user?: UserUserPrivateReadUserTokenRead | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserTokenUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  /** @format date-time */
  ttl: string;
  user?: UserUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserTokenUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  /** @format date-time */
  ttl: string;
  user?: UserUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserTokenUserTokenCreateRead {
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
}

export interface UserTokenUserTokenRead {
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  /** @format date-time */
  ttl: string;
  user?: UserUserTokenRead | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserTokenUserTokenWrite {
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  code?: string | null;
  /** @format date-time */
  ttl: string;
  user?: UserUserTokenWrite | null;
}

export interface UserTokenJsonldUserPrivateReadUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  /** @format date-time */
  ttl: string;
  user?: UserJsonldUserPrivateReadUserTokenRead | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserTokenJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  /** @format date-time */
  ttl: string;
  user?: UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserTokenJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  /** @format date-time */
  ttl: string;
  user?: UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserTokenJsonldUserTokenCreateRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
}

export interface UserTokenJsonldUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  /** @format date-time */
  ttl: string;
  user?: UserJsonldUserTokenRead | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format ulid */
  id?: string | null;
}

export interface UserTokenJsonldUserTokenWrite {
  token: string;
  type:
    | 'activation-code'
    | 'activation-link'
    | 'newsletter'
    | 'kickstarter'
    | 'activation-shop-member'
    | 'authorization-iban';
  code?: string | null;
  /** @format date-time */
  ttl: string;
  user?: UserJsonldUserTokenWrite | null;
}

export interface WalletBalancePaymentWalletBalanceRead {
  id?: string | null;
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletBalanceUserPrivateReadUserTokenRead {
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletBalanceUserReadUserPrivateRead {
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletBalanceUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletBalanceUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletBalanceJsonldPaymentWalletBalanceRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  id?: string | null;
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletBalanceJsonldUserPrivateReadUserTokenRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletBalanceJsonldUserReadUserPrivateRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletBalanceJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletBalanceJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        hydra: 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  balance?: number | null;
  currency?: string | null;
  instantBalance?: number | null;
}

export interface WalletTransactionPaymentWalletTransactionRead {
  id?: string | null;
  currency?: string | null;
  amount?: number | null;
  type?: string | null;
  status?: string | null;
  /** @format date-time */
  date?: string | null;
}

export interface WalletTransactionJsonldPaymentWalletTransactionRead {
  '@id'?: string;
  '@type'?: string;
  id?: string | null;
  currency?: string | null;
  amount?: number | null;
  type?: string | null;
  status?: string | null;
  /** @format date-time */
  date?: string | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '/';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Altered
 * @version 0.0.3
 * @baseUrl /
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  addresses = {
    /**
     * @description Retrieves the collection of Address resources.
     *
     * @tags Address
     * @name ApiAddressesGetCollection
     * @summary Retrieves the collection of Address resources.
     * @request GET:/addresses
     * @secure
     */
    apiAddressesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        zipCode?: string;
        city?: string;
        'country.reference'?: string;
        'country.reference[]'?: string[];
        isDefaultBilling?: boolean;
        'isDefaultBilling[]'?: boolean[];
        isDefaultShipping?: boolean;
        'isDefaultShipping[]'?: boolean[];
        'order[zipCode]'?: 'asc' | 'desc';
        'order[city]'?: 'asc' | 'desc';
        'order[country.reference]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': AddressJsonldAddressRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/addresses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a Address resource.
     *
     * @tags Address
     * @name ApiAddressesPost
     * @summary Creates a Address resource.
     * @request POST:/addresses
     * @secure
     */
    apiAddressesPost: (data: AddressJsonldAddressWrite, params: RequestParams = {}) =>
      this.request<AddressJsonldAddressRead, void>({
        path: `/addresses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Address resource.
     *
     * @tags Address
     * @name ApiAddressesIdGet
     * @summary Retrieves a Address resource.
     * @request GET:/addresses/{id}
     * @secure
     */
    apiAddressesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<AddressJsonldAddressRead, void>({
        path: `/addresses/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the Address resource.
     *
     * @tags Address
     * @name ApiAddressesIdPut
     * @summary Replaces the Address resource.
     * @request PUT:/addresses/{id}
     * @secure
     */
    apiAddressesIdPut: (id: string, data: AddressJsonldAddressWrite, params: RequestParams = {}) =>
      this.request<AddressJsonldAddressRead, void>({
        path: `/addresses/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the Address resource.
     *
     * @tags Address
     * @name ApiAddressesIdDelete
     * @summary Removes the Address resource.
     * @request DELETE:/addresses/{id}
     * @secure
     */
    apiAddressesIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/addresses/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  cardElementTypes = {
    /**
     * @description Retrieves the collection of CardElementType resources.
     *
     * @tags CardElementType
     * @name ApiCardElementTypesGetCollection
     * @summary Retrieves the collection of CardElementType resources.
     * @request GET:/card_element_types
     * @secure
     */
    apiCardElementTypesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardElementTypeJsonldCardElementTypeRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/card_element_types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cardElements = {
    /**
     * @description Retrieves a CardElement resource.
     *
     * @tags CardElement
     * @name ApiCardElementsIdGet
     * @summary Retrieves a CardElement resource.
     * @request GET:/card_elements/{id}
     * @secure
     */
    apiCardElementsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<CardElementJsonldCardElementRead, void>({
        path: `/card_elements/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cardExports = {
    /**
     * @description Retrieves a InDesign resource.
     *
     * @tags InDesign
     * @name ApiCardExportsIdGet
     * @summary Retrieves a InDesign resource.
     * @request GET:/card_exports/{id}
     * @secure
     */
    apiCardExportsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<InDesignJsonldCardExportRead, void>({
        path: `/card_exports/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the InDesign resource.
     *
     * @tags InDesign
     * @name ApiCardExportsIdPut
     * @summary Replaces the InDesign resource.
     * @request PUT:/card_exports/{id}
     * @secure
     */
    apiCardExportsIdPut: (id: string, data: InDesignJsonldCardExportWrite, params: RequestParams = {}) =>
      this.request<InDesignJsonldCardExportRead, void>({
        path: `/card_exports/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Informations from Card Set.
     *
     * @tags InDesign
     * @name ApiCardExportsIddataGet
     * @summary Get informations of Card Set for collection
     * @request GET:/card_exports/{id}/data
     * @secure
     */
    apiCardExportsIddataGet: (id: string, params: RequestParams = {}) =>
      this.request<InDesignJsonldCardExportRead, void>({
        path: `/card_exports/${id}/data`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cardGoogleSheets = {
    /**
     * @description Retrieves the collection of CardGoogleSheet resources.
     *
     * @tags CardGoogleSheet
     * @name ApiCardGoogleSheetsGetCollection
     * @summary Retrieves the collection of CardGoogleSheet resources.
     * @request GET:/card_google_sheets
     * @secure
     */
    apiCardGoogleSheetsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'cardSet.id'?: string;
        'cardSet.id[]'?: string[];
        cardSet?: string;
        'cardSet[]'?: string[];
        'order[reference]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardGoogleSheetJsonldCardGoogleSheetCardGoogleSheetRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/card_google_sheets`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a CardGoogleSheet resource.
     *
     * @tags CardGoogleSheet
     * @name ApiCardGoogleSheetsPost
     * @summary Creates a CardGoogleSheet resource.
     * @request POST:/card_google_sheets
     * @secure
     */
    apiCardGoogleSheetsPost: (
      data: CardGoogleSheetJsonldCardGoogleSheetCardGoogleSheetWrite,
      params: RequestParams = {},
    ) =>
      this.request<CardGoogleSheetJsonldCardGoogleSheetCardGoogleSheetRead, void>({
        path: `/card_google_sheets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a CardGoogleSheet resource.
     *
     * @tags CardGoogleSheet
     * @name ApiCardGoogleSheetsIdGet
     * @summary Retrieves a CardGoogleSheet resource.
     * @request GET:/card_google_sheets/{id}
     * @secure
     */
    apiCardGoogleSheetsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<CardGoogleSheetJsonldCardGoogleSheetCardGoogleSheetRead, void>({
        path: `/card_google_sheets/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the CardGoogleSheet resource.
     *
     * @tags CardGoogleSheet
     * @name ApiCardGoogleSheetsIdPut
     * @summary Replaces the CardGoogleSheet resource.
     * @request PUT:/card_google_sheets/{id}
     * @secure
     */
    apiCardGoogleSheetsIdPut: (
      id: string,
      data: CardGoogleSheetJsonldCardGoogleSheetCardGoogleSheetWrite,
      params: RequestParams = {},
    ) =>
      this.request<CardGoogleSheetJsonldCardGoogleSheetCardGoogleSheetRead, void>({
        path: `/card_google_sheets/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  cardProducts = {
    /**
     * @description Retrieves the collection of CardProduct resources.
     *
     * @tags CardProduct
     * @name ApiCardProductsGetCollection
     * @summary Retrieves the collection of CardProduct resources.
     * @request GET:/card_products
     * @secure
     */
    apiCardProductsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'order[reference]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardProductJsonldCardProductRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/card_products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a CardProduct resource.
     *
     * @tags CardProduct
     * @name ApiCardProductsIdGet
     * @summary Retrieves a CardProduct resource.
     * @request GET:/card_products/{id}
     * @secure
     */
    apiCardProductsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<CardProductJsonldCardProductRead, void>({
        path: `/card_products/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cardSets = {
    /**
     * @description Retrieves the collection of CardSet resources.
     *
     * @tags CardSet
     * @name ApiCardSetsGetCollection
     * @summary Retrieves the collection of CardSet resources.
     * @request GET:/card_sets
     * @secure
     */
    apiCardSetsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'translations.name'?: string;
        'translations.locale'?: string;
        'translations.locale[]'?: string[];
        isActive?: boolean;
        'isActive[]'?: boolean[];
        'order[reference]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'order[date]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'date[before]'?: string;
        'date[strictly_before]'?: string;
        'date[after]'?: string;
        'date[strictly_after]'?: string;
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
        'groups[]'?: (
          | 'lore_entry:read'
          | 'lore_entry_lore_entry_element:read'
          | 'lore_entry_type:read'
          | 'lore_entry_status:read'
          | 'illustration_lore_entry:read'
          | 'faction:read'
          | 'tag:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardSetJsonldCardSetRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/card_sets`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Informations from Card Set.
     *
     * @tags CardSet
     * @name ApiCardSetscollectionsGetCollection
     * @summary Get informations of Card Set for collection
     * @request GET:/card_sets/collections
     * @secure
     */
    apiCardSetscollectionsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'translations.name'?: string;
        'translations.locale'?: string;
        'translations.locale[]'?: string[];
        isActive?: boolean;
        'isActive[]'?: boolean[];
        'order[reference]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'order[date]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'date[before]'?: string;
        'date[strictly_before]'?: string;
        'date[after]'?: string;
        'date[strictly_after]'?: string;
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
        'groups[]'?: (
          | 'lore_entry:read'
          | 'lore_entry_lore_entry_element:read'
          | 'lore_entry_type:read'
          | 'lore_entry_status:read'
          | 'illustration_lore_entry:read'
          | 'faction:read'
          | 'tag:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardSetCardSetDataJsonldCardSetCollectionRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/card_sets/collections`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cardTypes = {
    /**
     * @description Retrieves the collection of CardType resources.
     *
     * @tags CardType
     * @name ApiCardTypesGetCollection
     * @summary Retrieves the collection of CardType resources.
     * @request GET:/card_types
     * @secure
     */
    apiCardTypesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardTypeJsonldCardTypeRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/card_types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cardUserListCards = {
    /**
     * @description Retrieves the collection of CardUserListCard resources.
     *
     * @tags CardUserListCard
     * @name ApiCardUserListCardsGetCollection
     * @summary Retrieves the collection of CardUserListCard resources.
     * @request GET:/card_user_list_cards
     * @secure
     */
    apiCardUserListCardsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardUserListCardJsonldCardUserListCardRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/card_user_list_cards`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Add multiple Cards in a specific list for the current user.
     *
     * @tags CardUserListCard
     * @name ApiCardUserListCardsPost
     * @summary Add multiple Cards to a list
     * @request POST:/card_user_list_cards
     * @secure
     */
    apiCardUserListCardsPost: (
      data: CardUserListCardPostCardsUserListCardDataJsonldPostCardsUserListCardDataWrite,
      params: RequestParams = {},
    ) =>
      this.request<CardUserListCardJsonldCardUserListRead, void>({
        path: `/card_user_list_cards`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a CardUserListCard resource.
     *
     * @tags CardUserListCard
     * @name ApiCardUserListCardsIdGet
     * @summary Retrieves a CardUserListCard resource.
     * @request GET:/card_user_list_cards/{id}
     * @secure
     */
    apiCardUserListCardsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<CardUserListCardJsonldCardUserListCardRead, void>({
        path: `/card_user_list_cards/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cardUserLists = {
    /**
     * @description Retrieves the collection of CardUserList resources.
     *
     * @tags CardUserList
     * @name ApiCardUserListsGetCollection
     * @summary Retrieves the collection of CardUserList resources.
     * @request GET:/card_user_lists
     * @secure
     */
    apiCardUserListsGetCollection: (params: RequestParams = {}) =>
      this.request<
        {
          'hydra:member': CardUserListJsonldCardUserListRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/card_user_lists`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the CardUserList resource.
     *
     * @tags CardUserList
     * @name ApiCardUserListsIdDelete
     * @summary Removes the CardUserList resource.
     * @request DELETE:/card_user_lists/{id}
     * @secure
     */
    apiCardUserListsIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/card_user_lists/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Delete a specific card in a specific list for the current user.
     *
     * @tags CardUserList
     * @name ApiCardUserListsListNamecardsReferenceDelete
     * @summary Delete a Card in a List
     * @request DELETE:/card_user_lists/{listName}/cards/{reference}
     * @secure
     */
    apiCardUserListsListNamecardsReferenceDelete: (listName: string, reference: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/card_user_lists/${listName}/cards/${reference}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  cards = {
    /**
     * @description Retrieves the collection of Card resources.
     *
     * @tags Card
     * @name ApiCardsGetCollection
     * @summary Retrieves the collection of Card resources.
     * @request GET:/cards
     * @secure
     */
    apiCardsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        /** Enable or disable pagination */
        pagination?: boolean;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        factions?: string;
        'factions[]'?: string[];
        'factions.reference'?: string;
        'factions.reference[]'?: string[];
        cardType?: string;
        'cardType[]'?: string[];
        'cardType.reference'?: string;
        'cardType.reference[]'?: string[];
        cardSubTypes?: string;
        'cardSubTypes[]'?: string[];
        cardSet?: string;
        'cardSet[]'?: string[];
        'cardSet.reference'?: string;
        'cardSet.reference[]'?: string[];
        rarity?: string;
        'rarity[]'?: string[];
        'rarity.reference'?: string;
        'rarity.reference[]'?: string[];
        'translations.name'?: string;
        'cardProduct.reference'?: string;
        'cardProduct.reference[]'?: string[];
        'order[reference]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'order[cardType.reference]'?: 'asc' | 'desc';
        'order[cardSubType.reference]'?: 'asc' | 'desc';
        'order[parent.reference]'?: 'asc' | 'desc';
        'order[cardSet.reference]'?: 'asc' | 'desc';
        'order[rarity.sequence]'?: 'asc' | 'desc';
        'order[factions.reference]'?: 'asc' | 'desc';
        'order[cardUserListCards.createdAt]'?: 'asc' | 'desc';
        /**
         * This filter search a CardElement of type mainCost with value in the array
         * @example "['1', '3']"
         */
        mainCost?: string;
        /**
         * This filter search a CardElement of type recallCost with value in the array
         * @example "['1', '3']"
         */
        recallCost?: string;
        /**
         * This filter search a CardElement of type mountainPower with value in the array
         * @example "['1', '3']"
         */
        mountainPower?: string;
        /**
         * This filter search a CardElement of type oceanPower with value in the array
         * @example "['1', '3']"
         */
        oceanPower?: string;
        /**
         * This filter search a CardElement of type forestPower with value in the array
         * @example "['1', '3']"
         */
        forestPower?: string;
        /**
         * This filter search a CardKeyword of type mainEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        mainEffect?: string;
        /**
         * This filter search a CardKeyword of type echoEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        echoEffect?: string;
        /** Order cards by MAIN COST. */
        'order[mainCost]'?: 'asc' | 'desc';
        /** Order cards by RECALL COST. */
        'order[recallCost]'?: 'asc' | 'desc';
        /** Order cards by Last Added in Collection. */
        'order[collectionLatestAdded]'?: 'asc' | 'desc';
        /**
         * This filter search all the card in the given list name of the current user.
         * @example "wantlist"
         */
        'cardList.name'?: string;
        /**
         * This filter order the card list by sequence
         * @example "ASC | DESC"
         */
        'cardList.order'?: string;
        /** Filter by available in collection */
        collection?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardJsonldCardRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/cards`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description List of all values needed to initiate card filters.
     *
     * @tags CardFilterData
     * @name ApiCardsfilterDataGetCollection
     * @summary Get the filter's data for Cards list
     * @request GET:/cards/filter-data
     * @secure
     */
    apiCardsfilterDataGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardFilterDataJsonldCardFilterData[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/cards/filter-data`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description List of all Cards offers in Marketplace.
     *
     * @tags Card
     * @name ApiCardsmarketplaceGetCollection
     * @summary Get Offer in marketplace
     * @request GET:/cards/marketplace
     * @secure
     */
    apiCardsmarketplaceGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        /** Filter Offer with missing card on deck */
        deckUserList?: string;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        factions?: string;
        'factions[]'?: string[];
        'factions.reference'?: string;
        'factions.reference[]'?: string[];
        cardType?: string;
        'cardType[]'?: string[];
        'cardType.reference'?: string;
        'cardType.reference[]'?: string[];
        cardSubTypes?: string;
        'cardSubTypes[]'?: string[];
        cardSet?: string;
        'cardSet[]'?: string[];
        'cardSet.reference'?: string;
        'cardSet.reference[]'?: string[];
        rarity?: string;
        'rarity[]'?: string[];
        'rarity.reference'?: string;
        'rarity.reference[]'?: string[];
        'translations.name'?: string;
        'cardProduct.reference'?: string;
        'cardProduct.reference[]'?: string[];
        'order[reference]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'order[cardType.reference]'?: 'asc' | 'desc';
        'order[cardSubType.reference]'?: 'asc' | 'desc';
        'order[parent.reference]'?: 'asc' | 'desc';
        'order[cardSet.reference]'?: 'asc' | 'desc';
        'order[rarity.sequence]'?: 'asc' | 'desc';
        'order[factions.reference]'?: 'asc' | 'desc';
        'order[cardUserListCards.createdAt]'?: 'asc' | 'desc';
        /**
         * This filter search a CardElement of type mainCost with value in the array
         * @example "['1', '3']"
         */
        mainCost?: string;
        /**
         * This filter search a CardElement of type recallCost with value in the array
         * @example "['1', '3']"
         */
        recallCost?: string;
        /**
         * This filter search a CardElement of type mountainPower with value in the array
         * @example "['1', '3']"
         */
        mountainPower?: string;
        /**
         * This filter search a CardElement of type oceanPower with value in the array
         * @example "['1', '3']"
         */
        oceanPower?: string;
        /**
         * This filter search a CardElement of type forestPower with value in the array
         * @example "['1', '3']"
         */
        forestPower?: string;
        /**
         * This filter search a CardKeyword of type mainEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        mainEffect?: string;
        /**
         * This filter search a CardKeyword of type echoEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        echoEffect?: string;
        /** Order cards by MAIN COST. */
        'order[mainCost]'?: 'asc' | 'desc';
        /** Order cards by RECALL COST. */
        'order[recallCost]'?: 'asc' | 'desc';
        /** Order cards by Last Added in Collection. */
        'order[collectionLatestAdded]'?: 'asc' | 'desc';
        /**
         * This filter search all the card in the given list name of the current user.
         * @example "wantlist"
         */
        'cardList.name'?: string;
        /**
         * This filter order the card list by sequence
         * @example "ASC | DESC"
         */
        'cardList.order'?: string;
        /** Filter by available in collection */
        collection?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardJsonldCardReadRarityReadCardMarketplaceRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/cards/marketplace`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description List of all Cards in my current sales.
     *
     * @tags Card
     * @name ApiCardsmySalesGetCollection
     * @summary Get Cards in my current sales
     * @request GET:/cards/my-sales
     * @secure
     */
    apiCardsmySalesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        /** Filter Offer with missing card on deck */
        deckUserList?: string;
        /** Filter Offer with seller id */
        seller?: string;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        factions?: string;
        'factions[]'?: string[];
        'factions.reference'?: string;
        'factions.reference[]'?: string[];
        cardType?: string;
        'cardType[]'?: string[];
        'cardType.reference'?: string;
        'cardType.reference[]'?: string[];
        cardSubTypes?: string;
        'cardSubTypes[]'?: string[];
        cardSet?: string;
        'cardSet[]'?: string[];
        'cardSet.reference'?: string;
        'cardSet.reference[]'?: string[];
        rarity?: string;
        'rarity[]'?: string[];
        'rarity.reference'?: string;
        'rarity.reference[]'?: string[];
        'translations.name'?: string;
        'cardProduct.reference'?: string;
        'cardProduct.reference[]'?: string[];
        'order[reference]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'order[cardType.reference]'?: 'asc' | 'desc';
        'order[cardSubType.reference]'?: 'asc' | 'desc';
        'order[parent.reference]'?: 'asc' | 'desc';
        'order[cardSet.reference]'?: 'asc' | 'desc';
        'order[rarity.sequence]'?: 'asc' | 'desc';
        'order[factions.reference]'?: 'asc' | 'desc';
        'order[cardUserListCards.createdAt]'?: 'asc' | 'desc';
        /**
         * This filter search a CardElement of type mainCost with value in the array
         * @example "['1', '3']"
         */
        mainCost?: string;
        /**
         * This filter search a CardElement of type recallCost with value in the array
         * @example "['1', '3']"
         */
        recallCost?: string;
        /**
         * This filter search a CardElement of type mountainPower with value in the array
         * @example "['1', '3']"
         */
        mountainPower?: string;
        /**
         * This filter search a CardElement of type oceanPower with value in the array
         * @example "['1', '3']"
         */
        oceanPower?: string;
        /**
         * This filter search a CardElement of type forestPower with value in the array
         * @example "['1', '3']"
         */
        forestPower?: string;
        /**
         * This filter search a CardKeyword of type mainEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        mainEffect?: string;
        /**
         * This filter search a CardKeyword of type echoEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        echoEffect?: string;
        /** Order cards by MAIN COST. */
        'order[mainCost]'?: 'asc' | 'desc';
        /** Order cards by RECALL COST. */
        'order[recallCost]'?: 'asc' | 'desc';
        /** Order cards by Last Added in Collection. */
        'order[collectionLatestAdded]'?: 'asc' | 'desc';
        /**
         * This filter search all the card in the given list name of the current user.
         * @example "wantlist"
         */
        'cardList.name'?: string;
        /**
         * This filter order the card list by sequence
         * @example "ASC | DESC"
         */
        'cardList.order'?: string;
        /** Filter by available in collection */
        collection?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardJsonldCardMySalesRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/cards/my-sales`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of Card resources.
     *
     * @tags Card
     * @name ApiCardsshortGetCollection
     * @summary Get a summary of Card
     * @request GET:/cards/short
     * @secure
     */
    apiCardsshortGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        factions?: string;
        'factions[]'?: string[];
        'factions.reference'?: string;
        'factions.reference[]'?: string[];
        cardType?: string;
        'cardType[]'?: string[];
        'cardType.reference'?: string;
        'cardType.reference[]'?: string[];
        cardSubTypes?: string;
        'cardSubTypes[]'?: string[];
        cardSet?: string;
        'cardSet[]'?: string[];
        'cardSet.reference'?: string;
        'cardSet.reference[]'?: string[];
        rarity?: string;
        'rarity[]'?: string[];
        'rarity.reference'?: string;
        'rarity.reference[]'?: string[];
        'translations.name'?: string;
        'cardProduct.reference'?: string;
        'cardProduct.reference[]'?: string[];
        'order[reference]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'order[cardType.reference]'?: 'asc' | 'desc';
        'order[cardSubType.reference]'?: 'asc' | 'desc';
        'order[parent.reference]'?: 'asc' | 'desc';
        'order[cardSet.reference]'?: 'asc' | 'desc';
        'order[rarity.sequence]'?: 'asc' | 'desc';
        'order[factions.reference]'?: 'asc' | 'desc';
        'order[cardUserListCards.createdAt]'?: 'asc' | 'desc';
        /**
         * This filter search a CardElement of type mainCost with value in the array
         * @example "['1', '3']"
         */
        mainCost?: string;
        /**
         * This filter search a CardElement of type recallCost with value in the array
         * @example "['1', '3']"
         */
        recallCost?: string;
        /**
         * This filter search a CardElement of type mountainPower with value in the array
         * @example "['1', '3']"
         */
        mountainPower?: string;
        /**
         * This filter search a CardElement of type oceanPower with value in the array
         * @example "['1', '3']"
         */
        oceanPower?: string;
        /**
         * This filter search a CardElement of type forestPower with value in the array
         * @example "['1', '3']"
         */
        forestPower?: string;
        /**
         * This filter search a CardKeyword of type mainEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        mainEffect?: string;
        /**
         * This filter search a CardKeyword of type echoEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        echoEffect?: string;
        /** Order cards by MAIN COST. */
        'order[mainCost]'?: 'asc' | 'desc';
        /** Order cards by RECALL COST. */
        'order[recallCost]'?: 'asc' | 'desc';
        /** Order cards by Last Added in Collection. */
        'order[collectionLatestAdded]'?: 'asc' | 'desc';
        /**
         * This filter search all the card in the given list name of the current user.
         * @example "wantlist"
         */
        'cardList.name'?: string;
        /**
         * This filter order the card list by sequence
         * @example "ASC | DESC"
         */
        'cardList.order'?: string;
        /** Filter by available in collection */
        collection?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardJsonldCardShortRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/cards/short`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Card resource.
     *
     * @tags Card
     * @name ApiCardsReferenceGet
     * @summary Retrieves a Card resource.
     * @request GET:/cards/{reference}
     * @secure
     */
    apiCardsReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<CardJsonldCardDetailRead, void>({
        path: `/cards/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a Card resource.
     *
     * @tags Card
     * @name ApiCardsReferencefoilPost
     * @summary Creates a Card resource.
     * @request POST:/cards/{reference}/foil
     * @secure
     */
    apiCardsReferencefoilPost: (reference: string, data?: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/cards/${reference}/foil`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description List of all Offers for a Card.
     *
     * @tags MarketplaceOffer
     * @name ApiCardsReferenceoffersGetCollection
     * @summary Get Card Offers
     * @request GET:/cards/{reference}/offers
     * @secure
     */
    apiCardsReferenceoffersGetCollection: (
      reference: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        /** Filter Offer with missing card on deck */
        deckUserList?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': MarketplaceOfferCardOfferDataJsonldCardOfferDataRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/cards/${reference}/offers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of Card resources.
     *
     * @tags Card
     * @name CardsReferenceVariants
     * @summary Get All Variants for one card
     * @request GET:/cards/{reference}/variants
     * @secure
     */
    cardsReferenceVariants: (
      reference: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        factions?: string;
        'factions[]'?: string[];
        'factions.reference'?: string;
        'factions.reference[]'?: string[];
        cardType?: string;
        'cardType[]'?: string[];
        'cardType.reference'?: string;
        'cardType.reference[]'?: string[];
        cardSubTypes?: string;
        'cardSubTypes[]'?: string[];
        cardSet?: string;
        'cardSet[]'?: string[];
        'cardSet.reference'?: string;
        'cardSet.reference[]'?: string[];
        rarity?: string;
        'rarity[]'?: string[];
        'rarity.reference'?: string;
        'rarity.reference[]'?: string[];
        'translations.name'?: string;
        'cardProduct.reference'?: string;
        'cardProduct.reference[]'?: string[];
        'order[reference]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'order[cardType.reference]'?: 'asc' | 'desc';
        'order[cardSubType.reference]'?: 'asc' | 'desc';
        'order[parent.reference]'?: 'asc' | 'desc';
        'order[cardSet.reference]'?: 'asc' | 'desc';
        'order[rarity.sequence]'?: 'asc' | 'desc';
        'order[factions.reference]'?: 'asc' | 'desc';
        'order[cardUserListCards.createdAt]'?: 'asc' | 'desc';
        /**
         * This filter search a CardElement of type mainCost with value in the array
         * @example "['1', '3']"
         */
        mainCost?: string;
        /**
         * This filter search a CardElement of type recallCost with value in the array
         * @example "['1', '3']"
         */
        recallCost?: string;
        /**
         * This filter search a CardElement of type mountainPower with value in the array
         * @example "['1', '3']"
         */
        mountainPower?: string;
        /**
         * This filter search a CardElement of type oceanPower with value in the array
         * @example "['1', '3']"
         */
        oceanPower?: string;
        /**
         * This filter search a CardElement of type forestPower with value in the array
         * @example "['1', '3']"
         */
        forestPower?: string;
        /**
         * This filter search a CardKeyword of type mainEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        mainEffect?: string;
        /**
         * This filter search a CardKeyword of type echoEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        echoEffect?: string;
        /** Order cards by MAIN COST. */
        'order[mainCost]'?: 'asc' | 'desc';
        /** Order cards by RECALL COST. */
        'order[recallCost]'?: 'asc' | 'desc';
        /** Order cards by Last Added in Collection. */
        'order[collectionLatestAdded]'?: 'asc' | 'desc';
        /**
         * This filter search all the card in the given list name of the current user.
         * @example "wantlist"
         */
        'cardList.name'?: string;
        /**
         * This filter order the card list by sequence
         * @example "ASC | DESC"
         */
        'cardList.order'?: string;
        /** Filter by available in collection */
        collection?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardJsonldCardVariant[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/cards/${reference}/variants`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cms = {
    /**
     * @description Retrieves a Pages resource.
     *
     * @tags CMS
     * @name ApiCmspagesGet
     * @summary Get a page from the CMS
     * @request GET:/cms/pages
     * @secure
     */
    apiCmspagesGet: (
      query?: {
        url?: string;
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PagesJsonldPagesGetList, void>({
        path: `/cms/pages`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  contacts = {
    /**
     * @description Creates a Contact resource.
     *
     * @tags Contact
     * @name ApiContactsPost
     * @summary Creates a Contact resource.
     * @request POST:/contacts
     * @secure
     */
    apiContactsPost: (data: ContactJsonldContactWrite, params: RequestParams = {}) =>
      this.request<ContactJsonldContactRead, void>({
        path: `/contacts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  countries = {
    /**
     * @description Retrieves the collection of Country resources.
     *
     * @tags Country
     * @name ApiCountriesGetCollection
     * @summary Retrieves the collection of Country resources.
     * @request GET:/countries
     * @secure
     */
    apiCountriesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        /** Enable or disable pagination */
        pagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CountryJsonldCountryRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/countries`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of Country resources.
     *
     * @tags Country
     * @name ApiCountrieslocatorGetCollection
     * @summary Retrieves the collection of Country resources.
     * @request GET:/countries/locator
     * @secure
     */
    apiCountrieslocatorGetCollection: (params: RequestParams = {}) =>
      this.request<
        {
          'hydra:member': CountryJsonldCountryRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/countries/locator`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Country resource.
     *
     * @tags Country
     * @name ApiCountriesReferenceGet
     * @summary Retrieves a Country resource.
     * @request GET:/countries/{reference}
     * @secure
     */
    apiCountriesReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<CountryJsonldCountryRead, void>({
        path: `/countries/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  deckUserLists = {
    /**
     * @description Retrieves the collection of DeckUserList resources.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsGetCollection
     * @summary Collection of Decks
     * @request GET:/deck_user_lists
     * @secure
     */
    apiDeckUserListsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        'faction.reference'?: string;
        'faction.reference[]'?: string[];
        'alterator.reference'?: string;
        'alterator.reference[]'?: string[];
        name?: string;
        isLegal?: boolean;
        'isLegal[]'?: boolean[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': DeckUserListJsonldDeckUserListRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/deck_user_lists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a DeckUserList resource.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsPost
     * @summary Create a Deck
     * @request POST:/deck_user_lists
     * @secure
     */
    apiDeckUserListsPost: (data: DeckUserListJsonldDeckUserListWrite, params: RequestParams = {}) =>
      this.request<DeckUserListJsonldDeckUserListRead, void>({
        path: `/deck_user_lists`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of DeckUserList resources.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsuserUserGetCollection
     * @summary Collection of public deck from one user
     * @request GET:/deck_user_lists/user/{user}
     * @secure
     */
    apiDeckUserListsuserUserGetCollection: (
      user: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        'faction.reference'?: string;
        'faction.reference[]'?: string[];
        'alterator.reference'?: string;
        'alterator.reference[]'?: string[];
        name?: string;
        isLegal?: boolean;
        'isLegal[]'?: boolean[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': DeckUserListJsonldDeckUserListRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/deck_user_lists/user/${user}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a DeckUserList resource.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsIdGet
     * @summary Get a Deck
     * @request GET:/deck_user_lists/{id}
     * @secure
     */
    apiDeckUserListsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<DeckUserListJsonldDeckUserListReadDeckUserListDetailRead, void>({
        path: `/deck_user_lists/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the DeckUserList resource.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsIdPut
     * @summary Update a Deck
     * @request PUT:/deck_user_lists/{id}
     * @secure
     */
    apiDeckUserListsIdPut: (id: string, data: DeckUserListJsonldDeckUserListPut, params: RequestParams = {}) =>
      this.request<DeckUserListJsonldDeckUserListRead, void>({
        path: `/deck_user_lists/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the DeckUserList resource.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsIdDelete
     * @summary Delete a Deck
     * @request DELETE:/deck_user_lists/{id}
     * @secure
     */
    apiDeckUserListsIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/deck_user_lists/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Replaces the DeckUserList resource.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsIdaddCardsPut
     * @summary Add Cards to a Deck
     * @request PUT:/deck_user_lists/{id}/add_cards
     * @secure
     */
    apiDeckUserListsIdaddCardsPut: (
      id: string,
      data: DeckUserListDeckAddCardDataJsonldDeckAddCardDataWrite,
      params: RequestParams = {},
    ) =>
      this.request<DeckUserListJsonldDeckUserListRead, void>({
        path: `/deck_user_lists/${id}/add_cards`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the DeckUserList resource.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsIdduplicatePut
     * @summary Duplicate a Deck
     * @request PUT:/deck_user_lists/{id}/duplicate
     * @secure
     */
    apiDeckUserListsIdduplicatePut: (
      id: string,
      data: DeckUserListJsonldDeckUserListWrite,
      params: RequestParams = {},
    ) =>
      this.request<DeckUserListJsonldDeckUserListRead, void>({
        path: `/deck_user_lists/${id}/duplicate`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the DeckUserList resource.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsIdmultiAddCardsPut
     * @summary Multi Scan to add Cards to a Deck
     * @request PUT:/deck_user_lists/{id}/multi_add_cards
     * @secure
     */
    apiDeckUserListsIdmultiAddCardsPut: (id: string, data?: string[], params: RequestParams = {}) =>
      this.request<DeckUserListDeckMultiScanJsonldDeckUserListRead, void>({
        path: `/deck_user_lists/${id}/multi_add_cards`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a DeckUserList resource.
     *
     * @tags Deckbuilder
     * @name ApiDeckUserListsIdstatsGet
     * @summary Get Statistics for a Deck
     * @request GET:/deck_user_lists/{id}/stats
     * @secure
     */
    apiDeckUserListsIdstatsGet: (id: string, params: RequestParams = {}) =>
      this.request<DeckUserListDeckStatisticsDataJsonldDeckUserListReadDeckUserListData, void>({
        path: `/deck_user_lists/${id}/stats`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  eventTypes = {
    /**
     * @description Retrieves the collection of EventType resources.
     *
     * @tags EventType
     * @name ApiEventTypesGetCollection
     * @summary Retrieves the collection of EventType resources.
     * @request GET:/event_types
     * @secure
     */
    apiEventTypesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': EventTypeJsonldEventTypeRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/event_types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a EventType resource.
     *
     * @tags EventType
     * @name ApiEventTypesIdGet
     * @summary Retrieves a EventType resource.
     * @request GET:/event_types/{id}
     * @secure
     */
    apiEventTypesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<EventTypeJsonldEventTypeRead, void>({
        path: `/event_types/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  eventUsers = {
    /**
     * @description Retrieves the collection of EventUser resources.
     *
     * @tags EventUser
     * @name ApiEventUsersGetCollection
     * @summary Retrieves the collection of EventUser resources.
     * @request GET:/event_users
     * @secure
     */
    apiEventUsersGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        event?: string;
        'event[]'?: string[];
        'user.id'?: string;
        'user.id[]'?: string[];
        status?: string;
        'order[user.nickName]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': EventUserJsonldEventUserRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/event_users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a EventUser resource.
     *
     * @tags EventUser
     * @name ApiEventUsersPost
     * @summary Creates a EventUser resource.
     * @request POST:/event_users
     * @secure
     */
    apiEventUsersPost: (data: EventUserJsonldEventUserWrite, params: RequestParams = {}) =>
      this.request<EventUserJsonldEventUserRead, void>({
        path: `/event_users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a EventUser resource.
     *
     * @tags EventUser
     * @name ApiEventUsersIdGet
     * @summary Retrieves a EventUser resource.
     * @request GET:/event_users/{id}
     * @secure
     */
    apiEventUsersIdGet: (id: string, params: RequestParams = {}) =>
      this.request<EventUserJsonldEventUserRead, void>({
        path: `/event_users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the EventUser resource.
     *
     * @tags EventUser
     * @name ApiEventUsersIdDelete
     * @summary Removes the EventUser resource.
     * @request DELETE:/event_users/{id}
     * @secure
     */
    apiEventUsersIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/event_users/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  events = {
    /**
     * @description Get the event list for the connected user.
     *
     * @tags Event
     * @name ApiEventsGetCollection
     * @summary Event Users List
     * @request GET:/events
     * @secure
     */
    apiEventsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        'eventType.id'?: string;
        'eventType.id[]'?: string[];
        isPublic?: boolean;
        'isPublic[]'?: boolean[];
        'translations.locale'?: string;
        'translations.locale[]'?: string[];
        'translations.name'?: string;
        'eventUsers.user.id'?: string;
        'eventUsers.user.id[]'?: string[];
        'eventUsers.status'?: string;
        'eventUsers.status[]'?: string[];
        organizer?: string;
        'organizer[]'?: string[];
        mobilityReducedAccessibility?: boolean;
        'mobilityReducedAccessibility[]'?: boolean[];
        'order[eventType.reference]'?: 'asc' | 'desc';
        'order[dateTime]'?: 'asc' | 'desc';
        'order[isPublic]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'dateTime[before]'?: string;
        'dateTime[strictly_before]'?: string;
        'dateTime[after]'?: string;
        'dateTime[strictly_after]'?: string;
        'groups[]'?: (
          | 'survey:read'
          | 'event_user:read'
          | 'event_type:read'
          | 'user:read'
          | 'game:read'
          | 'game_user:read'
          | 'faction:read'
          | 'address:read'
          | 'illustration:read'
          | 'event_ranking:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': EventJsonldEventReadEventTypeReadIllustrationRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/events`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a Event resource.
     *
     * @tags Event
     * @name ApiEventsPost
     * @summary Creates a Event resource.
     * @request POST:/events
     * @secure
     */
    apiEventsPost: (data: EventJsonldEventWrite, params: RequestParams = {}) =>
      this.request<EventJsonldEventRead, void>({
        path: `/events`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Event near the location.
     *
     * @tags Event
     * @name ApiEventslocatorGetCollection
     * @summary Event Locator
     * @request GET:/events/locator
     * @secure
     */
    apiEventslocatorGetCollection: (
      query?: {
        /** Filter by coordinate */
        latitude?: number;
        /** Filter by coordinate */
        longitude?: number;
        /** Max distance with latitude and longitude */
        maxDistance?: 10 | 20 | 50 | 100;
        /** Unit system used for calculation */
        unit?: 'meters' | 'miles';
        /** Date from which shops have an event planned */
        from?: number;
        /** Date until which shops have an event planned */
        to?: number;
        /**
         * Country
         * @example ["FR","US"]
         */
        country?: string;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        'eventType.id'?: string;
        'eventType.id[]'?: string[];
        isPublic?: boolean;
        'isPublic[]'?: boolean[];
        'translations.locale'?: string;
        'translations.locale[]'?: string[];
        'translations.name'?: string;
        'eventUsers.user.id'?: string;
        'eventUsers.user.id[]'?: string[];
        'eventUsers.status'?: string;
        'eventUsers.status[]'?: string[];
        organizer?: string;
        'organizer[]'?: string[];
        mobilityReducedAccessibility?: boolean;
        'mobilityReducedAccessibility[]'?: boolean[];
        'order[eventType.reference]'?: 'asc' | 'desc';
        'order[dateTime]'?: 'asc' | 'desc';
        'order[isPublic]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'dateTime[before]'?: string;
        'dateTime[strictly_before]'?: string;
        'dateTime[after]'?: string;
        'dateTime[strictly_after]'?: string;
        'groups[]'?: (
          | 'survey:read'
          | 'event_user:read'
          | 'event_type:read'
          | 'user:read'
          | 'game:read'
          | 'game_user:read'
          | 'faction:read'
          | 'address:read'
          | 'illustration:read'
          | 'event_ranking:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': EventEventLocatorJsonldEventLocatorReadShopLocatorRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/events/locator`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get the detail information of an Event with the connected user.
     *
     * @tags Event
     * @name ApiEventsIdGet
     * @summary Event Users
     * @request GET:/events/{id}
     * @secure
     */
    apiEventsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<EventJsonldEventRead, void>({
        path: `/events/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the Event resource.
     *
     * @tags Event
     * @name ApiEventsIdPut
     * @summary Replaces the Event resource.
     * @request PUT:/events/{id}
     * @secure
     */
    apiEventsIdPut: (id: string, data: EventJsonldEventWrite, params: RequestParams = {}) =>
      this.request<EventJsonldEventRead, void>({
        path: `/events/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get the event that the user shop created.
     *
     * @tags Event
     * @name ApiEventsIdorganizerGetCollection
     * @summary Event the user shop created
     * @request GET:/events/{id}/organizer
     * @secure
     */
    apiEventsIdorganizerGetCollection: (
      id: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        'eventType.id'?: string;
        'eventType.id[]'?: string[];
        isPublic?: boolean;
        'isPublic[]'?: boolean[];
        'translations.locale'?: string;
        'translations.locale[]'?: string[];
        'translations.name'?: string;
        'eventUsers.user.id'?: string;
        'eventUsers.user.id[]'?: string[];
        'eventUsers.status'?: string;
        'eventUsers.status[]'?: string[];
        organizer?: string;
        'organizer[]'?: string[];
        mobilityReducedAccessibility?: boolean;
        'mobilityReducedAccessibility[]'?: boolean[];
        'order[eventType.reference]'?: 'asc' | 'desc';
        'order[dateTime]'?: 'asc' | 'desc';
        'order[isPublic]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'dateTime[before]'?: string;
        'dateTime[strictly_before]'?: string;
        'dateTime[after]'?: string;
        'dateTime[strictly_after]'?: string;
        'groups[]'?: (
          | 'survey:read'
          | 'event_user:read'
          | 'event_type:read'
          | 'user:read'
          | 'game:read'
          | 'game_user:read'
          | 'faction:read'
          | 'address:read'
          | 'illustration:read'
          | 'event_ranking:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': EventJsonldEventReadAddressReadEventTypeReadIllustrationRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/events/${id}/organizer`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get the ranking of users.
     *
     * @tags Event
     * @name ApiEventsIdrankingGetCollection
     * @summary Event ranking
     * @request GET:/events/{id}/ranking
     * @secure
     */
    apiEventsIdrankingGetCollection: (
      id: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        'eventType.id'?: string;
        'eventType.id[]'?: string[];
        isPublic?: boolean;
        'isPublic[]'?: boolean[];
        'translations.locale'?: string;
        'translations.locale[]'?: string[];
        'translations.name'?: string;
        'eventUsers.user.id'?: string;
        'eventUsers.user.id[]'?: string[];
        'eventUsers.status'?: string;
        'eventUsers.status[]'?: string[];
        organizer?: string;
        'organizer[]'?: string[];
        mobilityReducedAccessibility?: boolean;
        'mobilityReducedAccessibility[]'?: boolean[];
        'order[eventType.reference]'?: 'asc' | 'desc';
        'order[dateTime]'?: 'asc' | 'desc';
        'order[isPublic]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'dateTime[before]'?: string;
        'dateTime[strictly_before]'?: string;
        'dateTime[after]'?: string;
        'dateTime[strictly_after]'?: string;
        'groups[]'?: (
          | 'survey:read'
          | 'event_user:read'
          | 'event_type:read'
          | 'user:read'
          | 'game:read'
          | 'game_user:read'
          | 'faction:read'
          | 'address:read'
          | 'illustration:read'
          | 'event_ranking:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': EventEventRankingJsonldEventRankingRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/events/${id}/ranking`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  factions = {
    /**
     * @description Retrieves the collection of Faction resources.
     *
     * @tags Faction
     * @name ApiFactionsGetCollection
     * @summary Retrieves the collection of Faction resources.
     * @request GET:/factions
     * @secure
     */
    apiFactionsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'translations.name'?: string;
        'translations.locale'?: string;
        'translations.locale[]'?: string[];
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
        'order[id]'?: 'asc' | 'desc';
        'order[reference]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': FactionJsonldFactionRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/factions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Faction resource.
     *
     * @tags Faction
     * @name ApiFactionsReferenceGet
     * @summary Retrieves a Faction resource.
     * @request GET:/factions/{reference}
     * @secure
     */
    apiFactionsReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<FactionJsonldFactionRead, void>({
        path: `/factions/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  gameTypes = {
    /**
     * @description Retrieves the collection of GameType resources.
     *
     * @tags GameType
     * @name ApiGameTypesGetCollection
     * @summary Retrieves the collection of GameType resources.
     * @request GET:/game_types
     * @secure
     */
    apiGameTypesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': GameTypeJsonldGameTypeRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/game_types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a GameType resource.
     *
     * @tags GameType
     * @name ApiGameTypesIdGet
     * @summary Retrieves a GameType resource.
     * @request GET:/game_types/{id}
     * @secure
     */
    apiGameTypesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<GameTypeJsonldGameTypeRead, void>({
        path: `/game_types/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  gameUsers = {
    /**
     * @description Retrieves the collection of GameUser resources.
     *
     * @tags GameUser
     * @name ApiGameUsersGetCollection
     * @summary Retrieves the collection of GameUser resources.
     * @request GET:/game_users
     * @secure
     */
    apiGameUsersGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
        id?: string;
        'id[]'?: string[];
        game?: string;
        'game[]'?: string[];
        'user.id'?: string;
        'user.id[]'?: string[];
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'groups[]'?: ('game:read' | 'user:read' | 'faction:read')[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': GameUserJsonldGameUserRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/game_users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a GameUser resource.
     *
     * @tags GameUser
     * @name ApiGameUsersPost
     * @summary Creates a GameUser resource.
     * @request POST:/game_users
     * @secure
     */
    apiGameUsersPost: (data: GameUserJsonldGameWriteGameUserWrite, params: RequestParams = {}) =>
      this.request<GameUserJsonldGameUserRead, void>({
        path: `/game_users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a GameUser resource.
     *
     * @tags GameUser
     * @name ApiGameUsersIdGet
     * @summary Retrieves a GameUser resource.
     * @request GET:/game_users/{id}
     * @secure
     */
    apiGameUsersIdGet: (id: string, params: RequestParams = {}) =>
      this.request<GameUserJsonldGameUserRead, void>({
        path: `/game_users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the GameUser resource.
     *
     * @tags GameUser
     * @name ApiGameUsersIdPut
     * @summary Replaces the GameUser resource.
     * @request PUT:/game_users/{id}
     * @secure
     */
    apiGameUsersIdPut: (id: string, data: GameUserJsonldGameUserWrite, params: RequestParams = {}) =>
      this.request<GameUserJsonldGameUserRead, void>({
        path: `/game_users/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  games = {
    /**
     * @description Retrieves the collection of Game resources.
     *
     * @tags Game
     * @name ApiGamesGetCollection
     * @summary Retrieves the collection of Game resources.
     * @request GET:/games
     * @secure
     */
    apiGamesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        'gameType.id'?: string;
        'gameType.id[]'?: string[];
        'event.id'?: string;
        'event.id[]'?: string[];
        'cardSet.id'?: string;
        'cardSet.id[]'?: string[];
        'gameUsers.user.id'?: string;
        'gameUsers.user.id[]'?: string[];
        'order[dateTime]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'dateTime[before]'?: string;
        'dateTime[strictly_before]'?: string;
        'dateTime[after]'?: string;
        'dateTime[strictly_after]'?: string;
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
        'groups[]'?: (
          | 'user:read'
          | 'event:read'
          | 'game_type:read'
          | 'game_user:read'
          | 'card_set:read'
          | 'faction:read'
          | 'event_type:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': GameJsonldGameRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/games`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a Game resource.
     *
     * @tags Game
     * @name ApiGamesPost
     * @summary Creates a Game resource.
     * @request POST:/games
     * @secure
     */
    apiGamesPost: (data: GameJsonldGameWriteGameUserWrite, params: RequestParams = {}) =>
      this.request<GameJsonldGameRead, void>({
        path: `/games`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of Game resources.
     *
     * @tags Game
     * @name ApiGamesexportGetCollection
     * @summary Get a CSV file of Games data.
     * @request GET:/games/export
     * @secure
     */
    apiGamesexportGetCollection: (
      query?: {
        id?: string;
        'id[]'?: string[];
        'gameType.id'?: string;
        'gameType.id[]'?: string[];
        'event.id'?: string;
        'event.id[]'?: string[];
        'cardSet.id'?: string;
        'cardSet.id[]'?: string[];
        'gameUsers.user.id'?: string;
        'gameUsers.user.id[]'?: string[];
        'order[dateTime]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'dateTime[before]'?: string;
        'dateTime[strictly_before]'?: string;
        'dateTime[after]'?: string;
        'dateTime[strictly_after]'?: string;
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
        'groups[]'?: (
          | 'user:read'
          | 'event:read'
          | 'game_type:read'
          | 'game_user:read'
          | 'card_set:read'
          | 'faction:read'
          | 'event_type:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': GameJsonldGameRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/games/export`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Game resource.
     *
     * @tags Game
     * @name ApiGamesIdGet
     * @summary Retrieves a Game resource.
     * @request GET:/games/{id}
     * @secure
     */
    apiGamesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<GameJsonldGameRead, void>({
        path: `/games/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the Game resource.
     *
     * @tags Game
     * @name ApiGamesIdPut
     * @summary Replaces the Game resource.
     * @request PUT:/games/{id}
     * @secure
     */
    apiGamesIdPut: (id: string, data: GameJsonldGameWrite, params: RequestParams = {}) =>
      this.request<GameJsonldGameRead, void>({
        path: `/games/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  illustrationStatuses = {
    /**
     * @description Retrieves the collection of IllustrationStatus resources.
     *
     * @tags IllustrationStatus
     * @name ApiIllustrationStatusesGetCollection
     * @summary Retrieves the collection of IllustrationStatus resources.
     * @request GET:/illustration_statuses
     * @secure
     */
    apiIllustrationStatusesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': IllustrationStatusJsonldIllustrationStatusRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/illustration_statuses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a IllustrationStatus resource.
     *
     * @tags IllustrationStatus
     * @name ApiIllustrationStatusesIdGet
     * @summary Retrieves a IllustrationStatus resource.
     * @request GET:/illustration_statuses/{id}
     * @secure
     */
    apiIllustrationStatusesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<IllustrationStatusJsonldIllustrationStatusRead, void>({
        path: `/illustration_statuses/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  illustrationTypes = {
    /**
     * @description Retrieves the collection of IllustrationType resources.
     *
     * @tags IllustrationType
     * @name ApiIllustrationTypesGetCollection
     * @summary Retrieves the collection of IllustrationType resources.
     * @request GET:/illustration_types
     * @secure
     */
    apiIllustrationTypesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': IllustrationTypeJsonldIllustrationTypeRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/illustration_types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a IllustrationType resource.
     *
     * @tags IllustrationType
     * @name ApiIllustrationTypesIdGet
     * @summary Retrieves a IllustrationType resource.
     * @request GET:/illustration_types/{id}
     * @secure
     */
    apiIllustrationTypesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<IllustrationTypeJsonldIllustrationTypeRead, void>({
        path: `/illustration_types/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  illustrations = {
    /**
     * @description Retrieves the collection of Illustration resources.
     *
     * @tags Illustration
     * @name ApiIllustrationsGetCollection
     * @summary Retrieves the collection of Illustration resources.
     * @request GET:/illustrations
     * @secure
     */
    apiIllustrationsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'illustrationType.reference'?: string;
        'illustrationType.reference[]'?: string[];
        'illustrationStatus.reference'?: string;
        'illustrationStatus.reference[]'?: string[];
        'order[reference]'?: 'asc' | 'desc';
        'groups[]'?: (
          | 'faction:read'
          | 'illustration_lore_entry:read'
          | 'lore_entry:read'
          | 'lore_entry_lore_entry_element:read'
          | 'rarity:read'
          | 'user:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': IllustrationJsonldIllustrationRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/illustrations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Illustration resource.
     *
     * @tags Illustration
     * @name ApiIllustrationsIdGet
     * @summary Retrieves a Illustration resource.
     * @request GET:/illustrations/{id}
     * @secure
     */
    apiIllustrationsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<IllustrationJsonldIllustrationRead, void>({
        path: `/illustrations/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  languages = {
    /**
     * @description Retrieves the collection of Language resources.
     *
     * @tags Language
     * @name ApiLanguagesGetCollection
     * @summary Retrieves the collection of Language resources.
     * @request GET:/languages
     * @secure
     */
    apiLanguagesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': LanguageJsonldLanguageRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/languages`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of Language resources.
     *
     * @tags Language
     * @name ApiLanguagesallGetCollection
     * @summary Retrieves the collection of Language resources.
     * @request GET:/languages/all
     * @secure
     */
    apiLanguagesallGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': LanguageJsonldLanguageRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/languages/all`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Language resource.
     *
     * @tags Language
     * @name ApiLanguagesIdGet
     * @summary Retrieves a Language resource.
     * @request GET:/languages/{id}
     * @secure
     */
    apiLanguagesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<LanguageJsonldLanguageRead, void>({
        path: `/languages/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  logQrAccess = {
    /**
     * @description Creates a LogQrAccess resource.
     *
     * @tags LogQrAccess
     * @name ApiLogQrAccessPost
     * @summary Creates a LogQrAccess resource.
     * @request POST:/log_qr_access
     * @secure
     */
    apiLogQrAccessPost: (data: LogQrAccessJsonldLogQrWrite, params: RequestParams = {}) =>
      this.request<LogQrAccessJsonldLogQrRead, void>({
        path: `/log_qr_access`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  login = {
    /**
     * No description
     *
     * @tags Login Check
     * @name LoginCheckPost
     * @summary Creates a user token.
     * @request POST:/login
     * @secure
     */
    loginCheckPost: (
      data: {
        email: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          token: string;
          refresh_token?: string;
        },
        any
      >({
        path: `/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  loreEntries = {
    /**
     * @description Retrieves a LoreEntry resource.
     *
     * @tags LoreEntry
     * @name ApiLoreEntriesIdGet
     * @summary Retrieves a LoreEntry resource.
     * @request GET:/lore_entries/{id}
     * @secure
     */
    apiLoreEntriesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<LoreEntryJsonldLoreEntryRead, void>({
        path: `/lore_entries/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  loreEntryElementTypes = {
    /**
     * @description Retrieves a LoreEntryElementType resource.
     *
     * @tags LoreEntryElementType
     * @name ApiLoreEntryElementTypesIdGet
     * @summary Retrieves a LoreEntryElementType resource.
     * @request GET:/lore_entry_element_types/{id}
     * @secure
     */
    apiLoreEntryElementTypesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<LoreEntryElementTypeJsonldLoreEntryElementTypeRead, void>({
        path: `/lore_entry_element_types/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  loreEntryElements = {
    /**
     * @description Retrieves a LoreEntryElement resource.
     *
     * @tags LoreEntryElement
     * @name ApiLoreEntryElementsIdGet
     * @summary Retrieves a LoreEntryElement resource.
     * @request GET:/lore_entry_elements/{id}
     * @secure
     */
    apiLoreEntryElementsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<LoreEntryElementJsonldLoreEntryElementRead, void>({
        path: `/lore_entry_elements/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  loreEntryStatuses = {
    /**
     * @description Retrieves a LoreEntryStatus resource.
     *
     * @tags LoreEntryStatus
     * @name ApiLoreEntryStatusesIdGet
     * @summary Retrieves a LoreEntryStatus resource.
     * @request GET:/lore_entry_statuses/{id}
     * @secure
     */
    apiLoreEntryStatusesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<LoreEntryStatusJsonldLoreEntryStatusRead, void>({
        path: `/lore_entry_statuses/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  loreEntryTypes = {
    /**
     * @description Retrieves a LoreEntryType resource.
     *
     * @tags LoreEntryType
     * @name ApiLoreEntryTypesIdGet
     * @summary Retrieves a LoreEntryType resource.
     * @request GET:/lore_entry_types/{id}
     * @secure
     */
    apiLoreEntryTypesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<LoreEntryTypeJsonldLoreEntryTypeRead, void>({
        path: `/lore_entry_types/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  manufactures = {
    /**
     * @description Retrieves the collection of CardManufacture resources.
     *
     * @tags CardManufacture
     * @name ApiManufacturesReferencecardsGetCollection
     * @summary Get Cards by manufacture
     * @request GET:/manufactures/{reference}/cards
     * @secure
     */
    apiManufacturesReferencecardsGetCollection: (
      reference: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardManufactureCardJsonldManufactureRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/manufactures/${reference}/cards`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  marketplace = {
    /**
     * @description Add a Card to Sylius.
     *
     * @tags MarketplaceOffer
     * @name ApiMarketplaceaddPost
     * @summary Add a Card to Sylius
     * @request POST:/marketplace/add
     * @secure
     */
    apiMarketplaceaddPost: (
      data?: {
        cards?: {
          /** @example "YZ_1" */
          reference?: string;
          /** @example "3" */
          quantity?: number;
          /** @example "3.5" */
          price?: number;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<MarketplaceOfferJsonld, void>({
        path: `/marketplace/add`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a Cart with address and payment method and process to its checkout.
     *
     * @tags Marketplace Order
     * @name ApiMarketplaceordercheckoutPost
     * @summary Process Cart Checkout
     * @request POST:/marketplace/order/checkout
     * @secure
     */
    apiMarketplaceordercheckoutPost: (
      data?: {
        /** @example "/addresses/XXXXXXX" */
        shippingAddress?: string;
        /** @example "/addresses/XXXXXXX" */
        billingAddress?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example "/owner_history_groups/xxxxxxxx" */
          ownerHistoryGroup?: string;
        },
        void
      >({
        path: `/marketplace/order/checkout`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create an Order.
     *
     * @tags Marketplace Order
     * @name ApiMarketplaceordercreatePost
     * @summary Create an Order
     * @request POST:/marketplace/order/create
     * @secure
     */
    apiMarketplaceordercreatePost: (
      data?: {
        quantity?: number;
        offerGroupId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/marketplace/order/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves a MarketplaceOrder resource.
     *
     * @tags Marketplace Order
     * @name ApiMarketplaceordermyCartGet
     * @summary Get the current cart
     * @request GET:/marketplace/order/my-cart
     * @secure
     */
    apiMarketplaceordermyCartGet: (params: RequestParams = {}) =>
      this.request<MarketplaceOrderJsonldMarketplaceOrderDetailRead, void>({
        path: `/marketplace/order/my-cart`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the MarketplaceOrder resource.
     *
     * @tags Marketplace Order
     * @name ApiMarketplaceordermyCartDelete
     * @summary Delete the current Cart
     * @request DELETE:/marketplace/order/my-cart
     * @secure
     */
    apiMarketplaceordermyCartDelete: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/marketplace/order/my-cart`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Removes the MarketplaceOrder resource.
     *
     * @tags Marketplace Order
     * @name ApiMarketplaceordermyCartofferGroupOfferGroupIdDelete
     * @summary Delete the offer group id in the current cart
     * @request DELETE:/marketplace/order/my-cart/offer-group/{offerGroupId}
     * @secure
     */
    apiMarketplaceordermyCartofferGroupOfferGroupIdDelete: (offerGroupId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/marketplace/order/my-cart/offer-group/${offerGroupId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieves a MarketplaceTransaction resource.
     *
     * @tags MarketplaceTransaction
     * @name ApiMarketplacetransactionIdGet
     * @summary Get Marketplace Transaction Detail
     * @request GET:/marketplace/transaction/{id}
     * @secure
     */
    apiMarketplacetransactionIdGet: (id: string, params: RequestParams = {}) =>
      this.request<MarketplaceTransactionMarketplaceOrderJsonldMarketplaceOrderDetailRead, void>({
        path: `/marketplace/transaction/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of MarketplaceTransaction resources.
     *
     * @tags MarketplaceTransaction
     * @name ApiMarketplacetransactionsGetCollection
     * @summary Get Marketplace transaction
     * @request GET:/marketplace/transactions
     * @secure
     */
    apiMarketplacetransactionsGetCollection: (
      query?: {
        /** Filter by transaction.minDate on Transactions */
        'transaction.minDate'?: 'asc' | 'desc';
        /** Filter by transaction.maxDate on Transactions */
        'transaction.maxDate'?: 'asc' | 'desc';
        /** Filter by transaction.minPrice on Transactions */
        'transaction.minPrice'?: 'asc' | 'desc';
        /** Filter by transaction.maxPrice on Transactions */
        'transaction.maxPrice'?: 'asc' | 'desc';
        /** Filter by transaction.transactionTypes on Transactions */
        'transaction.transactionTypes'?: 'sale' | 'purchase';
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': MarketplaceTransactionMarketplaceOrderJsonldMarketplaceOrderRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/marketplace/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update price to an Offer.
     *
     * @tags MarketplaceOffer
     * @name ApiMarketplaceGroupIdPut
     * @summary Update price to an Offer
     * @request PUT:/marketplace/{groupId}
     * @secure
     */
    apiMarketplaceGroupIdPut: (
      groupId: string,
      data?: {
        price?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/marketplace/${groupId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Delete an Offer.
     *
     * @tags MarketplaceOffer
     * @name ApiMarketplaceGroupIdDelete
     * @summary Delete an Offer
     * @request DELETE:/marketplace/{groupId}
     * @secure
     */
    apiMarketplaceGroupIdDelete: (groupId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/marketplace/${groupId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  me = {
    /**
     * @description Return the connected user.
     *
     * @tags User
     * @name ApiMeGet
     * @summary Get the connected User
     * @request GET:/me
     * @secure
     */
    apiMeGet: (params: RequestParams = {}) =>
      this.request<UserJsonldUserReadUserPrivateRead, void>({
        path: `/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  media = {
    /**
     * @description Creates a Media resource.
     *
     * @tags Media
     * @name ApiMediaPost
     * @summary Creates a Media resource.
     * @request POST:/media
     * @secure
     */
    apiMediaPost: (
      data?: {
        /** @format binary */
        file?: File;
        type?: 'shop_logo' | 'shop_background' | 'shop_photo' | 'shop_inc_certificate';
      },
      params: RequestParams = {},
    ) =>
      this.request<MediaJsonldMediaRead, void>({
        path: `/media`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Media resource.
     *
     * @tags Media
     * @name ApiMediaIdGet
     * @summary Retrieves a Media resource.
     * @request GET:/media/{id}
     * @secure
     */
    apiMediaIdGet: (id: string, params: RequestParams = {}) =>
      this.request<MediaJsonldMediaRead, void>({
        path: `/media/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the Media resource.
     *
     * @tags Media
     * @name ApiMediaIdDelete
     * @summary Removes the Media resource.
     * @request DELETE:/media/{id}
     * @secure
     */
    apiMediaIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/media/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  memberLogged = {
    /**
     * @description Return the user connected from Shop.
     *
     * @tags User
     * @name ApiMemberLoggedGet
     * @summary Get the user connected from Shop
     * @request GET:/member_logged
     * @secure
     */
    apiMemberLoggedGet: (params: RequestParams = {}) =>
      this.request<UserJsonldUserReadUserPrivateRead, void>({
        path: `/member_logged`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  notificationParameterUsers = {
    /**
     * @description Retrieves the collection of NotificationParameterUser resources.
     *
     * @tags NotificationParameterUser
     * @name ApiNotificationParameterUsersGetCollection
     * @summary Get all the notification parameters for the connected user
     * @request GET:/notification_parameter_users
     * @secure
     */
    apiNotificationParameterUsersGetCollection: (params: RequestParams = {}) =>
      this.request<
        {
          'hydra:member': NotificationParameterUserJsonldNotificationParameterUserRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/notification_parameter_users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a NotificationParameterUser resource.
     *
     * @tags NotificationParameterUser
     * @name ApiNotificationParameterUsersPost
     * @summary Save the notification parameters
     * @request POST:/notification_parameter_users
     * @secure
     */
    apiNotificationParameterUsersPost: (data: NotificationParameterUserJsonld, params: RequestParams = {}) =>
      this.request<NotificationParameterUserJsonldNotificationParameterUserRead, void>({
        path: `/notification_parameter_users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  notificationParameters = {
    /**
     * @description Retrieves the collection of NotificationParameter resources.
     *
     * @tags NotificationParameter
     * @name ApiNotificationParametersGetCollection
     * @summary Get all the notification parameters
     * @request GET:/notification_parameters
     * @secure
     */
    apiNotificationParametersGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': NotificationParameterJsonldNotificationParameterRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/notification_parameters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  notifications = {
    /**
     * @description Retrieves the collection of Notification resources.
     *
     * @tags Notification
     * @name ApiNotificationsGetCollection
     * @summary Get all the user notifications
     * @request GET:/notifications
     * @secure
     */
    apiNotificationsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': NotificationJsonldNotificationRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/notifications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the Notification resource.
     *
     * @tags Notification
     * @name ApiNotificationsmarkAsReadPut
     * @summary Put all user notification on read
     * @request PUT:/notifications/mark-as-read
     * @secure
     */
    apiNotificationsmarkAsReadPut: (data?: any, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/notifications/mark-as-read`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves a Notification resource.
     *
     * @tags Notification
     * @name ApiNotificationsunreadGet
     * @summary Get the count of unread notification
     * @request GET:/notifications/unread
     * @secure
     */
    apiNotificationsunreadGet: (params: RequestParams = {}) =>
      this.request<
        {
          unread?: number;
        },
        any
      >({
        path: `/notifications/unread`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  optins = {
    /**
     * @description Retrieves the collection of Optin resources.
     *
     * @tags Optin
     * @name ApiOptinsGetCollection
     * @summary Retrieves the collection of Optin resources.
     * @request GET:/optins
     * @secure
     */
    apiOptinsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        'userProfiles.reference'?: string;
        'userProfiles.reference[]'?: string[];
        'countries.reference'?: string;
        'countries.reference[]'?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': OptinJsonldOptinRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/optins`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Optin resource.
     *
     * @tags Optin
     * @name ApiOptinsReferenceGet
     * @summary Retrieves a Optin resource.
     * @request GET:/optins/{reference}
     * @secure
     */
    apiOptinsReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<OptinJsonldOptinRead, void>({
        path: `/optins/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  ownerExchangeRequests = {
    /**
     * @description Retrieves the collection of OwnerExchangeRequest resources.
     *
     * @tags OwnerExchangeRequest
     * @name ApiOwnerExchangeRequestsGetCollection
     * @summary Retrieves the collection of OwnerExchangeRequest resources.
     * @request GET:/owner_exchange_requests
     * @secure
     */
    apiOwnerExchangeRequestsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        isActive?: boolean;
        'isActive[]'?: boolean[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': OwnerExchangeRequestJsonldOwnerExchangeRequestRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/owner_exchange_requests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a OwnerExchangeRequest resource.
     *
     * @tags OwnerExchangeRequest
     * @name ApiOwnerExchangeRequestsPost
     * @summary Creates a OwnerExchangeRequest resource.
     * @request POST:/owner_exchange_requests
     * @secure
     */
    apiOwnerExchangeRequestsPost: (
      data: OwnerExchangeRequestJsonldOwnerExchangeRequestWrite,
      params: RequestParams = {},
    ) =>
      this.request<OwnerExchangeRequestJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead, void>({
        path: `/owner_exchange_requests`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a OwnerExchangeRequest resource.
     *
     * @tags OwnerExchangeRequest
     * @name ApiOwnerExchangeRequestsIdGet
     * @summary Retrieves a OwnerExchangeRequest resource.
     * @request GET:/owner_exchange_requests/{id}
     * @secure
     */
    apiOwnerExchangeRequestsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<OwnerExchangeRequestJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead, void>({
        path: `/owner_exchange_requests/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the OwnerExchangeRequest resource.
     *
     * @tags OwnerExchangeRequest
     * @name ApiOwnerExchangeRequestsIdPut
     * @summary Replaces the OwnerExchangeRequest resource.
     * @request PUT:/owner_exchange_requests/{id}
     * @secure
     */
    apiOwnerExchangeRequestsIdPut: (
      id: string,
      data: OwnerExchangeRequestJsonldOwnerExchangeRequestWrite,
      params: RequestParams = {},
    ) =>
      this.request<OwnerExchangeRequestJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead, void>({
        path: `/owner_exchange_requests/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the OwnerExchangeRequest resource.
     *
     * @tags OwnerExchangeRequest
     * @name ApiOwnerExchangeRequestsIdDelete
     * @summary Removes the OwnerExchangeRequest resource.
     * @request DELETE:/owner_exchange_requests/{id}
     * @secure
     */
    apiOwnerExchangeRequestsIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/owner_exchange_requests/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Replaces the OwnerExchangeRequest resource.
     *
     * @tags OwnerExchangeRequest
     * @name ApiOwnerExchangeRequestsIdacceptPut
     * @summary Replaces the OwnerExchangeRequest resource.
     * @request PUT:/owner_exchange_requests/{id}/accept
     * @secure
     */
    apiOwnerExchangeRequestsIdacceptPut: (
      id: string,
      data: OwnerExchangeRequestJsonldOwnerExchangeRequestWrite,
      params: RequestParams = {},
    ) =>
      this.request<OwnerExchangeRequestJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead, void>({
        path: `/owner_exchange_requests/${id}/accept`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the OwnerExchangeRequest resource.
     *
     * @tags OwnerExchangeRequest
     * @name ApiOwnerExchangeRequestsIdcancelPut
     * @summary Replaces the OwnerExchangeRequest resource.
     * @request PUT:/owner_exchange_requests/{id}/cancel
     * @secure
     */
    apiOwnerExchangeRequestsIdcancelPut: (
      id: string,
      data: OwnerExchangeRequestJsonldOwnerExchangeRequestWrite,
      params: RequestParams = {},
    ) =>
      this.request<OwnerExchangeRequestJsonldOwnerExchangeRequestReadOwnerExchangeRequestDetailRead, void>({
        path: `/owner_exchange_requests/${id}/cancel`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  ownerHistoryGroups = {
    /**
     * @description Retrieves the collection of OwnerHistoryGroup resources.
     *
     * @tags OwnerHistoryGroup
     * @name ApiOwnerHistoryGroupsIdGetCollection
     * @summary Retrieves the collection of OwnerHistoryGroup resources.
     * @request GET:/owner_history_groups/{id}
     * @secure
     */
    apiOwnerHistoryGroupsIdGetCollection: (
      id: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': OwnerHistoryGroupJsonldOwnerHistoryGroupRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/owner_history_groups/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  owners = {
    /**
     * @description Creates a Owner resource.
     *
     * @tags Owner
     * @name ApiOwnersPost
     * @summary Creates a Owner resource.
     * @request POST:/owners
     * @secure
     */
    apiOwnersPost: (data: OwnerJsonldOwnerWrite, params: RequestParams = {}) =>
      this.request<OwnerJsonldOwnerRead, void>({
        path: `/owners`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of Owner resources.
     *
     * @tags Owner
     * @name ApiOwnersavailableGetCollection
     * @summary Retrieves the collection of Owner resources.
     * @request GET:/owners/available
     * @secure
     */
    apiOwnersavailableGetCollection: (
      query?: {
        id?: string;
        'id[]'?: string[];
        user?: string;
        'user[]'?: string[];
        'ownership.card.reference'?: string;
        'ownership.card.reference[]'?: string[];
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
        'groups[]'?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': OwnerJsonldOwnerReadUserRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/owners/available`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Give a quantity of ownerships of a Card to an User.
     *
     * @tags Owner
     * @name ApiOwnersgivePost
     * @summary Give ownerships of a Card
     * @request POST:/owners/give
     * @secure
     */
    apiOwnersgivePost: (
      data?: {
        /** @example "/users/01GWKQNC4M55D3EWVFGCFJ5SB7" */
        user?: string;
        cards?: {
          /** @example "YZ_1" */
          reference?: string;
          /** @example "3" */
          quantity?: number;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example "/owner_history_groups/xxxxxxxx" */
          ownerHistoryGroup?: string;
        },
        void
      >({
        path: `/owners/give`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Claim a list of ownerships
     *
     * @tags Owner
     * @name ApiOwnersmultiClaimPost
     * @summary Claim Multiple ownerships
     * @request POST:/owners/multi-claim
     * @secure
     */
    apiOwnersmultiClaimPost: (data?: string[], params: RequestParams = {}) =>
      this.request<OwnerOwnerClaimMultiJsonldOwnerClaimMultiRead, void>({
        path: `/owners/multi-claim`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Transfer list of ownerships of a Card to the current User.
     *
     * @tags Owner
     * @name ApiOwnerstransferPost
     * @summary Transfer ownerships of a Card
     * @request POST:/owners/transfer
     * @secure
     */
    apiOwnerstransferPost: (
      data?: {
        /** @example "["357f5e58-c5de-4d99-b6a3-320507072399","25510413-32c9-4bdc-887b-421b382c1ffd"]" */
        ownerships?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/owners/transfer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get the number of copy available.
     *
     * @tags Owner
     * @name ApiOwnersCardRefavailableGet
     * @summary Get the number of copy available.
     * @request GET:/owners/{cardRef}/available
     * @secure
     */
    apiOwnersCardRefavailableGet: (cardRef: string, params: RequestParams = {}) =>
      this.request<OwnerJsonldMarketplaceRead, void>({
        path: `/owners/${cardRef}/available`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Owner resource.
     *
     * @tags Owner
     * @name ApiOwnersIdGet
     * @summary Retrieves a Owner resource.
     * @request GET:/owners/{id}
     * @secure
     */
    apiOwnersIdGet: (id: string, params: RequestParams = {}) =>
      this.request<OwnerJsonldOwnerRead, void>({
        path: `/owners/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the Owner resource.
     *
     * @tags Owner
     * @name ApiOwnersIdavailablePut
     * @summary Replaces the Owner resource.
     * @request PUT:/owners/{id}/available
     * @secure
     */
    apiOwnersIdavailablePut: (id: string, data: OwnerJsonldOwnerWrite, params: RequestParams = {}) =>
      this.request<OwnerJsonldOwnerRead, void>({
        path: `/owners/${id}/available`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the Owner resource.
     *
     * @tags Owner
     * @name ApiOwnersIdinSalePut
     * @summary Replaces the Owner resource.
     * @request PUT:/owners/{id}/in_sale
     * @secure
     */
    apiOwnersIdinSalePut: (id: string, data: OwnerJsonldOwnerWrite, params: RequestParams = {}) =>
      this.request<OwnerJsonldOwnerRead, void>({
        path: `/owners/${id}/in_sale`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  ownershipLists = {
    /**
     * @description Replaces the OwnershipListData resource.
     *
     * @tags Ownership List
     * @name ApiOwnershipListsListNamePut
     * @summary Add Cards to a OwnershipList
     * @request PUT:/ownership_lists/{listName}
     * @secure
     */
    apiOwnershipListsListNamePut: (
      listName: string,
      data: OwnershipListDataJsonldOwnershipListWrite,
      params: RequestParams = {},
    ) =>
      this.request<OwnershipListDataJsonldOwnershipListRead, void>({
        path: `/ownership_lists/${listName}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of Card resources.
     *
     * @tags Ownership List
     * @name ApiOwnershipListsListNameusersUserGetCollection
     * @summary Add Cards to a OwnershipList
     * @request GET:/ownership_lists/{listName}/users/{user}
     * @secure
     */
    apiOwnershipListsListNameusersUserGetCollection: (
      listName: string,
      user: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        factions?: string;
        'factions[]'?: string[];
        'factions.reference'?: string;
        'factions.reference[]'?: string[];
        cardType?: string;
        'cardType[]'?: string[];
        'cardType.reference'?: string;
        'cardType.reference[]'?: string[];
        cardSubTypes?: string;
        'cardSubTypes[]'?: string[];
        cardSet?: string;
        'cardSet[]'?: string[];
        'cardSet.reference'?: string;
        'cardSet.reference[]'?: string[];
        rarity?: string;
        'rarity[]'?: string[];
        'rarity.reference'?: string;
        'rarity.reference[]'?: string[];
        'translations.name'?: string;
        'cardProduct.reference'?: string;
        'cardProduct.reference[]'?: string[];
        'order[reference]'?: 'asc' | 'desc';
        'order[translations.name]'?: 'asc' | 'desc';
        'order[cardType.reference]'?: 'asc' | 'desc';
        'order[cardSubType.reference]'?: 'asc' | 'desc';
        'order[parent.reference]'?: 'asc' | 'desc';
        'order[cardSet.reference]'?: 'asc' | 'desc';
        'order[rarity.sequence]'?: 'asc' | 'desc';
        'order[factions.reference]'?: 'asc' | 'desc';
        'order[cardUserListCards.createdAt]'?: 'asc' | 'desc';
        /**
         * This filter search a CardElement of type mainCost with value in the array
         * @example "['1', '3']"
         */
        mainCost?: string;
        /**
         * This filter search a CardElement of type recallCost with value in the array
         * @example "['1', '3']"
         */
        recallCost?: string;
        /**
         * This filter search a CardElement of type mountainPower with value in the array
         * @example "['1', '3']"
         */
        mountainPower?: string;
        /**
         * This filter search a CardElement of type oceanPower with value in the array
         * @example "['1', '3']"
         */
        oceanPower?: string;
        /**
         * This filter search a CardElement of type forestPower with value in the array
         * @example "['1', '3']"
         */
        forestPower?: string;
        /**
         * This filter search a CardKeyword of type mainEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        mainEffect?: string;
        /**
         * This filter search a CardKeyword of type echoEffect with value in the array
         * @example "['-', 'X', '1', '3']"
         */
        echoEffect?: string;
        /** Order cards by MAIN COST. */
        'order[mainCost]'?: 'asc' | 'desc';
        /** Order cards by RECALL COST. */
        'order[recallCost]'?: 'asc' | 'desc';
        /** Order cards by Last Added in Collection. */
        'order[collectionLatestAdded]'?: 'asc' | 'desc';
        /**
         * This filter search all the card in the given list name of the current user.
         * @example "wantlist"
         */
        'cardList.name'?: string;
        /**
         * This filter order the card list by sequence
         * @example "ASC | DESC"
         */
        'cardList.order'?: string;
        /** Filter by available in collection */
        collection?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CardJsonldOwnershipListRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/ownership_lists/${listName}/users/${user}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  ownerships = {
    /**
     * @description Retrieves a Ownership resource.
     *
     * @tags Ownership
     * @name ApiOwnershipsIdGet
     * @summary Retrieves a Ownership resource.
     * @request GET:/ownerships/{id}
     * @secure
     */
    apiOwnershipsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<OwnershipJsonldOwnershipRead, void>({
        path: `/ownerships/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  payments = {
    /**
     * @description Retrieves a BankAccount resource.
     *
     * @tags Payment Payout
     * @name ApiPaymentsbankAccountGet
     * @summary Get Bank Account
     * @request GET:/payments/bank_account
     * @secure
     */
    apiPaymentsbankAccountGet: (params: RequestParams = {}) =>
      this.request<BankAccountJsonldBankAccountRead, void>({
        path: `/payments/bank_account`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a BankAccount resource.
     *
     * @tags Payment Payout
     * @name ApiPaymentsbankAccountPost
     * @summary Create Bank Account
     * @request POST:/payments/bank_account
     * @secure
     */
    apiPaymentsbankAccountPost: (data: BankAccountJsonld, params: RequestParams = {}) =>
      this.request<BankAccountJsonldBankAccountRead, void>({
        path: `/payments/bank_account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the BankAccount resource.
     *
     * @tags Payment Payout
     * @name ApiPaymentsbankAccountDelete
     * @summary Deactivate Bank Account
     * @request DELETE:/payments/bank_account
     * @secure
     */
    apiPaymentsbankAccountDelete: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/payments/bank_account`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Creates a CardRegistration resource.
     *
     * @tags Payment Card Registration
     * @name ApiPaymentscardRegistrationPost
     * @summary Create Card Resource
     * @request POST:/payments/card-registration
     * @secure
     */
    apiPaymentscardRegistrationPost: (data: CardRegistrationJsonldPaymentCardPostWrite, params: RequestParams = {}) =>
      this.request<CardRegistrationJsonldPaymentPaymentCardRead, void>({
        path: `/payments/card-registration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the CardRegistration resource.
     *
     * @tags Payment Card Registration
     * @name ApiPaymentscardRegistrationIdPut
     * @summary Update Card Resource
     * @request PUT:/payments/card-registration/{id}
     * @secure
     */
    apiPaymentscardRegistrationIdPut: (
      id: string,
      data: CardRegistrationJsonldPaymentCardPutWrite,
      params: RequestParams = {},
    ) =>
      this.request<CardRegistrationPaymentCardJsonldPaymentPaymentCardRead, void>({
        path: `/payments/card-registration/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of Currency resources.
     *
     * @tags Payment
     * @name ApiPaymentscurrenciesGetCollection
     * @summary Get Currency List
     * @request GET:/payments/currencies
     * @secure
     */
    apiPaymentscurrenciesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': CurrencyJsonldPaymentCurrencyRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/payments/currencies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of KYC resources.
     *
     * @tags Payment KYC
     * @name ApiPaymentskycGetCollection
     * @summary Get KYC status
     * @request GET:/payments/kyc
     * @secure
     */
    apiPaymentskycGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': KYCJsonldPaymentKycRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/payments/kyc`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a KYC resource.
     *
     * @tags Payment KYC
     * @name ApiPaymentskyccreatePost
     * @summary Create KYC Document
     * @request POST:/payments/kyc/create
     * @secure
     */
    apiPaymentskyccreatePost: (data: KYCJsonldPaymentKycWrite, params: RequestParams = {}) =>
      this.request<KYCJsonldPaymentKycRead, void>({
        path: `/payments/kyc/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of PaymentCard resources.
     *
     * @tags Payment Card
     * @name ApiPaymentspaymentCardsGetCollection
     * @summary Get Payment Card List
     * @request GET:/payments/payment-cards
     * @secure
     */
    apiPaymentspaymentCardsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': PaymentCardJsonldPaymentPaymentCardRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/payments/payment-cards`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a PaymentCard resource.
     *
     * @tags Payment Card
     * @name ApiPaymentspaymentCardsExternalIdGet
     * @summary Get Payment Card
     * @request GET:/payments/payment-cards/{externalId}
     * @secure
     */
    apiPaymentspaymentCardsExternalIdGet: (externalId: string, params: RequestParams = {}) =>
      this.request<PaymentCardJsonldPaymentPaymentCardRead, void>({
        path: `/payments/payment-cards/${externalId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the PaymentCard resource.
     *
     * @tags Payment Card
     * @name ApiPaymentspaymentCardsExternalIdDelete
     * @summary Delete Payment Card
     * @request DELETE:/payments/payment-cards/{externalId}
     * @secure
     */
    apiPaymentspaymentCardsExternalIdDelete: (externalId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/payments/payment-cards/${externalId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Replaces the PaymentCard resource.
     *
     * @tags Payment Card
     * @name ApiPaymentspaymentCardsExternalIdpayinPut
     * @summary Create Card Payin
     * @request PUT:/payments/payment-cards/{externalId}/payin
     * @secure
     */
    apiPaymentspaymentCardsExternalIdpayinPut: (
      externalId: string,
      data: PaymentCardJsonldPaymentPaymentCardPayinWrite,
      params: RequestParams = {},
    ) =>
      this.request<PaymentCardCardPayinJsonldPaymentPaymentCardPayinRead, void>({
        path: `/payments/payment-cards/${externalId}/payin`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Payout resource.
     *
     * @tags Payment Payout
     * @name ApiPaymentspayoutGet
     * @summary Webhook Payout
     * @request GET:/payments/payout
     * @secure
     */
    apiPaymentspayoutGet: (params: RequestParams = {}) =>
      this.request<PayoutJsonldPayoutRead, void>({
        path: `/payments/payout`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a Payout resource.
     *
     * @tags Payment Payout
     * @name ApiPaymentspayoutPost
     * @summary Create Payout
     * @request POST:/payments/payout
     * @secure
     */
    apiPaymentspayoutPost: (data: PayoutJsonldPayoutWrite, params: RequestParams = {}) =>
      this.request<PayoutJsonldPayoutRead, void>({
        path: `/payments/payout`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of PaymentPricingGrid resources.
     *
     * @tags Payment Card
     * @name ApiPaymentspricingGridGetCollection
     * @summary Get Payment Pricing Grid
     * @request GET:/payments/pricing-grid
     * @secure
     */
    apiPaymentspricingGridGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': PaymentPricingGridJsonldPaymentPricingGridRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/payments/pricing-grid`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a WalletBalance resource.
     *
     * @tags Payment Wallet
     * @name ApiPaymentswalletsbalanceGet
     * @summary Get Wallet Balance
     * @request GET:/payments/wallets/balance
     * @secure
     */
    apiPaymentswalletsbalanceGet: (params: RequestParams = {}) =>
      this.request<WalletBalanceJsonldPaymentWalletBalanceRead, void>({
        path: `/payments/wallets/balance`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of WalletTransaction resources.
     *
     * @tags Payment Wallet
     * @name ApiPaymentswalletstransactionsGetCollection
     * @summary Get Wallet Transactions
     * @request GET:/payments/wallets/transactions
     * @secure
     */
    apiPaymentswalletstransactionsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': WalletTransactionJsonldPaymentWalletTransactionRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/payments/wallets/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  printing = {
    /**
     * @description Retrieves a Printing resource.
     *
     * @tags Printing
     * @name ApiPrintingprinterInformationsGet
     * @summary Get the printer availaible slots for Order
     * @request GET:/printing/printer_informations
     * @secure
     */
    apiPrintingprinterInformationsGet: (params: RequestParams = {}) =>
      this.request<PrintingJsonldPrintingRead, void>({
        path: `/printing/printer_informations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  printingOrderStatuses = {
    /**
     * @description Retrieves the collection of PrintingOrderStatus resources.
     *
     * @tags PrintingOrderStatus
     * @name ApiPrintingOrderStatusesGetCollection
     * @summary Retrieves the collection of PrintingOrderStatus resources.
     * @request GET:/printing_order_statuses
     * @secure
     */
    apiPrintingOrderStatusesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': PrintingOrderStatusJsonldPrintingOrderStatusRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/printing_order_statuses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  printingOrders = {
    /**
     * @description Retrieves the collection of PrintingOrder resources.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersGetCollection
     * @summary Retrieves the collection of PrintingOrder resources.
     * @request GET:/printing_orders
     * @secure
     */
    apiPrintingOrdersGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        'printingOrderStatus.reference'?: string;
        'printingOrderStatus.reference[]'?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': PrintingOrderJsonldPrintingOrderRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/printing_orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersPost
     * @summary Creates a PrintingOrder resource.
     * @request POST:/printing_orders
     * @secure
     */
    apiPrintingOrdersPost: (data: PrintingOrderJsonldPrintingOrderWrite, params: RequestParams = {}) =>
      this.request<PrintingOrderJsonldPrintingOrderRead, void>({
        path: `/printing_orders`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrderscartamundiinformationsGet
     * @summary Get the information of price
     * @request GET:/printing_orders/cartamundi/informations
     * @secure
     */
    apiPrintingOrderscartamundiinformationsGet: (params: RequestParams = {}) =>
      this.request<PrintingOrderJsonldPrintingOrderRead, void>({
        path: `/printing_orders/cartamundi/informations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of PrintingOrder resources.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrderspartnersinformationsGetCollection
     * @summary Retrieves the collection of PrintingOrder resources.
     * @request GET:/printing_orders/partners/informations
     * @secure
     */
    apiPrintingOrderspartnersinformationsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        'printingOrderStatus.reference'?: string;
        'printingOrderStatus.reference[]'?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': PrintingOrderJsonldPrintingOrderPartnerRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/printing_orders/partners/informations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdGet
     * @summary Retrieves a PrintingOrder resource.
     * @request GET:/printing_orders/{id}
     * @secure
     */
    apiPrintingOrdersIdGet: (id: string, params: RequestParams = {}) =>
      this.request<PrintingOrderJsonldPrintingOrderRead, void>({
        path: `/printing_orders/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdPut
     * @summary Replaces the PrintingOrder resource.
     * @request PUT:/printing_orders/{id}
     * @secure
     */
    apiPrintingOrdersIdPut: (id: string, data: PrintingOrderJsonldPrintingOrderWrite, params: RequestParams = {}) =>
      this.request<PrintingOrderJsonldPrintingOrderRead, void>({
        path: `/printing_orders/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdDelete
     * @summary Removes the PrintingOrder resource.
     * @request DELETE:/printing_orders/{id}
     * @secure
     */
    apiPrintingOrdersIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/printing_orders/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieves a PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdcartGet
     * @summary Get Cart data for Printing Order
     * @request GET:/printing_orders/{id}/cart
     * @secure
     */
    apiPrintingOrdersIdcartGet: (id: string, params: RequestParams = {}) =>
      this.request<PrintingOrderMarketplaceOrderJsonldPrintingPaymentRead, void>({
        path: `/printing_orders/${id}/cart`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Add Checkout Addresses and update POD Language
     *
     * @tags PrintingPayment
     * @name ApiPrintingOrdersIdcheckoutPut
     * @summary Update Printing Order Checkout
     * @request PUT:/printing_orders/{id}/checkout
     * @secure
     */
    apiPrintingOrdersIdcheckoutPut: (
      id: string,
      data?: {
        /** @example "/addresses/XXXXXXX" */
        shippingAddress?: string;
        /** @example "/addresses/XXXXXXX" */
        billingAddress?: string;
        /** @example "/languages/XXXXXXX" */
        language?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PrintingOrderMarketplaceOrderJsonldPrintingPaymentRead, void>({
        path: `/printing_orders/${id}/checkout`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Finalize POD Checkout Process
     *
     * @tags PrintingPayment
     * @name ApiPrintingOrdersIdcheckoutPost
     * @summary Checkout a Printing Order
     * @request POST:/printing_orders/{id}/checkout
     * @secure
     */
    apiPrintingOrdersIdcheckoutPost: (id: string, data: any, params: RequestParams = {}) =>
      this.request<PrintingOrderJsonldPrintingOrderRead, void>({
        path: `/printing_orders/${id}/checkout`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdfinishPost
     * @summary Finish a Printing Order
     * @request POST:/printing_orders/{id}/finish
     * @secure
     */
    apiPrintingOrdersIdfinishPost: (
      id: string,
      data: PrintingOrderJsonldPrintingOrderWrite,
      params: RequestParams = {},
    ) =>
      this.request<PrintingOrderJsonldPrintingOrderRead, void>({
        path: `/printing_orders/${id}/finish`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdkickPost
     * @summary Remove an User from an Order
     * @request POST:/printing_orders/{id}/kick
     * @secure
     */
    apiPrintingOrdersIdkickPost: (
      id: string,
      data?: {
        /** @example "/users/XXXXXXX" */
        user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/printing_orders/${id}/kick`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves a PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdmembersGet
     * @summary Get the member's data for Order
     * @request GET:/printing_orders/{id}/members
     * @secure
     */
    apiPrintingOrdersIdmembersGet: (id: string, params: RequestParams = {}) =>
      this.request<PrintingOrderPrintingOrderMemberListJsonldPrintingOrderRead, void>({
        path: `/printing_orders/${id}/members`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the collection of PrintingOrder resources.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdpricingGridGetCollection
     * @summary Get the pricing grid for Printing Order
     * @request GET:/printing_orders/{id}/pricing_grid
     * @secure
     */
    apiPrintingOrdersIdpricingGridGetCollection: (
      id: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        'printingOrderStatus.reference'?: string;
        'printingOrderStatus.reference[]'?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': PrintingOrderPrintingPricingGridJsonldPrintingRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/printing_orders/${id}/pricing_grid`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdstartedGet
     * @summary Get the Order's data when order is started
     * @request GET:/printing_orders/{id}/started
     * @secure
     */
    apiPrintingOrdersIdstartedGet: (id: string, params: RequestParams = {}) =>
      this.request<PrintingOrderPrintingOrderDetailJsonldPrintingOrderRead, void>({
        path: `/printing_orders/${id}/started`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a PrintingOrder resource.
     *
     * @tags PrintingOrder
     * @name ApiPrintingOrdersIdstartedmembersGet
     * @summary Get the member's data for Order
     * @request GET:/printing_orders/{id}/started/members
     * @secure
     */
    apiPrintingOrdersIdstartedmembersGet: (id: string, params: RequestParams = {}) =>
      this.request<PrintingOrderPrintingOrderMemberListJsonldPrintingOrderRead, void>({
        path: `/printing_orders/${id}/started/members`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update status
     *
     * @tags PrintingPayment
     * @name ApiPrintingOrdersIdstatusPut
     * @summary Update Printing Order Status
     * @request PUT:/printing_orders/{id}/status
     * @secure
     */
    apiPrintingOrdersIdstatusPut: (
      id: string,
      data: PrintingOrderJsonldPrintingOrderStatusWrite,
      params: RequestParams = {},
    ) =>
      this.request<PrintingOrderJsonldPrintingOrderRead, void>({
        path: `/printing_orders/${id}/status`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  printingPageCards = {
    /**
     * @description Creates a PrintingPageCard resource.
     *
     * @tags PrintingPageCard
     * @name ApiPrintingPageCardsPost
     * @summary Creates a PrintingPageCard resource.
     * @request POST:/printing_page_cards
     * @secure
     */
    apiPrintingPageCardsPost: (data: PrintingPageCardJsonldPrintingPageCardWrite, params: RequestParams = {}) =>
      this.request<PrintingPageCardJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead, void>({
        path: `/printing_page_cards`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a PrintingPageCard resource.
     *
     * @tags PrintingPageCard
     * @name ApiPrintingPageCardsIdGet
     * @summary Retrieves a PrintingPageCard resource.
     * @request GET:/printing_page_cards/{id}
     * @secure
     */
    apiPrintingPageCardsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<PrintingPageCardJsonldPrintingPageCardReadPrintingOrderReadPrintingOrderPartnerRead, void>({
        path: `/printing_page_cards/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the PrintingPageCard resource.
     *
     * @tags PrintingPageCard
     * @name ApiPrintingPageCardsIdDelete
     * @summary Removes the PrintingPageCard resource.
     * @request DELETE:/printing_page_cards/{id}
     * @secure
     */
    apiPrintingPageCardsIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/printing_page_cards/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  printingPageTypes = {
    /**
     * @description Retrieves the collection of PrintingPageType resources.
     *
     * @tags PrintingPageType
     * @name ApiPrintingPageTypesGetCollection
     * @summary Retrieves the collection of PrintingPageType resources.
     * @request GET:/printing_page_types
     * @secure
     */
    apiPrintingPageTypesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': PrintingPageTypeJsonldPrintingPageTypeRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/printing_page_types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  printingPages = {
    /**
     * @description Creates a PrintingPage resource.
     *
     * @tags PrintingPage
     * @name ApiPrintingPagesPost
     * @summary Creates a PrintingPage resource.
     * @request POST:/printing_pages
     * @secure
     */
    apiPrintingPagesPost: (data: PrintingPageJsonldPrintingPageWrite, params: RequestParams = {}) =>
      this.request<PrintingPageJsonldPrintingPageReadPrintingOrderPartnerRead, void>({
        path: `/printing_pages`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a PrintingPage resource.
     *
     * @tags PrintingPage
     * @name ApiPrintingPagesIdGet
     * @summary Retrieves a PrintingPage resource.
     * @request GET:/printing_pages/{id}
     * @secure
     */
    apiPrintingPagesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<PrintingPageJsonldPrintingPageReadPrintingOrderPartnerRead, void>({
        path: `/printing_pages/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the PrintingPage resource.
     *
     * @tags PrintingPage
     * @name ApiPrintingPagesIdDelete
     * @summary Removes the PrintingPage resource.
     * @request DELETE:/printing_pages/{id}
     * @secure
     */
    apiPrintingPagesIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/printing_pages/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieves the collection of PrintingPage resources.
     *
     * @tags PrintingPage
     * @name ApiPrintingPagesIdcardsReferenceGetCollection
     * @summary Get the information about possibility of printing a Card in an Order
     * @request GET:/printing_pages/{id}/cards/{reference}
     * @secure
     */
    apiPrintingPagesIdcardsReferenceGetCollection: (
      id: string,
      reference: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': PrintingPagePrintingAuthorizationJsonldPrintingAuthorizationRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/printing_pages/${id}/cards/${reference}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  printings = {
    /**
     * @description List of all Print on demand Transactions.
     *
     * @tags PrintingTransaction
     * @name ApiPrintingstransactionsGetCollection
     * @summary Get PoD transactions
     * @request GET:/printings/transactions
     * @secure
     */
    apiPrintingstransactionsGetCollection: (
      query?: {
        /** Filter by transaction.minDate on Transactions */
        'transaction.minDate'?: 'asc' | 'desc';
        /** Filter by transaction.maxDate on Transactions */
        'transaction.maxDate'?: 'asc' | 'desc';
        /** Filter by transaction.minPrice on Transactions */
        'transaction.minPrice'?: 'asc' | 'desc';
        /** Filter by transaction.maxPrice on Transactions */
        'transaction.maxPrice'?: 'asc' | 'desc';
        /** Filter by transaction.transactionTypes on Transactions */
        'transaction.transactionTypes'?: 'sale' | 'purchase';
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': PrintingTransactionJsonldTransactionReadPrintingReadPrintingTransactionRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/printings/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Detail of Print on Demand transaction.
     *
     * @tags PrintingTransaction
     * @name ApiPrintingstransactionsIdGet
     * @summary Get PoD Transaction detail
     * @request GET:/printings/transactions/{id}
     * @secure
     */
    apiPrintingstransactionsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<PrintingTransactionJsonldTransactionReadPrintingReadPrintingTransactionRead, void>({
        path: `/printings/transactions/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  qrCodes = {
    /**
     * @description Retrieves the collection of QrCode resources.
     *
     * @tags QrCode
     * @name ApiQrCodesGetCollection
     * @summary Retrieves the collection of QrCode resources.
     * @request GET:/qr_codes
     * @secure
     */
    apiQrCodesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        hash?: string;
        'hash[]'?: string[];
        type?: string;
        'type[]'?: string[];
        'manufacture.reference'?: string;
        'manufacture.reference[]'?: string[];
        'ownership.card.reference'?: string;
        'ownership.card.reference[]'?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': QrCodeJsonldQrCodeRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/qr_codes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a QrCode resource.
     *
     * @tags QrCode
     * @name ApiQrCodesIdGet
     * @summary Retrieves a QrCode resource.
     * @request GET:/qr_codes/{id}
     * @secure
     */
    apiQrCodesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<QrCodeJsonldQrCodeRead, void>({
        path: `/qr_codes/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a QrCode resource.
     *
     * @tags QrCode
     * @name ApiQrCodesIdcardGet
     * @summary Get Card from QRCode Hash
     * @request GET:/qr_codes/{id}/card
     * @secure
     */
    apiQrCodesIdcardGet: (id: string, params: RequestParams = {}) =>
      this.request<QrCodeCardJsonldQrCodeRead, void>({
        path: `/qr_codes/${id}/card`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  rarities = {
    /**
     * @description Retrieves the collection of Rarity resources.
     *
     * @tags Rarity
     * @name ApiRaritiesGetCollection
     * @summary Retrieves the collection of Rarity resources.
     * @request GET:/rarities
     * @secure
     */
    apiRaritiesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': RarityJsonldRarityRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/rarities`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Rarity resource.
     *
     * @tags Rarity
     * @name ApiRaritiesReferenceGet
     * @summary Retrieves a Rarity resource.
     * @request GET:/rarities/{reference}
     * @secure
     */
    apiRaritiesReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<RarityJsonldRarityRead, void>({
        path: `/rarities/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  ruleActions = {
    /**
     * @description Retrieves the collection of RuleAction resources.
     *
     * @tags RuleAction
     * @name ApiRuleActionsGetCollection
     * @summary Retrieves the collection of RuleAction resources.
     * @request GET:/rule_actions
     * @secure
     */
    apiRuleActionsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': RuleActionJsonldRuleRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/rule_actions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a RuleAction resource.
     *
     * @tags RuleAction
     * @name ApiRuleActionsPost
     * @summary Creates a RuleAction resource.
     * @request POST:/rule_actions
     * @secure
     */
    apiRuleActionsPost: (data: RuleActionJsonldRuleWrite, params: RequestParams = {}) =>
      this.request<RuleActionJsonldRuleRead, void>({
        path: `/rule_actions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a RuleAction resource.
     *
     * @tags RuleAction
     * @name ApiRuleActionsIdGet
     * @summary Retrieves a RuleAction resource.
     * @request GET:/rule_actions/{id}
     * @secure
     */
    apiRuleActionsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<RuleActionJsonldRuleRead, void>({
        path: `/rule_actions/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the RuleAction resource.
     *
     * @tags RuleAction
     * @name ApiRuleActionsIdPut
     * @summary Replaces the RuleAction resource.
     * @request PUT:/rule_actions/{id}
     * @secure
     */
    apiRuleActionsIdPut: (id: string, data: RuleActionJsonldRuleWrite, params: RequestParams = {}) =>
      this.request<RuleActionJsonldRuleRead, void>({
        path: `/rule_actions/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  ruleConditions = {
    /**
     * @description Retrieves the collection of RuleCondition resources.
     *
     * @tags RuleCondition
     * @name ApiRuleConditionsGetCollection
     * @summary Retrieves the collection of RuleCondition resources.
     * @request GET:/rule_conditions
     * @secure
     */
    apiRuleConditionsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': RuleConditionJsonldRuleRuleRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/rule_conditions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a RuleCondition resource.
     *
     * @tags RuleCondition
     * @name ApiRuleConditionsPost
     * @summary Creates a RuleCondition resource.
     * @request POST:/rule_conditions
     * @secure
     */
    apiRuleConditionsPost: (data: RuleConditionJsonldRuleRuleWrite, params: RequestParams = {}) =>
      this.request<RuleConditionJsonldRuleRuleRead, void>({
        path: `/rule_conditions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a RuleCondition resource.
     *
     * @tags RuleCondition
     * @name ApiRuleConditionsIdGet
     * @summary Retrieves a RuleCondition resource.
     * @request GET:/rule_conditions/{id}
     * @secure
     */
    apiRuleConditionsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<RuleConditionJsonldRuleRuleRead, void>({
        path: `/rule_conditions/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the RuleCondition resource.
     *
     * @tags RuleCondition
     * @name ApiRuleConditionsIdPut
     * @summary Replaces the RuleCondition resource.
     * @request PUT:/rule_conditions/{id}
     * @secure
     */
    apiRuleConditionsIdPut: (id: string, data: RuleConditionJsonldRuleRuleWrite, params: RequestParams = {}) =>
      this.request<RuleConditionJsonldRuleRuleRead, void>({
        path: `/rule_conditions/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  ruleGroupConditions = {
    /**
     * @description Retrieves the collection of RuleGroupCondition resources.
     *
     * @tags RuleGroupCondition
     * @name ApiRuleGroupConditionsGetCollection
     * @summary Retrieves the collection of RuleGroupCondition resources.
     * @request GET:/rule_group_conditions
     * @secure
     */
    apiRuleGroupConditionsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': RuleGroupConditionJsonldRuleRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/rule_group_conditions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a RuleGroupCondition resource.
     *
     * @tags RuleGroupCondition
     * @name ApiRuleGroupConditionsPost
     * @summary Creates a RuleGroupCondition resource.
     * @request POST:/rule_group_conditions
     * @secure
     */
    apiRuleGroupConditionsPost: (data: RuleGroupConditionJsonldRuleWrite, params: RequestParams = {}) =>
      this.request<RuleGroupConditionJsonldRuleRead, void>({
        path: `/rule_group_conditions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a RuleGroupCondition resource.
     *
     * @tags RuleGroupCondition
     * @name ApiRuleGroupConditionsIdGet
     * @summary Retrieves a RuleGroupCondition resource.
     * @request GET:/rule_group_conditions/{id}
     * @secure
     */
    apiRuleGroupConditionsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<RuleGroupConditionJsonldRuleRead, void>({
        path: `/rule_group_conditions/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the RuleGroupCondition resource.
     *
     * @tags RuleGroupCondition
     * @name ApiRuleGroupConditionsIdPut
     * @summary Replaces the RuleGroupCondition resource.
     * @request PUT:/rule_group_conditions/{id}
     * @secure
     */
    apiRuleGroupConditionsIdPut: (id: string, data: RuleGroupConditionJsonldRuleWrite, params: RequestParams = {}) =>
      this.request<RuleGroupConditionJsonldRuleRead, void>({
        path: `/rule_group_conditions/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  ruleProcesses = {
    /**
     * @description Retrieves the collection of RuleProcess resources.
     *
     * @tags RuleProcess
     * @name ApiRuleProcessesGetCollection
     * @summary Retrieves the collection of RuleProcess resources.
     * @request GET:/rule_processes
     * @secure
     */
    apiRuleProcessesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': RuleProcessJsonldRuleRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/rule_processes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a RuleProcess resource.
     *
     * @tags RuleProcess
     * @name ApiRuleProcessesPost
     * @summary Creates a RuleProcess resource.
     * @request POST:/rule_processes
     * @secure
     */
    apiRuleProcessesPost: (data: RuleProcessJsonldRuleWrite, params: RequestParams = {}) =>
      this.request<RuleProcessJsonldRuleRead, void>({
        path: `/rule_processes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a RuleProcess resource.
     *
     * @tags RuleProcess
     * @name ApiRuleProcessesIdGet
     * @summary Retrieves a RuleProcess resource.
     * @request GET:/rule_processes/{id}
     * @secure
     */
    apiRuleProcessesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<RuleProcessJsonldRuleRead, void>({
        path: `/rule_processes/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the RuleProcess resource.
     *
     * @tags RuleProcess
     * @name ApiRuleProcessesIdPut
     * @summary Replaces the RuleProcess resource.
     * @request PUT:/rule_processes/{id}
     * @secure
     */
    apiRuleProcessesIdPut: (id: string, data: RuleProcessJsonldRuleWrite, params: RequestParams = {}) =>
      this.request<RuleProcessJsonldRuleRead, void>({
        path: `/rule_processes/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  rules = {
    /**
     * @description Retrieves the collection of Rule resources.
     *
     * @tags Rule
     * @name ApiRulesGetCollection
     * @summary Retrieves the collection of Rule resources.
     * @request GET:/rules
     * @secure
     */
    apiRulesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        type?: string;
        'type[]'?: string[];
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': RuleJsonldRuleRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/rules`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a Rule resource.
     *
     * @tags Rule
     * @name ApiRulesPost
     * @summary Creates a Rule resource.
     * @request POST:/rules
     * @secure
     */
    apiRulesPost: (data: RuleJsonldRuleWrite, params: RequestParams = {}) =>
      this.request<RuleJsonldRuleRead, void>({
        path: `/rules`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get the parameters needed to create a rule creation / edit form.
     *
     * @tags Rule
     * @name ApiRulesparametersGetCollection
     * @summary Rule Parameters
     * @request GET:/rules/parameters
     * @secure
     */
    apiRulesparametersGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        type?: string;
        'type[]'?: string[];
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': RuleJsonldRuleRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/rules/parameters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Rule resource.
     *
     * @tags Rule
     * @name ApiRulesReferenceGet
     * @summary Retrieves a Rule resource.
     * @request GET:/rules/{reference}
     * @secure
     */
    apiRulesReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<RuleJsonldRuleRead, void>({
        path: `/rules/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the Rule resource.
     *
     * @tags Rule
     * @name ApiRulesReferencePut
     * @summary Replaces the Rule resource.
     * @request PUT:/rules/{reference}
     * @secure
     */
    apiRulesReferencePut: (reference: string, data: RuleJsonldRuleWrite, params: RequestParams = {}) =>
      this.request<RuleJsonldRuleRead, void>({
        path: `/rules/${reference}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Launch a specific asynchronous rule.
     *
     * @tags Rule
     * @name ApiRulesReferencelaunchGet
     * @summary Rule Launch
     * @request GET:/rules/{reference}/launch
     * @secure
     */
    apiRulesReferencelaunchGet: (reference: string, params: RequestParams = {}) =>
      this.request<RuleJsonldRuleRead, void>({
        path: `/rules/${reference}/launch`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Preview asynchronous rule.
     *
     * @tags Rule
     * @name ApiRulesReferencepreviewGet
     * @summary Rule Preview
     * @request GET:/rules/{reference}/preview
     * @secure
     */
    apiRulesReferencepreviewGet: (reference: string, params: RequestParams = {}) =>
      this.request<RuleJsonldRuleRead, void>({
        path: `/rules/${reference}/preview`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  shopDeletionStatuses = {
    /**
     * @description Retrieves the collection of ShopDeletionStatus resources.
     *
     * @tags ShopDeletionStatus
     * @name ApiShopDeletionStatusesGetCollection
     * @summary Retrieves the collection of ShopDeletionStatus resources.
     * @request GET:/shop_deletion_statuses
     * @secure
     */
    apiShopDeletionStatusesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': ShopDeletionStatusJsonldShopDeletionStatusRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/shop_deletion_statuses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a ShopDeletionStatus resource.
     *
     * @tags ShopDeletionStatus
     * @name ApiShopDeletionStatusesIdGet
     * @summary Retrieves a ShopDeletionStatus resource.
     * @request GET:/shop_deletion_statuses/{id}
     * @secure
     */
    apiShopDeletionStatusesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<ShopDeletionStatusJsonldShopDeletionStatusRead, void>({
        path: `/shop_deletion_statuses/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  shopDistributors = {
    /**
     * @description Retrieves the collection of ShopDistributor resources.
     *
     * @tags ShopDistributor
     * @name ApiShopDistributorsGetCollection
     * @summary Retrieves the collection of ShopDistributor resources.
     * @request GET:/shop_distributors
     * @secure
     */
    apiShopDistributorsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': ShopDistributorJsonldShopDistributorRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/shop_distributors`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a ShopDistributor resource.
     *
     * @tags ShopDistributor
     * @name ApiShopDistributorsReferenceGet
     * @summary Retrieves a ShopDistributor resource.
     * @request GET:/shop_distributors/{reference}
     * @secure
     */
    apiShopDistributorsReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<ShopDistributorJsonldShopDistributorRead, void>({
        path: `/shop_distributors/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  shopMembers = {
    /**
     * @description Retrieves the collection of ShopMember resources.
     *
     * @tags ShopMember
     * @name ApiShopMembersGetCollection
     * @summary Retrieves the collection of ShopMember resources.
     * @request GET:/shop_members
     * @secure
     */
    apiShopMembersGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': ShopMemberJsonldShopMemberRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/shop_members`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a ShopMember resource.
     *
     * @tags ShopMember
     * @name ApiShopMembersIdGet
     * @summary Retrieves a ShopMember resource.
     * @request GET:/shop_members/{id}
     * @secure
     */
    apiShopMembersIdGet: (id: string, params: RequestParams = {}) =>
      this.request<ShopMemberJsonldShopMemberRead, void>({
        path: `/shop_members/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the ShopMember resource.
     *
     * @tags ShopMember
     * @name ApiShopMembersIdPut
     * @summary Replaces the ShopMember resource.
     * @request PUT:/shop_members/{id}
     * @secure
     */
    apiShopMembersIdPut: (id: string, data: ShopMemberJsonldShopMemberWrite, params: RequestParams = {}) =>
      this.request<ShopMemberJsonldShopMemberRead, void>({
        path: `/shop_members/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the ShopMember resource.
     *
     * @tags ShopMember
     * @name ApiShopMembersIdDelete
     * @summary Removes the ShopMember resource.
     * @request DELETE:/shop_members/{id}
     * @secure
     */
    apiShopMembersIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/shop_members/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  shopSocialNetworks = {
    /**
     * @description Retrieves the collection of ShopSocialNetwork resources.
     *
     * @tags ShopSocialNetwork
     * @name ApiShopSocialNetworksGetCollection
     * @summary Retrieves the collection of ShopSocialNetwork resources.
     * @request GET:/shop_social_networks
     * @secure
     */
    apiShopSocialNetworksGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': ShopSocialNetworkJsonldShopSocialNetworkRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/shop_social_networks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a ShopSocialNetwork resource.
     *
     * @tags ShopSocialNetwork
     * @name ApiShopSocialNetworksPost
     * @summary Creates a ShopSocialNetwork resource.
     * @request POST:/shop_social_networks
     * @secure
     */
    apiShopSocialNetworksPost: (data: ShopSocialNetworkJsonldShopSocialNetworkWrite, params: RequestParams = {}) =>
      this.request<ShopSocialNetworkJsonldShopSocialNetworkRead, void>({
        path: `/shop_social_networks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a ShopSocialNetwork resource.
     *
     * @tags ShopSocialNetwork
     * @name ApiShopSocialNetworksIdGet
     * @summary Retrieves a ShopSocialNetwork resource.
     * @request GET:/shop_social_networks/{id}
     * @secure
     */
    apiShopSocialNetworksIdGet: (id: string, params: RequestParams = {}) =>
      this.request<ShopSocialNetworkJsonldShopSocialNetworkRead, void>({
        path: `/shop_social_networks/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the ShopSocialNetwork resource.
     *
     * @tags ShopSocialNetwork
     * @name ApiShopSocialNetworksIdPut
     * @summary Replaces the ShopSocialNetwork resource.
     * @request PUT:/shop_social_networks/{id}
     * @secure
     */
    apiShopSocialNetworksIdPut: (
      id: string,
      data: ShopSocialNetworkJsonldShopSocialNetworkWrite,
      params: RequestParams = {},
    ) =>
      this.request<ShopSocialNetworkJsonldShopSocialNetworkRead, void>({
        path: `/shop_social_networks/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  shops = {
    /**
     * @description Retrieves the collection of Shop resources.
     *
     * @tags Shop
     * @name ApiShopsGetCollection
     * @summary Retrieves the collection of Shop resources.
     * @request GET:/shops
     * @secure
     */
    apiShopsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        mobilityReducedAccessibility?: boolean;
        'mobilityReducedAccessibility[]'?: boolean[];
        sanitary?: boolean;
        'sanitary[]'?: boolean[];
        eatingSpace?: boolean;
        'eatingSpace[]'?: boolean[];
        printOnDemand?: boolean;
        'printOnDemand[]'?: boolean[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': ShopJsonldShopRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/shops`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a Shop resource.
     *
     * @tags Shop
     * @name ApiShopsPost
     * @summary Creates a Shop resource.
     * @request POST:/shops
     * @secure
     */
    apiShopsPost: (data: ShopJsonldShopWrite, params: RequestParams = {}) =>
      this.request<ShopJsonldShopRead, void>({
        path: `/shops`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Shop near the location.
     *
     * @tags Shop
     * @name ApiShopslocatorGetCollection
     * @summary Shop Locator
     * @request GET:/shops/locator
     * @secure
     */
    apiShopslocatorGetCollection: (
      query?: {
        /** Filter by coordinate */
        latitude?: number;
        /** Filter by coordinate */
        longitude?: number;
        /** Max distance with latitude and longitude */
        maxDistance?: 10 | 20 | 50 | 100;
        /** Unit system used for calculation */
        unit?: 'meters' | 'miles';
        /** Date from which shops have an event planned */
        from?: number;
        /** Date until which shops have an event planned */
        to?: number;
        /**
         * Country
         * @example ["FR","US"]
         */
        country?: string;
        id?: string;
        'id[]'?: string[];
        mobilityReducedAccessibility?: boolean;
        'mobilityReducedAccessibility[]'?: boolean[];
        sanitary?: boolean;
        'sanitary[]'?: boolean[];
        eatingSpace?: boolean;
        'eatingSpace[]'?: boolean[];
        printOnDemand?: boolean;
        'printOnDemand[]'?: boolean[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': ShopJsonldShopLocatorRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/shops/locator`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Shop resource.
     *
     * @tags Shop
     * @name ApiShopsIdGet
     * @summary Retrieves a Shop resource.
     * @request GET:/shops/{id}
     * @secure
     */
    apiShopsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<
        ShopJsonldShopReadShopMemberReadShopDetailReadUserReadAddressReadCountryReadShopSocialNetworkReadSocialNetworkReadMediaRead,
        void
      >({
        path: `/shops/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the Shop resource.
     *
     * @tags Shop
     * @name ApiShopsIdPut
     * @summary Replaces the Shop resource.
     * @request PUT:/shops/{id}
     * @secure
     */
    apiShopsIdPut: (id: string, data: ShopJsonldShopWrite, params: RequestParams = {}) =>
      this.request<ShopJsonldShopRead, void>({
        path: `/shops/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the Shop resource.
     *
     * @tags Shop
     * @name ApiShopsIdDelete
     * @summary Delete a Shop
     * @request DELETE:/shops/{id}
     * @secure
     */
    apiShopsIdDelete: (
      id: string,
      data?: {
        onDeletionCommentary?: string;
        /** @example "/shop_deletion_statuses/XXXXXX" */
        shopDeletionStatus?: string;
        /** @example "mySuperPassword" */
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/shops/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves a Shop resource.
     *
     * @tags Shop
     * @name ApiShopsIdstatsGet
     * @summary Retrieves a Shop resource.
     * @request GET:/shops/{id}/stats
     * @secure
     */
    apiShopsIdstatsGet: (id: string, params: RequestParams = {}) =>
      this.request<ShopJsonldShopStatRead, void>({
        path: `/shops/${id}/stats`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  socialNetworks = {
    /**
     * @description Retrieves the collection of SocialNetwork resources.
     *
     * @tags SocialNetwork
     * @name ApiSocialNetworksGetCollection
     * @summary Retrieves the collection of SocialNetwork resources.
     * @request GET:/social_networks
     * @secure
     */
    apiSocialNetworksGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        name?: string;
        isConnect?: boolean;
        'isConnect[]'?: boolean[];
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
        'order[id]'?: 'asc' | 'desc';
        'order[reference]'?: 'asc' | 'desc';
        'order[name]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': SocialNetworkJsonldSocialNetworkRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/social_networks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a SocialNetwork resource.
     *
     * @tags SocialNetwork
     * @name ApiSocialNetworksReferenceGet
     * @summary Retrieves a SocialNetwork resource.
     * @request GET:/social_networks/{reference}
     * @secure
     */
    apiSocialNetworksReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<SocialNetworkJsonldSocialNetworkRead, void>({
        path: `/social_networks/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  surveyTypes = {
    /**
     * @description Retrieves the collection of SurveyType resources.
     *
     * @tags SurveyType
     * @name ApiSurveyTypesGetCollection
     * @summary Retrieves the collection of SurveyType resources.
     * @request GET:/survey_types
     * @secure
     */
    apiSurveyTypesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': SurveyTypeJsonldSurveyTypeRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/survey_types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a SurveyType resource.
     *
     * @tags SurveyType
     * @name ApiSurveyTypesIdGet
     * @summary Retrieves a SurveyType resource.
     * @request GET:/survey_types/{id}
     * @secure
     */
    apiSurveyTypesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<SurveyTypeJsonldSurveyTypeRead, void>({
        path: `/survey_types/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  surveys = {
    /**
     * @description Retrieves the collection of Survey resources.
     *
     * @tags Survey
     * @name ApiSurveysGetCollection
     * @summary Retrieves the collection of Survey resources.
     * @request GET:/surveys
     * @secure
     */
    apiSurveysGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        reference?: string;
        'reference[]'?: string[];
        name?: string;
        'order[name]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': SurveyJsonldSurveyRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/surveys`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a Survey resource.
     *
     * @tags Survey
     * @name ApiSurveysPost
     * @summary Creates a Survey resource.
     * @request POST:/surveys
     * @secure
     */
    apiSurveysPost: (data: SurveyJsonldSurveyWrite, params: RequestParams = {}) =>
      this.request<SurveyJsonldSurveyRead, void>({
        path: `/surveys`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get a form created from a Google Form.
     *
     * @tags Survey
     * @name ApiSurveysgoogleFormReferenceGet
     * @summary Get a survey from Google
     * @request GET:/surveys/google-form/{reference}
     * @secure
     */
    apiSurveysgoogleFormReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<SurveyJsonld, void>({
        path: `/surveys/google-form/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Send responses to a survey to Google Form.
     *
     * @tags Survey
     * @name ApiSurveysresponsesPost
     * @summary Send responses to Google
     * @request POST:/surveys/responses
     * @secure
     */
    apiSurveysresponsesPost: (data: SurveyJsonldSurveyWrite, params: RequestParams = {}) =>
      this.request<SurveyJsonldSurveyRead, void>({
        path: `/surveys/responses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Survey resource.
     *
     * @tags Survey
     * @name ApiSurveysReferenceGet
     * @summary Retrieves a Survey resource.
     * @request GET:/surveys/{reference}
     * @secure
     */
    apiSurveysReferenceGet: (reference: string, params: RequestParams = {}) =>
      this.request<SurveyJsonldSurveyRead, void>({
        path: `/surveys/${reference}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the Survey resource.
     *
     * @tags Survey
     * @name ApiSurveysReferencePut
     * @summary Replaces the Survey resource.
     * @request PUT:/surveys/{reference}
     * @secure
     */
    apiSurveysReferencePut: (reference: string, data: SurveyJsonldSurveyWrite, params: RequestParams = {}) =>
      this.request<SurveyJsonldSurveyRead, void>({
        path: `/surveys/${reference}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  tags = {
    /**
     * @description Retrieves the collection of Tag resources.
     *
     * @tags Tag
     * @name ApiTagsGetCollection
     * @summary Retrieves the collection of Tag resources.
     * @request GET:/tags
     * @secure
     */
    apiTagsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': TagJsonldTagRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/tags`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a Tag resource.
     *
     * @tags Tag
     * @name ApiTagsIdGet
     * @summary Retrieves a Tag resource.
     * @request GET:/tags/{id}
     * @secure
     */
    apiTagsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<TagJsonldTagRead, void>({
        path: `/tags/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  tokenDevices = {
    /**
     * @description Retrieves the collection of TokenDevice resources.
     *
     * @tags TokenDevice
     * @name ApiTokenDevicesGetCollection
     * @summary Retrieves the collection of TokenDevice resources.
     * @request GET:/token_devices
     * @secure
     */
    apiTokenDevicesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': TokenDeviceJsonldTokenDeviceRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/token_devices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a TokenDevice resource.
     *
     * @tags TokenDevice
     * @name ApiTokenDevicesPost
     * @summary Creates a TokenDevice resource.
     * @request POST:/token_devices
     * @secure
     */
    apiTokenDevicesPost: (data: TokenDeviceJsonldTokenDeviceWrite, params: RequestParams = {}) =>
      this.request<TokenDeviceJsonldTokenDeviceRead, void>({
        path: `/token_devices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a TokenDevice resource.
     *
     * @tags TokenDevice
     * @name ApiTokenDevicesIdGet
     * @summary Retrieves a TokenDevice resource.
     * @request GET:/token_devices/{id}
     * @secure
     */
    apiTokenDevicesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<TokenDeviceJsonldTokenDeviceRead, void>({
        path: `/token_devices/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the TokenDevice resource.
     *
     * @tags TokenDevice
     * @name ApiTokenDevicesIdPut
     * @summary Replaces the TokenDevice resource.
     * @request PUT:/token_devices/{id}
     * @secure
     */
    apiTokenDevicesIdPut: (id: string, data: TokenDeviceJsonldTokenDeviceWrite, params: RequestParams = {}) =>
      this.request<TokenDeviceJsonldTokenDeviceRead, void>({
        path: `/token_devices/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  transaction = {
    /**
     * @description List of all values needed to initiate Transactions Card filters.
     *
     * @tags MarketplaceTransaction
     * @name ApiTransactionfilterDataGet
     * @summary Get the filter's data for Transactions Cards list
     * @request GET:/transaction/filter-data
     * @secure
     */
    apiTransactionfilterDataGet: (params: RequestParams = {}) =>
      this.request<
        {
          transactionTypes?: any[];
        },
        void
      >({
        path: `/transaction/filter-data`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  user = {
    /**
     * @description Return the stats of the game for the user.
     *
     * @tags User
     * @name ApiUsergameStatsGet
     * @summary Get the stats of the user's game
     * @request GET:/user/gameStats
     * @secure
     */
    apiUsergameStatsGet: (params: RequestParams = {}) =>
      this.request<UserUserGameStatsJsonldUserGameStatsRead, void>({
        path: `/user/gameStats`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userAccesses = {
    /**
     * @description Retrieves the collection of UserAccess resources.
     *
     * @tags UserAccess
     * @name ApiUserAccessesGetCollection
     * @summary Retrieves the collection of UserAccess resources.
     * @request GET:/user_accesses
     * @secure
     */
    apiUserAccessesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserAccessJsonldUserAccessRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/user_accesses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a UserAccess resource.
     *
     * @tags UserAccess
     * @name ApiUserAccessesIdGet
     * @summary Retrieves a UserAccess resource.
     * @request GET:/user_accesses/{id}
     * @secure
     */
    apiUserAccessesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<UserAccessJsonldUserAccessRead, void>({
        path: `/user_accesses/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userBlockeds = {
    /**
     * @description Retrieves the collection of UserBlocked resources.
     *
     * @tags UserBlocked
     * @name ApiUserBlockedsGetCollection
     * @summary Retrieves the collection of UserBlocked resources.
     * @request GET:/user_blockeds
     * @secure
     */
    apiUserBlockedsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserBlockedJsonldUserBlockedRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/user_blockeds`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Blocked User.
     *
     * @tags UserBlocked
     * @name ApiUserBlockedsIdPost
     * @summary Blocked User
     * @request POST:/user_blockeds/{id}
     * @secure
     */
    apiUserBlockedsIdPost: (id: string, data: UserBlockedJsonld, params: RequestParams = {}) =>
      this.request<UserBlockedJsonldUserBlockedRead, void>({
        path: `/user_blockeds/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the UserBlocked resource.
     *
     * @tags UserBlocked
     * @name ApiUserBlockedsIdDelete
     * @summary Removes the UserBlocked resource.
     * @request DELETE:/user_blockeds/{id}
     * @secure
     */
    apiUserBlockedsIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user_blockeds/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  userFriendRequests = {
    /**
     * @description Retrieves the collection of UserFriendRequest resources.
     *
     * @tags UserFriendRequest
     * @name ApiUserFriendRequestsGetCollection
     * @summary Retrieves the collection of UserFriendRequest resources.
     * @request GET:/user_friend_requests
     * @secure
     */
    apiUserFriendRequestsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        status?: string;
        'status[]'?: string[];
        'sender.nickName'?: string;
        'receiver.nickName'?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserFriendRequestJsonldUserFriendRequestRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/user_friend_requests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a UserFriendRequest resource.
     *
     * @tags UserFriendRequest
     * @name ApiUserFriendRequestsPost
     * @summary Creates a UserFriendRequest resource.
     * @request POST:/user_friend_requests
     * @secure
     */
    apiUserFriendRequestsPost: (data: UserFriendRequestJsonldUserFriendRequestWrite, params: RequestParams = {}) =>
      this.request<UserFriendRequestJsonldUserFriendRequestRead, void>({
        path: `/user_friend_requests`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  userFriendships = {
    /**
     * @description Retrieves the collection of UserFriendship resources.
     *
     * @tags UserFriendship
     * @name ApiUserFriendshipsGetCollection
     * @summary Retrieves the collection of UserFriendship resources.
     * @request GET:/user_friendships
     * @secure
     */
    apiUserFriendshipsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        'user.nickName'?: string;
        'userFriend.nickName'?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserFriendshipJsonldUserFriendshipReadUserRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/user_friendships`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get the user's friends information's of a friend.
     *
     * @tags UserFriendship
     * @name ApiUserFriendshipsfriendsIdGetCollection
     * @summary Get the user's friends of a friend
     * @request GET:/user_friendships/friends/{id}
     * @secure
     */
    apiUserFriendshipsfriendsIdGetCollection: (
      id: string,
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        'user.nickName'?: string;
        'userFriend.nickName'?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserFriendshipJsonldUserFriendshipReadUserRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/user_friendships/friends/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the UserFriendship resource.
     *
     * @tags UserFriendship
     * @name ApiUserFriendshipsIdDelete
     * @summary Removes the UserFriendship resource.
     * @request DELETE:/user_friendships/{id}
     * @secure
     */
    apiUserFriendshipsIdDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user_friendships/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  userGroups = {
    /**
     * @description Retrieves the collection of UserGroup resources.
     *
     * @tags UserGroup
     * @name ApiUserGroupsGetCollection
     * @summary Retrieves the collection of UserGroup resources.
     * @request GET:/user_groups
     * @secure
     */
    apiUserGroupsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserGroupJsonldUserGroupRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/user_groups`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a UserGroup resource.
     *
     * @tags UserGroup
     * @name ApiUserGroupsIdGet
     * @summary Retrieves a UserGroup resource.
     * @request GET:/user_groups/{id}
     * @secure
     */
    apiUserGroupsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<UserGroupJsonldUserGroupRead, void>({
        path: `/user_groups/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userOptins = {
    /**
     * @description Retrieves the collection of UserOptin resources.
     *
     * @tags UserOptin
     * @name ApiUserOptinsGetCollection
     * @summary Retrieves the collection of UserOptin resources.
     * @request GET:/user_optins
     * @secure
     */
    apiUserOptinsGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserOptinJsonldUserOptinRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/user_optins`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a UserOptin resource.
     *
     * @tags UserOptin
     * @name ApiUserOptinsPost
     * @summary Creates a UserOptin resource.
     * @request POST:/user_optins
     * @secure
     */
    apiUserOptinsPost: (data: UserOptinJsonldUserOptinWrite, params: RequestParams = {}) =>
      this.request<UserOptinJsonldUserOptinRead, void>({
        path: `/user_optins`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a UserOptin resource.
     *
     * @tags UserOptin
     * @name ApiUserOptinsIdGet
     * @summary Retrieves a UserOptin resource.
     * @request GET:/user_optins/{id}
     * @secure
     */
    apiUserOptinsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<UserOptinJsonldUserOptinRead, void>({
        path: `/user_optins/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the UserOptin resource.
     *
     * @tags UserOptin
     * @name ApiUserOptinsIdPut
     * @summary Replaces the UserOptin resource.
     * @request PUT:/user_optins/{id}
     * @secure
     */
    apiUserOptinsIdPut: (id: string, data: UserOptinJsonldUserOptinWrite, params: RequestParams = {}) =>
      this.request<UserOptinJsonldUserOptinRead, void>({
        path: `/user_optins/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  userProfiles = {
    /**
     * @description Retrieves the collection of UserProfile resources.
     *
     * @tags UserProfile
     * @name ApiUserProfilesGetCollection
     * @summary Retrieves the collection of UserProfile resources.
     * @request GET:/user_profiles
     * @secure
     */
    apiUserProfilesGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserProfileJsonldUserProfileRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/user_profiles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a UserProfile resource.
     *
     * @tags UserProfile
     * @name ApiUserProfilesIdGet
     * @summary Retrieves a UserProfile resource.
     * @request GET:/user_profiles/{id}
     * @secure
     */
    apiUserProfilesIdGet: (id: string, params: RequestParams = {}) =>
      this.request<UserProfileJsonldUserProfileRead, void>({
        path: `/user_profiles/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userSocialNetworks = {
    /**
     * @description Retrieves the collection of UserSocialNetwork resources.
     *
     * @tags UserSocialNetwork
     * @name ApiUserSocialNetworksGetCollection
     * @summary Retrieves the collection of UserSocialNetwork resources.
     * @request GET:/user_social_networks
     * @secure
     */
    apiUserSocialNetworksGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserSocialNetworkJsonldUserSocialNetworkRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/user_social_networks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a UserSocialNetwork resource.
     *
     * @tags UserSocialNetwork
     * @name ApiUserSocialNetworksPost
     * @summary Creates a UserSocialNetwork resource.
     * @request POST:/user_social_networks
     * @secure
     */
    apiUserSocialNetworksPost: (data: UserSocialNetworkJsonldUserSocialNetworkWrite, params: RequestParams = {}) =>
      this.request<UserSocialNetworkJsonldUserSocialNetworkRead, void>({
        path: `/user_social_networks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a UserSocialNetwork resource.
     *
     * @tags UserSocialNetwork
     * @name ApiUserSocialNetworksIdGet
     * @summary Retrieves a UserSocialNetwork resource.
     * @request GET:/user_social_networks/{id}
     * @secure
     */
    apiUserSocialNetworksIdGet: (id: string, params: RequestParams = {}) =>
      this.request<UserSocialNetworkJsonldUserSocialNetworkRead, void>({
        path: `/user_social_networks/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the UserSocialNetwork resource.
     *
     * @tags UserSocialNetwork
     * @name ApiUserSocialNetworksIdPut
     * @summary Replaces the UserSocialNetwork resource.
     * @request PUT:/user_social_networks/{id}
     * @secure
     */
    apiUserSocialNetworksIdPut: (
      id: string,
      data: UserSocialNetworkJsonldUserSocialNetworkWrite,
      params: RequestParams = {},
    ) =>
      this.request<UserSocialNetworkJsonldUserSocialNetworkRead, void>({
        path: `/user_social_networks/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  userTokens = {
    /**
     * @description Creates a UserToken resource.
     *
     * @tags UserToken
     * @name ApiUserTokensPost
     * @summary Creates a UserToken resource.
     * @request POST:/user_tokens
     * @secure
     */
    apiUserTokensPost: (data: UserTokenJsonldUserTokenWrite, params: RequestParams = {}) =>
      this.request<UserTokenJsonldUserTokenCreateRead, void>({
        path: `/user_tokens`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a UserToken resource.
     *
     * @tags UserToken
     * @name ApiUserTokensTokenGet
     * @summary Retrieves a UserToken resource.
     * @request GET:/user_tokens/{token}
     * @secure
     */
    apiUserTokensTokenGet: (token: string, params: RequestParams = {}) =>
      this.request<UserTokenJsonldUserTokenRead, void>({
        path: `/user_tokens/${token}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  users = {
    /**
     * @description Retrieves the collection of User resources.
     *
     * @tags User
     * @name ApiUsersGetCollection
     * @summary Retrieves the collection of User resources.
     * @request GET:/users
     * @secure
     */
    apiUsersGetCollection: (
      query?: {
        /**
         * The collection page number
         * @default 1
         */
        page?: number;
        /**
         * The number of items per page
         * @min 0
         * @default 30
         */
        itemsPerPage?: number;
        id?: string;
        'id[]'?: string[];
        email?: string;
        nickName?: string;
        'userProfile.id'?: string;
        'userProfile.id[]'?: string[];
        'userProfile.reference'?: string;
        'userProfile.reference[]'?: string[];
        'factions.id'?: string;
        'factions.id[]'?: string[];
        'country.id'?: string;
        'country.id[]'?: string[];
        isActive?: boolean;
        'isActive[]'?: boolean[];
        'userEvents.event'?: string;
        'userEvents.event[]'?: string[];
        'userEvents.status'?: string;
        'userEvents.status[]'?: string[];
        'order[id]'?: 'asc' | 'desc';
        'order[email]'?: 'asc' | 'desc';
        'order[nickName]'?: 'asc' | 'desc';
        'order[firstName]'?: 'asc' | 'desc';
        'order[civility]'?: 'asc' | 'desc';
        'order[userProfile.reference]'?: 'asc' | 'desc';
        'order[factions.reference]'?: 'asc' | 'desc';
        'order[country.reference]'?: 'asc' | 'desc';
        'order[isActive]'?: 'asc' | 'desc';
        'order[activation]'?: 'asc' | 'desc';
        'order[createdAt]'?: 'asc' | 'desc';
        'order[updatedAt]'?: 'asc' | 'desc';
        'activation[before]'?: string;
        'activation[strictly_before]'?: string;
        'activation[after]'?: string;
        'activation[strictly_after]'?: string;
        'createdAt[before]'?: string;
        'createdAt[strictly_before]'?: string;
        'createdAt[after]'?: string;
        'createdAt[strictly_after]'?: string;
        'updatedAt[before]'?: string;
        'updatedAt[strictly_before]'?: string;
        'updatedAt[after]'?: string;
        'updatedAt[strictly_after]'?: string;
        'groups[]'?: (
          | 'address:read'
          | 'card_user_list:read'
          | 'contact:read'
          | 'country:read'
          | 'event:read'
          | 'event_user:read'
          | 'game_user:read'
          | 'illustration:read'
          | 'language:read'
          | 'owner_history_group:read'
          | 'social_network:read'
          | 'survey:read'
          | 'token_device:read'
          | 'user_access:read'
          | 'user_group:read'
          | 'user_profile:read'
          | 'user_token:read'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          'hydra:member': UserJsonldMessengerUserRead[];
          /** @min 0 */
          'hydra:totalItems'?: number;
          /** @example {"@id":"string","type":"string","hydra:first":"string","hydra:last":"string","hydra:previous":"string","hydra:next":"string"} */
          'hydra:view'?: {
            /** @format iri-reference */
            '@id'?: string;
            '@type'?: string;
            /** @format iri-reference */
            'hydra:first'?: string;
            /** @format iri-reference */
            'hydra:last'?: string;
            /** @format iri-reference */
            'hydra:previous'?: string;
            /** @format iri-reference */
            'hydra:next'?: string;
          };
          'hydra:search'?: {
            '@type'?: string;
            'hydra:template'?: string;
            'hydra:variableRepresentation'?: string;
            'hydra:mapping'?: {
              '@type'?: string;
              variable?: string;
              property?: string | null;
              required?: boolean;
            }[];
          };
        },
        any
      >({
        path: `/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Activate an user with a token and a code.
     *
     * @tags User
     * @name ApiUsersactivationPost
     * @summary User activation with code
     * @request POST:/users/activation
     * @secure
     */
    apiUsersactivationPost: (data: UserJsonldUserWrite, params: RequestParams = {}) =>
      this.request<UserJsonldUserRead, void>({
        path: `/users/activation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Activation of shop Member.
     *
     * @tags User
     * @name ApiUsersactivationShopMemberPost
     * @summary Activation of shop Member
     * @request POST:/users/activation-shop-member
     * @secure
     */
    apiUsersactivationShopMemberPost: (data: UserShopMemberActivationJsonldUserWrite, params: RequestParams = {}) =>
      this.request<UserJsonldUserRead, void>({
        path: `/users/activation-shop-member`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create an User for join newsletter.
     *
     * @tags User
     * @name ApiUsersaddToNewsletterPost
     * @summary Create a Newsletter User
     * @request POST:/users/add-to-newsletter
     * @secure
     */
    apiUsersaddToNewsletterPost: (data: UserJsonldUserWrite, params: RequestParams = {}) =>
      this.request<UserJsonldUserPrivateReadUserTokenRead, void>({
        path: `/users/add-to-newsletter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a User resource.
     *
     * @tags User
     * @name ApiUserschangePasswordPost
     * @summary Change password
     * @request POST:/users/change-password
     * @secure
     */
    apiUserschangePasswordPost: (
      data?: {
        id?: string;
        oldPassword?: string;
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserJsonldUserRead, void>({
        path: `/users/change-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create an User with the minimum properties.
     *
     * @tags User
     * @name ApiUserscreatePost
     * @summary Create an User
     * @request POST:/users/create
     * @secure
     */
    apiUserscreatePost: (data: UserJsonldUserWrite, params: RequestParams = {}) =>
      this.request<UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead, void>({
        path: `/users/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create an User with the minimum properties.
     *
     * @tags User
     * @name ApiUserscreateIdPut
     * @summary Create an User
     * @request PUT:/users/create/{id}
     * @secure
     */
    apiUserscreateIdPut: (id: string, data: UserJsonldUserWriteAddressWrite, params: RequestParams = {}) =>
      this.request<UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead, void>({
        path: `/users/create/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes the User resource.
     *
     * @tags User
     * @name ApiUsersdeleteAccountDelete
     * @summary Removes the User resource.
     * @request DELETE:/users/delete-account
     * @secure
     */
    apiUsersdeleteAccountDelete: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/users/delete-account`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Create a forgot-password token and send an e-mail.
     *
     * @tags User
     * @name ApiUsersforgotPasswordEmailGet
     * @summary Forgot Password
     * @request GET:/users/forgot-password/{email}
     * @secure
     */
    apiUsersforgotPasswordEmailGet: (email: string, params: RequestParams = {}) =>
      this.request<UserJsonldUserRead, void>({
        path: `/users/forgot-password/${email}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a User resource.
     *
     * @tags User
     * @name ApiUsersnewPasswordPost
     * @summary Change forgotten password
     * @request POST:/users/new-password
     * @secure
     */
    apiUsersnewPasswordPost: (
      data?: {
        token?: string;
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserJsonldUserRead, void>({
        path: `/users/new-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Resend a token for an user.
     *
     * @tags User
     * @name ApiUsersresendTokenTokenGet
     * @summary Resend Token
     * @request GET:/users/resend-token/{token}
     * @secure
     */
    apiUsersresendTokenTokenGet: (token: string, params: RequestParams = {}) =>
      this.request<UserJsonldUserRead, void>({
        path: `/users/resend-token/${token}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get the user by unique ID.
     *
     * @tags User
     * @name ApiUserssearchUniqueIdGet
     * @summary Get the user by unique ID
     * @request GET:/users/search/{uniqueId}
     * @secure
     */
    apiUserssearchUniqueIdGet: (uniqueId: string, params: RequestParams = {}) =>
      this.request<UserJsonldUserReadUserFriendshipRead, void>({
        path: `/users/search/${uniqueId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a Shop User
     *
     * @tags User
     * @name ApiUsersshopCreatePost
     * @summary Create a Shop User
     * @request POST:/users/shop-create
     * @secure
     */
    apiUsersshopCreatePost: (data: UserJsonldUserWriteShopWriteAddressWrite, params: RequestParams = {}) =>
      this.request<
        UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead,
        void
      >({
        path: `/users/shop-create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a Shop Member
     *
     * @tags User
     * @name ApiUsersshopMemberPost
     * @summary Create a Shop Member
     * @request POST:/users/shop-member
     * @secure
     */
    apiUsersshopMemberPost: (data: UserJsonldUserWriteShopWriteAddressWrite, params: RequestParams = {}) =>
      this.request<
        UserJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinReadShopReadAddressReadCountryRead,
        void
      >({
        path: `/users/shop-member`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Check if a token is ok.
     *
     * @tags User
     * @name ApiUserstokenValidationPost
     * @summary Check token
     * @request POST:/users/token-validation
     * @secure
     */
    apiUserstokenValidationPost: (data: UserJsonldUserWrite, params: RequestParams = {}) =>
      this.request<UserJsonldUserRead, void>({
        path: `/users/token-validation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Check if the email is unique.
     *
     * @tags User
     * @name ApiUsersuniqueEmailEmailGet
     * @summary Unique Email
     * @request GET:/users/unique-email/{email}
     * @secure
     */
    apiUsersuniqueEmailEmailGet: (email: string, params: RequestParams = {}) =>
      this.request<UserUserUniqueEmailJsonldUserUniqueEmailRead, void>({
        path: `/users/unique-email/${email}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Check if the nickname is unique.
     *
     * @tags User
     * @name ApiUsersuniqueNicknameNickNameGet
     * @summary Unique Nickname
     * @request GET:/users/unique-nickname/{nickName}
     * @secure
     */
    apiUsersuniqueNicknameNickNameGet: (nickName: string, params: RequestParams = {}) =>
      this.request<UserUserUniqueNicknameJsonldUserUniqueNicknameRead, void>({
        path: `/users/unique-nickname/${nickName}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves a User resource.
     *
     * @tags User
     * @name ApiUsersIdGet
     * @summary Retrieves a User resource.
     * @request GET:/users/{id}
     * @secure
     */
    apiUsersIdGet: (id: string, params: RequestParams = {}) =>
      this.request<UserJsonldUserReadUserFriendshipRead, void>({
        path: `/users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces the User resource.
     *
     * @tags User
     * @name ApiUsersIdPut
     * @summary Replaces the User resource.
     * @request PUT:/users/{id}
     * @secure
     */
    apiUsersIdPut: (id: string, data: UserJsonldUserWrite, params: RequestParams = {}) =>
      this.request<UserJsonldUserRead, void>({
        path: `/users/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
