import { useTranslation } from 'react-i18next';
import Button from '@/atoms/Button';
import RedirectTile from '@/atoms/RedirectTile';
import Spinner from '@/atoms/Spinner';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import { VARIANT_COLOR_ENUM } from 'types/utils';
import useAddressList from './hook';

type AddressListModal = {
  onSubmit?: () => void;
  closeModal: () => void;
  onChange: (id: string) => void;
  title: string;
};
const AddressList = ({ onSubmit, closeModal, onChange, title }: AddressListModal) => {
  const { t } = useTranslation();
  const { addresses, isLoading, handleCreateAddress } = useAddressList(onChange);

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title={title}>
      <div className="flex flex-col pb-4">
        {isLoading && <Spinner />}
        {addresses?.map((address) => (
          <div key={address['@id']} className="first:border-t border-neutral-300 border-b py-6">
            <RedirectTile
              description={`${address.street}, ${address.zipCode}`}
              icon="fa-solid fa-house"
              label={address.label || ''}
              onClick={() => {
                onChange(address['@id'] as string);
                closeModal();
              }}
              tags={[address.isDefaultBilling ? { label: 'billing', color: VARIANT_COLOR_ENUM.NEUTRAL } : {}].concat(
                address.isDefaultShipping ? { label: 'shipping', color: VARIANT_COLOR_ENUM.SUCCESS } : {},
              )}
            />
          </div>
        ))}
      </div>
      <Button className="mx-auto my-4" onClick={handleCreateAddress}>
        {t('add-new-address')}
      </Button>
    </ModalClassicLayout>
  );
};
export default AddressList;
