export const NUMBER_TO_COLOR: { [arg: number]: string } = {
  1: 'text-security-red-400',
  2: 'text-security-orange-400',
  3: 'text-security-blue-400',
  4: 'text-security-green-400',
};

export const NUMBER_TO_TEXT: { [arg: number]: string } = {
  1: 'security-level-1',
  2: 'security-level-2',
  3: 'security-level-3',
  4: 'security-level-4',
};

export const REGEX_PWD = ['[A-Z]', '[a-z]', '[0-9]', '\\W'];
