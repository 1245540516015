import clx from 'classnames';
import Link from 'next/link';
import { PropsWithChildren } from 'react';
import TileHeader, { TileHeaderProps } from './components/header';

type TileProps = TileHeaderProps & {
  href?: string;
  className?: string;
  shadow?: boolean;
};

const TileContainer = ({ href, children }: PropsWithChildren<{ href?: string }>) =>
  href ? (
    <Link className="hover:no-underline" href={href}>
      {children}
    </Link>
  ) : (
    children
  );
const Tile = ({ href, children, className, shadow, ...tileHeaderProps }: PropsWithChildren<TileProps>) => (
  <div className={clx('rounded-lg bg-neutral-20 group', className, shadow && ' shadow-overlap')}>
    <TileContainer href={href}>
      {tileHeaderProps && tileHeaderProps.title && <TileHeader {...tileHeaderProps} />}
      <div className="p-3">{children}</div>
    </TileContainer>
  </div>
);

export default Tile;
