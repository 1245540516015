import AvatarLabel from '@/atoms/AvatarLabel';
import MoneyData from '@/atoms/MoneyData';
import ScanButton from '@/atoms/ScanButton';
import Spinner from '@/atoms/Spinner';
import MenuItemList from '@/molecules/MenuItemList';
import Metrics from '@/molecules/Metrics';
import ERROR_ROUTES from 'constants/routes';
import ModalNavLayout from 'context/Modal/layout/Nav';
import useUserNav from 'context/Modal/views/nav/user/hooks';
import useUserApi from 'hooks/UserApi/useUserApi';
import Link from 'next/link';
import { isInAppWebview } from 'utils/webview';

type Prop = {
  closeModal: () => void;
};

const UserNavModal = ({ closeModal }: Prop) => {
  const { user } = useUserApi();
  const { menuItems, walletItem, wallet, scanItem, footerImage } = useUserNav();

  const scanUrl = scanItem && new URL(scanItem?.href || '');

  return (
    <ModalNavLayout closeModal={closeModal} direction="right">
      <div className="flex flex-col gap-4 h-full overflow-x-hidden">
        <div className="flex flex-1 flex-col gap-4 grow p-4 ">
          <div className="flex flex-row justify-between">
            <AvatarLabel avatarPath={user?.avatarPath} nickName={user?.nickName} />
          </div>
          {walletItem && (
            <Link className="hover:no-underline" href={walletItem.href || ERROR_ROUTES.NOT_FOUND}>
              <Metrics icon={walletItem.acf?.icon} subLine={walletItem.label}>
                <div className="flex">
                  {wallet ? (
                    <MoneyData amount={wallet.balance} currency={wallet.currency} />
                  ) : (
                    <Spinner className="p-3" />
                  )}
                </div>
              </Metrics>
            </Link>
          )}
          <MenuItemList items={menuItems} />
        </div>
        <div
          className="flex flex-1 bg-cover bg-no-repeat w-full h-96 flex-col justify-end items-stretch"
          style={{
            backgroundImage: footerImage ? `url(${footerImage.sizes.medium_large})` : undefined,
          }}
        >
          {scanItem && isInAppWebview() && (
            <ScanButton className="mx-4 mb-4" href={scanUrl.pathname}>
              {scanItem.label}
            </ScanButton>
          )}
        </div>
      </div>
    </ModalNavLayout>
  );
};

export default UserNavModal;
