import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { BankAccountJsonld } from '@/api/generated';
import { useToaster } from 'context/Toaster/ToasterContext';
import useCountriesOptions from 'hooks/options/useCountriesOptions';
import { userBankMethodsFactory } from 'hooks/queries/banking/useUserBankAccount';
import useApi from 'hooks/useApi';
import { VARIANT_COLOR_ENUM } from 'types/utils';
import { BankType } from '../types';

const schemaIfBankType = (type: BankType, schema: yup.Schema) =>
  yup.string().when('type', { is: type, then: () => schema });

const matchNumericRegExp = /^[0-9]$/g;
const matchAlphaNumericRegExp = /[^a-z0-9]$/g;

// see https://mangopay.com/docs/endpoints/payouts#bank-account-object

const bankAccountSchema = () =>
  yup.object({
    type: yup.string().required(),
    ownerName: yup.string().required(),

    IBAN: schemaIfBankType(
      'IBAN',
      yup.string().required().max(34).matches(matchAlphaNumericRegExp, {
        message: 'string-only-alphanumeric',
      }),
    ),

    BIC: yup.string().when('type', {
      is: (v: string) => ['IBAN', 'OTHER'].includes(v),
      then: () => yup.string().required().min(8).max(11),
    }),

    accountNumber: yup.string().when('type', {
      is: (value: string) => value !== 'IBAN',
      then: () => yup.string().required().matches(matchNumericRegExp, { message: 'string-only-digits' }),
    }),

    ABA: schemaIfBankType('US', yup.string().required().length(9)),

    institutionNumber: schemaIfBankType(
      'CA',
      yup.string().required().length(3).matches(matchNumericRegExp, { message: 'string-only-digits' }),
    ),
    branchCode: schemaIfBankType(
      'CA',
      yup.string().required().length(5).matches(matchNumericRegExp, { message: 'string-only-digits' }),
    ),
    bankName: schemaIfBankType(
      'CA',
      yup.string().required().max(50).matches(matchAlphaNumericRegExp, {
        message: 'string-only-alphanumeric',
      }),
    ),

    sortCode: schemaIfBankType('GB', yup.string().required().max(6)),

    country: schemaIfBankType('OTHER', yup.string().required()),

    addressLine1: yup.string().required(),
    addressLine2: yup.string(),
    city: yup.string().required(),
    postalCode: yup.string().required(),
    region: yup
      .string()
      .when('country', { is: (v: string) => ['CA', 'US', 'MX'].includes(v), then: () => yup.string().required() }),
  });

export type BankAccountFormValues = yup.InferType<ReturnType<typeof bankAccountSchema>>;

const useBankAccountForm = () => {
  const queryClient = useQueryClient();
  const apiClient = useApi();
  const { t } = useTranslation();
  const { addToaster } = useToaster();

  const formMethods = useForm<BankAccountFormValues>({
    resolver: yupResolver(bankAccountSchema()),
    defaultValues: {
      addressLine2: '',
      region: '',
    },
  });

  const { handleSubmit } = formMethods;

  const { countriesOptions } = useCountriesOptions();

  const {
    mutate: createBankAccount,
    isPending,
    isError,
  } = useMutation({
    mutationKey: ['createBankAccount'],
    mutationFn: async (data: BankAccountJsonld) =>
      apiClient.payments.apiPaymentsbankAccountPost({
        ...data,
        // send country as country code
        country: data.country?.split('/').pop(),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [userBankMethodsFactory.all()] });
      addToaster({
        variant: VARIANT_COLOR_ENUM.SUCCESS,
        message: t('bank-account-create-success'),
      });
    },
  });

  const onSubmit = (data: BankAccountFormValues) => createBankAccount(data as BankAccountJsonld);

  return {
    onSubmit: handleSubmit(onSubmit),
    formMethods,
    isPending,
    isError,
    countriesOptions,
  };
};

export default useBankAccountForm;
