import { FormProvider } from 'react-hook-form';
import Form from '@/molecules/Form';
import AddressForm from '@/organisms/form/AddressForm';
import ModalClassicLayout from '../../layout/Classic';
import useAddressCreateModal from './hooks';

const AdressCreateModal = ({
  closeModal,
  onChange,
  title,
}: {
  closeModal: () => void;
  onChange: (id: string) => void;
  title?: string;
}) => {
  const { formMethods, onSubmit } = useAddressCreateModal({ closeModal, onChange });

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title={title}>
      <FormProvider {...formMethods}>
        <Form onSubmit={onSubmit}>
          <AddressForm />
          <input className="hidden" type="submit" />
        </Form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default AdressCreateModal;
