import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
import languagesFactory from 'utils/factories/languages';
import formatOptions from 'utils/form/formatOptions';

const useLanguagesOptions = () => {
  const apiClient = useApi();

  const { data } = useQuery({
    queryKey: languagesFactory.list(),
    queryFn: async () => {
      const res = await apiClient.languages.apiLanguagesGetCollection();

      return res.data;
    },
  });

  return { languagesOptions: formatOptions(data?.['hydra:member']) };
};

export default useLanguagesOptions;

export const useAllLanguagesOptions = () => {
  const apiClient = useApi();

  const { data } = useQuery({
    queryKey: languagesFactory.allLang(),
    queryFn: async () => {
      const res = await apiClient.languages.apiLanguagesallGetCollection();

      return res.data;
    },
  });

  return { languagesOptions: formatOptions(data?.['hydra:member']) };
};
