import { useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { IllustrationJsonldIllustrationRead } from '@/api/generated';
import { AvatarType, ProfileControlType } from 'types/profile';

type PickAnAvatarHookProps = {
  onSubmit: (args: AvatarType) => void;
  control: Control<ProfileControlType>;
};

const usePickAnAvatar = ({ onSubmit, control }: PickAnAvatarHookProps) => {
  const { avatar, avatarFilePath } = useWatch({ control });
  const [selected, setSelected] = useState<AvatarType>([
    {
      key: 'avatar',
      value: avatar || null,
    },
    {
      key: 'avatarFilePath',
      value: avatarFilePath || null,
    },
  ]);
  const handleChange = (illu: IllustrationJsonldIllustrationRead) => {
    setSelected([
      {
        key: 'avatar',
        value: illu['@id'] as string,
      },
      {
        key: 'avatarFilePath',
        value: illu.filePath as string,
      },
    ]);
  };
  const handleSubmit = () => {
    if (selected !== null) {
      onSubmit(selected);
    }
  };

  return { handleSubmit, handleChange, selected };
};

export default usePickAnAvatar;
