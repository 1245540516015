import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';

import createKeyFactory, { KEYS } from 'utils/createKeyFactory';
import formatOptions from 'utils/form/formatOptions';

const keyFactory = createKeyFactory([KEYS.COUNTRIES]);
const queryKey = keyFactory.list({ itemsPerPage: '300' });

const useCountriesOptions = () => {
  const apiClient = useApi();

  const { data } = useQuery({
    queryKey,
    queryFn: async () => apiClient.countries.apiCountriesGetCollection({ itemsPerPage: 300 }),
    select: (res) => res?.data,
  });

  return { countriesOptions: formatOptions(data?.['hydra:member']) };
};

export default useCountriesOptions;
