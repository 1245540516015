import { MutationCache, QueryCache, QueryClient, QueryClientProvider, keepPreviousData } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { env } from 'next-runtime-env';
import { ReactNode, useState } from 'react';

import ERROR_ROUTES from 'constants/routes';
import { useToaster } from 'context/Toaster/ToasterContext';
import { getErrorMessage } from 'hooks/networkError/utils';
import { VARIANT_COLOR_ENUM } from 'types/utils';
import { Session } from 'next-auth';

let refreshingSessionPromise: Promise<Session | null>;

export const SKIP_ERROR_HANDLER_META = { skipErrorHandler: true };

const ReactQueryProvider = ({ children }: { children: ReactNode }) => {
  const { addToaster } = useToaster();
  const { update } = useSession();
  const { push } = useRouter();

  const onError = async (error: any) => {
    // Handle API errors from generated API client or custom fetch
    if (error.status === 401 || error.status === 403 || error.code === 401 || error.code === 403) {
      if (error.status === 401 || error.code === 401) {
        // Update session to refresh JWT
        if (!refreshingSessionPromise) {
          refreshingSessionPromise = update().then((session) => {
            if (!session) {
              addToaster({ variant: VARIANT_COLOR_ENUM.ERROR, message: 'not-logged' });
            }

            return session;
          });
        }

        await refreshingSessionPromise;
      }
    } else if (error.status === 404 && env('NEXT_PUBLIC_ENV') !== 'local') {
      push(ERROR_ROUTES.NOT_FOUND);
    } else {
      addToaster({ variant: VARIANT_COLOR_ENUM.ERROR, message: await getErrorMessage(error) });
    }
  };
  const queryClient = new QueryClient({
    mutationCache: new MutationCache(),
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (!query.meta?.skipErrorHandler) {
          onError(error);
        }
      },
    }),
    defaultOptions: {
      queries: {
        placeholderData: keepPreviousData,
        retry: false,
        refetchOnWindowFocus: false,
      },
      mutations: { onError },
    },
  });

  const [client] = useState(queryClient);

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};

export default ReactQueryProvider;
