import calcPercent from 'utils/calcPercent';

type Props = {
  value: string;
  max: string | number;
};

const VerticalBar = ({ value, max }: Props) => (
  <div className="bg-neutral-500 p-1 w-fit rounded-md h-42 flex flex-col m-0.5">
    <p className="xs-bold text-neutral-0 drop-shadow-contastText pb-1 text-center">{value}</p>
    <div className="bg-neutral-200 rounded-sm relative min-w-3 h-full">
      <div
        className="absolute rounded-sm left-0 right-0 bg-gradient-to-t from-[#D5AD3B] via-[#F1E174] to-[#DEC04E] bottom-0 z-1 shadow-overlap transition-all duration-700"
        style={{ height: `${calcPercent(Number(value), Number(max))}%` }}
      />
    </div>
  </div>
);

export default VerticalBar;
