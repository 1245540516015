import clx from 'classnames';
import { useTranslation } from 'next-i18next';
import { BaseHTMLAttributes, useMemo } from 'react';

type ErrorLabelProps = BaseHTMLAttributes<HTMLParagraphElement> & {
  error: any;
};

const ErrorLabel = ({ error, className }: ErrorLabelProps) => {
  const { t } = useTranslation();
  const message = useMemo(() => {
    if (typeof error === 'string') {
      return t(error);
    }
    const varName = error.values ? Object.keys(error.values)[0] : undefined;
    const values = varName
      ? {
          [varName]: error.values[varName],
        }
      : undefined;

    return t(error.key, values) as string;
  }, [error, t]);

  return <p className={clx('text-system-error-400 sm-medium', className)}>{message}</p>;
};

export default ErrorLabel;
