import { FormProvider } from 'react-hook-form';
import { PrintingOrderJsonldPrintingOrderRead } from '@/api/generated';
import TextInput from '@/atoms/forms/Input/Text/Input';
import Select from '@/atoms/forms/Select';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import useCreatePrintingModal from './hook';

const CreatePrintingOrderModal = ({
  closeModal,
  orderName,
  orderLang,
  id,
  onSubmit,
}: {
  closeModal: () => void;
  orderName?: string;
  orderLang?: string;
  id?: string;
  onSubmit?: (data: PrintingOrderJsonldPrintingOrderRead) => void;
}) => {
  const { handleSubmit, formMethods, languagesOptions } = useCreatePrintingModal({
    orderName,
    orderLang,
    id,
    onSubmit,
    closeModal,
  });

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={id ? 'modify-order' : 'new-print-order'}>
      <div className="flex flex-col pb-4">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit}>
            <TextInput label="order-name" name="name" />
            <Select label="printing-language" name="language" options={languagesOptions} placeholder="language" />
          </form>
        </FormProvider>
      </div>
    </ModalClassicLayout>
  );
};

export default CreatePrintingOrderModal;
