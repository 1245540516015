import clx from 'classnames';

export enum FEATURED_ICON_VARIANTS {
  'lightCircle' = 'lightCircle',
  'darkCircle' = 'darkCircle',
  'noBackground' = 'noBackground',
}

const VARIANT_TO_CLASSNAME = {
  lightCircle: 'bg-primary-100 text-neutral-500',
  darkCircle: 'bg-neutral-500 text-neutral-0',
  noBackground: 'bg-transparent text-neutral-500',
};

const FeaturedIcon = ({
  icon,
  variant = FEATURED_ICON_VARIANTS.lightCircle,
}: {
  icon: string;
  variant?: FEATURED_ICON_VARIANTS;
}) => (
  <span
    className={clx(
      'rounded-full flex items-center justify-center shrink-0 sm-regular h-6 w-6 md:md-regular md:h-8 md:w-8',
      VARIANT_TO_CLASSNAME[variant],
    )}
  >
    <i className={icon} />
  </span>
);

export default FeaturedIcon;
