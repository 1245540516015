import clx from 'classnames';
import { Ref, forwardRef } from 'react';
import { SIZE, VARIANT_COLOR_ENUM } from 'types/utils';

const VARIANT_TO_CSS = {
  [VARIANT_COLOR_ENUM.NEUTRAL]: 'text-neutral-900 hover:text-neutral-300 hover:bg-neutral-50 focus:text-neutral-300 ',
  [VARIANT_COLOR_ENUM.PRIMARY]: 'text-primary-500 hover:bg-primary-100',
  [VARIANT_COLOR_ENUM.SECONDARY]: 'text-neutral-0 hover:bg-neutral-50 focus:bg-primary-200',
  [VARIANT_COLOR_ENUM.INFO]: 'text-system-info-400 hover:bg-system-info-300 focus:bg-neutral-0',
  [VARIANT_COLOR_ENUM.ERROR]: 'text-system-error-400 hover:bg-system-error-300 focus:bg-neutral-0',
  [VARIANT_COLOR_ENUM.SUCCESS]: 'text-system-success-400 hover:bg-system-success-300 focus:bg-neutral-0',
  [VARIANT_COLOR_ENUM.DISABLED]: 'text-system-disabled-400',
};

type XcloseProps = {
  onClick: () => void;
  variant?: VARIANT_COLOR_ENUM;
  size?: SIZE;
};

const SIZE_TO_CSS = {
  [SIZE.SM]: 'w-3.5 h-3.5 text-xs p-1',
  [SIZE.MD]: 'w-4 h-4 text-sm p-2',
  [SIZE.LG]: 'w-5 h-5 text-xl p-3',
};

const Xclose = forwardRef(
  (
    { onClick, size = SIZE.SM, variant = VARIANT_COLOR_ENUM.NEUTRAL }: XcloseProps,
    ref: Ref<HTMLButtonElement> | undefined,
  ) => (
    <button
      ref={ref}
      className={clx(
        'rounded flex items-center justify-center ml-2',
        'focus:shadow-focus transition-all duration-quick ease-out',
        SIZE_TO_CSS[size],
        VARIANT_TO_CSS[variant],
      )}
      onClick={onClick}
      type="button"
    >
      <i className="fa-solid fa-xmark-large" />
    </button>
  ),
);

Xclose.displayName = 'Xclose';

export default Xclose;
