import { useModal } from 'context/Modal/Context';
import { MODAL_NAME } from 'context/Modal/MODAL_NAME';
import useUserAddresses from 'hooks/queries/address/useUserAddresses';

const useAddressList = (onChange: (id: string) => void) => {
  const { openModal } = useModal();
  const { addresses, isLoading } = useUserAddresses();
  const handleCreateAddress = () => {
    openModal(MODAL_NAME.ADDRESS_CREATE, { onChange });
  };

  return {
    addresses,
    isLoading,
    handleCreateAddress,
  };
};

export default useAddressList;
