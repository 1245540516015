import { createContext, useContext } from 'react';

export type ConfigContextType = {
  isMobileApp: boolean;
};

export const ConfigContext = createContext<ConfigContextType>({
  isMobileApp: false,
});

export const useConfig = () => useContext(ConfigContext);
