import { useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Grade from '@/atoms/forms/grades/Grade';
import { BaseProps } from '@/atoms/forms/types';
import Fieldset from '@/molecules/Fieldset';
import { FormOptions } from 'types/forms';
import addOrRemoveItem from 'utils/form/addOrRemoveItem';
import sortOptions from './sortOptions';

type HorizontalCheckboxListProps = BaseProps & {
  options: FormOptions;
  disabled?: boolean;
  type?: 'checkbox' | 'radio';
};

const Grades = ({ name, options, label, type = 'checkbox', disabled }: HorizontalCheckboxListProps) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control,
    defaultValue: [],
  });

  const sortedOptions = useMemo(() => sortOptions(options), [options]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(addOrRemoveItem(event.target.value, field.value));
  };

  const getCheckedStatus = useCallback((value: string) => field.value.includes(value), [field.value]);

  return (
    <Fieldset className="max-w-full min-w-0 overflow-x-auto" disabled={disabled} legend={label}>
      <ul className="flex gap-1.5 md:flex-wrap">
        {sortedOptions.map(([id, option]) => (
          <li key={`${name}-${option.label}`}>
            <Grade
              getCheckedStatus={getCheckedStatus}
              icon={option.icon}
              id={`${name}-${option.label}-${id}`}
              label={option.label}
              name={name}
              onChange={onChange}
              type={type}
              value={id}
            />
          </li>
        ))}
      </ul>
    </Fieldset>
  );
};

export default Grades;
