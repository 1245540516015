import clx from 'classnames';
import MenuItem from '@/atoms/MenuItem';
import { MenuItemType } from 'types/cms';

type MenuItemListProps = {
  items: MenuItemType[];
  className?: string;
};

const MenuItemList = ({ items, className }: MenuItemListProps) => (
  <ul className={clx('md:flex flex-col', className)}>
    {items.map((item) => (
      <li key={item.key}>
        <MenuItem item={item} />
      </li>
    ))}
  </ul>
);

export default MenuItemList;
