import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import MenuItem from '@/atoms/MenuItem';
import VisualButton from '@/atoms/VisualButton';
import SecondaryTitle from '@/molecules/titles/SecondaryTitle';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import ScanButton from '@/atoms/ScanButton';
import LogoSvg from '@/svg/logo.svg';
import ERROR_ROUTES from 'constants/routes';
import ModalNavLayout from 'context/Modal/layout/Nav';
import useEditorialNav from 'context/Modal/views/nav/editorial/hooks';
import { MenuItemType } from 'types/cms';
import { isInAppWebview } from 'utils/webview';

type Prop = {
  closeModal: () => void;
};

const EditorialNavItem = ({ item }: { item: MenuItemType }) => {
  if (item.items && item.items.length > 0) {
    return (
      <>
        <SecondaryTitle showLine>{item.label}</SecondaryTitle>
        <ul className="pt-4">
          {item.items.map((subItem) => (
            <li key={subItem.key}>
              <EditorialNavItem item={subItem} />{' '}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return item.acf?.image ? (
    <VisualButton
      key={item.key}
      className="my-2"
      image={item.acf?.image}
      title={item.label}
      url={item.href || ERROR_ROUTES.NOT_FOUND}
    />
  ) : (
    <MenuItem item={item} />
  );
};

const EditorialNavModal = ({ closeModal }: Prop) => {
  const { menuItems, handleLanguageChoice, i18nItem, scanItem } = useEditorialNav();
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <ModalNavLayout closeModal={closeModal} direction="left">
      <div className="flex flex-col flex-grow-1 gap-6 p-4">
        <div className="flex flex-row justify-between items-center">
          <Link href="/">
            <LogoSvg height="40" width="116" />
          </Link>
        </div>
        {scanItem && isInAppWebview() && <ScanButton href={scanItem.href}>{scanItem.label}</ScanButton>}

        <ul className="flex flex-col gap-6">
          {menuItems.map((item) => (
            <li key={item.key}>
              <EditorialNavItem key={item.key} item={item} />
            </li>
          ))}
        </ul>
        {i18nItem && (
          <Button
            className="mt-4 lg:mt-6 shadow-raised"
            icon={i18nItem.acf?.icon}
            onClick={handleLanguageChoice}
            variant={BUTTON_VARIANTS.TERTIARY}
          >
            {t(language)}
          </Button>
        )}
      </div>
    </ModalNavLayout>
  );
};

export default EditorialNavModal;
