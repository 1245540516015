import { useMutation } from '@tanstack/react-query';
import useApi from 'hooks/useApi';

const useEditOffers = (onSuccess: () => void, onError: (error: unknown) => void) => {
  const apiClient = useApi();
  const updateOffer = useMutation({
    mutationKey: ['editOffer'],
    mutationFn: ({ price, groupId }: { price: number; groupId: string }) =>
      apiClient.marketplace.apiMarketplaceGroupIdPut(groupId, { price }),
    onSuccess,
    onError,
  });

  return { updateOffer };
};

export default useEditOffers;
