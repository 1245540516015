import { useQuery, useQueryClient } from '@tanstack/react-query';
import { EventJsonldEventRead } from '@/api/generated';
import useApi from 'hooks/useApi';
import useUserApi from 'hooks/UserApi/useUserApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const useCurrEventUser = (event?: EventJsonldEventRead) => {
  const apiClient = useApi();
  const { userId } = useUserApi();
  const queryClient = useQueryClient();

  const eventUsersFactory = createKeyFactory([KEYS.EVENT_USERS]);
  const queryKey = eventUsersFactory.list({
    event: event?.id || '',
    'user.id': userId,
  });

  const { data } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await apiClient.eventUsers.apiEventUsersGetCollection({
        event: event?.id || '',
        'user.id': userId,
      });

      return res.data;
    },
    enabled: !!(userId && event && event?.deckUserMax && event?.deckUserMax > 0),
  });

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return { currEventUser: data?.['hydra:member'][0], invalidateQuery };
};

export default useCurrEventUser;
