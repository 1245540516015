import clx from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HTMLInputProps, INPUT_STATE } from '../types';

import styles from './Input.module.css';

const STATE_TO_CLASS = {
  [INPUT_STATE.ERROR]: 'border-system-error-400 focus:border-system-error-400 focus:shadow-focus-error',
  [INPUT_STATE.DEFAULT]: 'focus:border-neutral-50 bg-neutral-40',
  [INPUT_STATE.SUCCESS]: 'border-system-success-400 focus:border-system-success-400 focus:shadow-focus-success',
};

const HTMLInput = forwardRef(
  ({ state, value, placeholder, disabled, ...inputProps }: HTMLInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();

    return (
      <input
        ref={ref}
        {...inputProps}
        aria-invalid={state === INPUT_STATE.ERROR}
        className={clx(
          'w-full autofill:bg-neutral-40 outline-none appearance-none rounded-lg border-[1px] px-3 py-2 lg-medium',
          'focus:bg-neutral-30 focus:outline-none',
          'hover:bg-neutral-50',
          'text-neutral-800 placeholder:text-neutral-300',
          'transition-all duration-quick ease-out',
          state ? STATE_TO_CLASS[state] : STATE_TO_CLASS[INPUT_STATE.DEFAULT],
          disabled && 'bg-system-disabled-50 border-system-disabled-300 placeholder:text-system-disabled-400',
          styles.input,
        )}
        disabled={disabled}
        placeholder={(placeholder && t(placeholder)) || ''}
        value={value}
      />
    );
  },
);

HTMLInput.displayName = 'HTMLInput';

export default HTMLInput;
