import { FormOptions, VisualOptions } from '../../types/forms';
import { Mapped } from '../../types/utils';

export type Entity = Mapped<string, string>;

const formatOptions = (entities?: any[], options?: { valueField?: string; labelField?: string }): FormOptions => {
  const { valueField, labelField } = options || {};
  if (!entities) {
    return {};
  }

  return entities.reduce<FormOptions>((acc, entity) => {
    const defaultLabel = entity.name ?? entity.reference;
    const label = labelField && entity[labelField] ? entity[labelField] : defaultLabel;
    const value = valueField && entity[valueField] ? entity[valueField] : entity['@id'];

    return {
      ...acc,
      [value]: {
        ...entity,
        label,
      },
    };
  }, {});
};

export const formatVisualOptions = (
  entities?: any[],
  options?: {
    valueField?: string;
    labelField?: string;
    iconField?: string;
    getIconField?: (entity: any) => string;
    imageField?: string;
  },
): VisualOptions => {
  const { valueField, labelField, iconField, getIconField, imageField } = options || {};
  if (!entities) {
    return {};
  }

  return entities.reduce<FormOptions>((acc, entity) => {
    const defaultLabel = entity.name ?? entity.reference;
    const label = labelField && entity[labelField] ? entity[labelField] : defaultLabel;
    const value = valueField && entity[valueField] ? entity[valueField] : entity['@id'];

    return {
      ...acc,
      [value]: {
        ...entity,
        label,
        ...(iconField && entity[iconField] && { icon: entity[iconField] }),
        ...(imageField && entity[imageField] && { image: entity[imageField] }),
        ...(getIconField && { icon: getIconField(entity) }),
      },
    };
  }, {});
};

export default formatOptions;
