import { useTranslation } from 'react-i18next';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import TagGroup from '@/molecules/TagGroup';
import { FilterDataDto, SIZE } from 'types/utils';
import useActiveFilters from './useActiveFilters';

type ActiveFiltersProps = {
  onFieldReset: (key: string) => void;
  onClear: () => void;
  filterData?: FilterDataDto;
};

const ActiveFilters = ({ onFieldReset, onClear, ...hookProps }: ActiveFiltersProps) => {
  const { filtersOptions } = useActiveFilters(hookProps);
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-2">
      <TagGroup closeTag={onFieldReset} options={filtersOptions} size={SIZE.MD} />
      {Object.keys(filtersOptions).length > 0 && (
        <Button className="whitespace-nowrap" onClick={onClear} type="button" variant={BUTTON_VARIANTS.TERTIARY}>
          {t('clear-all')}
        </Button>
      )}
    </div>
  );
};

export default ActiveFilters;
