import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import ERROR_ROUTES from 'constants/routes';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const shopsFactory = createKeyFactory([KEYS.SHOPS]);

const useShopDetailsApi = (ulid?: string | null) => {
  const apiClient = useApi();
  const { push } = useRouter();

  const {
    data: shop,
    isLoading,
    status,
    error,
  } = useQuery({
    queryKey: shopsFactory.details(ulid as string),
    queryFn: async () => {
      const apiData = await apiClient.shops.apiShopsIdGet(ulid as string);

      return apiData.data;
    },
    enabled: !!ulid,
  });

  useEffect(() => {
    if (error) {
      push(ERROR_ROUTES.NOT_FOUND);
    }
  }, [error]);

  return { shop, isLoading, status };
};

export default useShopDetailsApi;
