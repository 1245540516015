import clx from 'classnames';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Avatar from '@/atoms/Avatar';
import getFactionIconName from '@/atoms/FactionIcon/utils';
import SecondaryTitle from '@/molecules/titles/SecondaryTitle';
import usePickAnAvatar from 'context/Modal/views/PickAnAvatar/usePickAnAvatar';
import { AvatarType, ProfileControlType } from 'types/profile';
import { SIZE } from 'types/utils';
import { SortedIllustrationType } from 'utils/illustration';
import ModalClassicLayout from '../../layout/Classic';

type PickAnAvatarProps = {
  onSubmit: (args: AvatarType) => void;
  closeModal: () => void;
  control: Control<ProfileControlType>;
  sortedData: SortedIllustrationType;
};

const PickAnAvatar = ({ sortedData, closeModal, ...hookProps }: PickAnAvatarProps) => {
  const { handleChange, handleSubmit, selected } = usePickAnAvatar(hookProps);
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title={t('pick-an-avatar')}>
      <div className="flex flex-col">
        <div className="place-self-center">
          <Avatar
            alt={t('current-avatar')}
            className="border-4 border-primary-500"
            size={SIZE.LG}
            src={selected[1].value}
          />
        </div>
        {Object.keys(sortedData)
          .toSorted()
          .map((key) => (
            <div key={key} className="flex flex-col">
              <SecondaryTitle className="truncate" icon={getFactionIconName(key)}>
                {key}
              </SecondaryTitle>
              <ul className="flex flex-wrap gap-2 ">
                {sortedData[key].map((illu, index) => (
                  <li key={illu.id} className="flex-none">
                    <button data-test-id={`avatar-${index}`} onClick={() => handleChange(illu)} type="button">
                      <Avatar
                        alt={illu.reference}
                        className={clx(
                          'hover:border-4 hover:border-primary-500',
                          selected[0].value === illu['@id'] && 'border-4 border-primary-500',
                        )}
                        size={SIZE.LG}
                        src={illu.filePath as string}
                      />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </ModalClassicLayout>
  );
};

export default PickAnAvatar;
