import * as Select from '@radix-ui/react-select';
import clx from 'classnames';
import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from '@/atoms/forms/labels';
import { FormOptions } from 'types/forms';
import FieldLayout from '../FieldLayout';
import ErrorLabel from '../labels/ErrorLabel';
import { BaseProps } from '../types';

type SelectProps = BaseProps & {
  placeholder: string;
  required?: boolean;
  options: FormOptions;
  defaultValue?: string;
  onChange?: (v: { label: string; value: string }) => void;
  disabled?: boolean;
};

const CustomSelect = ({ options, label, name, placeholder, required, onChange, ...restSelectProps }: SelectProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
  });

  const handleChange = (v: string) => {
    if (v && v.length) {
      setValue(name, v);
    }
    if (onChange) {
      onChange({ label: options[v]?.label, value: v });
    }
  };

  return (
    <FieldLayout className="z-2">
      {label && <Label label={t(label)} name={name} required={required} />}
      <Select.Root {...field} {...restSelectProps} dir="ltr" onValueChange={handleChange}>
        <Select.Trigger
          ref={ref}
          aria-label={(label && t(label)) || ''}
          className={clx(
            'group text-neutral-800 placeholder:text-neutral-100 lg-regular flex items-center justify-between',
            'autofill:bg-neutral-40 bg-neutral-40 rounded-lg border px-3 py-2',
            'hover:bg-neutral-50 transition-colors duration-quick ease-out',
            errors[name]
              ? `border-system-error-400 focus:border-system-error-400 focus:shadow-focus-error
              data-[state=open]:shadow-focus-error data-[state=open]:border-system-error-400`
              : `focus:border-neutral-50 focus:bg-neutral-30
              data-[state=open]:border-primary-300`,
            `disabled:bg-system-disabled-50 disabled:autofill:bg-system-disabled-50 
            disabled:border-system-disabled-300 disabled:text-system-disabled-300`,
          )}
          data-testid={`select-${label}`}
        >
          <Select.Value aria-label={options[field.value]?.label}>
            {options[field.value]?.label || (placeholder && t(placeholder))}
          </Select.Value>
          <Select.Icon className="SelectIcon">
            <i
              className={`fa-solid fa-caret-down text-neutral-500 group-disabled:text-system-disabled-300 
            group-data-[state=open]:rotate-180 transition-transform duration-quick ease-out`}
            />
          </Select.Icon>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content
            className="z-[100] bg-neutral-0 border border-neutral-30 rounded-lg shadow-raised max-h-52"
            position="popper"
            asChild
          >
            <Select.Viewport className="px-1.5 py-0.5">
              {Object.keys(options).map((value) => (
                <Select.Item value={value} {...options[value]} key={value} className="group">
                  <Select.ItemText>
                    <span
                      className={clx(
                        'flex items-center justify-between gap-2 p-2.5 text-neutral-900 lg-regular',
                        'group-hover:bg-neutral-30 clickable outline-0 cursor-pointer group-data-[state=checked]:bg-neutral-50',
                        'transition-colors duration-quick ease-out',
                      )}
                    >
                      {t(options[value].label)}
                      <i className="group-data-[state=checked]:visible invisible fa-solid fa-circle-check" />
                    </span>
                  </Select.ItemText>
                </Select.Item>
              ))}
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
      {!!errors[name] && <ErrorLabel error={errors[name]?.message} />}
    </FieldLayout>
  );
};

export default CustomSelect;
