import {
  CardElementTypeCardElementTypeRead,
  CardTypeCardTypeRead,
  FactionFactionRead,
  IllustrationTypeIllustrationTypeRead,
  RarityRarityRead,
  UserProfileJsonldOptinRead,
  UserTokenJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead,
} from '@/api/generated';
import { Mapped } from 'types/utils';

export const FACTION_API_REF: Mapped<FactionFactionRead['reference'], FactionFactionRead['reference']> = {
  OR: 'OR',
  BR: 'BR',
  LY: 'LY',
  AX: 'AX',
  MU: 'MU',
  YZ: 'YZ',
};

export const RARITY_API_REF: Mapped<RarityRarityRead['reference'], RarityRarityRead['reference']> = {
  COMMON: 'COMMON',
  RARE: 'RARE',
  UNIQUE: 'UNIQUE',
};

export const CARD_TYPE_API_REF: Mapped<CardTypeCardTypeRead['reference'], CardTypeCardTypeRead['reference']> = {
  HERO: 'HERO',
  CHARACTER: 'CHARACTER',
  PERMANENT: 'PERMANENT',
  LANDMARK_PERMANENT: 'LANDMARK_PERMANENT',
  EXPEDITION_PERMANENT: 'EXPEDITION_PERMANENT',
  SPELL: 'SPELL',
  TOKEN: 'TOKEN',
  FOILER: 'FOILER',
  TOKEN_MANA: 'TOKEN_MANA',
};

export const CARD_PRODUCT_API_REF = {
  BOOSTER: 'B',
  ALT: 'A',
  PROMO: 'P',
};

export const CARD_ELEMENT_API_REF: Mapped<
  CardElementTypeCardElementTypeRead['reference'],
  CardElementTypeCardElementTypeRead['reference']
> = {
  MAIN_COST: 'MAIN_COST',
  RECALL_COST: 'RECALL_COST',
  OCEAN_POWER: 'OCEAN_POWER',
  MOUNTAIN_POWER: 'MOUNTAIN_POWER',
  FOREST_POWER: 'FOREST_POWER',
  MAIN_EFFECT: 'MAIN_EFFECT',
  ECHO_EFFECT: 'ECHO_EFFECT',
  RESERVE: 'RESERVE',
  PERMANENT: 'PERMANENT',
};

export const ILLUSTRATION_TYPE_API_REF: Mapped<string, IllustrationTypeIllustrationTypeRead['reference']> = {
  CARD: 'CARD',
  AVATAR: 'USER_AVATARS',
  PROFILE_BACKGROUND: 'USER_BACKGROUNDS',
  DECK_THUMB: 'DECK_THUMB',
  HERO_THUMB: 'HERO_THUMB',
  FACTION_ICON_FILTER: 'FACTION_ICON_FILTER',
  FRAMELESS: 'FRAMELESS',
  WEB: 'WEB',
  CARD_BACK: 'CARD_BACK',
};

export const USER_TOKEN_TYPE_REF: Mapped<
  UserTokenJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead['type'],
  UserTokenJsonldUserReadUserPrivateReadUserTokenReadUserSocialNetworkReadUserOptinRead['type']
> = {
  'activation-code': 'activation-code',
  'activation-link': 'activation-link',
  'activation-shop-member': 'activation-shop-member',
  'authorization-iban': 'authorization-iban',
  kickstarter: 'kickstarter',
  newsletter: 'newsletter',
};

export const LORE_ENTRY_ELEMENT_TYPE_API_REF = {
  FLAVOR_TEXT: 'FLAVOR_TEXT',
};

// TODO: GET CODE LIST IN GENERATED.TS
export const CURRENCY_API_CODE = {
  USD: 'USD',
  EUR: 'EUR',
};

export const USER_ROLE_API_REF: Mapped<
  UserProfileJsonldOptinRead['reference'],
  UserProfileJsonldOptinRead['reference']
> = {
  ROLE_CUSTOMER: 'ROLE_CUSTOMER',
  ROLE_SHOP: 'ROLE_SHOP',
  ROLE_SHOP_OWNER: 'ROLE_SHOP_OWNER',
  ROLE_SHOP_MEMBER: 'ROLE_SHOP_MEMBER',
  ROLE_BGA: 'ROLE_BGA',
  ROLE_SHOP_ARTIST: 'ROLE_SHOP_ARTIST',
  ROLE_PROD_PARTNER: 'ROLE_PROD_PARTNER',
  ROLE_ADMIN: 'ROLE_ADMIN',
};
