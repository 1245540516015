import { PropsWithChildren } from 'react';
import { ButtonOrLinkProps } from '@/atoms/ButtonOrLink/ButtonOrLink';
import { SIZE } from 'types/utils';

export enum BUTTON_VARIANTS {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  TERTIARY = 'Tertiary',
}

export type ButtonProps = PropsWithChildren<
  ButtonOrLinkProps & {
    size?: SIZE;
    variant?: BUTTON_VARIANTS;
    icon?: string;
    className?: string;
  }
>;
