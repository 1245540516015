import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ErrorLabel, Label } from '@/atoms/forms/labels';
import IconButton from '@/atoms/IconButton';
import FieldLayout from '../../FieldLayout';
import HTMLInput from '../Text/HTMLInput';
import { InputProps } from '../types';
import { computeState } from '../utils';
import { REGEX_PWD } from './passwordComplexity';
import ShowComplexity from './ShowComplexity';

const InputPassword = (props: InputProps) => {
  const { label, name, disabled, required, ...inputProps } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const { control, formState } = useFormContext();
  const { field } = useController({
    name,
    control,
  });
  const { errors, dirtyFields } = formState;
  const state = computeState(props, formState);

  const toggleHandler = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    setPasswordStrength(
      REGEX_PWD.reduce((strength, regex) => strength + Number(new RegExp(regex).test(field.value)), 0),
    );
  }, [field.value]);

  return (
    <FieldLayout>
      {label && <Label disabled={disabled} label={label} name={name} required={required} />}
      <div className="relative">
        <HTMLInput
          id={name}
          {...field}
          {...inputProps}
          name={name}
          state={state}
          type={showPassword ? 'text' : 'password'}
        />
        <IconButton
          className="absolute right-1 top-1/2 -translate-y-1/2"
          icon={showPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
          onClick={toggleHandler}
        />
      </div>
      {!!errors[name] && <ErrorLabel error={errors[name]?.message} />}
      {dirtyFields[name] && !!field.value && <ShowComplexity complexity={passwordStrength} />}
    </FieldLayout>
  );
};

export default InputPassword;
