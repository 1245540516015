import clx from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import FieldLayout from '@/atoms/forms/FieldLayout';
import { ErrorLabel, Label } from '@/atoms/forms/labels';
import HTMLTextArea from './HTMLTextArea';
import { TextAreaProps } from './types';

const TextArea = (props: TextAreaProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { label, name, maxLength, disabled, ...restProps } = props;
  const {
    field,
    field: { value },
  } = useController({
    name,
    control,
  });

  return (
    <FieldLayout>
      {label && <Label disabled={disabled} label={label} name={name} />}
      <HTMLTextArea
        {...restProps}
        {...field}
        disabled={disabled}
        id={name}
        isInvalid={!!errors[name]}
        value={field.value || ''}
      />
      <div className="flex items-center justify-between">
        {!!errors[name] && <ErrorLabel error={errors[name]?.message} />}
        {maxLength && (
          <p className={clx('md-regular text-neutral-300', value && value.length > maxLength && 'text-error-400')}>
            {value ? value.length : 0}/{maxLength}
          </p>
        )}
      </div>
    </FieldLayout>
  );
};

export default TextArea;
