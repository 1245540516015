import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextInput from '@/atoms/forms/Input/Text/Input';
import { ErrorLabel } from '@/atoms/forms/labels';
import Select from '@/atoms/forms/Select';
import Form from '@/molecules/Form';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import { FormOptions } from 'types/forms';
import BankAccountTypeFields from './BankAccountTypeFields';
import useBankAccountForm from './hooks/useBankAccountForm';
import { BankAccountFields, BankType, bankAccountTypes } from './types';

const bankAccountTypeOptions: FormOptions = bankAccountTypes.reduce((acc: FormOptions, type: BankType) => {
  return { ...acc, [type]: { label: type } };
}, {});

type Props = {
  closeModal: () => void;
};

const BankAccountCreateModal = ({ closeModal }: Props) => {
  const { t } = useTranslation();
  const { onSubmit, formMethods, isPending, isError, countriesOptions } = useBankAccountForm();

  const type = formMethods.watch('type');

  return (
    <ModalClassicLayout closeModal={closeModal} title={t('bank-account-create')}>
      <FormProvider {...formMethods}>
        <Form className="flex flex-col gap-4" isMutating={isPending} onSubmit={onSubmit} submitLabel={t('save')}>
          <TextInput
            label="bank-account-owner-name"
            name={BankAccountFields.ownerName}
            placeholder="bank-account-owner-name"
            type="text"
            required
          />

          <TextInput
            label="addressLine1"
            name={BankAccountFields.addressLine1}
            placeholder="address-line-1"
            type="text"
            required
          />
          <TextInput
            label="addressLine2"
            name={BankAccountFields.addressLine2}
            placeholder="address-line-2"
            type="text"
          />

          <div className="flex md:flex-row gap-4">
            <TextInput
              label="postalcode"
              name={BankAccountFields.postalCode}
              placeholder="postalcode"
              type="text"
              required
            />
            <TextInput label="city" name={BankAccountFields.city} placeholder="city" type="text" required />
          </div>

          <div className="flex md:flex-row gap-4">
            <TextInput label="region" name={BankAccountFields.region} placeholder="region" type="text" />

            {countriesOptions && (
              <Select
                label="country"
                name={BankAccountFields.countryLabel}
                options={countriesOptions}
                placeholder="country"
                required
              />
            )}
          </div>

          <Select
            label="bank-account-type"
            name={BankAccountFields.type}
            options={bankAccountTypeOptions}
            placeholder="bank-account-type"
            required
          />

          {type && <BankAccountTypeFields type={type} />}

          {isError && <ErrorLabel error="bank-account-create-error" />}
        </Form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default BankAccountCreateModal;
