import clx from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { FormProvider } from 'react-hook-form';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import SearchBar from '@/atoms/forms/SearchBar';
import DropDown from '@/molecules/DropDown';
import TabsLink from '@/molecules/Tabs/TabsLink';
import { FilterDataDto, SIZE } from 'types/utils';
import ActiveFilters from './components/ActiveFilters';
import useListHeader from './useListHeader';

const ListHeader = ({
  loading,
  filterData,
  totalItems,
  onFilterSubmit,
}: {
  loading?: boolean;
  filterData?: FilterDataDto;
  totalItems?: number;
  onFilterSubmit?: () => void;
}) => {
  const { t } = useTranslation();
  const {
    openModal,
    formMethods,
    handleSubmit,
    searchBar,
    optionsOrder,
    activeFilterCount,
    onClear,
    onFieldReset,
    showModal,
    tabs,
  } = useListHeader({ filterData, onFilterSubmit });

  return (
    <FormProvider {...formMethods}>
      <form className="flex flex-col gap-4 mb-8" name="filters-bar" onSubmit={handleSubmit}>
        <div className={clx('flex flex-col lg:flex-row items-center lg:items-start gap-2 my-2')}>
          <div className={clx('flex gap-2 items-center w-full')}>
            {searchBar && !!searchBar.choicesSource && (
              <SearchBar
                className="grow"
                label={searchBar.choicesSource[0].title}
                name={searchBar.choicesSource[0].source}
                onReset={() => searchBar.choicesSource && onFieldReset(searchBar.choicesSource[0].source)}
                placeholder="..."
              />
            )}
            {showModal && (
              <Button
                className="h-fit"
                icon={clx('fa-solid', loading ? 'fa-circle-notch animate-spin' : 'fa-filters')}
                onClick={openModal}
                size={SIZE.MD}
              >
                <span className="hidden lg:inline-block">
                  <span className="mr-1">{t('filters')}</span>
                  <span
                    className={clx(activeFilterCount > 0 ? 'inline-block' : 'hidden')}
                  >{`(${activeFilterCount})`}</span>
                </span>
              </Button>
            )}
          </div>
        </div>
        <ActiveFilters filterData={filterData} onClear={onClear} onFieldReset={onFieldReset} />
        <TabsLink items={tabs} />
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <p>
            <span className="md-bold mr-1">{totalItems || 0}</span>
            <span className="md-regular">{t('results-found', { count: totalItems })}</span>
          </p>
          {optionsOrder && (
            <DropDown
              items={optionsOrder.map(({ href, label }) => (
                <Link key={href} href={href}>
                  {label}
                </Link>
              ))}
              trigger={
                <Button icon="fa-solid fa-sort" variant={BUTTON_VARIANTS.SECONDARY}>
                  {optionsOrder?.find(({ selected }) => selected)?.label || t('sort-by')}
                </Button>
              }
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default ListHeader;
