import clx from 'classnames';
import { useTranslation } from 'react-i18next';

type LabelProps = {
  label: string;
  disabled?: boolean;
  required?: boolean;
  name: string;
  isInvalid?: boolean;
} & JSX.IntrinsicElements['label'];

const Label = ({ label, disabled, name, required = false, isInvalid, ...props }: LabelProps) => {
  const { t } = useTranslation();

  return (
    <label
      className={clx(
        'md-medium text-neutral-900',
        disabled && 'text-system-disabled-300',
        isInvalid && 'text-system-error-400',
      )}
      htmlFor={name}
      {...props}
    >
      {t(label)} {required && <span className="md-bold">*</span>}
    </label>
  );
};

export default Label;
