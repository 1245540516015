import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCmsData } from 'utils/cms';
import formatOptions from 'utils/form/formatOptions';

const localeSchema = yup
  .object({
    locale: yup.string().required(),
  })
  .required();

type FormValues = yup.InferType<typeof localeSchema>;

const useLanguageModal = ({ closeModal }: { closeModal: () => void }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    generalSettings: { languages },
  } = useCmsData();

  const router = useRouter();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      locale: language,
    },
  });
  const { handleSubmit } = formMethods;

  const onSubmit = async (formValues: FormValues) => {
    const { asPath } = router;
    window.location.href = `/${formValues.locale}/${asPath}`;
    closeModal();
  };

  const i18nOptions = formatOptions(languages, { valueField: 'locale' });

  return {
    onSubmit: handleSubmit(onSubmit),
    i18nOptions,
    formMethods,
  };
};

export default useLanguageModal;
