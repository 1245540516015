import { RequestParams } from '@/api/generated';
import { ParsedQuery } from 'query-string';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const tradeListFactory = {
  ...createKeyFactory([KEYS.TRADE_LIST]),
  myFriendList: (params?: ParsedQuery | string) => [...tradeListFactory.all(), 'myFriendsList', params],
  myRequests: (params?: RequestParams) => [...tradeListFactory.all(), 'myRequests', params],
};

export default tradeListFactory;
