import { useModal } from 'context/Modal/Context';
import { MODAL_NAME } from 'context/Modal/MODAL_NAME';
import useCardsApi from 'hooks/queries/cards/useCardsApi';

const useCardListModal = ({ printingPage }: { printingPage: string }) => {
  const data = useCardsApi({ collection: 'true' });
  const { openModal } = useModal();

  const handleCardClick = (id: string, productReference?: string) => {
    if (productReference === 'B') {
      openModal(MODAL_NAME.ADD_CARD_TO_PAGE, { cardId: id, printingPage });
    } else {
      openModal(MODAL_NAME.ADD_CARD_ALT, { cardId: id, printingPage });
    }
  };

  const onFilterSubmit = () => {
    openModal(MODAL_NAME.CARD_LIST, { printingPage });
  };

  return { data, handleCardClick, onFilterSubmit };
};

export default useCardListModal;
