import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

export const userBankMethodsFactory = createKeyFactory([KEYS.PAYOUT_BANK]);

const useUserBankAccount = () => {
  const apiClient = useApi();

  return useQuery({
    queryKey: userBankMethodsFactory.all(),
    queryFn: async () => apiClient.payments.apiPaymentsbankAccountGet(),
    select: (data) => data?.data,
  });
};

export default useUserBankAccount;
