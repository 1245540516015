import { useController, useFormContext } from 'react-hook-form';
import { BaseProps } from '@/atoms/forms/types';
import Fieldset from '@/molecules/Fieldset';
import { CmsImageType } from 'utils/cms/types';
import addOrRemoveItem from 'utils/form/addOrRemoveItem';
import SetChip from './SetChip';

interface FormOption {
  acf: {
    api_id: string;
    background_image: CmsImageType;
    display_title: string;
    icon_font: string;
    reference: string;
    type: string;
  };
}

interface FormOptions {
  [value: string]: FormOption;
}

type CheckSetProps = BaseProps & {
  options: FormOptions;
  disabled?: boolean;
};

const CheckSetChip = ({ name, options, label, disabled }: CheckSetProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name,
    control,
    defaultValue: [],
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(addOrRemoveItem(event.target.value, field.value));
  };

  return (
    <Fieldset className="max-w-full min-w-0 overflow-x-auto" disabled={disabled} legend={label}>
      <ul className="flex gap-2">
        {Object.keys(options).map((key) => (
          <li key={`${name}-${options[key].acf.reference}`}>
            <SetChip
              background={options[key].acf.background_image.sizes.large}
              checked={field.value.includes(options[key].acf.reference)}
              icon={options[key].acf.icon_font}
              id={`${name}-${options[key].acf.reference}`}
              isInvalid={!!errors[name]}
              label={options[key].acf.display_title}
              name={name}
              onChange={onChange}
              value={options[key].acf.reference}
            />
          </li>
        ))}
      </ul>
    </Fieldset>
  );
};

export default CheckSetChip;
