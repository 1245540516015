import * as Dialog from '@radix-ui/react-dialog';
import clx from 'classnames';
import { LayoutGroup, easeOut, motion } from 'framer-motion';
import IconButton from '@/atoms/IconButton';
import OVERLAY_CLASSNAME from 'context/Modal/layout/const';
import { SIZE } from 'types/utils';
import { ModalProps } from '../views/types';

const MotionDialogOverlay = motion(Dialog.Overlay);
const MotionDialogContent = motion(Dialog.Content);

const ModalNavLayout = ({ children, closeModal, direction }: ModalProps & { direction: 'left' | 'right' }) => (
  <Dialog.Root onOpenChange={closeModal} defaultOpen>
    <Dialog.DialogPortal forceMount>
      <LayoutGroup>
        <MotionDialogOverlay
          animate={{
            backgroundColor: '#00000090',
            transition: { duration: 0.4, ease: easeOut },
          }}
          className={clx(OVERLAY_CLASSNAME)}
          exit={{ backgroundColor: '#00000000', transition: { duration: 0.4, ease: easeOut } }}
          initial={{ backgroundColor: '#00000000' }}
          forceMount
        >
          <MotionDialogContent
            animate={{
              translateX: 0,
              transition: { duration: 0.4, ease: easeOut },
            }}
            className={clx('h-full absolute', direction === 'left' ? 'left-0 w-80 lg:w-[470px]' : 'right-0 w-80')}
            exit={{
              translateX: direction === 'left' ? '-100%' : '100%',
              transition: { duration: 0.4, ease: easeOut },
            }}
            initial={{ translateX: direction === 'left' ? '-100%' : '100%' }}
            forceMount
          >
            <div className="bg-sand-300 h-full overflow-y-auto shadow-overflow relative">
              <IconButton
                className="fixed z-10 right-4 top-4 flex items-center"
                icon="fa-solid fa-xmark-large"
                onClick={closeModal}
                size={SIZE.MD}
              />
              {children}
            </div>
          </MotionDialogContent>
        </MotionDialogOverlay>
      </LayoutGroup>
    </Dialog.DialogPortal>
  </Dialog.Root>
);

export default ModalNavLayout;
