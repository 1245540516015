import { init as initTracking, push } from '@socialgouv/matomo-next';
import { env } from 'next-runtime-env';

export const init = () => {
  initTracking({
    url: env('NEXT_PUBLIC_MATOMO_URL') || '',
    siteId: env('NEXT_PUBLIC_MATOMO_SITE_ID') || '',
  });
};

export const trackEvent = (
  category: string,
  action: string,
  name?: string,
  value?: string | number,
  dimensions?: Record<string, string>,
) => {
  push(['trackEvent', category, action, name, value, dimensions]);
};
