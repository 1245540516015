import { useTranslation } from 'react-i18next';
import AvatarLabel from '@/atoms/AvatarLabel';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import ProductWrap from '@/molecules/ProductWrap';
import useMyCart from 'hooks/queries/marketplace/carts/useMyCart';
import { MarketplaceOrderItem } from 'types/alias/types';

const OrderItemsList = ({
  orderItems,
  disableDelete = true,
}: {
  orderItems: MarketplaceOrderItem[];
  disableDelete?: boolean;
}) => {
  const { t } = useTranslation();
  const { removeGroupOffer } = useMyCart();

  return (
    <ul className="flex flex-col gap-4">
      {orderItems.map(({ card, seller, quantity, total, offerGroupId, enabled }) => (
        <li
          key={card['@id']}
          className={`flex flex-wrap gap-2
  before:h-px before:basis-full before:bg-neutral-50 before:block before:first:hidden before:mb-2`}
        >
          {!enabled && <p className="basis-full sm-regular text-system-error-400">{t('group-offer-unavailable')}</p>}
          <ProductWrap
            className="grow"
            image={card.imagePath}
            line1={`${t('quantity')}: ${quantity}`}
            line2={<AvatarLabel avatarPath={seller.avatarPath} nickName={seller.nickName} />}
            price={total}
            subTitle={card.rarity?.name}
            title={card.name}
          />
          {disableDelete && (
            <Button
              className="w-fit self-start"
              icon="fa-solid fa-trash"
              onClick={() => removeGroupOffer.mutate(offerGroupId)}
              variant={BUTTON_VARIANTS.TERTIARY}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default OrderItemsList;
