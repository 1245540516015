import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { MarketplaceOfferCardOfferDataJsonldCardOfferDataRead } from '@/api/generated';
import { MAX_PRICE, MIN_PRICE } from 'context/Modal/views/MarketplaceActions/ManageOffer/consts';
import useNumberCopyAvailable from 'context/Modal/views/MarketplaceActions/useNumberCopyAvailable';
import { useToaster } from 'context/Toaster/ToasterContext';
import { getErrorCode, getErrorMessage } from 'hooks/networkError/utils';
import useAddOffers from 'hooks/queries/marketplace/offers/useAddOffers';
import useEditOffers from 'hooks/queries/marketplace/offers/useEditOffers';
import { CardDetailsAlias } from 'types/alias/types';
import { VARIANT_COLOR_ENUM } from 'types/utils';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

type FormValues = { quantity: number; price: number };
const offersFactory = createKeyFactory([KEYS.OFFERS]);
const cardsMarketplaceFactory = createKeyFactory([KEYS.CARDS_MARKETPLACE]);

const useManageOffer = ({
  closeModal,
  cardOffer,
  card,
}: {
  closeModal: () => void;
  card: CardDetailsAlias;
  cardOffer?: MarketplaceOfferCardOfferDataJsonldCardOfferDataRead;
}) => {
  const { addToaster } = useToaster();
  const { numberCopyAvailable = 0 } = useNumberCopyAvailable(card.reference);
  const queryClient = useQueryClient();

  const schema = () =>
    yup
      .object({
        quantity: yup
          .number()
          .min(1)
          .max(cardOffer?.quantity || numberCopyAvailable)
          .required(),
        price: yup
          .number()
          .required()
          .min(MIN_PRICE)
          .max(MAX_PRICE)
          .test('two-decimals', 'two-decimals-error', (value) => /^\d+(\.\d{0,2})?$/.test(value.toString())),
      })
      .required();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      quantity: cardOffer ? cardOffer.quantity : 1,
      price: cardOffer ? cardOffer.price : card.lowerPrice,
    },
    resolver: yupResolver(schema()),
  });
  const { handleSubmit, watch, setError } = formMethods;

  const onSuccess = async () => {
    addToaster({ message: 'publish-marketplace-success', variant: VARIANT_COLOR_ENUM.SUCCESS });
    await queryClient.invalidateQueries({
      queryKey: [
        offersFactory.list({
          reference: card.reference,
        }),
        cardsMarketplaceFactory.list(),
      ],
    });
    closeModal();
  };

  const onError = async (error: unknown) => {
    if (getErrorCode(error) === 400) {
      setError('price', {
        type: 'server',
        message: await getErrorMessage(error),
      });
    } else {
      addToaster({ variant: VARIANT_COLOR_ENUM.ERROR, message: await getErrorMessage(error) });
      closeModal();
    }
  };
  const { addOffer } = useAddOffers(onSuccess, onError);
  const { updateOffer } = useEditOffers(onSuccess, onError);
  const onSubmit = (formValues: FormValues) => {
    if (cardOffer && cardOffer.groupId) {
      updateOffer.mutate({ price: formValues.price, groupId: cardOffer.groupId });
    } else {
      addOffer.mutate({ cards: [{ ...formValues, reference: card.reference }] });
    }
  };

  const total = watch('quantity') * watch('price');

  return {
    formMethods,
    onSubmit: handleSubmit(onSubmit),
    closeModal,
    total,
    numberCopyAvailable: cardOffer?.quantity || numberCopyAvailable,
  };
};

export default useManageOffer;
