import * as Dialog from '@radix-ui/react-dialog';
import clx from 'classnames';
import { LayoutGroup, easeOut, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import OVERLAY_CLASSNAME from 'context/Modal/layout/const';
import { ModalFooterProps, ModalProps } from '../views/types';
import ModalHeader from './ModalHeader';

const MotionDialogOverlay = motion(Dialog.Overlay);
const MotionDialogContent = motion(Dialog.Content);

const ModalFooter = ({ onSubmit, alert = false, submitLabel }: ModalFooterProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row px-6 pb-6 gap-3 bg-neutral-20  mt-8 rounded-b-lg">
      {alert ? (
        <Dialog.DialogClose asChild>
          <Button className="grow" onClick={onSubmit} type="button">
            {t(submitLabel || 'close')}
          </Button>
        </Dialog.DialogClose>
      ) : (
        <>
          <Dialog.DialogClose asChild>
            <Button className="grow" type="button" variant={BUTTON_VARIANTS.SECONDARY}>
              {t('cancel')}
            </Button>
          </Dialog.DialogClose>
          <Dialog.DialogClose asChild>
            <Button className="grow" onClick={onSubmit} type="button">
              {t(submitLabel || 'validate')}
            </Button>
          </Dialog.DialogClose>
        </>
      )}
    </div>
  );
};

const ModalClassicLayout = ({ children, closeModal, title, onSubmit, alert = false, submitLabel }: ModalProps) => (
  <Dialog.Root onOpenChange={closeModal} defaultOpen>
    <Dialog.DialogPortal forceMount>
      <LayoutGroup>
        <MotionDialogOverlay
          animate={{
            backgroundColor: '#FBF7EB70',
            backdropFilter: 'blur(10px) opacity(1)',
            WebkitBackdropFilter: 'blur(10px) opacity(1)',
            transition: { duration: 0.2, ease: easeOut },
          }}
          className={clx(OVERLAY_CLASSNAME, 'flex items-end md:items-center bg-primary-50/70 backdrop-blur-sm')}
          exit={{
            backgroundColor: '#D4AF3700',
            backdropFilter: 'blur(10px) opacity(0)',
            WebkitBackdropFilter: 'blur(10px) opacity(0)',
            opacity: 0,
            transition: { duration: 0.2, ease: easeOut },
          }}
          initial={{ backgroundColor: '#D4AF3700', backdropFilter: 'blur(10px) opacity(0)' }}
          forceMount
        >
          <MotionDialogContent
            animate={{
              translateY: 0,
              transition: { duration: 0.2, ease: easeOut },
            }}
            className="w-full md:max-w-vw-2/3 md:mx-auto shadow-overlap"
            exit={{
              translateY: '-0.75rem',
              transition: { duration: 0.2, ease: easeOut },
            }}
            initial={{ translateY: '-0.75rem' }}
            forceMount
          >
            {title && <ModalHeader closeModal={closeModal} title={title} />}
            <div className="bg-neutral-20 h-fit max-h-[80vh] md:max-h-[70vh] flex flex-col rounded-b-lg">
              <div className="px-6 py-4 overflow-y-auto grow">{children}</div>
              {(onSubmit || alert) && <ModalFooter alert={alert} onSubmit={onSubmit} submitLabel={submitLabel} />}
            </div>
          </MotionDialogContent>
        </MotionDialogOverlay>
      </LayoutGroup>
    </Dialog.DialogPortal>
  </Dialog.Root>
);

export default ModalClassicLayout;
