import clx from 'classnames';
import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import { BaseProps } from '../../types';

type SetChipProps = InputHTMLAttributes<HTMLInputElement> &
  BaseProps & {
    checked: boolean;
    value: string;
    isInvalid?: boolean;
    background: string;
    icon: string;
  };

const SetChip = forwardRef(
  (
    { name, onChange, label, id, value, checked, icon, background, isInvalid }: SetChipProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <label
      className={clx(
        'relative rounded-2xl overflow-hidden bg-[#333333] group block transition w-42 h-24',
        isInvalid && 'border-security-red-400 border-2',
      )}
      htmlFor={id}
    >
      <img
        alt=""
        className={clx(
          'block transition max-w-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
          checked ? 'opacity-70' : 'opacity-20 group-hover:opacity-60',
        )}
        src={background}
      />
      <input
        ref={ref}
        checked={checked}
        className="appearance-none hidden peer"
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <div
        className={clx(
          'absolute top-1/2 -translate-y-1/2 w-full text-center drop-shadow-md',
          isInvalid ? 'text-security-red-400' : 'text-neutral-0',
        )}
      >
        <p>{icon && <i className={clx(icon, 'text-3xl')} />}</p>
        <p className="sm-bold-caps">{label}</p>
      </div>
    </label>
  ),
);

SetChip.displayName = 'SetChip';

export default SetChip;
