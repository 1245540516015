import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useApi from 'hooks/useApi';
import printingFactory from 'utils/factories/printing';

type Props = {
  printingOrder: string;
  closeModal: () => void;
};

const schema = () =>
  yup.object({
    printingPageType: yup.string().required(),
  });

type FormValues = yup.InferType<ReturnType<typeof schema>>;

const REFERENCE_TO_ICON = {
  NORMAL: 'fa-solid fa-cards-blank',
  FOIL: 'fa-solid fa-sparkles',
};

const useCreatePrintingPage = ({ printingOrder, closeModal }: Props) => {
  const api = useApi();
  const { query } = useRouter();
  const { data } = useQuery({
    queryKey: printingFactory.pageType(),
    queryFn: async () => (await api.printingPageTypes.apiPrintingPageTypesGetCollection()).data,
  });

  const queryClient = useQueryClient();
  const currentId = query.slug?.[1] || '';

  const createPage = useMutation({
    mutationKey: ['createPage'],
    mutationFn: ({ printingPageType }: { printingPageType: string }) =>
      api.printingPages.apiPrintingPagesPost({ printingOrder: `/printing_orders/${printingOrder}`, printingPageType }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: printingFactory.details(currentId) }),
  });

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema()),
  });

  const options = data?.['hydra:member'].reduce((acc, { '@id': id, name, reference }) => {
    if (!id) {
      return acc;
    }

    return {
      ...acc,
      [id]: {
        label: name,
        icon: REFERENCE_TO_ICON[reference],
      },
    };
  }, {});

  const { handleSubmit } = formMethods;
  const onSubmit = async (formValues: { printingPageType: string }) => {
    await createPage.mutate(formValues);
    closeModal();
  };

  return { formMethods, options, handleSubmit: handleSubmit(onSubmit) };
};

export default useCreatePrintingPage;
