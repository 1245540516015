/* eslint-disable camelcase */
import { QueryParametersType } from 'types/cms';
import { CMSQueryParametersType } from 'utils/cms/types';

const formatQueryParams = (queryParams: CMSQueryParametersType[]): QueryParametersType[] =>
  queryParams.map(
    ({
      query_parameter_type,
      query_parameter_name,
      query_parameter_order,
      query_parameter_display_title,
      query_parameter_choices,
      query_parameter_choices_source,
      query_parameter_is_display,
      query_parameter_is_query_string,
      query_parameter_with_disclosure,
    }) => {
      return {
        withDisclosure: !!query_parameter_with_disclosure,
        type: query_parameter_type,
        name: query_parameter_name,
        order: query_parameter_order,
        displayTitle: query_parameter_display_title,
        choices:
          !!query_parameter_choices &&
          query_parameter_choices.map((choice) => {
            return {
              choice: choice.choice,
              value: choice.choice_value,
              label: choice.choice_label,
              icon: choice.choice_icon,
            };
          }),
        choicesSource:
          !!query_parameter_choices_source &&
          query_parameter_choices_source.map((choiceSource) => {
            return {
              source: choiceSource.choice_source,
              component: choiceSource.choice_source_component,
              title: choiceSource.choice_source_title,
              data: choiceSource.choice_data || '',
            };
          }),
        isDetails: query_parameter_is_display,
        isDisplay: query_parameter_is_display,
        isQueryString: query_parameter_is_query_string,
      };
    },
  );

export default formatQueryParams;
