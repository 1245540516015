import { createContext, useContext } from 'react';
import type { ModalViewsKeys, ModalViewsProps } from 'context/Modal/constants';

type ModalContextType<T extends ModalViewsKeys | null> = {
  openModal: <TT extends ModalViewsKeys>(modalName: TT, modalProps: ModalViewsProps<TT>) => void;
  closeModal: () => void;
  modalProps: ModalViewsProps<NonNullable<T>> | null;
};

export const initalState: ModalContextType<ModalViewsKeys> = {
  openModal: () => {},
  closeModal: () => {},
  modalProps: null,
};

export const ModalContext = createContext(initalState);
export const useModal = () => useContext(ModalContext);
