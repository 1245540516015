/* eslint-disable import/prefer-default-export */
import { FieldValues, FormState } from 'react-hook-form';
import { INPUT_STATE, InputProps } from './types';

export const computeState = (inputProps: InputProps, formState: FormState<FieldValues>) => {
  const { errors, isSubmitted, dirtyFields } = formState;
  const { name, validMessage } = inputProps;
  if (errors[name]) {
    return INPUT_STATE.ERROR;
  }
  if (isSubmitted && dirtyFields[name] && validMessage) {
    return INPUT_STATE.SUCCESS;
  }

  return INPUT_STATE.DEFAULT;
};
