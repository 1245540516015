import { ReactNode } from 'react';
import ModalClassicLayout from 'context/Modal/layout/Classic';

type AlertModalProps = {
  closeModal: () => void;
  title: string;
  submitLabel?: string;
  content: ReactNode;
};

const AlertModal = ({ closeModal, title, content, submitLabel }: AlertModalProps) => (
  <ModalClassicLayout closeModal={closeModal} submitLabel={submitLabel} title={title} alert>
    <div className="flex flex-col pb-4">{content}</div>
  </ModalClassicLayout>
);
export default AlertModal;
