import { useTranslation } from 'react-i18next';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import IconButton from '@/atoms/IconButton';
import Facility from '@/molecules/Facility';
import ShowAddress from '@/molecules/ShowAddress';
import ERROR_ROUTES from 'constants/routes';
import useShopDetailsApi from 'hooks/queries/shop/useShopDetailsApi';
import { SIZE } from 'types/utils';
import ModalClassicLayout from '../../layout/Classic';

type ShopDetailsModalProps = {
  closeModal: () => void;
  shopId: string;
};

const ShopDetailsModal = ({ closeModal, shopId }: ShopDetailsModalProps) => {
  const { shop, isLoading } = useShopDetailsApi(shopId);
  const { t } = useTranslation();
  const address = shop?.user?.addresses?.[0];

  return (
    <ModalClassicLayout closeModal={closeModal} title={isLoading || !shop ? t('loading') : shop.name || ''}>
      <div className="flex justify-center">
        {shop?.shopSocialNetworks?.map(
          (socialNetwork) =>
            !!socialNetwork.socialUrl && (
              <IconButton
                key={socialNetwork.id}
                href={socialNetwork.socialUrl || ERROR_ROUTES.NOT_FOUND}
                icon={`fa-brands fa-${socialNetwork.socialNetwork?.reference.toLocaleLowerCase()}`}
                size={SIZE.SM}
              />
            ),
        )}
      </div>
      <p className="text-center lg-regular text-neutrail-800 pb-8">{shop?.biography}</p>
      <div className="flex items-center flex-col bg-neutral-30 p-4 grow rounded-lg gap-3">
        {address && <ShowAddress city={address.city} street={address.street} zipCode={address.zipCode} />}
        <div className="flex text-neutral-600 md-medium gap-4">
          {shop?.mobilityReducedAccessibility && (
            <Facility icon="fa-solid fa-wheelchair-move">{t('PRM-access')}</Facility>
          )}
          {shop?.eatingSpace && <Facility icon="fa-solid fa-plate-utensils">{t('eating-space')}</Facility>}
          {shop?.sanitary && <Facility icon="fa-solid fa-toilet-portable">{t('sanitary')}</Facility>}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-3 mt-16">
        <Button className="grow" href={`/events?shop=${shopId}`} variant={BUTTON_VARIANTS.SECONDARY}>
          {t('events')}
        </Button>
        <Button className="grow" href={`/shop-profile/${shopId}`} variant={BUTTON_VARIANTS.PRIMARY}>
          {t('profil')}
        </Button>
      </div>
    </ModalClassicLayout>
  );
};

export default ShopDetailsModal;
