import { useFormContext, useController } from 'react-hook-form';
import { ErrorLabel, Label, SuccessLabel } from '@/atoms/forms/labels';
import Spinner from '@/atoms/Spinner';
import FieldLayout from '../../FieldLayout';
import { INPUT_STATE, InputProps } from '../types';
import { computeState } from '../utils';
import HTMLInput from './HTMLInput';

const TextInput = (props: InputProps) => {
  const { label, name, className, validMessage, disabled, required, ...restProps } = props;
  const { control, formState } = useFormContext();
  const { field } = useController({
    name,
    control,
  });
  const { errors, isValidating, dirtyFields } = formState;
  const error = errors[name];
  const state = computeState(props, formState);

  return (
    <FieldLayout className={className}>
      {label && <Label disabled={disabled} label={label} name={name} required={required} />}
      <div className="relative">
        {validMessage && !!dirtyFields[name] && !!isValidating && <Spinner className="absolute right-3 top-2" />}
        <HTMLInput {...restProps} disabled={disabled} {...field} id={name} state={state} value={field.value || ''} />
      </div>
      {!!error && <ErrorLabel error={error.message} />}
      {validMessage && state === INPUT_STATE.SUCCESS && <SuccessLabel text={validMessage} />}
    </FieldLayout>
  );
};

export default TextInput;
