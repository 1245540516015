import { useMutation } from '@tanstack/react-query';
import { DonationPayload } from 'context/Modal/views/MarketplaceActions/Give/type';
import useApi from 'hooks/useApi';

export type GiverOwnerShipPayload = {
  /** @example "/users/01GWKQNC4M55D3EWVFGCFJ5SB7" */
  user?: string;
  cards?: {
    /** @example "YZ_1" */
    reference?: string;
    /** @example "3" */
    quantity?: number;
  }[];
};

const useGiveOwnership = (
  onSuccess: (payload?: DonationPayload | undefined) => void,
  onError: (error: unknown) => void,
) => {
  const apiClient = useApi();
  const giveOwnership = useMutation({
    mutationKey: ['owners_give'],
    mutationFn: async (payload: GiverOwnerShipPayload) => (await apiClient.owners.apiOwnersgivePost(payload))?.data,
    onSuccess,
    onError,
  });

  return { giveOwnership };
};

export default useGiveOwnership;
