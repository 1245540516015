import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useModal } from 'context/Modal/Context';
import { MODAL_NAME } from 'context/Modal/MODAL_NAME';
import useApi from 'hooks/useApi';
import { useTranslation } from 'react-i18next';
import usersFactory from 'utils/factories/user';

export enum FRIENDS_STATUS {
  PENDING = 'PENDING',
  SENDING = 'SENDING',
  ACCEPTED = 'ACCEPTED',
  NONE = 'NONE',
  BLOCKED = 'BLOCKED',
}

const FriendListCta = ({
  userId,
  friendStatus,
  friendshipId,
}: {
  userId: string;
  friendshipId: string;
  friendStatus: FRIENDS_STATUS;
}) => {
  const api = useApi();
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const sendRequest = useMutation({
    mutationKey: ['send-request'],
    mutationFn: () => api.userFriendRequests.apiUserFriendRequestsPost({ receiver: userId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usersFactory.all() });
    },
  });

  const postRequest = useMutation({
    mutationKey: ['accept'],
    mutationFn: ({ accept, receiver }: { accept: boolean; receiver: string }) =>
      api.userFriendRequests.apiUserFriendRequestsPost({ accept, receiver }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usersFactory.all() });
    },
  });

  const removeFriendship = useMutation({
    mutationKey: ['remove-request'],
    mutationFn: () => api.userFriendships.apiUserFriendshipsIdDelete(friendshipId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usersFactory.all() });
    },
  });

  const handleRemoveFriend = async () => {
    const result = await new Promise((resolve) => {
      openModal(MODAL_NAME.CONFIRM_MODAL, {
        content: <p>{t('by-removing-friendship')}</p>,
        title: 'not-friends-anymore',
        onSubmit: () => resolve(true),
      });
    });
    if (result) {
      removeFriendship.mutate();
    }
  };

  switch (friendStatus) {
    case 'PENDING':
      return (
        <div className="flex gap-2">
          <Button
            onClick={() => {
              postRequest.mutate({ accept: false, receiver: userId || '' });
            }}
            variant={BUTTON_VARIANTS.SECONDARY}
          >
            {t('reject')}
          </Button>
          <Button
            onClick={() => {
              postRequest.mutate({ accept: true, receiver: userId || '' });
            }}
          >
            {t('accept')}
          </Button>
        </div>
      );
    case 'SENDING':
      return <Button disabled>{t('request-sent')}</Button>;
    case 'NONE':
      return (
        <Button icon="fa-solid fa-user-plus" onClick={() => sendRequest.mutate()}>
          {t('become-friend')}
        </Button>
      );
    case 'ACCEPTED':
      return (
        <Button icon="fa-solid fa-user-minus" onClick={handleRemoveFriend} variant={BUTTON_VARIANTS.TERTIARY}>
          {t('not-friends-anymore')}
        </Button>
      );

    default:
      return null;
  }
};

export default FriendListCta;
