import clx from 'classnames';
import { useFormContext, useController } from 'react-hook-form';
import SearchButton from '@/atoms/Button/SearchButton';
import Xclose from '@/atoms/Xclose';
import { InputProps } from './types';

interface SearchBarProps extends InputProps {
  onReset?: () => void;
}
const SearchBar = ({ label, name, className, onReset, ...inputProps }: SearchBarProps) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control,
  });

  return (
    <div
      className={clx(
        className,
        ' bg-neutral-40 hover:bg-neutral-50 transition clickable-lg',
        'lg-medium text-neutral-900 w-full py-2 pr-2 px-4 placeholder:text-neutral-100 flex gap-4 items-center',
      )}
    >
      {field.value && field.value !== '' && onReset && <Xclose onClick={onReset} />}
      <input
        {...inputProps}
        className={clx('appearance-none outline-none w-full', 'bg-transparent autofill:bg-transparent transition')}
        {...field}
      />
      <SearchButton />
    </div>
  );
};

export default SearchBar;
