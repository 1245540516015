import clx from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { HTMLTextAreaProps } from './types';

const HTMLTextArea = forwardRef(
  ({ value, isInvalid, ...inputProps }: HTMLTextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) => (
    <textarea
      ref={ref}
      {...inputProps}
      aria-invalid={isInvalid}
      className={clx(
        'w-full transition-all appearance-none border-neutral-500 shadow rounded-lg border px-3 py-2',
        'text-neutral-300 placeholder:text-neutral-100 lg-regular focus:outline-0',
        'disabled:border-system-disabled-300 disabled:bg-system-disabled-50 disabled:text-system-disabled-300 disabled:autofill:bg-system-disabled-50',
        isInvalid
          ? 'border-system-error-400 focus:border-system-error-400 focus:shadow-focus-error'
          : 'bg-sand-50 autofill:bg-sand-50 focus:border-primary-300 focus:shadow-focus',
      )}
      value={value}
    />
  ),
);

HTMLTextArea.displayName = 'HTMLTextArea';

export default HTMLTextArea;
