import { InputHTMLAttributes } from 'react';

export enum INPUT_STATE {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  validMessage?: string | null;
  // TODO: pass label to mandatory
  label?: string;
  name: string;
}

export type HTMLInputProps = InputHTMLAttributes<HTMLInputElement> & {
  state?: INPUT_STATE;
};
