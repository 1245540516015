import { Url } from 'next/dist/shared/lib/router/router';
import { useRouter } from 'next/router';
import { PageItem } from '@/molecules/Pagination/type';
import getPageItems from './getPageItems';

type PaginationType = (lastPage: number) => {
  currPage: number;
  createUrl: (page: number) => Url;
  pages: PageItem[];
};
const usePagination: PaginationType = (lastPage) => {
  const {
    query: { page, ...queryParams },
  } = useRouter();

  const createUrl = (newPage: number) => {
    return {
      query: { page: newPage, ...queryParams },
    };
  };

  const currPage = typeof page === 'string' ? parseInt(page, 10) : 1;

  const pages = getPageItems(lastPage, currPage);

  return {
    currPage,
    createUrl,
    pages,
  };
};

export default usePagination;
