import { UserFriendshipJsonldUserFriendshipReadUserRead } from '@/api/generated';
import { FormOptions } from 'types/forms';

const filterAndFormatOptions = (users: UserFriendshipJsonldUserFriendshipReadUserRead[]): FormOptions => {
  if (!users) {
    return {};
  }

  return users.reduce<FormOptions>((acc, user) => {
    if (!user['@id']) {
      return acc;
    }
    if (!user.userFriend || !user.userFriend['@id']) {
      return acc;
    }

    return {
      ...acc,
      [user.userFriend['@id']]: {
        label: user.userFriend.nickName || '',
      },
    };
  }, {} as FormOptions);
};

export default filterAndFormatOptions;
