import Tag from '@/molecules/Tag';
import { FormOptions } from 'types/forms';
import { SIZE } from 'types/utils';

type TagGroupProps = {
  options: FormOptions;
  closeTag?: (value: string) => void;
  size?: SIZE;
};

const TagGroup = ({ options, closeTag, size = SIZE.SM }: TagGroupProps) => (
  <ul className="flex gap-1 flex-wrap">
    {Object.entries(options).map(([key, option]) => (
      <li key={key}>
        <Tag onClick={closeTag ? () => closeTag(key) : undefined} size={size}>
          {option.label}
        </Tag>
      </li>
    ))}
  </ul>
);

export default TagGroup;
