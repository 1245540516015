import { i18n } from 'next-i18next';

import { env } from 'next-runtime-env';
import { defaultLang } from '../i18n/constants';
import { Api } from './generated';

type JwtToken = string | undefined;
export type ApiClient = Api<JwtToken>;

// eslint-disable-next-line import/prefer-default-export
export const createApiClient = (token?: string) => {
  const apiClient = new Api<JwtToken>({
    baseUrl: env('NEXT_PUBLIC_API_ENTRYPOINT'),
    baseApiParams: {
      secure: true,
      headers: {
        'Accept-Language': i18n?.language || defaultLang,
      },
    },
    securityWorker: async (securityData) => {
      const headers: HeadersInit = {
        'Accept-Language': i18n?.language || defaultLang,
      };

      if (securityData) {
        headers.Authorization = `Bearer ${securityData}`;
      }

      return {
        headers,
      };
    },
  });

  apiClient.setSecurityData(token);

  return apiClient;
};
