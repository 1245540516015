import { useRouter } from 'next/router';
import TabItem from '@/molecules/Tabs/TabItem';
import { TabsProps } from '@/molecules/Tabs/TabItem/types';
import TabsContainer from '@/molecules/Tabs/TabsContainer';
import useTabsLink from './useTabsLink';

const TabsLink = ({ items, className }: TabsProps<string>) => {
  const { itemsWithParam } = useTabsLink({ items });
  const { asPath } = useRouter();

  return (
    <TabsContainer className={className}>
      {itemsWithParam.map((item) => (
        <TabItem
          {...item}
          key={item.value}
          isActive={
            typeof item.isActive !== 'undefined'
              ? !!item.isActive
              : decodeURI(asPath.replace(/\//gi, '')) === item.href?.replace(/\//gi, '')
          }
        />
      ))}
    </TabsContainer>
  );
};

export default TabsLink;
