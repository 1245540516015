import { useTranslation } from 'react-i18next';
import RedirectTile from '@/atoms/RedirectTile';
import ModalClassicLayout from '../../layout/Classic';

type PaymentTypeListModal = {
  onSubmit?: () => void;
  closeModal: () => void;
  next: () => void;
};
const PaymentTypeList = ({ onSubmit, closeModal, next }: PaymentTypeListModal) => {
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="your-payin-method">
      <div className="flex flex-col pb-4">
        <RedirectTile icon="fa-regular fa-credit-card" label={t('credit-card-or-debit-card')} onClick={next} />
      </div>
    </ModalClassicLayout>
  );
};
export default PaymentTypeList;
