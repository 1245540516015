import Avatar from '@/atoms/Avatar';
import clx from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type AvatarLabelProps = {
  nickName?: string | null;
  avatarPath?: string | null;
  subLine?: ReactNode;
  icon?: string;
};

const AvatarLabel = ({ nickName, avatarPath, subLine, icon }: AvatarLabelProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-2 pr-3 items-center">
      <Avatar alt="" className="shrink-0" src={avatarPath} border />
      <div>
        <span className="text-neutral-900 lg-medium whitespace-nowrap">
          {nickName || t('not-registered')} {icon && <i className={clx(icon, 'ml-2')} />}
        </span>
        {subLine}
      </div>
    </div>
  );
};
export default AvatarLabel;
