import { useTranslation } from 'next-i18next';
import ModalClassicLayout from '../../../layout/Classic';

type ConfirmDeckDeletionProps = {
  onSubmit: () => void;
  closeModal: () => void;
  cardName: string;
};
const ConfirmOfferDeletion = ({ onSubmit, closeModal, cardName }: ConfirmDeckDeletionProps) => {
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={onSubmit} title="delete-an-offer">
      <div className="flex flex-col pb-4 text-center">
        <img alt={cardName} className="m-auto" height="146" src="/empty-illu.png" width="168" />
        {t('confirm-offer-deletion-description', { cardName })}
      </div>
    </ModalClassicLayout>
  );
};
export default ConfirmOfferDeletion;
