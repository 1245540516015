import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { MODAL_VIEWS, ModalViewsKeys, type ModalViewsProps, type BaseModalViewsProps } from './constants';
import { ModalContext } from './Context';

type StoreType<T extends ModalViewsKeys | null> = {
  modalName: T | null;
  modalProps: T extends ModalViewsKeys ? ModalViewsProps<T> : null;
};

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const [store, setStore] = useState<StoreType<ModalViewsKeys | null>>({
    modalProps: null,
    modalName: null,
  });

  const { modalProps, modalName } = store;

  const openModal = useCallback(
    <T extends ModalViewsKeys>(name: T, props: ModalViewsProps<T>) => {
      setStore({
        ...store,
        modalProps: props,
        modalName: name,
      });
    },
    [store],
  );

  const closeModal = useCallback(() => {
    setStore({
      ...store,
      modalName: null,
    });
  }, [store]);

  useEffect(() => {
    const startHandler = (url: string) => {
      const oldurl = window?.location.pathname;
      const newurl = url.split('?')[0];
      if (oldurl !== newurl) {
        closeModal();
      }
    };

    router.events.on('routeChangeStart', startHandler);

    return () => {
      router.events.off('routeChangeStart', startHandler);
    };
  }, []);

  const renderModal = () => {
    const ModalComponent = (modalName && MODAL_VIEWS[modalName]) as React.ComponentType<
      BaseModalViewsProps<NonNullable<typeof modalName>>
    > | null;

    return (
      <AnimatePresence>
        {ModalComponent && <ModalComponent {...modalProps} key={modalName} closeModal={closeModal} />}
      </AnimatePresence>
    );
  };

  const providerValue = useMemo(() => {
    return { modalProps: store.modalProps, openModal, closeModal };
  }, [closeModal, openModal, store.modalProps]);

  return (
    <ModalContext.Provider value={providerValue}>
      {renderModal()}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
