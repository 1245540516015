import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
import deckFactory from 'utils/factories/decks';

export const useDecksDetails = (id?: string | null) => {
  const queryKey = deckFactory.details(id || '');
  const apiClient = useApi();

  const queryResult = useQuery({
    queryKey,
    queryFn: () => apiClient.deckUserLists.apiDeckUserListsIdGet(id as string),
    select: (res) => res.data,
    enabled: !!id,
  });

  return { queryResult, queryKey };
};

export const useDeckStats = (id?: string | null) => {
  const queryKey = deckFactory.deckStats(id || '');
  const apiClient = useApi();

  return useQuery({
    queryKey,
    queryFn: () => apiClient.deckUserLists.apiDeckUserListsIdstatsGet(id as string),
    select: (v) => v.data,
    enabled: !!id,
  });
};
