import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { UserJsonldUserWrite } from '@/api/generated';
import { useToaster } from 'context/Toaster/ToasterContext';
import useApi from 'hooks/useApi';
import { VARIANT_COLOR_ENUM } from 'types/utils';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';
import useAuth from 'hooks/useAuth';

const meFactory = createKeyFactory([KEYS.ME]);

type useUserApiOptions = {
  successMessage?: string;
};

const useUserApi = (options?: useUserApiOptions) => {
  const session = useSession();
  const apiClient = useApi();
  const client = useQueryClient();
  const { update } = useSession();
  const { logout } = useAuth();

  const { addToaster } = useToaster();
  const { data: user, isLoading: isUserLoading } = useQuery({
    queryKey: [session.data?.accessToken ?? session.data?.jwt] || meFactory.all(),
    queryFn: async () => {
      const apiData = await apiClient.me.apiMeGet();

      return apiData.data;
    },
    retry: false,
    enabled: !!session.data?.accessToken || !!session.data?.jwt,
  });

  const userId = user?.id || '';
  const currency = user?.wallet?.currency;

  const editUser = useMutation({
    mutationKey: ['editUser'],
    mutationFn: async (userData: Partial<UserJsonldUserWrite>) => {
      const res = await apiClient.users.apiUsersIdPut(userId, userData as UserJsonldUserWrite);

      return res;
    },
    onSuccess: async (_data, vars) => {
      // If the user changes his email, we need to update the session to get the new token from the API and logout
      if (vars.email) {
        update();
        await logout();
      } else {
        client.invalidateQueries({ queryKey: [session.data?.accessToken ?? session.data?.jwt] || meFactory.all() });
      }

      addToaster({ variant: VARIANT_COLOR_ENUM.SUCCESS, message: options?.successMessage || 'changes-saved' });
    },
  });

  return {
    user: session?.data?.accessToken || session?.data?.jwt ? user : undefined,
    isLoading: isUserLoading,
    editUser,
    userId,
    currency,
  };
};

export default useUserApi;
