import clx from 'classnames';
import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import { BaseProps } from '@/atoms/forms/types';

type GradeProps = InputHTMLAttributes<HTMLInputElement> &
  BaseProps & {
    getCheckedStatus: (value: string, name?: string) => boolean;
    value: string;
    isInvalid?: boolean;
    icon?: string;
  };

const Grade = forwardRef(
  (
    { name, onChange, label, id, value, type = 'radio', getCheckedStatus, isInvalid, icon }: GradeProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <>
      <input
        ref={ref}
        checked={getCheckedStatus(value, name)}
        className="appearance-none hidden peer"
        id={id}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
      />
      <label
        className={clx(
          'transition rounded-full py-2 px-3 cursor-pointer xs-bold-caps whitespace-nowrap flex items-center',
          'peer-disabled:text-system-disabled-400 peer-disabled:bg-system-disabled-300',
          isInvalid
            ? 'text-system-error-400 bg-system-error-200 border-system-error-400 border-[1px] peer-focus:shadow-focus-error hover:bg-system-error-300'
            : `peer-focus:shadow-focus text-neutral-700 bg-neutral-50 hover:bg-neutral-60
            peer-checked:text-neutral-0 peer-checked:bg-secondary-600
            peer-checked:after:content-['✓'] peer-checked:after:ml-2`,
        )}
        htmlFor={id}
      >
        {icon && <i className={clx(icon, 'mr-2')} />}
        {label}
      </label>
    </>
  ),
);

Grade.displayName = 'Grade';
export default Grade;
