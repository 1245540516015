import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const walletBalanceFactory = createKeyFactory([KEYS.WALLET_BALANCE]);

const useMyWallet = () => {
  const apiClient = useApi();

  return useQuery({
    queryKey: walletBalanceFactory.all(),
    queryFn: async () => apiClient.payments.apiPaymentswalletsbalanceGet(),
    select: ({ data }) => data,
  });
};

export default useMyWallet;
