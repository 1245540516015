import { IllustrationJsonldIllustrationRead } from '@/api/generated';

export type SortedIllustrationType = {
  [key: string]: IllustrationJsonldIllustrationRead[];
};

const sortByFaction = (data: IllustrationJsonldIllustrationRead[] | undefined): SortedIllustrationType | undefined =>
  data &&
  data.reduce((acc: SortedIllustrationType, curr: IllustrationJsonldIllustrationRead) => {
    if (!curr.factions || !curr.factions[0]) {
      return acc;
    }
    const currentFaction = curr.factions[0].name || curr.factions[0].reference;
    if (acc[currentFaction]) {
      acc[currentFaction].push(curr);
    } else {
      acc[currentFaction] = [curr];
    }

    return acc;
  }, {});

export default sortByFaction;
