import Badge from '@/atoms/Badge';
import ButtonOrLink from '@/atoms/ButtonOrLink/ButtonOrLink';
import Sticker from '@/atoms/Sticker';
import clx from 'classnames';
import { useTranslation } from 'react-i18next';
import { MenuItemType } from 'types/cms';

type MenuItemProps = {
  item: MenuItemType;
  className?: string;
  isSelected?: boolean;
};

const MenuItem = ({ item, className, isSelected }: MenuItemProps) => {
  const { acf, count, hasPlaceholder, ...props } = item;
  const { t } = useTranslation();

  return (
    <ButtonOrLink
      className={clx(
        'clickable hover:bg-neutral-30 ease-in-out transition-colors duration-100 py-2 px-3 w-full',
        'flex flex-row gap-2 items-center hover:no-underline text-neutral-700 whitespace-nowrap md-medium',
        className,
      )}
      {...props}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-1">
          {acf?.icon && <i className={clx(acf.icon, 'fa-fw text-neutral-500')} />} {item.label}
          {hasPlaceholder && <Sticker>{t('soon')}</Sticker>}
          {!hasPlaceholder && !!count && <Badge className="ml-1">{count}</Badge>}
        </div>
        {isSelected && <i className="fa-solid fa-circle-check" />}
      </div>
    </ButtonOrLink>
  );
};

export default MenuItem;
