import { EventJsonldEventReadAddressReadEventTypeReadIllustrationRead } from '@/api/generated';
import Spinner from '@/atoms/Spinner';
import EventTile from '@/organisms/EventTile';
import useEventsApi from 'hooks/queries/events/useEventsApi';
import ModalClassicLayout from '../../layout/Classic';

const ShopEventsListModal = ({
  closeModal,
  organizerId,
  storeName,
  handleClick,
}: {
  closeModal: () => void;
  organizerId: string;
  storeName: string;
  handleClick: (s: EventJsonldEventReadAddressReadEventTypeReadIllustrationRead) => void;
}) => {
  const { events, isLoading } = useEventsApi({ organizer: organizerId });

  return (
    <ModalClassicLayout closeModal={closeModal} title={storeName}>
      {isLoading && <Spinner />}
      <div className="grid grid-cols-1 md:grid-cols-2">
        {events?.map((event) => (
          <EventTile key={event.id} event={event} onClick={() => handleClick(event)} slug="events" />
        ))}
      </div>
    </ModalClassicLayout>
  );
};
export default ShopEventsListModal;
