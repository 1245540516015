import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { AddressFormValues, addressSchema } from 'constants/schemas/addressSchema';
import useApi from 'hooks/useApi';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const addressesFactory = createKeyFactory([KEYS.ADDRESSES]);

const useAddressCreateModal = ({
  closeModal,
  onChange,
}: {
  closeModal: () => void;
  onChange: (id: string) => void;
}) => {
  const client = useQueryClient();
  const apiClient = useApi();

  const formMethods = useForm<AddressFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(addressSchema()),
  });
  const { handleSubmit } = formMethods;

  const create = useMutation({
    mutationKey: ['createAddress'],
    mutationFn: async (values: AddressFormValues) => apiClient.addresses.apiAddressesPost(values),
    onSuccess: (res) => {
      closeModal();
      if (res.data) {
        onChange(res.data['@id'] as string);
        client.invalidateQueries({ queryKey: addressesFactory.all() });
      }
    },
  });
  const onSubmit = (values: AddressFormValues) => create.mutateAsync(values);

  return { formMethods, onSubmit: handleSubmit(onSubmit) };
};

export default useAddressCreateModal;
