const numberToList = (max: number) =>
  Array.from(Array(max).keys()).map((index) => {
    return { key: (index + 1).toString(), value: index + 1, label: (index + 1).toString() };
  });

const createSuspendItem = (key: string) => {
  return {
    key,
    value: 0,
    label: '...',
  };
};

const createPageItem = (pageNumber: number) => {
  return {
    key: pageNumber.toString(),
    value: pageNumber,
    label: pageNumber.toString(),
  };
};

const getPageItems = (lastPage: number, currentPage: number) => {
  if (lastPage <= 7) {
    return numberToList(lastPage);
  }
  if (currentPage <= 4) {
    return [...numberToList(4), createPageItem(5), createSuspendItem('after'), createPageItem(lastPage)];
  }

  if (currentPage >= lastPage - 2) {
    return [
      createPageItem(1),
      createSuspendItem('previous'),
      createPageItem(lastPage - 3),
      createPageItem(lastPage - 2),
      createPageItem(lastPage - 1),
      createPageItem(lastPage),
    ];
  }

  return [
    createPageItem(1),
    createSuspendItem('previous'),
    createPageItem(currentPage - 1),
    createPageItem(currentPage),
    createPageItem(currentPage + 1),
    createSuspendItem('after'),
    createPageItem(lastPage),
  ];
};

export default getPageItems;
