import clx from 'classnames';
import { easeInOut, motion } from 'framer-motion';
import { PropsWithChildren } from 'react';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import FeaturedIcon, { FEATURED_ICON_VARIANTS } from '@/atoms/FeaturedIcon';
import { LinkType } from 'types/utils';

export type SecondaryTitleProps = {
  icon?: string;
  subtitle?: string | null;
  className?: string;
  iconVariant?: FEATURED_ICON_VARIANTS;
  linkProps?: LinkType;
  showLine?: boolean;
  showLeftLine?: boolean;
  showGradient?: boolean;
};

const SecondaryTitle = ({
  children,
  subtitle,
  icon,
  showLeftLine = !!icon,
  linkProps,
  className,
  iconVariant,
  showGradient,
  showLine = false,
}: PropsWithChildren<SecondaryTitleProps>) => (
  <div
    className={clx('flex flex-col gap-2', className, showGradient && 'bg-gradient-to-r from-primary-75 to-transparent')}
  >
    <div className={clx('flex gap-2 items-center justify-stretch items-stretch')}>
      {icon && <FeaturedIcon icon={icon} variant={iconVariant} />}
      {showLeftLine && <div className="w-1   shrink-0 bg-primary-500" />}
      <hgroup>
        <h2 className={clx('text-neutral-900 sm-bold-caps md:md-bold-caps')}>{children}</h2>
        {subtitle && <p className={clx('text-neutral-400 sm-regular md:md-regular')}>{subtitle}</p>}
      </hgroup>
      {linkProps && (
        <Button href={linkProps.href} variant={BUTTON_VARIANTS.TERTIARY}>
          {linkProps.label}
        </Button>
      )}
    </div>
    {showLine && (
      <motion.span
        animate={{ width: '100%' }}
        className="bg-gradient-to-r from-transparent to-transparent via-primary-400 h-1"
        initial={{ width: 0 }}
        transition={{ duration: 0.3, ease: easeInOut, delay: 1 }}
      />
    )}
  </div>
);

export default SecondaryTitle;
