import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useShopMembersApi from 'hooks/queries/shop/useShopMembersApi';
import { isUniqueEmail } from 'utils/form/validation';

const teamMemberSchema = () =>
  yup
    .object({
      lastName: yup.string().required(),
      firstName: yup.string().required(),
      email: yup.string().email().required().test('is-unique', `email-not-unique`, isUniqueEmail),
    })
    .required();

type FormValues = yup.InferType<ReturnType<typeof teamMemberSchema>>;

const useCreateTeamMember = ({ closeModal }: { closeModal: () => void }) => {
  const { handleAddMember } = useShopMembersApi();
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(teamMemberSchema()),
  });
  const { handleSubmit } = formMethods;

  const onSubmit = (formValues: FormValues) => {
    handleAddMember(formValues);
    closeModal();
  };

  return { onSubmit: handleSubmit(onSubmit), formMethods };
};

export default useCreateTeamMember;
