export enum AVAILABLE_COMPONENTS {
  'date' = 'date',
  'radio' = 'radio',
  'checkboxList' = 'checkboxList',
  'multiSearchSelect' = 'multiSearchSelect',
  'booleanInput' = 'booleanInput',
  'horizontalCheckboxList' = 'horizontalCheckboxList',
  'checkFactionChip' = 'checkFactionChip',
  'checkSetChip' = 'checkSetChip',
  'bigDivider' = 'bigDivider',
}

export type SpecificTextField = {
  internal_name: string;
  value: string;
  acf_fc_layout: string;
};
