import { useMemo } from 'react';
import { FormOptions } from 'types/forms';
import { FilterDataDto, Mapped } from 'types/utils';
import { useCmsData } from 'utils/cms';
import { useDecodeFilters } from 'utils/filters/encodeDecodeFilters';

const useActiveFilters = ({ filterData }: { filterData?: FilterDataDto }) => {
  const {
    acf: { queryParameters },
  } = useCmsData();
  const currFilters = useDecodeFilters();
  const sourceToLabel = useMemo(
    () =>
      queryParameters.reduce(
        (dict, param) => {
          return {
            ...dict,
            ...(param.choicesSource && {
              ...param.choicesSource.reduce(
                (subDict, choiceSource) => {
                  return { ...subDict, [choiceSource.source]: choiceSource.title };
                },
                {} as Mapped<string, string>,
              ),
            }),
            ...(param.choices && {
              ...param.choices?.reduce(
                (subDict, choice) => {
                  return { ...subDict, [choice.value]: choice.label };
                },
                {} as Mapped<string, string>,
              ),
            }),
          };
        },
        {} as Mapped<string, string>,
      ),
    [queryParameters],
  );

  const filtersOptions = useMemo(() => {
    if (!currFilters || !filterData) {
      return {};
    }

    return Object.keys(currFilters).reduce((acc, key) => {
      const filterValue = currFilters[key];
      if (!filterValue || !sourceToLabel[key]) {
        return acc;
      }
      if (Array.isArray(filterValue)) {
        return {
          ...acc,
          [key]: {
            label: `${sourceToLabel[key]}: `.concat(
              filterValue
                .map((id) => {
                  if (!id || !filterData[key]) {
                    return '';
                  }

                  return filterData[key][id].label;
                })
                .join(', '),
            ),
          },
        };
      }
      if (filterValue && filterData && filterData[key] && filterData[key][filterValue]) {
        return {
          ...acc,
          [key]: { label: `${sourceToLabel[key]}: ${filterData[key][filterValue].label}` },
        };
      }

      return {
        ...acc,
        [key]: { label: `${sourceToLabel[key]}: ${sourceToLabel[filterValue] || filterValue}` },
      };
    }, {} as FormOptions);
  }, [filterData, currFilters]);

  return { filtersOptions };
};

export default useActiveFilters;
