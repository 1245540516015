const ERROR_ROUTES = {
  NOT_LOGGED: '/login',
  NOT_FOUND: '/404',
  NOT_LINKED: '/login/not-linked',
  ALREADY_REGISTERED: '/register/already-registered',
};

export default ERROR_ROUTES;

export const CMS_ROUTING = {
  notificationSettings: '/manage-account/notifications-settings/',
};
