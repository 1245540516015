import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardRegistrationPaymentCardJsonldPaymentPaymentCardRead,
  PaymentCardJsonldPaymentPaymentCardRead,
} from '@/api/generated';
import Button from '@/atoms/Button';
import MoneyData from '@/atoms/MoneyData';
import RedirectTile from '@/atoms/RedirectTile';
import ModalClassicLayout from 'context/Modal/layout/Classic';

type PaymentSummaryModal = {
  closeModal: () => void;
  next: () => void;
  currency?: string | null;
  amount: number;
  fee?: number;
  card: PaymentCardJsonldPaymentPaymentCardRead | CardRegistrationPaymentCardJsonldPaymentPaymentCardRead;
  postPayment: () => void;
};

const PaymentSummary = ({ closeModal, next, amount, fee, currency, card, postPayment }: PaymentSummaryModal) => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);
  const { number, type, expirationMonth, expirationYear, active } = card;

  return (
    <ModalClassicLayout closeModal={closeModal} title="your-payin-method">
      <div className="flex flex-col gap-4 pb-4">
        <div className="bg-neutral-0 flex flex-col items-center pb-2 shadow-raised rounded-lg">
          <i className="text-5xl fa-solid fa-coins text-primary-500 py-4" />
          <p className="text-neutral-400 md-bold ">
            <MoneyData amount={amount} currency={currency} />
          </p>
          {fee && (
            <p className="text-neutral-400 md-regular ">
              <MoneyData amount={fee} currency={currency} />
            </p>
          )}
        </div>
        <RedirectTile
          description={`${expirationMonth}/${expirationYear}`}
          icon="fa-regular fa-credit-card"
          label={`${type} ${number}`}
          onClick={next}
          tags={[{ label: active ? 'active' : 'unactive' }]}
        />
      </div>
      <Button
        className="w-full"
        disabled={isLoading}
        icon={isLoading ? 'fa-solid fa-circle-notch animate-spin' : undefined}
        onClick={() => {
          setIsloading(true);
          postPayment();
        }}
      >
        {t('confirm-payment')}
      </Button>
    </ModalClassicLayout>
  );
};
export default PaymentSummary;
