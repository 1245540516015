import { Mapped, SIZE } from 'types/utils';

export const SIZE_TO_CLASSE: Mapped<SIZE, string> = {
  [SIZE.LG]: 'w-32 h-32',
  [SIZE.MD]: 'w-10 h-10',
  [SIZE.SM]: 'w-8 h-8',
};

export const IMAGE_SIZE: Mapped<SIZE, number> = {
  [SIZE.LG]: 128,
  [SIZE.MD]: 40,
  [SIZE.SM]: 32,
};

export const BORDER_SIZE: Mapped<SIZE, string> = {
  [SIZE.LG]: 'border-6 border-primary-500',
  [SIZE.MD]: 'border-2 border-primary-500',
  [SIZE.SM]: 'border-2 border-primary-500',
};
