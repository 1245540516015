import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CheckFactionChip from '@/atoms/forms/CheckFactionChip';
import Grades from '@/atoms/forms/grades/Grades';
import RadioList from '@/atoms/forms/radio/RadioList';
import ModalClassicLayout from 'context/Modal/layout/Classic';
import BaseComponent from '../../Filters/utils/BaseComponent';
import useModalDeckFilter from './hook';

type Prop = {
  closeModal: () => void;
};

const ModalDeckFilter = ({ closeModal }: Prop) => {
  const { formMethods, factionsOptions, handleSubmit, heroesOptions, legalOption } = useModalDeckFilter();
  const { t } = useTranslation();

  return (
    <ModalClassicLayout closeModal={closeModal} onSubmit={handleSubmit} title="filters">
      <FormProvider {...formMethods}>
        <form className="min-w-vw-1/2">
          <ul className="flex flex-col gap-4">
            <BaseComponent id="faction" title={t('factions')}>
              <CheckFactionChip name="faction.reference" options={factionsOptions} />
            </BaseComponent>
            <BaseComponent id="heroes" title={t('heroes')}>
              <Grades name="alterator.reference" options={heroesOptions} />
            </BaseComponent>
            <BaseComponent id="legal" title={t('legal')}>
              <RadioList name="isLegal" options={legalOption} />
            </BaseComponent>
          </ul>
          <input className="hidden" type="submit" />
        </form>
      </FormProvider>
    </ModalClassicLayout>
  );
};

export default ModalDeckFilter;
