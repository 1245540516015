import clx from 'classnames';
import { useTranslation } from 'next-i18next';
import { NUMBER_TO_COLOR, NUMBER_TO_TEXT } from './passwordComplexity';

const ShowComplexity = ({ complexity }: { complexity: number }) => {
  const { t } = useTranslation();

  return (
    <p className={clx(NUMBER_TO_COLOR[complexity], 'sm-medium')}>
      {t('security-level')} : {t(NUMBER_TO_TEXT[complexity])}
    </p>
  );
};

export default ShowComplexity;
