import { ParsedQuery } from 'query-string';
import createKeyFactory, { KEYS } from 'utils/createKeyFactory';

const usersFactory = {
  ...createKeyFactory([KEYS.USERS]),
  myFriends: (params?: ParsedQuery | string) => [...usersFactory.all(), 'myFriends', params],
  friends: (params?: ParsedQuery | string) => [...usersFactory.all(), 'friends', params],
  myRequests: (params?: ParsedQuery | string) => [...usersFactory.all(), 'myRequests', params],
  searchFriends: (params?: ParsedQuery | string) => [...usersFactory.all(), 'searchFriends', params],
  blockedFriends: (params?: ParsedQuery | string) => [...usersFactory.all(), 'blockedFriends', params],
};

export default usersFactory;
