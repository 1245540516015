import { FilterDataDto, Mapped } from 'types/utils';
import formatOptions, { Entity } from 'utils/form/formatOptions';

export type AgnosticFilterData = Mapped<string, string[] | string | Entity[]>;

const filterDataDto = (filterData: AgnosticFilterData) =>
  Object.keys(filterData).reduce((dto, key) => {
    if (key.includes('@')) {
      return dto;
    }
    if (typeof filterData[key][0] === 'string') {
      return {
        ...dto,
        [key]: (filterData[key] as string[]).reduce((dict, value, index) => {
          return { ...dict, [value]: { label: value, sequence: index } };
        }, {}),
      };
    }

    return {
      ...dto,
      [key]: Array.isArray(filterData[key])
        ? formatOptions(filterData[key] as Entity[], { valueField: 'reference' })
        : filterData[key],
    };
  }, {} as FilterDataDto);

export default filterDataDto;
