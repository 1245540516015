import { ParsedUrlQuery } from 'querystring';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { env } from 'next-runtime-env';
import { ApiClient } from '@/api/client';
import useApi from 'hooks/useApi';
import formatQueryParams from 'utils/cms/formatQueryParams';
import { defaultLang } from '../../i18n/constants';
import { DtoPage } from '../../types/cms';
import { CMSPages } from './types';

const formatPageDto = ({
  id,
  date,
  date_gmt: dateGmt,
  modified,
  slug,
  status,
  type,
  title,
  author,
  parent,
  menu_order: menuOrder,
  template,
  acf,
  siblings,
  yoast_head_json: yoastHeadJson,
  children,
  previous_child: previousChild,
  next_child: nextChild,
  url,
  wpUser,
  article_details: articleDetails,
  articles_categories: articlesCategories,
  articles,
  factions,
  alterators,
  alterator_details: alteratorDetails,
  faction_details: factionDetails,
  general_settings: generalSettings,
  cousins,
}: CMSPages): DtoPage => {
  return {
    id,
    date,
    dateGmt,
    modified,
    slug,
    status,
    type,
    title,
    author,
    parent,
    template,
    acf: {
      background: {
        image: acf.background.image,
        position: acf.background.position || null,
      },
      displayTitle: acf.display_title,
      displayMenuTitle: acf.display_menu_title,
      footer: acf.footer,
      footerMenus: {
        ...(acf.footer_menus.footer_classic && {
          footerClassic: {
            menuItems: acf.footer_menus.footer_classic.menu_items,
            discord: {
              title: acf.footer_menus.footer_classic.acf.discord_title || null,
              link: acf.footer_menus.footer_classic.acf.discord_link || null,
              icon: acf.footer_menus.footer_classic.acf.discord_icon || null,
            },
            retailer: {
              image: acf.footer_menus.footer_classic.acf.retailer_image || null,
              link: acf.footer_menus.footer_classic.acf.retailer_link || null,
            },
          },
        }),
        ...(acf.footer_menus.footer_legal_classic && {
          footerLegalClassic: {
            menuItems: acf.footer_menus.footer_legal_classic.menu_items,
          },
        }),
      },
      header: acf.header,
      headerMenus: {
        ...(acf.header_menus.header_classic && {
          headerClassic: {
            menuItems: acf.header_menus.header_classic.menu_items,
          },
        }),
        ...(acf.header_menus.header_account_classic && {
          headerAccountClassic: {
            menuItems: acf.header_menus.header_account_classic.menu_items,
            footerImage: acf.header_menus.header_account_classic.acf.image_footer_menu || null,
          },
        }),
        ...(acf.header_menus.header_editorial_classic && {
          headerEditorialClassic: {
            menuItems: acf.header_menus.header_editorial_classic.menu_items,
          },
        }),
      },
      linkedInternalMenu: acf.linked_internal_menu || null,
      ...(acf.widgets && { widgets: acf.widgets }),
      layout: acf.layout,
      redirectToFirstChild: acf.redirect_to_first_child,
      parentForceUrl: acf.parent_force_url,
      ...(!!acf.query_parameters && { queryParameters: formatQueryParams(acf.query_parameters) }),
      ...(!!acf.form_options && {
        formOptions: {
          formName: acf.form_options.form_name,
          containerName: acf.form_options.container_name,
          beforeForm: acf.form_options.before_form,
          afterForm: acf.form_options.after_form,
          submitLabel: acf.form_options.submit_label,
          useSteps: !!acf.form_options.use_steps,
        },
      }),
      ...(!!acf.empty_options && {
        emptyOptions: {
          title: acf.empty_options.title,
          image: acf.empty_options.image,
          text: acf.empty_options.text,
        },
      }),
      ...(!!acf.initial_options && {
        initialOptions: {
          title: acf.initial_options.title,
          image: acf.initial_options.image,
          text: acf.initial_options.text,
        },
      }),
    },
    articles: articles || null,
    factions: factions || null,
    generalSettings: {
      ...generalSettings,
      linkToShop: !!generalSettings.link_to_shop,
      cardLanguageSwitch: !!generalSettings.card_language_switch,
      defaultOgImage: generalSettings.default_og_image || '',
      deckBuilding: !!generalSettings.deck_building,
      marketplace: !!generalSettings.marketplace,
      printOnDemand: !!generalSettings.print_on_demand,
      playGame: !!generalSettings.play_game,
      defaultDescription: generalSettings.default_description || '',
      countries: generalSettings.countries || null,
      languages: generalSettings.languages.map((language) => {
        return {
          locale: language.language_code,
          reference: language.language_reference,
          name: language.language_label,
        };
      }),
    },
    alterators: alterators || null,
    factionDetails: factionDetails || null,
    alteratorDetails: alteratorDetails || null,
    articleDetails: articleDetails || null,
    articlesCategories: articlesCategories || null,
    siblings,
    cousins:
      cousins?.map(({ is_current: isCurrent, ...cousin }) => {
        return { isCurrent, ...cousin };
      }) || null,
    yoastHeadJson,
    children,
    previousChild,
    nextChild,
    url,
    menuOrder,
    wpUser,
  };
};

export const fetchApi = async (path: string, language?: string) => {
  const headers = new Headers({
    'Accept-Language': language || defaultLang,
  });

  const response = await fetch(`${env('NEXT_PUBLIC_API_ENTRYPOINT')}/${path}`, {
    headers,
  });
  if (response.status === 204) {
    return undefined;
  }
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }

  throw await response;
};

export const fetchSlug = async (apiClient: ApiClient, slug: string, language?: string, params?: ParsedUrlQuery) =>
  apiClient.cms
    .apiCmspagesGet({ url: slug, ...params, locale: language || defaultLang })
    // @ts-expect-error TODO the API DTO is not synced with data returned from Wordpress
    // see api/src/Cms/ApiResource/Pages.php
    .then((data) => formatPageDto(data.data as CMSPages));

export const parseSlug = (slug?: string[] | string): string => {
  if (!slug || Object.keys(slug).length === 0) {
    return '/';
  }

  return Array.isArray(slug) ? `/${slug.join('/')}` : `/${slug}`;
};

export const useCmsData = (): DtoPage => {
  const { locale, query } = useRouter();
  const { slug, ...params } = query;
  const url = parseSlug(slug);
  const apiClient = useApi();
  const session = useSession();
  const { data } = useQuery<DtoPage>({
    queryKey: [url, { locale, ...params }, session?.data?.accessToken || 'no-auth'],
    queryFn: (): Promise<DtoPage> => fetchSlug(apiClient, url, locale, params),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  if (!data) {
    throw new Error('no data found for current route ');
  }

  return data || { acf: {}, parent: {} };
};
