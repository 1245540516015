import clx from 'classnames';
import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, PropsWithChildren, forwardRef } from 'react';

type ButtonActionProps = ButtonHTMLAttributes<HTMLButtonElement>;
type ButtonLinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & LinkProps;
export type ButtonOrLinkProps = ButtonActionProps | ButtonLinkProps;

const ButtonOrLink = forwardRef((props: PropsWithChildren<ButtonLinkProps | ButtonActionProps>, ref: any) => {
  if ('href' in props) {
    return (
      <Link
        {...props}
        ref={ref}
        className={clx('hover:no-underline', props.className)}
        scroll={!props.href.toString().startsWith('#')}
      />
    );
  }

  return <button type="button" {...props} ref={ref} />;
});

ButtonOrLink.displayName = 'ButtonOrLink';
export default ButtonOrLink;
