import clx from 'classnames';
import { PropsWithChildren } from 'react';

const Sticker = ({ className, children }: PropsWithChildren<{ className?: string }>) => (
  <div
    className={clx(
      'p-[2px] bg-gradient-to-b from-primary-400 via-[#1093D1] to-primary-400 rounded-full w-fit',
      className,
    )}
  >
    <div className="bg-gradient-to-b from-[#00567A] to-[#1093D1] rounded-full lg:py-1 px-1 lg:px-2">
      <p className="text-neutral-0 lg:uppercase text-[10px] leading-none font-black">{children}</p>
    </div>
  </div>
);

export default Sticker;
