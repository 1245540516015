import { useQuery } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { ParsedQuery } from 'query-string';
import { useForm } from 'react-hook-form';
import { CardJsonldCardRead, FactionJsonldFactionRead } from '@/api/generated';
import getFactionIconName from '@/atoms/FactionIcon/utils';
import { CheckFactionOptions } from '@/atoms/forms/CheckFactionChip';
import { useModal } from 'context/Modal/Context';
import useApi from 'hooks/useApi';
import { FormOptions } from 'types/forms';
import { CARD_PRODUCT_API_REF, CARD_TYPE_API_REF } from 'types/modifiers/references';
import cardsFactory from 'utils/factories/card';
import factionsFactory from 'utils/factories/factions';
import convertToDotNotation, { NestedFormValues } from 'utils/filters/convertToDotNotation';
import { encodeFilters, useDecodeFilters } from 'utils/filters/encodeDecodeFilters';

const formatFactions = (factions: FactionJsonldFactionRead[]): CheckFactionOptions =>
  factions.reduce((acc, faction) => {
    return {
      ...acc,
      [faction.reference]: {
        ...faction,
        acf: {
          // eslint-disable-next-line camelcase
          display_title: faction.name,
          color: faction.color,
          reference: faction.reference,
        },
      },
    };
  }, {});

const formatHeroes = (heroes: CardJsonldCardRead[]): FormOptions =>
  heroes.reduce((acc, hero) => {
    return {
      ...acc,
      [hero.reference]: {
        label: hero.name,
        icon: getFactionIconName(hero.mainFaction?.name),
      },
    };
  }, {});

const useModalDeckFilter = () => {
  const apiClient = useApi();
  const { data: factions } = useQuery({
    queryKey: factionsFactory.list(),
    queryFn: () => apiClient.factions.apiFactionsGetCollection(),
    // @ts-expect-error NE not type correctly
    select: (v) => v.data?.['hydra:member'].filter((f) => f.reference !== 'NE'),
  });
  const factionsOptions = factions ? formatFactions(factions) : {};
  const { push } = useRouter();
  const { closeModal } = useModal();
  const pathname = usePathname();

  const { data: heroes } = useQuery({
    queryKey: cardsFactory.list({
      'cardType[]': CARD_TYPE_API_REF.HERO,
      'cardProduct.reference': CARD_PRODUCT_API_REF.BOOSTER,
    }),
    queryFn: () =>
      apiClient.cards.apiCardsGetCollection({
        cardType: CARD_TYPE_API_REF.HERO,
        'cardProduct.reference': CARD_PRODUCT_API_REF.BOOSTER,
      }),
    select: (v) => v.data?.['hydra:member'],
  });
  const heroesOptions = heroes ? formatHeroes(heroes) : {};
  const currFilters = useDecodeFilters();
  const formMethods = useForm<ParsedQuery>({
    mode: 'onSubmit',
    defaultValues: currFilters,
  });

  const onSubmit = (formValues: NestedFormValues) => {
    const { page: _pagination, ...denestedFormValues } = convertToDotNotation(formValues);
    closeModal();

    push(`${pathname}?${encodeFilters(denestedFormValues)}`);
  };
  const legalOption = {
    true: { label: 'legal' },
    false: { label: 'not-legal' },
  };

  return { formMethods, factionsOptions, handleSubmit: formMethods.handleSubmit(onSubmit), heroesOptions, legalOption };
};
export default useModalDeckFilter;
