import clx from 'classnames';
import getFactionIconName from '@/atoms/FactionIcon/utils';

type FactionIconProps = {
  name: string;
  className?: string;
};

const FactionIcon = ({ name, className }: FactionIconProps) => (
  <i className={clx(getFactionIconName(name), className)} />
);

export default FactionIcon;
