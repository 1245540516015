import * as Dialog from '@radix-ui/react-dialog';
import { useTranslation } from 'next-i18next';
import IconButton from '@/atoms/IconButton';
import { SIZE } from 'types/utils';
import { ModalHeaderProps } from '../views/types';

const ModalHeader = ({ title, closeModal }: ModalHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex p-6 justify-between items-baseline bg-neutral-30 rounded-t-lg">
      {title && <p className="title-5 text-neutral-900">{t(title)}</p>}
      <Dialog.DialogClose asChild>
        <IconButton icon="fa-solid fa-xmark-large" onClick={closeModal} size={SIZE.SM} />
      </Dialog.DialogClose>
    </div>
  );
};

export default ModalHeader;
