import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import clx from 'classnames';
import ModalSimpleLayout from 'context/Modal/layout/Simple';
import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkType } from 'types/utils';

type Prop = {
  closeModal: () => void;
  imagePath: string;
  linkProps?: LinkType;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  assistText?: {
    title?: string;
    description?: string;
  };
  showCloseHeader?: boolean;
  maxWidth?: `max-w-[${number}vw]`;
};

const ZoomModal = ({ closeModal, imagePath, linkProps, buttonProps, assistText, maxWidth = 'max-w-[90vw]' }: Prop) => {
  const { t } = useTranslation();

  return (
    <ModalSimpleLayout closeModal={closeModal}>
      <div className="flex flex-col items-center gap-6">
        {assistText && (
          <div className={clx('flex items-center py-2 px-3 gap-4 rounded-md bg-neutral-40', maxWidth)}>
            <i className="fa-solid fa-circle-info text-xl text-neutral-400" />
            <div className="flex-1 flex flex-col gap-2 text-neutral-700">
              <p className="font-bold">{assistText?.title}</p>
              <p>{assistText?.description}</p>
            </div>
          </div>
        )}
        <figure className="rounded-lg overflow-hidden">
          <img alt="" className={clx('max-h-[70vh]', maxWidth)} src={imagePath} />
        </figure>
        {linkProps && linkProps.href && (
          <Button href={linkProps.href} variant={BUTTON_VARIANTS.PRIMARY}>
            {t(linkProps.label)}
          </Button>
        )}
        {buttonProps && (
          <Button
            {...buttonProps}
            onClick={
              buttonProps.onClick
                ? (e: React.MouseEvent<HTMLButtonElement>) => {
                    closeModal();
                    buttonProps?.onClick?.(e);
                  }
                : undefined
            }
            variant={BUTTON_VARIANTS.PRIMARY}
          />
        )}
      </div>
    </ModalSimpleLayout>
  );
};

export default ZoomModal;
