import { ParsedQuery } from 'query-string';
import { ReactElement } from 'react';
import BigDivider from '@/atoms/BigDivider';
import Checkbox from '@/atoms/forms/checkbox';
import CheckboxList from '@/atoms/forms/checkbox/CheckboxList';
import CheckFactionChip from '@/atoms/forms/CheckFactionChip';
import CheckSetChip from '@/atoms/forms/CheckSetChip';
import Grades from '@/atoms/forms/grades/Grades';
import TextInput from '@/atoms/forms/Input/Text/Input';
import RadioList from '@/atoms/forms/radio/RadioList';
import SearchSelect from '@/atoms/forms/Select/SearchSelect';
import { AVAILABLE_COMPONENTS } from 'context/Modal/views/Filters/type';
import Disclosure from 'context/Modal/views/Filters/utils/DisclosureComponent';
import { QueryParameterChoice, QueryParameterChoiceSource, QueryParametersType } from 'types/cms';
import { FormOptions } from 'types/forms';
import { FilterDataDto, SIZE } from 'types/utils';
import BaseComponent from './BaseComponent';

const choiceToOptions = (arg: QueryParameterChoice[]): FormOptions =>
  arg.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.value]: { label: cur.label },
    };
  }, {});

const ControllerComponent = ({
  choice,
  filterData,
  manualChoices,
}: {
  choice: QueryParameterChoiceSource;
  manualChoices?: QueryParameterChoice[];
  filterData?: FilterDataDto;
}) => {
  switch (choice.component) {
    case AVAILABLE_COMPONENTS.radio:
      if (!manualChoices) {
        return null;
      }

      return <RadioList name={choice.source} options={choiceToOptions(manualChoices)} />;

    case AVAILABLE_COMPONENTS.date:
      return (
        <div className="flex gap-4">
          {manualChoices?.map(({ value, label }) => (
            <TextInput key={label} label={label} name={value} placeholder="jj/MM/YYYY" type="date" />
          ))}
        </div>
      );

    case AVAILABLE_COMPONENTS.checkboxList:
      if (!filterData) {
        return null;
      }

      return <CheckboxList name={choice.source} options={filterData[choice.source]} />;
    case AVAILABLE_COMPONENTS.booleanInput: {
      if (!manualChoices) {
        return null;
      }

      return (
        <div className="flex gap-2">
          {manualChoices.map(({ value, label, choice: manualChoice }) => (
            <Checkbox key={label} label={label} name={manualChoice} value={value} />
          ))}
        </div>
      );
    }

    case AVAILABLE_COMPONENTS.horizontalCheckboxList:
      if (!filterData) {
        return null;
      }

      return <Grades name={choice.source} options={filterData[choice.source]} />;
    case AVAILABLE_COMPONENTS.multiSearchSelect:
      if (!filterData) {
        return null;
      }

      return <SearchSelect name={choice.source} options={filterData[choice.source]} isMulti />;
    case AVAILABLE_COMPONENTS.checkFactionChip:
      if (!filterData) {
        return null;
      }

      return <CheckFactionChip name={choice.source} options={choice.data} />;
    case AVAILABLE_COMPONENTS.checkSetChip:
      if (!filterData) {
        return null;
      }

      return <CheckSetChip name={choice.source} options={choice.data} />;
    case AVAILABLE_COMPONENTS.bigDivider:
      return <BigDivider size={SIZE.SM} />;
    default:
      return null;
  }
};

const createFilterComponents = (
  queryParams: QueryParametersType[],
  filterData?: FilterDataDto,
  currFilters?: ParsedQuery,
) =>
  queryParams.reduce((components, param) => {
    // check param configuration
    if (
      !param.choicesSource ||
      !param.isDisplay ||
      param.choicesSource.some((choice) => !(choice.component in AVAILABLE_COMPONENTS))
    ) {
      return components;
    }
    const title = param.displayTitle !== '' ? param.displayTitle : param.choicesSource[0].title;
    const node = param.choicesSource.map((choice) => (
      <>
        {choice.title !== title && <p className="sm-bold-caps text-neutral-900">{choice.title}</p>}
        <ControllerComponent
          key={choice.source}
          choice={choice}
          filterData={filterData}
          manualChoices={param.choices ? param.choices : undefined}
        />
      </>
    ));
    if (!param.withDisclosure) {
      return components.concat(
        <BaseComponent id={param.name} title={title}>
          {node}
        </BaseComponent>,
      );
    }
    const activeFilters = param.choicesSource.reduce((acc, choiceSource) => {
      if (!currFilters) {
        return acc;
      }
      const currValue = currFilters[choiceSource.source];
      if (!currValue) {
        return acc;
      }

      return Array.isArray(currValue) ? acc + currValue.length : acc + 1;
    }, 0);
    const Component = (
      <Disclosure activeFilters={activeFilters} id={param.name} title={title}>
        {node}
      </Disclosure>
    );

    return components.concat(Component);
  }, [] as ReactElement[]);

export default createFilterComponents;
